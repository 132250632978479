import { AppThunk } from '../rootStore';
import { AvailabilityInput } from '../../API';
import { userAvailabilitySelectors } from './selectors';
import { CLONE_NAME_POSTFIX } from './constants';
import { changeAvailabilityNameModalActions } from './modal';
import { UserDataInputCreatedAt } from '../global/types';

// TODO: rename to availabilityActionTypes
// TODO: remove from all types "USER_" part
export enum UserAvailabilityActionTypes {
  GET_USER_AVAILABILITY_REQUEST = 'userAvailability/GET_USER_AVAILABILITY_REQUEST',
  GET_USER_AVAILABILITY_SUCCESS = 'userAvailability/GET_USER_AVAILABILITY_SUCCESS',
  GET_USER_AVAILABILITY_FAIL = 'userAvailability/GET_USER_AVAILABILITY_FAIL',
  CREATE_USER_AVAILABILITY_REQUEST = 'userAvailability/CREATE_USER_AVAILABILITY_REQUEST',
  CREATE_USER_AVAILABILITY_SUCCESS = 'userAvailability/CREATE_USER_AVAILABILITY_SUCCESS',
  CREATE_USER_AVAILABILITY_FAIL = 'userAvailability/CREATE_USER_AVAILABILITY_FAIL',
  CLONE_USER_AVAILABILITY_REQUEST = 'userAvailability/CLONE_USER_AVAILABILITY_REQUEST',
  CLONE_USER_AVAILABILITY_SUCCESS = 'userAvailability/CLONE_USER_AVAILABILITY_SUCCESS',
  CLONE_USER_AVAILABILITY_FAIL = 'userAvailability/CLONE_USER_AVAILABILITY_FAIL',
  DELETE_USER_AVAILABILITY_REQUEST = 'userAvailability/DELETE_USER_AVAILABILITY_REQUEST',
  DELETE_USER_AVAILABILITY_SUCCESS = 'userAvailability/DELETE_USER_AVAILABILITY_SUCCESS',
  DELETE_USER_AVAILABILITY_FAIL = 'userAvailability/DELETE_USER_AVAILABILITY_FAIL',
  SAVE_USER_AVAILABILITY_REQUEST = 'userAvailability/SAVE_USER_AVAILABILITY_REQUEST',
  SAVE_USER_AVAILABILITY_SUCCESS = 'userAvailability/SAVE_USER_AVAILABILITY_SUCCESS',
  SAVE_USER_AVAILABILITY_FAIL = 'userAvailability/SAVE_USER_AVAILABILITY_FAIL',
  SET_USER_AVAILABILITY = 'userAvailability/SET_USER_AVAILABILITY',
  UPDATE_USER_AVAILABILITY = 'userAvailability/UPDATE_USER_AVAILABILITY',
  SET_CLONE_NAME = 'userAvailability/SET_CLONE_NAME',
  SET_DEFAULT_AVAILABILITY_REQUEST = 'userAvailability/SET_DEFAULT_AVAILABILITY_REQUEST',
  SET_DEFAULT_AVAILABILITY_SUCCESS = 'userAvailability/SET_DEFAULT_AVAILABILITY_SUCCESS',
  SET_DEFAULT_AVAILABILITY_FAIL = 'userAvailability/SET_DEFAULT_AVAILABILITY_FAIL',
}

// TODO: rename to availabilityAction
export type UserAvailabilityAction =
  | { type: UserAvailabilityActionTypes.GET_USER_AVAILABILITY_REQUEST }
  | { type: UserAvailabilityActionTypes.GET_USER_AVAILABILITY_SUCCESS; payload: UserDataInputCreatedAt[] }
  | { type: UserAvailabilityActionTypes.GET_USER_AVAILABILITY_FAIL; error: unknown }
  | { type: UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_REQUEST }
  | { type: UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_SUCCESS; payload: UserDataInputCreatedAt }
  | { type: UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_FAIL; error: unknown }
  | { type: UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_REQUEST }
  | { type: UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_SUCCESS; payload: UserDataInputCreatedAt }
  | { type: UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_FAIL; error: unknown }
  | { type: UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST; payload: string }
  | { type: UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_SUCCESS; payload: string }
  | { type: UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_FAIL; error: unknown }
  | { type: UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_REQUEST }
  | { type: UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_SUCCESS; payload: UserDataInputCreatedAt }
  | { type: UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_FAIL; error: unknown }
  | { type: UserAvailabilityActionTypes.SET_USER_AVAILABILITY; payload: UserDataInputCreatedAt }
  | { type: UserAvailabilityActionTypes.UPDATE_USER_AVAILABILITY; payload: Partial<AvailabilityInput> }
  | { type: UserAvailabilityActionTypes.SET_CLONE_NAME; payload: string }
  | { type: UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST; payload: boolean }
  | { type: UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_SUCCESS }
  | { type: UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL; error: unknown };

// TODO: remove from all actions "User" part
const getUserAvailabilityRequest = (): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.GET_USER_AVAILABILITY_REQUEST,
});

const getUserAvailabilitySuccess = (payload: UserDataInputCreatedAt[]): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.GET_USER_AVAILABILITY_SUCCESS,
  payload,
});

const getUserAvailabilityFail = (error: unknown): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.GET_USER_AVAILABILITY_FAIL,
  error,
});

const createUserAvailabilityRequest = (): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_REQUEST,
});

const createUserAvailabilitySuccess = (payload: UserDataInputCreatedAt): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_SUCCESS,
  payload,
});

const createUserAvailabilityFail = (error: unknown): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_FAIL,
  error,
});

const cloneUserAvailabilityRequest = (): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_REQUEST,
});

const cloneUserAvailabilitySuccess = (payload: UserDataInputCreatedAt): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_SUCCESS,
  payload,
});

const cloneUserAvailabilityFail = (error: unknown): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_FAIL,
  error,
});

const deleteUserAvailabilityRequest = (payload: string): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST,
  payload,
});

const deleteUserAvailabilitySuccess = (payload: string): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_SUCCESS,
  payload,
});

const deleteUserAvailabilityFail = (error: unknown): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_FAIL,
  error,
});

const saveUserAvailabilityRequest = (): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_REQUEST,
});

const saveUserAvailabilitySuccess = (payload: UserDataInputCreatedAt): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_SUCCESS,
  payload,
});

const saveUserAvailabilityFail = (error: unknown): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_FAIL,
  error,
});

const setUserAvailability = (payload: UserDataInputCreatedAt): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SET_USER_AVAILABILITY,
  payload,
});

const updateUserAvailability = (payload: Partial<AvailabilityInput>): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.UPDATE_USER_AVAILABILITY,
  payload,
});

const setCloneName = (payload: string): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SET_CLONE_NAME,
  payload,
});

const setDefaultAvailabilityRequest = (payload: boolean): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST,
  payload,
});

const setDefaultAvailabilitySuccess = (): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_SUCCESS,
});

const setDefaultAvailabilityFail = (error: unknown): UserAvailabilityAction => ({
  type: UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL,
  error,
});

// TODO: move to /thunks please
const cloneAvailabilityThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = userAvailabilitySelectors.selectName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(name));
  dispatch(changeAvailabilityNameModalActions.openModal());
};
const selectAvailabilityThunk =
  (link: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const availaility = userAvailabilitySelectors.selectAvailabilityByLink(link)(state);
    availaility && dispatch(setUserAvailability(availaility));
  };

const editAvailabilityNameThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = userAvailabilitySelectors.selectName(state) || '';
  dispatch(setCloneName(name));
  dispatch(changeAvailabilityNameModalActions.openModal());
};

// TODO: rename to availabilityActions
export const UserAvailabilityActions = {
  getUserAvailabilityRequest,
  getUserAvailabilitySuccess,
  getUserAvailabilityFail,
  createUserAvailabilityRequest,
  createUserAvailabilitySuccess,
  createUserAvailabilityFail,
  cloneUserAvailabilityRequest,
  cloneUserAvailabilitySuccess,
  cloneUserAvailabilityFail,
  deleteUserAvailabilityRequest,
  deleteUserAvailabilitySuccess,
  deleteUserAvailabilityFail,
  saveUserAvailabilityRequest,
  saveUserAvailabilitySuccess,
  saveUserAvailabilityFail,
  setUserAvailability,
  updateUserAvailability,
  setCloneName,
  setDefaultAvailabilityRequest,
  setDefaultAvailabilitySuccess,
  setDefaultAvailabilityFail,
  cloneAvailabilityThunk,
  selectAvailabilityThunk,
  editAvailabilityNameThunk,
};
