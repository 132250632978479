import { t } from '../../../i18n/i18n';

export default {
  acceptInvite: t('Common:ACCEPT_INVITE'),
  cancel: t('Common:CANCEL'),
  inviteFrom: t('DialogUserAccounts:INVITE_FROM'),
  inviteText: t('DialogUserAccounts:INVITE_TEXT'),
  lastSuperAdmin: t('DialogLeaveAccount:LAST_SUPER_ADMIN_NOTE'),
  onlyOneAccount: t('Common:ONLY_ONE_ACCOUNT'),
  selectText: t('DialogUserAccounts:SELECT_TEXT'),
  stayInMyAccount: t('DialogUserAccounts:STAY_IN_MY_ACCOUNT'),
  title: t('DialogUserAccounts:TITLE'),
};
