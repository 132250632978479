export const OPSConsoleUsers = Object.freeze({
  ACCOUNT_ID: `Konto-ID`,
  EXPORT: `Exportieren`,
  SEARCH_PLACEHOLDER: `Suchen nach Name oder E-Mail`,
  SUBHEADER_TEXT: `Aktivste Benutzer`,
  VIEW_AS_USER: `Als Benutzer anzeigen`,
  REGISTRATION_DATE: `1. Anmeldedatum`,
  RENEWAL_DATE: `Erneuerungsdatum`,
  USER_INFO_TITLE: `Benutzerinformationen`,
  LICENSE_STATUS_TITLE: `Lizenzstatus`,
  CUSTOM_ROLE: `Benutzerdefinierte Rolle`,
});

export const OPSConsoleUsersToastsNotifications = Object.freeze({
  ACTIVATE_USER_SUCCESS_MESSAGE: `Aktivierung der Benutzeranfrage erfolgreich`,
  DEACTIVATE_USER_FAIL_MESSAGE: `Deaktivierungs-/Aktivierungsanfrage für Benutzer fehlgeschlagen`,
  DEACTIVATE_USER_SUCCESS_MESSAGE: `Deaktivierung der Benutzeranfrage erfolgreich`,
  DELETE_USER_FAIL_MESSAGE: `Löschanfrage für Benutzer fehlgeschlagen`,
  DELETE_USER_SUCCESS_MESSAGE: `Löschanfrage für Benutzer erfolgreich`,
  GET_USER_DETAILS_FAIL_MESSAGE: `Abruf der Benutzerdetails fehlgeschlagen`,
  GET_USERS_FAIL_MESSAGE: `Benutzerabrufanfrage fehlgeschlagen`,
});

export const ViewAsUser = Object.freeze({
  BACK_TO_OPS_CONSOLE: `Zurück zu OPS-Konsole`,
  MODAL_DESCRIPTION_PART1: `Der Modus "Als Benutzer anzeigen" ist aktiv.`,
  MODAL_DESCRIPTION_PART2: `Um die OPS-Konsole weiterhin zu verwenden, beenden Sie bitte die Sitzung "Als Benutzer anzeigen", indem Sie eine der folgenden Optionen auswählen:`,
  MODAL_DESCRIPTION_PART3: `Klicken Sie auf die Schaltfläche "Zurück zu OPS-Konsole".`,
  MODAL_DESCRIPTION_PART4: `Drücken Sie die Option "Zurücksetzen" hier.`,
  VIEW_AS_USER_MODE: `Als Benutzer anzeigen Modus`,
  VIEWING_SUMO1_TITLE: `SUMO1 als Benutzer anzeigen.`,
});
