export const EditTeam = Object.freeze({
  AVAILABLE_FOR_USE: `Disponible à l'utilisation`,
  AVAILABLE_FOR_USE_TOOLTIP: `Ceci activera ou désactivera cette équipe dans tous les outils de réservation, y compris les pages de réservation, les modèles de réservation, et plus encore.`,
  EDIT_TEAM: `Modifier l'équipe`,
  MEMBERS_LABEL: `Ajouter des membres de l'équipe`,
  MEMBERS_PLACEHOLDER: `Rechercher et ajouter un membre de l'équipe`,
  ROUND_ROBIN_DESC_PART1: `Round-Robin`,
  ROUND_ROBIN_DESC_PART2: `Pour que SUMO1 attribue automatiquement un hôte à une réunion, vous devez créer une équipe (également appelée pool de ressources) d'utilisateurs SUMO1. Ensuite, les utilisateurs et les administrateurs peuvent ajouter des équipes aux réunions via les pages de réservation ou la boîte de réception SUMO (extension Chrome).`,
  TEAM_NAME: `Nom d'affichage`,
  TEAM_NAME_TOOLTIP: `Ce texte sera affiché aux invités sur la page de réservation publique. Par exemple : "Équipe d'ingénieurs commerciaux" ou "Équipe de dentistes" seraient de bons noms.`,
  TEAM_NAME_PLACEHOLDER: `Entrer un nom d'équipe`,
  WORKSPACES: `Espaces de travail`,
  WORKSPACES_LABEL: `Espace de travail par défaut`,
  WORKSPACES_PLACEHOLDER: `Rechercher et ajouter un espace de travail`,
  DEFAULT_WORKSPACE_TOOLTIP: `Espace de travail où l'équipe a été créée et peut être modifiée ou supprimée.`,
});

export const TeamsToastsNotifications = Object.freeze({
  ACTIVATE_TEAM_SUCCESS_MESSAGE: `Équipe activée avec succès`,
  DEACTIVATE_ACTIVATE_TEAM_ERROR_MESSAGE: `Échec de la désactivation/activation de l'équipe`,
  DEACTIVATE_TEAM_SUCCESS_MESSAGE: `Équipe désactivée avec succès`,
  DELETE_TEAM_ERROR_MESSAGE: `Échec de la suppression de l'équipe`,
  DELETE_TEAM_SUCCESS_MESSAGE: `Équipe supprimée avec succès`,
  GET_TEAMS_FAIL_MESSAGE: `Échec de la demande de récupération des équipes`,
  SAVE_TEAM_ERROR_MESSAGE: `Échec de la demande de sauvegarde de l'équipe`,
  SAVE_TEAM_SUCCESS_MESSAGE: `Équipe sauvegardée avec succès`,
});

export const Teams = Object.freeze({
  CREATE_TEAM: `Créer une équipe`,
  CREATED_BY: `Créé par`,
  CREATED_DATE: `Date de création`,
  DEACTIVATE_TEAM_DESCRIPTION: `Êtes-vous sûr de vouloir désactiver`,
  DEACTIVATE_TEAM_TITLE: `Désactiver l'équipe`,
  DELETE_TEAM_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  DELETE_TEAM_TITLE: `Supprimer l'équipe`,
  MEMBERS: `Membres`,
  NAME: `Nom`,
  TITLE: `Équipes`,
  TOOLTIP: `Les équipes sont importantes pour définir si vous voulez que SUMO attribue automatiquement un hôte ou un co-hôte à une réunion, en fonction de la logique d'attribution (Round-Robin, etc.).`,
  WORKSPACE: `Espace de travail`,
});
