import { t } from '../../../i18n/i18n';

export default {
  acceptTerms_step1: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART1'),
  acceptTerms_step2: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART2'),
  acceptTerms_step3: t('QuickSetupChromeExtensionStep:ACCEPT_TERMS_PART3'),
  back: t('Common:BACK'),
  benefitsDesc: t('QuickSetupChromeExtensionStep:BENEFITS_DESCRIPTION'),
  benefitsTitle: t('QuickSetupChromeExtensionStep:BENEFITS_TITLE'),
  benefitsList: [
    t('QuickSetupChromeExtensionStep:BENEFITS_LIST_PART1'),
    t('QuickSetupChromeExtensionStep:BENEFITS_LIST_PART2'),
    t('QuickSetupChromeExtensionStep:BENEFITS_LIST_PART3'),
  ],
  chromeExtension: t('QuickSetupChromeExtensionStep:CHROME_EXTENSION'),
  requirementsDesc: t('QuickSetupChromeExtensionStep:REQUIREMENTS_DESCRIPTION'),
  requirementsTitle: t('QuickSetupChromeExtensionStep:REQUIREMENTS_TITLE'),
  save: t('QuickSetupYourBrandStep:SAVE_BUTTON'),
  sumo1ForGoogle: t('QuickSetupChromeExtensionStep:SUMO1_FOR_GOOGLE'),
  sumo1ForMicrosoft: t('QuickSetupChromeExtensionStep:SUMO1_FOR_MICROSOFT'),
  title: t('QuickSetupChromeExtensionStep:TITLE'),
};
