// import { BookingTemplateDurationSettings, BookingTemplateDateRangeSettings, BookingTemplateBufferTimeSettings } from '..';

import { useSelector } from 'react-redux';
import { WhenStep } from '../../common/meeting/whenStep/WhenStep';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';

export const BookingTemplateWhenStep = () => {
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);
  return <WhenStep selectors={bookingTemplatesSelectors} actions={bookingTemplatesActions} isReadOnly={isReadOnly} />;
};
