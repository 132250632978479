export const Billing = Object.freeze({
  TITLE: `Faturamento`,
  YOUR_SUBSCRIPTION: `Sua Assinatura`,
  TEAMS_MONTHLY: `Equipes Mensal`,
  TEAMS_ANNUAL: `Equipes Anual`,
  SEATS: `assentos`,
  LAST_UPDATED: `Última atualização em`,
  CHANGE_TERM: `Alterar Prazo`,
  ADD_SEATS: `Adicionar Assentos`,
  REMOVE_SEATS: `Remover Assentos`,
  YOUR_NEXT_BILL: `Sua Próxima Fatura`,
  DUE_ON: `Vencimento em`,
  BILL_IS_LATE_PART_1: `A fatura está atualmente`,
  BILL_IS_LATE_PART_2_SINGLE: `dia atrasada.`,
  BILL_IS_LATE_PART_2_MULTIPLE: `dias atrasada.`,
  BILLING_DETAILS: `Detalhes de Faturamento`,
  CHARGEBEE_BUTTON: `Abrir Portal de Faturamento`,
  BILLING_PORTAL_DESRIPTION: `Você pode visualizar seu histórico de transações e mais.`,
  SCHEDULED_CHANGES: `Mudanças Agendadas para`,
  SCHEDULED_CHANGES_DESRIPTION: `As seguintes mudanças foram solicitadas para ocorrer no final do período atual e podem ser canceladas antes do final do período.`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reduzir`,
  SCHEDULED_SEATS_CHANGE_PART_2: `assentos`,
  SCHEDULED_TERM_CHANGE: `Prazo alterado para`,
  SCHEDULED_COLUMN_LABEL: `Ordem de Mudança`,
  SCHEDULED_COLUMN_CHANGE_BY: `Mudança por`,
  SCHEDULED_COLUMN_DATE: `Data da Solicitação`,
  SCHEDULED_COLUMN_OPTIONS: `Opções`,
  ADD_SEATS_DESCRIPTION_PART_1: `Adicionar`,
  ADD_SEATS_DESCRIPTION_PART_2: `assento(s) aumentará`,
  ADD_SEATS_DESCRIPTION_PART_3: `sua`,
  ADD_SEATS_DESCRIPTION_PART_4: `fatura, começando`,
  ADD_SEATS_PRORATED_PART_1: `Nos`,
  ADD_SEATS_PRORATED_PART_2: `dias restantes até seu próximo pagamento, será cobrado hoje um valor proporcional de`,
  ADD_SEATS_PRORATED_PART_3: `em seu cartão de crédito`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Remover`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `assento(s) reduzirá sua`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `fatura em`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `começando`,
  EXPIRE_MESSAGE_PART_1: `A fatura está`,
  EXPIRE_MESSAGE_PART_2: `dias atrasada. Sua organização ficará inoperante em`,
  EXPIRE_MESSAGE_PART_3: `dias.`,
  NUMBER_OF_LICENSES: `# Licenças`,
  LICENSES_TITLE: `licenças`,
  LICENSES_PLACEHOLDER: `Insira um número`,
  LICENSE_MESSAGE_PART_1: `Você já tem`,
  LICENSE_MESSAGE_PART_2: `licenças atribuídas a usuários. Por favor, insira mais`,
  LICENSE_MESSAGE_PART_3: `ou desative/exclua usuários para liberar licenças`,
  LOADING_LABEL_1: `Conectando…`,
  LOADING_LABEL_2: `Adicionando Licenças`,
  PAY_NOW_TITLE: `Pagar Agora`,
  TRIAL_LICENSE_EXPIRED: `Sua licença SUMO1 expirou. Para continuar usando o SUMO1 sem interrupções, por favor, adquira uma nova
  licença.`,
  BUY_LICENSES_MESSAGE: `Sua fatura será`,
  MONTH_TITLE: `mês`,
  MONTHLY_TITLE: `mensal`,
  ANNUAL_TITLE: `anual`,
  ANNUALLY_TITLE: `anualmente`,
  REMOVE_SEATS_NOTE_PART1: `Para remover mais de`,
  REMOVE_SEATS_NOTE_PART2: `assento(s), você pode desativar ou excluir usuários para liberar licenças.`,
  CANCELLATION_SCHEDULED: `Sua assinatura está agendada para ser cancelada em `,
  ACCOUNT_SUSPENDED_PART1: `Sua conta está suspensa. Você deve`,
  ACCOUNT_SUSPENDED_PART2: `pagar sua fatura pendente`,
  ACCOUNT_SUSPENDED_PART3: `para reativar o serviço.`,
});

export const BillingToastsNotifications = Object.freeze({
  GET_BILLING_ERROR_TOAST: `Erro de Conexão com ChargeBee`,
  GET_ITEM_TIERS_ERROR_TOAST: `Não foi possível obter os preços das licenças`,
  BUY_LICENSE_SUCCESS_TOAST: `Assinatura criada com sucesso`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Assentos adicionados.`,
  ADD_SEATS_SUCCESS_TOAST: `Por favor, aguarde até 1 minuto antes de recebermos o seu pagamento`,
  ADD_SEATS_ERROR_TOAST: `Não foi possível adicionar os assentos`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_1: `Reduzir`, 
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_2: `assentos`,
  REMOVE_SEATS_SUCCESS_TOAST: `Alterações programadas`,
  REMOVE_SEATS_ERROR_TOAST: `Não foi possível remover os assentos`,
  CHANGE_TERM_SUCCESS_TOAST: `Prazo alterado com sucesso.`,
  CHANGE_TERM_ERROR_TOAST: `Não foi possível alterar o prazo.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Por favor, aguarde até 1 minuto antes de recebermos se você fez alguma alteração, então reabra a página de faturamento novamente`,
  DELETE_CHANGE_SUCCESS_TOAST: `Alteração agendada excluída com sucesso.`,
  DELETE_CHANGE_ERROR_TOAST: `Não foi possível excluir a alteração agendada.`,
});
