import { NavigateFunction } from 'react-router-dom';

class NavigationService {
  private navigate: NavigateFunction | null = null;

  constructor() {
    this.navigateTo = this.navigateTo.bind(this);
  }

  setNavigate(navigateFunction: NavigateFunction) {
    this.navigate = navigateFunction;
  }

  navigateTo(path: string) {
    this.navigate && this.navigate(path);
  }
}

export const navigationService = new NavigationService();
