import {
  NavigationKey,
  NavigationState,
  NavigationSettings,
  navigationsActionsFactory,
  navigationsSelectorsFactory,
} from '../navigations';

export const QUICK_SETUP_NAVIGATION_MODULE_KEY: NavigationKey = 'quickSetup';

export enum QuickSetupStep {
  //LINK = 'LINK',
  CALENDAR_CONNECTED = 'CALENDAR_CONNECTED',
  AVAILABILITY = 'AVAILABILITY',
  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
  YOUR_BRAND = 'YOUR_BRAND',
  CHROME_EXTENSION = 'CHROME_EXTENSION',
  PHONE_CALLS = 'PHONE_CALLS',
}

const QUICK_SETUP_NAVIGATION_STATE_ADMIN: NavigationState = Object.freeze({
  currentStep: 0,
  steps: [
    { name: QuickSetupStep.CALENDAR_CONNECTED },
    { name: QuickSetupStep.AVAILABILITY },
    { name: QuickSetupStep.VIDEO_CONFERENCE },
    { name: QuickSetupStep.PHONE_CALLS },
    { name: QuickSetupStep.YOUR_BRAND },
    // { name: QuickSetupStep.CHROME_EXTENSION },
  ],
});

const QUICK_SETUP_NAVIGATION_STATE_STANDARD_USER: NavigationState = Object.freeze({
  currentStep: 0,
  steps: [
    { name: QuickSetupStep.CALENDAR_CONNECTED },
    { name: QuickSetupStep.AVAILABILITY },
    { name: QuickSetupStep.VIDEO_CONFERENCE },
    { name: QuickSetupStep.PHONE_CALLS },
    // { name: QuickSetupStep.CHROME_EXTENSION },
  ],
});

export const QUICK_SETUP_NAVIGATION_SETTINGS_ADMIN: NavigationSettings = Object.freeze({
  key: QUICK_SETUP_NAVIGATION_MODULE_KEY,
  state: QUICK_SETUP_NAVIGATION_STATE_ADMIN,
});

export const QUICK_SETUP_NAVIGATION_SETTINGS_STANDARD_USER: NavigationSettings = Object.freeze({
  key: QUICK_SETUP_NAVIGATION_MODULE_KEY,
  state: QUICK_SETUP_NAVIGATION_STATE_STANDARD_USER,
});

export const quickSetupNavigationActions = navigationsActionsFactory(QUICK_SETUP_NAVIGATION_MODULE_KEY);
export const quickSetupNavigationSelectors = navigationsSelectorsFactory(QUICK_SETUP_NAVIGATION_MODULE_KEY);
