import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';
import { Preloader } from '../../common';
import {
  PublicBookingPageBookedInfo,
  PublicBookingPageCancel,
  PublicBookingPageDateTime,
  PublicBookingPageError,
  PublicBookingPageHeader,
  PublicBookingPageJourneyPage,
  PublicBookingPageLocations,
  PublicBookingPageMeetingDetails,
  PublicBookingPagePotentialHosts,
  PublicBookingPageYourInformation,
  PublicGroupBookingPage,
} from '..';
import { eventSelectors, eventActions } from '../../../store/publicBookingPage';
import { BackgroundType, CreateJourneyInput, UpdateBookingPageInput, UpdateUserEventInput } from '../../../API';
import { timeHandlerService } from '../../../services/TimeHandlerService';
import { ReactComponent as SumoIcon } from '../../../assets/images/sumoIcon.svg';
import { ReactComponent as SumoIconWhite } from '../../../assets/images/sumoIconWhite.svg';
import { PublicBookingPageJourneyDestination } from '../publicBookingPageJourneyDestination/PublicBookingPageJourneyDestination';
import { getBackgroundTypeClassName } from '../../../store/publicBookingPage/utils';
// import labels from './labels';

interface IPublicBookingPageContainer {
  previewJourney?: CreateJourneyInput;
  previewBookingPage?: UpdateBookingPageInput;
  rescheduleEvent?: UpdateUserEventInput;
  isReadOnly?: boolean;
}

export const PublicBookingPageContainer = ({
  previewJourney,
  previewBookingPage,
  rescheduleEvent,
  isReadOnly,
}: IPublicBookingPageContainer) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(eventSelectors.selectIsFetching);
  const showError = useSelector(eventSelectors.selectShowError);
  const showBookingState = useSelector(eventSelectors.selectShowBookingState);
  const showBookedState = useSelector(eventSelectors.selectShowBookedState);
  const showPotentialHosts = useSelector(eventSelectors.selectShowPotentialHosts);
  const showDateTime = useSelector(eventSelectors.selectShowDateTime);
  const showYourInfo = useSelector(eventSelectors.selectShowYourInfo);
  const showCancel = useSelector(eventSelectors.selectShowCancel);
  const showFooter = useSelector(eventSelectors.selectShowFooter);
  const showButtons = useSelector(eventSelectors.selectShowButtons);
  const backgroundImage = useSelector(eventSelectors.selectBackground);
  const backgroundColor = useSelector(eventSelectors.selectBackgroundColor);
  const backgroundType = useSelector(eventSelectors.selectBackgroundType);
  const primaryColor = useSelector(eventSelectors.selectPrimaryColor);
  const css = useSelector(eventSelectors.selectCss);
  const footerHtml = useSelector(eventSelectors.selectFooterHtml);
  const isHideHeader = useSelector(eventSelectors.selectIsHideHeader);
  // const isHideCookie = useSelector(eventSelectors.selectIsHideCookie);
  const isIframe = useSelector(eventSelectors.selectIsIframe);
  const step = useSelector(eventSelectors.selectStep);
  const showGroup = useSelector(eventSelectors.selectShowGroupState);
  const showLocations = useSelector(eventSelectors.selectShowLocations);
  const showJourneyState = useSelector(eventSelectors.selectShowJourneyState);
  const showJourneyPageStep = useSelector(eventSelectors.selectShowJourneyPageStep);
  const showJourneyDestinationStep = useSelector(eventSelectors.selectShowJourneyDestinationStep);
  const pageName = useSelector(eventSelectors.selectPageName);
  const googleAnalytics = useSelector(eventSelectors.selectGoogleAnalytics);

  const [isInitializedGoogleAnalytics, setInitializedGoogleAnalytics] = useState(false);

  useEffect(() => {
    if (googleAnalytics && !isFetching && !isInitializedGoogleAnalytics) {
      const trackingIds: string[] = [];

      if (googleAnalytics.trackingId) trackingIds.push(googleAnalytics.trackingId);
      if (googleAnalytics.measurementId) trackingIds.push(googleAnalytics.measurementId);

      ReactGA.initialize(
        trackingIds.map<InitOptions>((trackingId) => {
          return {
            trackingId,
            gaOptions: {
              send_page_view: false,
            },
          };
        })
      );
      setInitializedGoogleAnalytics(true);

      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: `Booking page: ${pageName}`,
      });
    }
  }, [googleAnalytics, isFetching]);

  useEffect(() => {
    if (rescheduleEvent) {
      dispatch(eventActions.setRescheduleData({ event: rescheduleEvent, bookingPage: previewBookingPage }));
      dispatch(eventActions.getAgendaRequest());
    } else if (previewJourney || previewBookingPage) {
      dispatch(eventActions.setPreviewData({ journey: previewJourney, bookingPage: previewBookingPage }));
      dispatch(eventActions.setReadOnly(!!isReadOnly));
    }

    // define isMobile based on initial window width, 768px corresponds to md: breakpoint
    if (window.innerWidth < 768) {
      dispatch(eventActions.setIsMobile());
    }

    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  // const handleCookieBanner = () => {
  //   dispatch(authenticationActions.setCookieOpen(true));
  // };

  const bookingPageStyles = `:root {
    --booking-page-color: ${primaryColor};
  }
  `;

  return (
    <div className={`booking-container ${getBackgroundTypeClassName(backgroundType)}`}>
      <div
        className="booking-background"
        style={
          backgroundType === BackgroundType.BLANK
            ? undefined
            : backgroundType === BackgroundType.COLOR
            ? { backgroundColor: `${backgroundColor}` }
            : { backgroundImage: `url(${backgroundImage})` }
        }
      ></div>
      <div className="booking-card-container">
        <div className={`booking-card ${isIframe && !rescheduleEvent ? 'card-iframe' : ''}`}>
          <style dangerouslySetInnerHTML={{ __html: bookingPageStyles + (css || '') }} />

          {isFetching && <Preloader />}

          {!isHideHeader && <PublicBookingPageHeader />}

          {showError && <PublicBookingPageError />}

          {showJourneyState && (
            <div className="journey-content">
              {showJourneyPageStep && <PublicBookingPageJourneyPage />}
              {showJourneyDestinationStep && <PublicBookingPageJourneyDestination />}
            </div>
          )}

          {showGroup && <PublicGroupBookingPage />}

          {showPotentialHosts && <PublicBookingPagePotentialHosts />}

          {showLocations && (
            <div className="locations-content">
              <PublicBookingPageLocations />
            </div>
          )}

          {showBookingState && (
            <div className="booking-content">
              <PublicBookingPageMeetingDetails />
              {showDateTime && <PublicBookingPageDateTime />}
              {showYourInfo && <PublicBookingPageYourInformation />}
              {showCancel && <PublicBookingPageCancel />}
              {showButtons && <div className="footer-space"></div>}
            </div>
          )}

          {showBookedState && (
            <div className="booked-content">
              <PublicBookingPageBookedInfo />
              {showButtons && <div className="footer-space"></div>}
            </div>
          )}
        </div>

        {showFooter && (
          <div className="booking-footer">
            <div className="flex-center flex-column gap-8px">
              {backgroundType === BackgroundType.FULL || backgroundType === BackgroundType.COLOR ? (
                <SumoIconWhite />
              ) : (
                <SumoIcon />
              )}
              {footerHtml && (
                <div className="text-label-s-med" dangerouslySetInnerHTML={{ __html: footerHtml || '' }} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PublicBookingPageContainer.displayName = 'PublicBookingPageContainer';
