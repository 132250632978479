import { t } from '../../../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  extend: t('OrgExtendTrialModal:EXTEND_TITLE'),
  extendDesc: t('OrgExtendTrialModal:EXTEND_DESCRIPTION'),
  extendMessage: t('OrgExtendTrialModal:EXTEND_MESSAGE'),
  saveExtend: t('OrgExtendTrialModal:SAVE_AND_EXTEND'),
  rules: t('OrgExtendTrialModal:RULES_TITLE'),
  rulesDesc: [
    t('OrgExtendTrialModal:RULES_DESCRIPTION_PART1'),
    t('OrgExtendTrialModal:RULES_DESCRIPTION_PART2'),
    t('OrgExtendTrialModal:RULES_DESCRIPTION_PART3'),
    t('OrgExtendTrialModal:RULES_DESCRIPTION_PART4'),
  ],
  title: t('OrgExtendTrialModal:TITLE'),
};
