import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Path } from '../../routing';
import { AppDispatch } from '../../store/rootStore';
import {
  deleteBookingTemplatesModalActions,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  AccordionIndexes,
} from '../../store/bookingTemplates';
import {
  BookingTemplateAdditionalRulesStep,
  BookingTemplateWhatStep,
  BookingTemplateWhoStep,
  BookingTemplateWhereStep,
  BookingTemplateWhenStep,
  BookingTemplateNotificationsStep,
  BookingTemplateConfirmationStep,
  BookingTemplateCloneModal,
  BookingTemplateDeleteModal,
  BookingTemplateHowStep,
} from '../../components/bookingTemplates';
import { ConfirmationModal } from '../../components/common';
import labels from './labels';

import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { navigationService } from '../../services/NavigationService';
import { userSettingsSelectors } from '../../store/userSettings';
import { LocationType } from '../../API';
import { smartAlertsSelectors } from '../../store/smartAlerts';
import {
  ArrowLeftIcon,
  BoltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { InputSwitch } from 'primereact/inputswitch';
import { ProgressSpinner } from 'primereact/progressspinner';

export const EditBookingTemplate = () => {
  const { bookingTemplateId: bookingTemplateIdParam } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const bookingTemplate = useSelector(bookingTemplatesSelectors.selectBookingTemplate);
  const isFetching = useSelector(bookingTemplatesSelectors.selectIsFetching);
  const isFetchingSmartAlerts = useSelector(smartAlertsSelectors.selectIsFetching);
  const isEdited = useSelector(bookingTemplatesSelectors.selectIsEdited);
  const isExisting = useSelector(bookingTemplatesSelectors.selectIsExisting);
  const isActive = useSelector(bookingTemplatesSelectors.selectIsActive);
  const bookingTemplateName = useSelector(bookingTemplatesSelectors.selectWhatName);
  const bookingTemplateColor = useSelector(bookingTemplatesSelectors.selectWhatColor);
  const isWhatStepValid = useSelector(bookingTemplatesSelectors.selectIsWhatStepValid);
  const isWhoStepValid = useSelector(bookingTemplatesSelectors.selectIsWhoStepValid);
  const isWhereStepValid = useSelector(bookingTemplatesSelectors.selectIsWhereStepValid);
  const isWhenStepValid = useSelector(bookingTemplatesSelectors.selectIsWhenStepValid);
  const isNotificationsStepValid = useSelector(bookingTemplatesSelectors.selectIsNotificationsStepValid);
  const isConfirmationStepValid = useSelector(bookingTemplatesSelectors.selectIsConfirmationStepValid);
  const isBookingTemplatesCreate = useSelector(userSettingsSelectors.selectBookingTemplatesCreate);
  const isBookingTemplatesEdit = useSelector(bookingTemplatesSelectors.selectIsEditBookingTemplate);
  const isBookingTemplatesDelete = useSelector(userSettingsSelectors.selectBookingTemplatesDelete);
  const lockedTooltip = useSelector(bookingTemplatesSelectors.selectLockedTooltip);
  const hostsInfo = useSelector(bookingTemplatesSelectors.selectHostsInfo);
  const isNoHost = useSelector(bookingTemplatesSelectors.selectIsNoHost);

  const accordionIndexes = useSelector(bookingTemplatesSelectors.selectAccordionIndexes);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    if (bookingTemplateIdParam && bookingTemplateIdParam !== 'new') {
      dispatch(bookingTemplatesActions.getBookingTemplateRequest(bookingTemplateIdParam));
    } else {
      dispatch(bookingTemplatesActions.getBookingTemplateRequest(''));
    }
  }, []);

  const handleActivate = () => {
    dispatch(
      bookingTemplatesActions.enableBookingTemplateRequest({
        ...bookingTemplate,
        enabled: !isActive,
      })
    );
  };

  const handleClone = () => {
    dispatch(bookingTemplatesActions.cloneBookingTemplateThunk());
  };

  const handleDelete = () => {
    dispatch(bookingTemplatesActions.selectBookingTemplate(bookingTemplate.id));
    dispatch(deleteBookingTemplatesModalActions.openModal());
  };

  const menuItems = [
    {
      label: isActive ? labels.deactivate : labels.activate,
      command: handleActivate,
      visible: isBookingTemplatesEdit && !isNoHost,
    },
    { label: labels.clone, command: handleClone, visible: isBookingTemplatesCreate && !isNoHost },
    { label: labels.delete, command: handleDelete, visible: isBookingTemplatesDelete },
  ];

  const handleActiveChange = (value: boolean) => {
    dispatch(bookingTemplatesActions.updateRecord({ enabled: value }));
  };

  const handleAccordionIndexChange = (value: Partial<AccordionIndexes>) => {
    dispatch(bookingTemplatesActions.updateAccordionIndexes(value));
  };

  const handleSave = () => {
    if (!isWhatStepValid) {
      handleAccordionIndexChange({ what: true });
      return;
    }
    if (!isWhoStepValid) {
      handleAccordionIndexChange({ who: true });
      return;
    }
    if (!isWhereStepValid) {
      handleAccordionIndexChange({ where: true });
      return;
    }
    if (!isWhenStepValid) {
      handleAccordionIndexChange({ when: true });
      return;
    }
    if (!isNotificationsStepValid) {
      handleAccordionIndexChange({ alerts: true });
      return;
    }
    if (!isConfirmationStepValid) {
      handleAccordionIndexChange({ after: true });
      return;
    }

    isExisting
      ? dispatch(bookingTemplatesActions.saveBookingTemplateRequest())
      : dispatch(bookingTemplatesActions.createBookingTemplateRequest());
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // for no close the accordion
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleBack();
    }
  };

  const handleBack = () => {
    navigationService.navigateTo(Path.BookingTemplates);
    dispatch(bookingTemplatesActions.clearBookingTemplate());
  };

  const generateLocationTypeIcon = (locationType: LocationType | null) => {
    switch (locationType) {
      case LocationType.IN_PERSON:
        return <UserIcon className="icon-16px" />;
      case LocationType.PHONE_CALL:
        return <PhoneIcon className="icon-16px" />;
      case LocationType.VIDEO_CONFERENCE:
        return <VideoCameraIcon className="icon-16px" />;
      default:
        return <></>;
    }
  };

  const generateHeader = () => (
    <div className="sumo-card border-none">
      <div className="flex-left-center gap-6px px-20px py-16px">
        {!isBookingTemplatesEdit && (
          <>
            <Tooltip target=".locked" />
            <LockClosedIcon
              className="icon-16px text-heavy-60 locked"
              data-pr-tooltip={lockedTooltip}
              data-pr-position="top"
            />
          </>
        )}
        <div className="text-title-lg-med">{isExisting ? bookingTemplateName : labels.newBookingTemplate}</div>

        {isFetching || isFetchingSmartAlerts ? (
          <ProgressSpinner className="w-32px h-32px m-0" strokeWidth="5" />
        ) : (
          (isBookingTemplatesCreate || isBookingTemplatesEdit || isBookingTemplatesDelete) && (
            <div className="action-button" onClick={(e) => menu.current?.toggle(e)}>
              <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
              <EllipsisVerticalIcon className="icon-20px" />
            </div>
          )
        )}
      </div>
      <div className="flex-between pt-12px pb-18px px-20px border-top-1 border-heavy-20">
        <div className="flex gap-4px">
          <div className="p-8px bg-heavy-1 border-radius-6px">
            <div className="w-16px h-16px border-radius-4px" style={{ backgroundColor: bookingTemplateColor || '' }} />
          </div>
          {bookingTemplate.where?.locationTypes?.map((locationType) => (
            <div key={locationType} className="flex-center w-32px h-32px bg-heavy-1 border-radius-6px text-heavy-60">
              {generateLocationTypeIcon(locationType)}
            </div>
          ))}
          {hostsInfo && (
            <div className="text-label-s-med text-heavy-80 bg-heavy-1 border-radius-6px py-10px px-8px ml-8px">
              {hostsInfo}
            </div>
          )}
        </div>
        <div className="flex align-items-center gap-10px">
          <InputSwitch
            checked={!!isActive}
            onChange={(e) => handleActiveChange(!!e.value)}
            disabled={!isBookingTemplatesEdit || isNoHost}
          />
          <div className="text-label-lg-med">{labels.active}</div>
        </div>
      </div>
    </div>
  );
  const generateAccordion = (
    title: string,
    description: string,
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    content: React.ReactNode
  ) => (
    <Accordion
      className="sumo-card border-none"
      activeIndex={isOpen ? 0 : null}
      onTabChange={(e) => setIsOpen(e.index === 0)}
    >
      <AccordionTab
        header={
          <div className="flex-left-center px-20px py-8px">
            <div className="flex-1 flex flex-column pb-4px">
              <div className="text-title-s-med text-heavy-100">{title}</div>
              <div className="text-body-s-reg">{description}</div>
            </div>
            <div className="flex-none">
              {isOpen ? <ChevronUpIcon className="icon-20px" /> : <ChevronDownIcon className="icon-20px" />}
            </div>
          </div>
        }
      >
        <div className="px-20px pt-12px pb-24px">{content}</div>
        <div className="border-top-1 border-heavy-20" />
        <div className="flex-left-center gap-6px px-20px py-12px">
          <Button
            className="min-w-90px"
            label={labels.save}
            onClick={handleSave}
            disabled={!isBookingTemplatesEdit || isFetching || isFetchingSmartAlerts}
          />
          <Button label={labels.cancel} text onClick={handleCancel} />
        </div>
      </AccordionTab>
    </Accordion>
  );

  return (
    <>
      <BookingTemplateCloneModal />
      <BookingTemplateDeleteModal />
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        confirmButtonProps={{ className: 'min-w-120px' }}
        onConfirm={handleBack}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <Button className="button-blue button-text-line mb-16px" text onClick={handleCancel}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column gap-16px">
        {generateHeader()}

        {generateAccordion(
          labels.whatTitle,
          labels.whatDescription,
          accordionIndexes.what,
          (isOpen) => handleAccordionIndexChange({ what: isOpen }),
          <BookingTemplateWhatStep />
        )}
        {generateAccordion(
          labels.whoTitle,
          labels.whoDescription,
          accordionIndexes.who,
          (isOpen) => handleAccordionIndexChange({ who: isOpen }),
          <BookingTemplateWhoStep />
        )}
        {generateAccordion(
          labels.whereTitle,
          labels.whereDescription,
          accordionIndexes.where,
          (isOpen) => handleAccordionIndexChange({ where: isOpen }),
          <BookingTemplateWhereStep />
        )}
        {generateAccordion(
          labels.whenTitle,
          labels.whenDescription,
          accordionIndexes.when,
          (isOpen) => handleAccordionIndexChange({ when: isOpen }),
          <BookingTemplateWhenStep />
        )}

        <div className="flex-left-center gap-6px text-heavy-60 pt-24px">
          <BoltIcon className="icon-20px" />
          <div className="text-label-s-med">{labels.additionalConfigurations}</div>
        </div>

        {generateAccordion(
          labels.howTitle,
          labels.howDescription,
          accordionIndexes.how,
          (isOpen) => handleAccordionIndexChange({ how: isOpen }),
          <BookingTemplateHowStep />
        )}
        {generateAccordion(
          labels.alertsTitle,
          labels.alertsDescription,
          accordionIndexes.alerts,
          (isOpen) => handleAccordionIndexChange({ alerts: isOpen }),
          <BookingTemplateNotificationsStep />
        )}
        {generateAccordion(
          labels.inviteeTitle,
          labels.inviteeDescription,
          accordionIndexes.invitee,
          (isOpen) => handleAccordionIndexChange({ invitee: isOpen }),
          <BookingTemplateAdditionalRulesStep />
        )}
        {generateAccordion(
          labels.afterTitle,
          labels.afterDescription,
          accordionIndexes.after,
          (isOpen) => handleAccordionIndexChange({ after: isOpen }),
          <BookingTemplateConfirmationStep />
        )}
      </div>
    </>
  );
};
