import { useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { ConfirmationStep } from '../../common/meeting/confirmationStep/ConfirmationStep';

export const BookingTemplateConfirmationStep = () => {
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);

  return (
    <ConfirmationStep selectors={bookingTemplatesSelectors} actions={bookingTemplatesActions} isReadOnly={isReadOnly} />
  );
};
