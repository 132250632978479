import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../../store/bookingTemplates';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import labels from './labels';
import { BookingPagePreviewModal } from '../../../bookingPages';
import { UpdateBookingPageInput } from '../../../../API';
import { CalendarModal } from '../calendarModal/CalendarModal';
import { CalendarDaysIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

type HowStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly?: boolean;
};

export const HowStep = ({ selectors, actions, isReadOnly }: HowStepProps) => {
  const dispatch = useDispatch();
  const bookingPage = useSelector(selectors.selectBookingPageForPreview);
  const isBookingPage = useSelector(selectors.selectIsBookingPageSelector);

  const [brandingOpened, setBrandingOpened] = useState(false);
  const [calendarOpened, setCalendarOpened] = useState(false);

  const handleSaveBranding = (bookingPage?: UpdateBookingPageInput) => {
    if (bookingPage) {
      dispatch(actions.updateStyleStep({ ...bookingPage.style }));
      dispatch(actions.updateHowStep({ ...bookingPage.labels }));
    }
    setBrandingOpened(false);
  };

  const generateModalSection = (
    icon: ReactNode,
    title: string,
    description: string,
    openFunction: Dispatch<SetStateAction<boolean>>
  ) => (
    <div className="flex-left-center gap-10px">
      <div
        className="flex-none flex-center w-70px h-70px border-radius-8px bg-heavy-1 text-heavy-60 hover-text-heavy-80 cursor-pointer"
        onClick={() => !isReadOnly && openFunction(true)}
      >
        {icon}
      </div>
      <div className="flex flex-column">
        <div className="text-title-xs-med text-heavy-100">{title}</div>
        <div className="text-body-s-reg text-heavy-60 mt-4px mb-10px">{description}</div>
        <Button
          className="button-blue button-text-line w-fit"
          text
          label={labels.customize}
          onClick={() => openFunction(true)}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );

  return (
    <>
      {!isReadOnly && (
        <>
          <BookingPagePreviewModal
            visible={brandingOpened}
            isBookingTemplate={!isBookingPage}
            bookingPage={bookingPage}
            onSave={handleSaveBranding}
            onClose={() => setBrandingOpened(false)}
          />
          <CalendarModal
            visible={calendarOpened}
            selectors={selectors}
            actions={actions}
            isReadOnly={isReadOnly}
            onClose={() => setCalendarOpened(false)}
          />
        </>
      )}

      <div className="flex flex-column gap-20px">
        {generateModalSection(
          <PencilSquareIcon className="icon-24px" />,
          labels.brandingTitle,
          isBookingPage ? labels.brandingDescriptionBookingPage : labels.brandingDescriptionBookingTemplate,
          setBrandingOpened
        )}
        {generateModalSection(
          <CalendarDaysIcon className="icon-24px" />,
          labels.calendarTitle,
          labels.calendarDescription,
          setCalendarOpened
        )}
      </div>
    </>
  );
};
