import dayjs from 'dayjs';
import {
  BackgroundType,
  BookingPageStyleInput,
  IntegrationType,
  MemberInput,
  MemberType,
  TimeFormat,
  UpdateBookingPageInput,
} from '../../API';
import { timeZoneItem } from '../../services/TimeZoneService';
import { UserAdminDataFullRecord } from '../users/types';
import { TeamRecord } from '../teams';
import { BackgroundOption } from './types';
import { FILE_COPY_POSTFIX } from '../../types/constants';
import { HostOption, MemberSubType } from '../../types/types';
import { t } from '../../i18n/i18n';

export const generatePublicFileKey = (filePath: string | null | undefined) =>
  filePath ? filePath.substring(filePath.lastIndexOf('public/') + 7) : '';

export const generatePublicFileKeyCopy = (filePath: string | null | undefined) => {
  const fileName = generatePublicFileKey(filePath);
  if (!fileName) {
    return '';
  }
  return fileName.indexOf('.') ? fileName.replace('.', FILE_COPY_POSTFIX + '.') : fileName + FILE_COPY_POSTFIX;
};

export const assignTime = (timeZoneList: timeZoneItem[], UTC: number, timeFormat: TimeFormat | null | undefined) =>
  timeZoneList.map((timeZone) => ({
    ...timeZone,
    value: timeZone.timeZone,
    time: dayjs(UTC)
      .tz(timeZone.timeZone)
      .format(timeFormat !== TimeFormat.HOUR_12 ? 'HH:mm' : 'hh:mm A'),
  }));

export const getWhoData = (
  users: UserAdminDataFullRecord[],
  teams: TeamRecord[],
  records: Array<MemberInput | null>
) => {
  const hostData: HostOption[] = [];
  for (const record of records) {
    if (record?.type === MemberType.SUMO1 && record?.subType === MemberSubType.USER) {
      const user = users.find((user) => user.userId === record?.id);
      if (user) {
        hostData.push({
          value: user.userId || '',
          label: user.fullName || user.email,
          email: user.email,
          avatar: user.avatar,
          isTeam: false,
          hasPhoneNumber: Boolean(user.phoneNumber),
          integrationTypes: user.integrationTypes || [],
          defaultVideoIntegration: user.defaultVideoIntegration,
        });
      }
    } else if (record?.type === MemberType.SUMO1 && record?.subType === MemberSubType.TEAM) {
      const team = teams.find((team) => team.id === record?.id);
      if (team) {
        let hasPhoneNumber = false;
        const integrationTypes = new Set<IntegrationType>();
        team.members?.forEach((member) => {
          const user = users.find((user) => user.userId === member?.userId);
          if (user) {
            hasPhoneNumber = hasPhoneNumber || Boolean(user.phoneNumber);
            user.integrationTypes?.forEach((integration) => integrationTypes.add(integration));
          }
        });
        hostData.push({
          value: team.id || '',
          label: team.name || '',
          isTeam: true,
          hasPhoneNumber,
          integrationTypes: Array.from(integrationTypes.values()),
        });
      }
    }
  }
  return hostData;
};

export const isStylesEqual = (
  style1: BookingPageStyleInput | null | undefined,
  style2: BookingPageStyleInput | null | undefined
) => {
  return (
    (style1?.logoImage || '') == (style2?.logoImage || '') &&
    (style1?.backgroundImage || '') == (style2?.backgroundImage || '') &&
    (style1?.backgroundType || '') == (style2?.backgroundType || '') &&
    (style1?.css || '') == (style2?.css || '') &&
    (style1?.footerHtml || '') == (style2?.footerHtml || '') &&
    (style1?.primaryColor || '') == (style2?.primaryColor || '') &&
    (style1?.backgroundColor || '') == (style2?.backgroundColor || '') &&
    (style1?.fontColor || '') == (style2?.fontColor || '') &&
    (style1?.fontFamily || '') == (style2?.fontFamily || '')
  );
};

export const convertBackgroundTypeToOption = (type: BackgroundType | null | undefined) => {
  switch (type) {
    case BackgroundType.BLANK:
      return BackgroundOption.BLANK;
    case BackgroundType.COLOR:
      return BackgroundOption.COLOR;
    case BackgroundType.LEFT:
      return BackgroundOption.WALLPAPER;
    case BackgroundType.RIGHT:
      return BackgroundOption.WALLPAPER;
    case BackgroundType.TOP:
      return BackgroundOption.WALLPAPER;
    case BackgroundType.FULL:
    default:
      return BackgroundOption.WALLPAPER;
  }
};

export const convertBackgroundOptionToType = (option: BackgroundOption) => {
  switch (option) {
    case BackgroundOption.BLANK:
      return BackgroundType.BLANK;
    case BackgroundOption.COLOR:
      return BackgroundType.COLOR;
    case BackgroundOption.WALLPAPER:
    default:
      return BackgroundType.TOP;
  }
};

export const getBackgroundLabelbyType = (type: BackgroundType) => {
  switch (type) {
    case BackgroundType.TOP:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_TOP');
    case BackgroundType.FULL:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_FULL');
    case BackgroundType.LEFT:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_LEFT');
    case BackgroundType.RIGHT:
      return t('BookingPageBrandingModal:BACKGROUND_TYPE_RIGHT');
    default:
      return '';
  }
};

export const canDeleteBookingPage = (
  bookingPage: UpdateBookingPageInput,
  userId: string,
  isMyDelete: boolean,
  isAllDelete: boolean,
  isAdmin: boolean
) => {
  const isExisting = !!bookingPage;
  const isMyBookingPage = bookingPage?.who?.hostMembers?.[0]?.id === userId;
  const isAdminLocked = bookingPage?.adminOnly && !isAdmin;
  return isExisting && !isAdminLocked && (isMyBookingPage ? isMyDelete : isAllDelete);
};
