import { combineReducers } from 'redux';
import { workspacesActionTypes, WorkspacesAction } from './actions';
import { WorkspaceInput } from '../../API';
import { DEFAULT_WORKSPACE, EMPTY_ACCORDION_INDEXES } from './constants';
import { AccordionIndexes, WorkspaceData } from './types';
import { GlobalAction, GlobalActionTypes } from '../global/actions';

const workspace = (state: WorkspaceData = {} as WorkspaceData, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.SET_WORKSPACE:
      return action.payload;
    case workspacesActionTypes.GET_WORKSPACE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case workspacesActionTypes.UPDATE_WORKSPACE:
      return { ...state, ...action.payload };
    case workspacesActionTypes.CREATE_WORKSPACE_SUCCESS:
      return DEFAULT_WORKSPACE;
    case workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.DELETE_WORKSPACES_SUCCESS:
      return {} as WorkspaceData;
    case workspacesActionTypes.UPDATE_WORKSPACE_STYLE:
      return {
        ...state,
        style: { ...state.style, ...action.payload },
      };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as WorkspaceData;
    default:
      return state;
  }
};

const workspaces = (state: WorkspaceInput[] = [], action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.GET_WORKSPACES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const cloneName = (state = '', action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.SET_CLONE_NAME:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const accordionIndexes = (
  state: AccordionIndexes = EMPTY_ACCORDION_INDEXES,
  action: WorkspacesAction | GlobalAction
) => {
  switch (action.type) {
    case workspacesActionTypes.UPDATE_ACCORDION_INDEXES:
      return { ...EMPTY_ACCORDION_INDEXES, ...action.payload };
    case workspacesActionTypes.SET_WORKSPACE:
    case workspacesActionTypes.GET_WORKSPACE_REQUEST:
      return { ...EMPTY_ACCORDION_INDEXES, ...{ what: true } };
    case workspacesActionTypes.GET_WORKSPACES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return EMPTY_ACCORDION_INDEXES;
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.GET_WORKSPACES_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isFetching = (state = false, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.GET_WORKSPACES_REQUEST:
    case workspacesActionTypes.GET_WORKSPACE_REQUEST:
    case workspacesActionTypes.SAVE_WORKSPACE_REQUEST:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST:
    case workspacesActionTypes.DELETE_WORKSPACES_REQUEST:
    case workspacesActionTypes.CLONE_WORKSPACE_REQUEST:
    case workspacesActionTypes.REMOVE_INTEGRATION_REQUEST:
      return true;
    case workspacesActionTypes.GET_WORKSPACES_SUCCESS:
    case workspacesActionTypes.GET_WORKSPACES_FAIL:
    case workspacesActionTypes.GET_WORKSPACE_SUCCESS:
    case workspacesActionTypes.GET_WORKSPACE_FAIL:
    case workspacesActionTypes.SAVE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.SAVE_WORKSPACE_FAIL:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_FAIL:
    case workspacesActionTypes.DELETE_WORKSPACES_SUCCESS:
    case workspacesActionTypes.DELETE_WORKSPACES_FAIL:
    case workspacesActionTypes.CLONE_WORKSPACE_SUCCESS:
    case workspacesActionTypes.CLONE_WORKSPACE_FAIL:
    case workspacesActionTypes.REMOVE_INTEGRATION_SUCCESS:
    case workspacesActionTypes.REMOVE_INTEGRATION_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: WorkspacesAction | GlobalAction) => {
  switch (action.type) {
    case workspacesActionTypes.GET_WORKSPACES_REQUEST:
    case workspacesActionTypes.GET_WORKSPACE_REQUEST:
    case workspacesActionTypes.SAVE_WORKSPACE_REQUEST:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST:
    case workspacesActionTypes.DELETE_WORKSPACES_REQUEST:
    case workspacesActionTypes.CLONE_WORKSPACE_REQUEST:
    case workspacesActionTypes.REMOVE_INTEGRATION_REQUEST:
      return null;
    case workspacesActionTypes.GET_WORKSPACES_FAIL:
    case workspacesActionTypes.GET_WORKSPACE_FAIL:
    case workspacesActionTypes.SAVE_WORKSPACE_FAIL:
    case workspacesActionTypes.ACTIVATE_WORKSPACE_FAIL:
    case workspacesActionTypes.DELETE_WORKSPACES_FAIL:
    case workspacesActionTypes.CLONE_WORKSPACE_FAIL:
    case workspacesActionTypes.REMOVE_INTEGRATION_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  workspace,
  workspaces,
  isFetching,
  cloneName,
  accordionIndexes,
  lastLoadTime,
  error,
});
