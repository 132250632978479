import { FilteringType } from './types';

export enum OpsConsoleActionTypes {
  SET_FILTERING = 'opsConsole/SET_FILTERING',
  SET_SEARCH_STRING = 'opsConsole/SET_SEARCH_STRING',
  RESET_FILTERING = 'opsConsole/RESET_FILTERING',
}

export type OpsConsoleAction =
  | { type: OpsConsoleActionTypes.SET_FILTERING; payload: FilteringType }
  | { type: OpsConsoleActionTypes.SET_SEARCH_STRING; payload: string }
  | { type: OpsConsoleActionTypes.RESET_FILTERING };

const setFiltering = (payload: FilteringType): OpsConsoleAction => ({ type: OpsConsoleActionTypes.SET_FILTERING, payload });
const setSearchString = (payload: string): OpsConsoleAction => ({ type: OpsConsoleActionTypes.SET_SEARCH_STRING, payload });
const resetFiltering = (): OpsConsoleAction => ({ type: OpsConsoleActionTypes.RESET_FILTERING });

export const opsConsoleActions = {
  setFiltering,
  setSearchString,
  resetFiltering,
};
