import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DateRangeType, TimeUnit } from '../../../../../API';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  SCHEDULE_BUFFER_OPTIONS,
} from '../../../../../store/bookingTemplates';
import labels from './labels';
import { bookingPageSelectors, bookingPagesActions } from '../../../../../store/bookingPages';
import i18n from '../../../../../i18n/i18n';
import { SumoTooltip } from '../../../sumoTooltip/SumoTooltip';
import { useState } from 'react';

type DateRangeSettingsProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const DateRangeSettings = ({ selectors, actions, isReadOnly }: DateRangeSettingsProps) => {
  const dispatch = useDispatch();
  const dateRangeType = useSelector(selectors.selectDateRangeType);
  const dateRangeCount = useSelector(selectors.selectDateRangeCount);
  const dateRangeList = useSelector(selectors.selectDateRangeList);
  const scheduleBufferCount = useSelector(selectors.selectScheduleBufferCount);
  const scheduleBufferTimeUnit = useSelector(selectors.selectScheduleBufferTimeUnit);
  const isDateRangeCountValid = useSelector(selectors.selectIsDateRangeCountValid);
  const isDateRangeValid = useSelector(selectors.selectIsDateRangeValid);
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const [selectedRange, setSelectedRange] = useState(dateRangeList.map((date) => new Date(+date)));

  const handleDateRangeTypeChange = (dateRangeType: DateRangeType) => {
    dispatch(actions.updateWhenDateRange({ type: dateRangeType }));
  };

  const handleDateRangeCountChange = (value: number | null | undefined) => {
    dispatch(actions.updateWhenDateRange({ count: value }));
  };

  const handleDateRangeChange = (e: CalendarChangeEvent) => {
    if (Array.isArray(e.value)) {
      setSelectedRange(e.value);
      const from = e.value[0] ? dayjs(e.value[0]).valueOf().toString() : '';
      const to = e.value[1] ? dayjs(e.value[1]).valueOf().toString() : from;
      dispatch(actions.updateWhenDateRange({ from, to }));
    } else {
      setSelectedRange([]);
      dispatch(actions.updateWhenDateRange({ from: null, to: null }));
    }
  };

  const handleScheduleBufferCountChange = (value: number | null | undefined) => {
    dispatch(actions.updateWhenScheduleBuffer({ count: value }));
  };

  const handleScheduleBufferTimeUnitChange = (timeUnit: TimeUnit) => {
    dispatch(actions.updateWhenScheduleBuffer({ timeUnit }));
  };

  return (
    <div className="flex">
      <div className="w-6 flex flex-column gap-10px">
        <div className="text-title-xs-med text-heavy-100">{labels.scheduleAvailability}</div>
        <div className="flex flex-column gap-8px w-264px">
          <Dropdown
            options={[
              { value: DateRangeType.DAYS_IN_FUTURE, label: labels.optionDaysIntoTheFuture },
              { value: DateRangeType.SPECIFIC_DATES, label: labels.optionDateRange },
              { value: DateRangeType.UNLIMITED, label: labels.optionIndefinitely },
            ]}
            value={dateRangeType}
            onChange={(e) => handleDateRangeTypeChange(e.value as DateRangeType)}
            disabled={isReadOnly}
          />
          {dateRangeType === DateRangeType.DAYS_IN_FUTURE && (
            <InputNumber
              className={isDateRangeCountValid ? '' : 'p-invalid'}
              showButtons
              min={1}
              max={365}
              value={dateRangeCount}
              onValueChange={(e) => handleDateRangeCountChange(e.target.value)}
              disabled={isReadOnly}
            ></InputNumber>
          )}
          {dateRangeType === DateRangeType.SPECIFIC_DATES && (
            <Calendar
              className={isDateRangeValid ? '' : 'p-invalid'}
              selectionMode="range"
              showIcon
              locale={i18n.language}
              minDate={new Date()}
              maxDate={oneYearFromNow}
              value={selectedRange.length ? selectedRange : null}
              onChange={handleDateRangeChange}
              disabled={isReadOnly}
            ></Calendar>
          )}
        </div>
      </div>

      <div className="w-6 pl-20px flex flex-column gap-10px">
        <div className="flex-left-center gap-6px">
          <div className="text-title-xs-med text-heavy-100">{labels.minimumNoticePeriod}</div>
          <SumoTooltip text={labels.minimumNoticePeriodTooltip} />
        </div>
        <div className="flex-left-center gap-4px">
          <InputNumber
            className="w-90px"
            inputClassName="w-full"
            showButtons
            min={0}
            max={999}
            value={scheduleBufferCount}
            onValueChange={(e) => handleScheduleBufferCountChange(e.target.value)}
            disabled={isReadOnly}
          ></InputNumber>
          <Dropdown
            value={scheduleBufferTimeUnit}
            onChange={(e) => handleScheduleBufferTimeUnitChange(e.target.value)}
            options={SCHEDULE_BUFFER_OPTIONS}
            optionLabel="label"
            disabled={isReadOnly}
          />
        </div>
      </div>
    </div>
  );
};

DateRangeSettings.displayName = 'DateRangeSettings';
