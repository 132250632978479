import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const AVAILABILITY_OVERRIDE_MODAL_NAME: ModalName = 'availabilityOverrideModal';
export const availabilityOverrideModalActions = modalsActionsFactory(AVAILABILITY_OVERRIDE_MODAL_NAME);
export const availabilityOverrideModalSelectors = modalsSelectorsFactory(AVAILABILITY_OVERRIDE_MODAL_NAME);

export const NEW_AVAILABILITY_NAME: ModalName = 'newAvailabilityNameModal';
export const newAvailabilityNameModalActions = modalsActionsFactory(NEW_AVAILABILITY_NAME);
export const newAvailabilityNameModalSelectors = modalsSelectorsFactory(NEW_AVAILABILITY_NAME);

export const CHANGE_AVAILABILITY_NAME: ModalName = 'changeAvailabilityNameModal';
export const changeAvailabilityNameModalActions = modalsActionsFactory(CHANGE_AVAILABILITY_NAME);
export const changeAvailabilityNameModalSelectors = modalsSelectorsFactory(CHANGE_AVAILABILITY_NAME);
