import { memo, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { CustomType, CustomFieldInput } from '../../../../API';
import { MULTISELECT_DELIMITER } from '../../../../store/bookingTemplates';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_SHORT_ANSWER } from '../../../../types/constants';
import { PhoneNumber } from '../../phoneNumber/PhoneNumber';
import { validateCustomField } from '../../../../store/publicBookingPage/utils';

interface ICustomField {
  customField: CustomFieldInput;
  onChange?: (customField: CustomFieldInput) => void;
  disabled?: boolean;
  hideLabel?: boolean;
}

export const CustomField = memo(({ customField, onChange, disabled, hideLabel }: ICustomField) => {
  const [value, setValue] = useState<string>(customField.value || '');
  const className = !customField.enabled
    ? 'bg-gray-200'
    : validateCustomField({ ...customField, value })
    ? ''
    : 'p-invalid';

  const handleChange = (value: string) => {
    setValue(value);
    onChange && onChange({ ...customField, value: value.trim() });
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const values = (value && value.split(MULTISELECT_DELIMITER)) || [];
    const updatedValues = e.checked ? [...values, e.value] : values.filter((item) => item !== e.value);
    handleChange(updatedValues.join(MULTISELECT_DELIMITER));
  };

  const handlePhoneChange = (phone: string) => {
    const phoneValue = `${value.split('-')[0]}-${phone}`;
    handleChange(phoneValue);
  };

  const handlePhoneCodeChange = (code: string) => {
    handleChange(`${code}-${value?.slice(value.indexOf('-') + 1)}`);
  };

  return (
    <div className="flex flex-column gap-10px">
      {!hideLabel && (
        <div className="text-body-lg-reg">
          <span>{customField.label}</span>
          {customField.required && <span className="text-tomato-main"> *</span>}
        </div>
      )}

      {customField.type === CustomType.TEXT && (
        <InputText
          disabled={!customField.enabled || disabled}
          className={className}
          value={value}
          placeholder={(hideLabel && customField.label) || ''}
          onChange={(e) => handleChange(e.target.value)}
          maxLength={MAX_LENGTH_SHORT_ANSWER}
        />
      )}

      {customField.type === CustomType.TEXTAREA && (
        <InputTextarea
          disabled={!customField.enabled || disabled}
          className={className}
          autoResize
          rows={3}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          maxLength={MAX_LENGTH_DESCRIPTION}
        />
      )}

      {customField.type === CustomType.PHONE && (
        // format BY_375-xxxxxxxx
        <PhoneNumber
          countryCode={value.split('-')[0] || ''}
          handleChangeCode={handlePhoneCodeChange}
          phoneNumber={value?.slice(value.indexOf('-') + 1) || ''}
          handleChangePhone={handlePhoneChange}
          inputClassName={className}
          disabled={!customField.enabled || disabled}
        />
      )}

      {customField.type === CustomType.CHECKBOXES &&
        customField.answers?.map((answer, index) => (
          <div key={index} className="flex-left-center gap-8px">
            <Checkbox
              inputId={(customField.label || '') + index}
              disabled={!customField.enabled || disabled}
              className={className}
              value={answer}
              checked={!!value.split(MULTISELECT_DELIMITER).find((item) => item === answer)}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={(customField.label || '') + index} className="cursor-pointer text-label-s-med">
              {answer}
            </label>
          </div>
        ))}

      {customField.type === CustomType.RADIO_BUTTONS &&
        customField.answers?.map((answer, index) => (
          <div key={index} className="flex-left-center gap-8px">
            <RadioButton
              inputId={(customField.label || '') + index}
              disabled={!customField.enabled || disabled}
              className={className}
              value={answer}
              checked={value === answer}
              onChange={(e) => handleChange(e.value)}
            />
            <label htmlFor={(customField.label || '') + index} className="cursor-pointer text-label-s-med">
              {answer}
            </label>
          </div>
        ))}

      {customField.type === CustomType.SELECT && (
        <Dropdown
          disabled={!customField.enabled || disabled}
          className={className}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          options={(customField.answers || []).map((answer) => answer)}
        />
      )}
    </div>
  );
});

CustomField.displayName = 'CustomField';
