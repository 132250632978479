import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  save: t('Common:SAVE'),
  title: t('OPSConsoleAccounts:TITLE'),
  tenants: t('OPSConsoleAccountDetails:TENANTS'),
  accountName: t('OPSConsoleAccountDetails:ACCOUNT_NAME'),
  accountNote: t('OPSConsoleAccountDetails:ACCOUNT_NOTE'),
  createdBy: t('OPSConsoleAccountDetails:CREATED_BY'),
  createdAt: t('OPSConsoleAccountDetails:CREATED_AT'),
  duplicateNameMessage: t('OPSConsoleAccountDetails:DUPLICATE_NAME_MESSAGE'),
};
