import { TabPanel, TabView } from 'primereact/tabview';
import { JourneyCanvas, JourneyResponses } from '../../components/journeyBuilder';
import labels from './labels';
import { Button } from 'primereact/button';
import { CodeBracketIcon, LinkIcon } from '@heroicons/react/24/outline';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { journeysActions, journeysSelectors } from '../../store/journeyBuilder';
import { MAX_LENGTH_NAME } from '../../types/constants';
import { InputSwitch } from 'primereact/inputswitch';
import { addToWebsiteModalActions, bookingPagesActions } from '../../store/bookingPages';
import { BookingPageAddToWebsiteModal } from '../../components/bookingPages';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUrlOrigin } from '../../services/URLService';
import { Path } from '../../routing';
import { ProgressSpinner } from 'primereact/progressspinner';

export const EditJourney = () => {
  const { journeyId } = useParams();
  const dispatch = useDispatch();
  const isFetching = useSelector(journeysSelectors.selectIsFetching);
  const isNewJourney = useSelector(journeysSelectors.selectIsNewJourney);
  const journey = useSelector(journeysSelectors.selectJourney);
  const isNameValid = useSelector(journeysSelectors.selectIsJourneyNameValid);
  const isReadOnly = useSelector(journeysSelectors.selectJourneyReadOnly);

  const [activeTabIndex, setActiveTabIndex] = useState(0); // index of selected Tab: 0 - Canvas, 1 - Responses

  useEffect(() => {
    dispatch(journeysActions.getJourneyRequest(journeyId || ''));
  }, []);

  useEffect(() => {
    if (activeTabIndex && journeyId !== 'new') {
      dispatch(journeysActions.getJourneyEventsRequest());
    }
  }, [activeTabIndex]);

  const handleNameChange = (name: string) => {
    dispatch(journeysActions.updateJourney({ name }));
  };

  const handleActiveChange = (active: boolean) => {
    dispatch(journeysActions.updateJourney({ active }));
  };

  const handleCopyLink = () => {
    const journeyPageUrl =
      journey.shortLink || getUrlOrigin() + Path.PublicJourneyPage.replace(':journeyId', journey.id);
    navigator.clipboard.writeText(journeyPageUrl);
  };

  const handleAddToWebsite = () => {
    dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicJourneyPage.replace(':journeyId', journey.id)));
    dispatch(addToWebsiteModalActions.openModal());
  };

  return (
    <div className="edit-journey-container">
      <BookingPageAddToWebsiteModal />
      <div className="px-24px">
        <div className="flex-left-center gap-8px">
          <div className="text-title-xl-med text-heavy-100">
            {isNewJourney ? labels.createJourney : labels.editJourney}
          </div>
          {isFetching && <ProgressSpinner className="w-32px h-32px mx-0 -my-4px" strokeWidth="5" />}
        </div>

        <div className="flex align-items-end justify-content-between my-16px gap-12px flex-wrap">
          <div className="flex align-items-end w-12 md:w-10 lg:w-8">
            <div className="flex flex-column w-10 md:w-8 lg:w-6 gap-8px">
              <label htmlFor="name" className="text-title-xs-med text-heavy-100">
                {labels.name}
              </label>
              <InputText
                id="name"
                value={journey.name || ''}
                onChange={(e) => handleNameChange(e.target.value)}
                placeholder={labels.namePlaceholder}
                maxLength={MAX_LENGTH_NAME}
                className={`${!isNameValid ? 'p-invalid' : ''}`}
                disabled={!!activeTabIndex || isReadOnly}
              />
            </div>
            <div className="text-title-xs-med flex-right-center gap-12px h-fit py-10px ml-24px mr-12px">
              <InputSwitch
                inputId="active"
                className="size-small"
                checked={journey.active || false}
                onChange={(e) => handleActiveChange(!!e.value)}
                disabled={!!activeTabIndex || isReadOnly}
              />
              <label htmlFor="active" className={`${!activeTabIndex && !isReadOnly ? 'cursor-pointer' : ''}`}>
                {labels.active}
              </label>
            </div>
          </div>
          <div className="ml-auto flex-center gap-8px text-button-md-med text-heavy-60">
            {/* <Button outlined className="button-icon">
                <EyeIcon className="icon-18px" />
              </Button> */}
            <Button
              outlined
              className="button-icon"
              onClick={handleAddToWebsite}
              disabled={!journey.id || !journey.active}
            >
              <CodeBracketIcon className="icon-18px" />
            </Button>
            <Button outlined className="button-icon" onClick={handleCopyLink} disabled={!journey.id || !journey.active}>
              <LinkIcon className="icon-18px" />
            </Button>
          </div>
        </div>
      </div>
      <TabView
        className="flex-1 h-0 tabview-embed"
        activeIndex={activeTabIndex}
        onTabChange={(e) => setActiveTabIndex(e.index)}
      >
        <TabPanel header={labels.journey} className="h-full">
          <JourneyCanvas />
        </TabPanel>
        <TabPanel header={labels.responses} className="h-full">
          <JourneyResponses />
        </TabPanel>
      </TabView>
    </div>
  );
};
