import { UserAdminDataFullRecord, UserStatus } from '../../../store/users/types';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from '../../../store/users';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import labels from './labels';
import { confirmUsersModalActions, editUsersModalActions } from '../../../store/users/modal';
import { authenticationSelectors } from '../../../store/authentication';
import { userSettingsSelectors } from '../../../store/userSettings';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import { Tooltip } from 'primereact/tooltip';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

type UserRowActionsProps = {
  data: UserAdminDataFullRecord;
  setConfirmDialogProps: (isEdit: boolean, isDisable: boolean) => void;
};

export const UserRowActions = ({ data, setConfirmDialogProps }: UserRowActionsProps) => {
  const dispatch = useDispatch();
  const remainingLicenses = useSelector(authenticationSelectors.selectRemainingLicenses);
  const currentUserId = useSelector(authenticationSelectors.selectUserId);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isEditUsers = useSelector(userSettingsSelectors.selectUsersEdit);
  const isDeleteUsers = useSelector(userSettingsSelectors.selectUsersDelete);
  const supportedRolesId = useSelector(userSettingsSelectors.selectSupportedRolesId);
  const userId = useSelector(authenticationSelectors.selectUserId);
  const menu = useRef<Menu | null>(null);
  const isInviteSentAndByCurrentUser = data.status === UserStatus.InviteSent && data.invitedById === currentUserId; // InviteSent users which was invited by Current User
  const isButtonVisiable = isEditUsers || isSuperAdmin || (isDeleteUsers && isInviteSentAndByCurrentUser);
  const isDeleteActive = isSuperAdmin || isInviteSentAndByCurrentUser;

  // Define menu items
  const menuItems = [
    {
      visible: isEditUsers,
      label: labels.edit,
      command: () => {
        setConfirmDialogProps(true, true);
        if (data && data.status === UserStatus.InviteSent) {
          dispatch(usersActions.getInviteUsersOptionsRequest());
        }
        dispatch(editUsersModalActions.openModal());
      },
    },
    {
      visible:
        data &&
        data.status === UserStatus.Active &&
        isEditUsers &&
        data.userId !== userId &&
        supportedRolesId.includes(data.roleId),
      label: labels.deactivate,
      command: () => {
        setConfirmDialogProps(false, true);
        dispatch(confirmUsersModalActions.openModal());
      },
    },
    {
      visible: data && data.status === UserStatus.Inactive && isEditUsers && supportedRolesId.includes(data.roleId),
      disabled: remainingLicenses < 1,
      label: labels.activate,
      command: () => {
        dispatch(usersActions.updateUserRecord({ status: UserStatus.Active }));
        dispatch(usersActions.disableUserRecordRequest());
      },
    },
    {
      visible: data.userId !== userId && isDeleteUsers, // User can't delete himself & User has Role permissions for delete
      label: labels.delete,
      command: () => {
        setConfirmDialogProps(false, false);
        dispatch(confirmUsersModalActions.openModal());
      },
      template: (item: MenuItem, options: MenuItemOptions) => (
        <span
          className={`${options.className} name-info ${isDeleteActive ? '' : 'p-disabled'}`}
          data-pr-tooltip={labels.deleteTooltip}
          data-pr-position="bottom"
          onClick={isDeleteActive ? options.onClick : undefined}
        >
          {!isDeleteActive && <Tooltip target=".name-info" />}
          <span className={options.labelClassName}>{item.label}</span>
        </span>
      ),
    },
  ];

  return (
    <>
      {isButtonVisiable && (
        <div
          className="action-button"
          onClick={(e) => {
            dispatch(usersActions.setUserRecord(data));
            menu.current?.toggle(e);
          }}
        >
          <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
          <EllipsisVerticalIcon className="icon-20px" />
        </div>
      )}
    </>
  );
};
