export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Nova página de reserva`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Tem certeza de que deseja excluir esta página de reserva?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Tem certeza de que deseja excluir estas páginas de reserva?`,
  TITLE: `Páginas de reserva`,
  ADD_TO_SITE: `Adicionar ao site`,
  CLONE_MODAL_TITLE: `Clonar página de reserva`,
  DEFAULT_FILTER: `Minhas páginas de reserva`,
  SHARED_LABEL: `Compartilhado`,
  CLONE_NOTIFICATION: `Por favor, esteja ciente de que a propriedade "Somente administrador" está desativada por padrão para um registro clonado.`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Falha na solicitação de clonagem da página de reserva`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Página de reserva clonada com sucesso`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Falha na solicitação de exclusão das páginas de reserva`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Página de reserva excluída com sucesso`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Não foi possível recuperar a página de reserva, ela pode ter sido excluída`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Falha na solicitação de recuperação das páginas de reserva`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Falha na solicitação de salvamento da página de reserva`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Página de reserva salva com sucesso`,
});

export const DefaultBookingPage = Object.freeze({
  NAME: `Reunião de 30 minutos`,
  BOOK_A_MEETING: `Marcar uma reunião`,
  MEETING_DETAILS: `Detalhes da reunião`,
  GUESTS_INFO: `Informações do convidado`,
  BOOK_MEETING_BUTTON: `Confirmar`,
  BOOK_ANOTHER_MEETING_BUTTON: `Marcar outra reunião`,
  CANCEL: `Cancelar`,
  CANCEL_MEETING_BUTTON: `Cancelar reunião`,
  CONFIRM_CANCEL_BUTTON: `Confirmar`,
  SAVE_MEETING_BUTTON: `Reagendar`,
  MEETING_BOOKED_TITLE: `Você está reservado`,
  MEETING_BOOKED_DESCRIPTION: `Uma confirmação por e-mail foi enviada para sua caixa de entrada.`,
  MEETING_RESCHEDULED_TITLE: `Você está reagendado`,
  MEETING_RESCHEDULED_DESCRIPTION: `Uma confirmação por e-mail foi enviada para sua caixa de entrada.`,
  MEETING_CANCELED_TITLE: `Você está cancelado`,
  MEETING_CANCELED_DESCRIPTION: `Uma confirmação por e-mail foi enviada para sua caixa de entrada.`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE: `O que`,
  WHAT_DESCRIPTION: `Modelos de reserva e detalhes`,
  WHO_TITLE: `Quem`,
  WHO_DESCRIPTION: `Anfitrião da reunião`,
  WHERE_TITLE: `Onde`,
  WHERE_DESCRIPTION: `Detalhes e locais do evento`,
  WHEN_TITLE: `Quando`,
  WHEN_DESCRIPTION: `Duração e disponibilidade`,
  HOW_TITLE: `Como deve parecer?`,
  HOW_DESCRIPTION: `Estilos e exibições de marca`,
  ALERTS_TITLE: `Alertas`,
  ALERTS_DESCRIPTION: `E-mails e lembretes`,
  INVITEE_TITLE: `Configurações do convidado`,
  INVITEE_DESCRIPTION: `Formulário de reserva e permissão do convidado`,
  AFTER_TITLE: `Após a reserva`,
  AFTER_DESCRIPTION: `Página de confirmação e Política de Cancelamento`,
  ADDITIONAL_CONFIGURATIONS: `Configurações adicionais`,
  VIEW_BOOKING_PAGE: `Visualizar página de reserva`,
  COPY_LINK: `Copiar link`,
  ADD_TO_WEBSITE: `Adicionar ao site`,
  ACTIVE: `Ativo`,
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Registro bloqueado. Essas configurações são do modelo de reserva intitulado: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Clique aqui para substituir essas configurações`,
  LOCKED_ADMIN_ONLY: `Apenas um administrador pode modificar essas configurações. Última modificação por:`,
  LOCKED_NO_PERMISSIONS: `Você não tem permissão para editar esta página de reserva. Última modificação por:`,
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Nome`,
  NAME_PLACEHOLDER: `Nome personalizado para esta página de reserva`,
  NAME_TOOLTIP: `Este texto será exibido aos participantes da reunião no calendário e nos e-mails. Por exemplo, "Reunião de 30 min" ou "Exame Dentário".`,
  COLOR: `Cor`,
  COLOR_TOOLTIP: `Escolha uma cor para esta página de reserva e para a reunião criada.`,
  BOOKING_TEMPLATE: `Modelo de Reserva`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Selecione o Modelo de Reserva`,
  BOOKING_TEMPLATE_TOOLTIP: `Selecione um modelo de reserva para aplicar automaticamente todas as configurações para a página de reserva atual.`,
  ADMIN_ONLY: `Apenas Administrador`,
  ADMIN_ONLY_TOOLTIP: `Apenas um administrador pode modificar estas configurações`,
  MEETING_INSTRUCTIONS: `Instruções da Reunião`,
  MEETING_INSTRUCTIONS_PLACEHOLDER: `Insira as instruções da reunião`,
  MEETING_INSTRUCTIONS_TOOLTIP: `Escreva um resumo e quaisquer detalhes que o seu convidado deva saber sobre o evento.`,
});

export const EditBookingPageWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Anfitriões e equipes`,
  HOSTS_TOOLTIP: `Escolha uma pessoa, várias pessoas ou uma equipe (onde um membro será selecionado via rodízio) para participar desta reunião.`,
  SEARCH_PLACEHOLDER: `Procurar anfitriões e equipes`,
  UNASSIGNED: `Não atribuído`,
  WRONG_HOST: `Você deve ser o anfitrião principal para salvar a página de reserva`,
});

export const EditBookingPageDurationStep = Object.freeze({
  DURATION: `Duração`,
  DURATION_DESCRIPTION: `Defina a duração da sua reunião. Não deve exceder 12 horas.`,
  BUFFER_TIME: `Tempo de buffer`,
  BUFFER_TIME_TOOLTIP: `Definir tempos de buffer antes e depois`,
  AFTER_CHECKBOX_LABEL: `após o evento`,
  BEFORE_CHECKBOX_LABEL: `antes do evento`,
});

export const EditBookingPageDateRangeStep = Object.freeze({
  SCHEDULE_AVAILABILITY: `Disponibilidade do agendamento`,
  MINIMUM_NOTICE_PERIOD: `Período de aviso mínimo`,
  MINIMUM_NOTICE_PERIOD_TOOLTIP: `Esta configuração determina a quantidade mínima de tempo necessária antes que uma reunião possa ser agendada. Ajuda a garantir que você tenha aviso suficiente para se preparar para reuniões futuras.`,
  OPTION_DAYS_INTO_THE_FUTURE: `Dias no futuro`,
  OPTION_DATE_RANGE: `Dentro de um intervalo de datas`,
  OPTION_INDEFINITELY: `Indefinidamente no futuro`,
});

export const EditBookingPageInviteeStep = Object.freeze({
  QUESTIONS: `Perguntas do formulário de reserva`,
  QUESTIONS_DESCRIPTION: `Melhore o formulário de reserva com perguntas de convite`,
  NEW_QUESTION: `Nova pergunta`,
  EDIT_QUESTION: `Editar pergunta`,
  PERMISSIONS: `Permissões do convidado`,
  PERMISSIONS_DESCRIPTION: `Defina as ações disponíveis para seus convidados`,
  OPTION_INVITE_OTHERS: `Os convidados podem convidar outras pessoas`,
  OPTION_INVITE_OTHERS_TOOLTIP: `Habilitar esta funcionalidade exibirá um campo "Convidar Outros" na Página de Reserva, permitindo que o convidado adicione outras pessoas à lista de participantes.`,
  OPTION_ENTER_NOTE: `Os convidados podem inserir uma nota para todos os convidados`,
  OPTION_ENTER_NOTE_TOOLTIP: `Habilitar esta funcionalidade exibirá um campo "Nota para os Participantes", permitindo que o convidado insira uma nota a ser exibida no compromisso de calendário reservado para que todos os participantes possam ver.`,
});

export const EditBookingPageAfterStep = Object.freeze({
  CONFIRMATION_PAGE: `Página de confirmação`,
  OPTION_DISAPLY_CONFIRMATION_PAGE: `Exibir página de confirmação do SUMO`,
  OPTION_REDIRECT_TO_EXTERNAL_PAGE: `Redirecionar para uma página externa específica`,
  EXTERNAL_LINK_PLACEHOLDER: `URL de redirecionamento externo`,
  AVAILABLE_ACTIONS: `Ações disponíveis`,
  AVAILABLE_ACTIONS_DESCRIPTION: `Ações acessíveis para convidados a partir desta página de confirmação.`,
  OPTION_RESCHEDULE: `Reagendar`,
  OPTION_CANCEL: `Cancelar reunião`,
  OPTION_BOOK_ANOTHER: `Agendar outra reunião`,
  CUSTOM_LINKS: `Links personalizados adicionais`,
  CUSTOM_LINKS_DESCRIPTION: `Adicione links personalizados na página de confirmação, como uma pesquisa ou recurso adicional.`,
  ADD_CUSTOM_LINK: `Adicionar link personalizado`,
  CHANGE_CUSTOM_LINK: `Alterar link personalizado`,
  LINK_URL: `URL do link`,
  CUSTOM_LINK_LABEL: `Rótulo do link personalizado`,
  CUSTOM_LINK_LABEL_PLACEHOLDER: `Acordo de reunião`,
  CANCELATION_POLICY: `Política de cancelamento`,
  CANCELATION_POLICY_DESCRIPTION: `Forneça detalhes sobre sua política de cancelamento para esclarecer as consequências das alterações.`,
  CANCELATION_POLICY_PLACEHOLDER: `Texto da política de cancelamento`,
});

export const BookingPageHowStep = Object.freeze({
  BRANDING_TITLE: `Personalização da página de reservas`,
  BRANDING_DESCRIPTION_BOOKING_PAGE: `Personalize a aparência, estilos e rótulos desta página de reservas.`,
  BRANDING_DESCRIPTION_BOOKING_TEMPLATE: `Personalize a aparência, estilos e rótulos deste modelo de reservas.`,
  CALENDAR_TITLE: `Configurações do calendário`,
  CALENDAR_DESCRIPTION: `Personalize o formato de exibição de calendário, data e fuso horário.`,
});

export const BookingPageBrandingModal = Object.freeze({
  TITLE: `Personalização da página de reservas`,
  DESCRIPTION_BOOKING_PAGE: `Esta página de reservas usa as configurações de marca padrão do espaço de trabalho, mas você pode personalizá-las abaixo.`,
  DESCRIPTION_BOOKING_TEMPLATE: `Este modelo de reservas usa as configurações de marca padrão do espaço de trabalho, mas você pode personalizá-las abaixo.`,
  LOGO: `Logotipo`,
  BACKGROUND: `Fundo`,
  BACKGROUND_OPTION_BLANK: `Em branco`,
  BACKGROUND_OPTION_WALLPAPER: `Papel de parede`,
  BACKGROUND_OPTION_Color: `Cor`,
  BACKGROUND_TYPE: `Tipo de fundo`,
  BACKGROUND_TYPE_TOP: `Banner`,
  BACKGROUND_TYPE_FULL: `Página inteira`,
  BACKGROUND_TYPE_LEFT: `Lado esquerdo`,
  BACKGROUND_TYPE_RIGHT: `Lado direito`,
  BACKGROUND_COLOR: `Cor de fundo`,
  MAIN_COLOR: `Cor principal`,
  FONT_COLOR: `Cor da fonte`,
  CUSTOM_CSS: `CSS personalizado`,
  CUSTOM_CSS_PLACEHOLDER: `Você pode adicionar código CSS personalizado`,
  FOOTER_HTML: `HTML do rodapé`,
  FOOTER_HTML_PLACEHOLDER: `Você pode adicionar HTML do rodapé ou texto simples`,
  RESET_DEFAULT_STYLES: `Redefinir para estilos padrão`,
  STEP_JOURNEY: `Jornada`,
  STEP_BOOKING_PAGE: `Página de reservas`,
  STEP_BOOKING_FORM: `Formulário de reserva`,
  STEP_BOOKED: `Reservado`,
  STEP_RESCHEDULED: `Reagendado`,
  STEP_CANCELED: `Cancelado`,
  STEP_CANCEL: `Cancelar`,
});

export const BookingPageCalendarModal = Object.freeze({
  TITLE: `Configurações do calendário`,
  TIME_FORMAT: `Formato de hora`,
  HOUR_12: `12 horas`,
  HOUR_12_EXAMPLE: `1:00 p.m.`,
  HOUR_24: `24 horas`,
  HOUR_24_EXAMPLE: `13:00`,
  START_TIME_INTERVALS: `Intervalos de tempo`,
  START_TIME_INTERVALS_DESCRIPTION: `Selecione a duração do intervalo que deseja exibir ao agendar uma consulta.`,
  START_TIME_INTERVALS_TOOLTIP: `Escolha a duração dos intervalos que deseja exibir ao agendar uma consulta. Por padrão, temos 15 minutos selecionados para maximizar a disponibilidade de slots. Esta opção exibirá slots disponíveis por este intervalo.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Comece no topo do intervalo`,
  TOP_OF_THE_INTERVAL_DESCRIPTION: `Ative esta opção para iniciar todas as consultas no topo de cada intervalo (por exemplo, 8h00, 9h00)`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Ative "Comece no topo do intervalo" se você precisar que todas as consultas comecem sempre no topo do intervalo, independentemente da duração do Modelo de Reserva. Por exemplo, as durações dos slots estariam disponíveis às 8h00, 9h00, 10h00, etc. NOTA: Ativar este recurso não permitirá que o usuário maximize a disponibilidade.`,
  TIME_ZONES: `Fusos horários`,
  TIME_ZONES_TOOLTIP: `Os seguintes fusos horários estarão disponíveis na página de reservas voltada para o cliente. Se você escolher apenas um fuso horário, ele será fixo, de modo que o campo de fuso horário ficará desativado e o usuário não poderá alterá-lo.`,
  TIME_ZONE_DETECT: `Detectar automaticamente e exibir intervalos de tempo no fuso horário do convidado.`,
  TIME_ZONE_DISPLAY: `Exibir apenas os seguintes fusos horários (ideal para eventos presenciais)`,
  DEFAULT_TIME_ZONE: `Fuso horário padrão`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Inferior esquerdo`,
  BOTTOM_RIGHT: `Inferior direito`,
  BUTTON_POPUP_TITLE: `Botão`,
  BUTTON_POPUP_LABEL: `Agendar uma reunião`,
  COPY_CODE_EMBED_DESCRIPTION: `Incorpore seu widget SUMO no seu site colando o código HTML no local desejado.`,
  COPY_CODE_LINK_DESCRIPTION: `Exiba um botão de reserva flutuante nas páginas desejadas colocando este código no seu HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Exiba seu link de reserva em qualquer lugar colocando este código HTML em suas páginas, assinaturas de e-mail e muito mais.`,
  COPY_CODE_TITLE: `Copiar código`,
  CUSTOMIZE_DESCRIPTION: `Personalize a aparência para se integrar perfeitamente ao seu site.`,
  CUSTOMIZE_TITLE: `Personalizar`,
  EMBED_DESCRIPTION: `Exiba sua página de reserva SUMO1 em um iFrame no seu site.`,
  EMBED_TITLE: `Incorporar`,
  FLOATING_BUTTON_DESCRIPTION: `Botão flutuante`,
  HIDE_PAGE_DETAILS: `Ocultar detalhes da página`,
  HIDE_COOKIE_BANNER: `Ocultar banner de cookies`,
  LABEL: `Rótulo`,
  LINK_DESCRIPTION: `Adicione um link de texto ao seu site que abrirá sua página de reserva SUMO1 em uma popup.`,
  LINK_TITLE: `Link de texto`,
  POSITION: `Posição`,
  POPUP_DESCRIPTION: `Exiba sua página de reserva SUMO1 em uma popup quando um botão for clicado.`,
  POPUP_TITLE: `Popup`,
  TITLE: `Como você vai adicionar o SUMO ao seu site?`,
  TOP_LEFT: `Superior esquerdo`,
  TOP_RIGHT: `Superior direito`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirmar substituição`,
  DESCRIPTION: `Ao substituir as configurações de um Modelo de Reserva, você tecnicamente não está mais usando este Modelo de Reserva, mas criando uma Página de Reserva Personalizada, que exige um nome.`,
});
