export const EditRole = Object.freeze({
  ACCESS_TO_ALL_WORKSPACES: `Acceso a todos los espacios de trabajo y roles`,
  ACTIVE: `Activo`,
  ADMIN_TITLE: `Administrador`,
  MY_BOOKING_PAGE: `Mis Páginas de Reserva`,
  ALL_BOOKING_PAGE: `Todas las Páginas de Reserva`,
  BOOKING_TEMPLATES: `Plantillas de Reserva`,
  CHROME_EXTENSION_TITLE: `SUMO1 Bandeja de entrada (Extensión de Chrome)`,
  CREATE: `Crear`,
  DELETE: `Eliminar`,
  EDIT: `Editar`,
  FEATURE_ACCESS_TITLE: `Acceso a funciones`,
  JOURNEY: `Constructor de Viaje`,
  LOCATIONS: `Ubicaciones`,
  PERMISSIONS_TITLE: `Permisos`,
  PRIMARY_TITLE: `Primario`,
  READ: `Leer`,
  RESOURCES_TITLE: `Recursos (Ubicaciones, Salas)`,
  ROLE_NAME: `Nombre del rol`,
  SEARCH_ADD_WORKSPACE: `Buscar y agregar espacio de trabajo`,
  SMART_ALERT_TEMPLATES: `Plantillas de alertas inteligentes`,
  STANDARD_USER: `Usuario estándar`,
  SUPER_ADMIN: `Superadministrador`,
  TEAMS: `Equipos`,
  EDIT_TITLE: 'Editar rol',
  NEW_TITLE: 'Nuevo rol',
  WORKSPACE_ADMIN: `Administrador del espacio de trabajo`,
  WORKSPACE_ADMIN_DESCRIPTION: `Por defecto, un administrador con este rol puede administrar todos los espacios de trabajo, a menos que especifique a continuación espacios de trabajo específicos que solo puede administrar:`,
});

export const RoleToastsNotifications = Object.freeze({
  ACTIVATE_ROLE_SUCCESS_MESSAGE: `Rol activado`,
  DEACTIVATE_ACTIVATE_ROLE_ERROR_MESSAGE: `Error al activar/desactivar el rol`,
  DEACTIVATE_ROLE_SUCCESS_MESSAGE: `Rol desactivado`,
  DELETE_ROLE_ASSIGNED_ERROR_MESSAGE: `No se puede eliminar. Usuarios están asignados a este rol.`,
  DELETE_ROLE_ERROR_MESSAGE: `Error al eliminar el rol`,
  DELETE_ROLE_SUCCESS_MESSAGE: `Rol eliminado con éxito`,
  GET_ROLES_FAIL_MESSAGE: `Error al obtener roles`,
  GET_ROLE_FAIL_MESSAGE: `Fallo en la solicitud de rol`,
  SAVE_ROLE_ERROR_MESSAGE: `Error al guardar el rol`,
  SAVE_ROLE_SUCCESS_MESSAGE: `Rol guardado con éxito`,
});

export const Roles = Object.freeze({
  ACTIVE: `Activo`,
  CUSTOM: `Personalizado`,
  DEACTIVATE_DESCRIPTION: `¿Seguro que quieres desactivar`,
  DELETE_DESCRIPTION: `¿Seguro que quieres eliminar`,
  DELETE_MESSAGE: `Los usuarios no se eliminarán, pero pueden perder el acceso y requerir una asignación de rol.`,
  LAST_MODIFIED: `Última modificación`,
  LAST_MODIFIED_BY: `Última modificado por`,
  NEW_ROLE: `Nuevo rol`,
  ROLE: `Rol`,
  TOOLTIP: `Gestione los roles para dar permisos a los usuarios para ver, crear/editar o eliminar varias funciones`,
});
