export const OPSConsoleUsers = Object.freeze({
  ACCOUNT_ID: `ID du compte`,
  EXPORT: `Exporter`,
  SEARCH_PLACEHOLDER: `Rechercher par nom ou email`,
  SUBHEADER_TEXT: `Utilisateurs les plus actifs`,
  VIEW_AS_USER: `Voir en tant qu'utilisateur`,
  REGISTRATION_DATE: `Date d'inscription`,
  RENEWAL_DATE: `Date de renouvellement`,
  USER_INFO_TITLE: `Informations sur l'utilisateur`,
  LICENSE_STATUS_TITLE: `Statut de la licence`,
  CUSTOM_ROLE: `Rôle personnalisé`,
});

export const OPSConsoleUsersToastsNotifications = Object.freeze({
  ACTIVATE_USER_SUCCESS_MESSAGE: `Succès de la demande d'activation de l'utilisateur`,
  DEACTIVATE_USER_FAIL_MESSAGE: `Échec de la demande de désactivation/activation de l'utilisateur`,
  DEACTIVATE_USER_SUCCESS_MESSAGE: `Succès de la demande de désactivation de l'utilisateur`,
  DELETE_USER_FAIL_MESSAGE: `Échec de la demande de suppression de l'utilisateur`,
  DELETE_USER_SUCCESS_MESSAGE: `Succès de la demande de suppression de l'utilisateur`,
  GET_USER_DETAILS_FAIL_MESSAGE: `Échec de la demande de détails de l'utilisateur`,
  GET_USERS_FAIL_MESSAGE: `Échec de la demande des utilisateurs`,
});

export const ViewAsUser = Object.freeze({
  BACK_TO_OPS_CONSOLE: `Retour à la console OPS`,
  MODAL_DESCRIPTION_PART1: `Le mode "Voir en tant qu'utilisateur" est actif.`,
  MODAL_DESCRIPTION_PART2: `Pour continuer à utiliser la console OPS, veuillez terminer la session "Voir en tant qu'utilisateur" en sélectionnant l'une des options suivantes :`,
  MODAL_DESCRIPTION_PART3: `Cliquez sur le bouton "Retour à la console OPS".`,
  MODAL_DESCRIPTION_PART4: `Appuyez sur l'option "Réinitialiser" ici.`,
  VIEW_AS_USER_MODE: `Mode Voir en tant qu'utilisateur`,
  VIEWING_SUMO1_TITLE: `Affichage de SUMO1 en tant qu'utilisateur.`,
});
