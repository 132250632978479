import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/rootStore';
import { notificationsSelectors, notificationsActions } from '../../../store/notifications';
import { Toast, ToastMessage } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import labels from './labels';
import { InviteExpiredToast } from '../../public/inviteExpiredToast/InviteExpiredToast';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';

export const ToastNotification = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toastNotificationsQueue = useSelector(notificationsSelectors.selectToastNotificationsQueue);

  const toastNotification = useRef<Toast>(null);
  const localMessages = useRef<ToastMessage[]>([]);

  const severityToTitle = {
    error: labels.error,
    success: labels.success,
    info: labels.info,
    warn: labels.warning,
  };
  const severityToIcon = {
    error: <XCircleIcon className="p-toast-message-icon" />,
    success: <CheckCircleIcon className="p-toast-message-icon" />,
    info: <InformationCircleIcon className="p-toast-message-icon" />,
    warn: <ExclamationCircleIcon className="p-toast-message-icon" />,
  };

  useEffect(() => {
    const toastMessages: ToastMessage[] = toastNotificationsQueue.map((item) => ({
      id: item.id,
      severity: item.color,
      summary: item.title ? item.title : item.color ? severityToTitle[item.color] : '',
      detail: item.message,
      life: item.autoHideDuration,
      icon: item.color ? severityToIcon[item.color] : null,
      content: item.isInviteExpired ? InviteExpiredToast() : undefined,
    }));

    const newMessages = toastMessages.filter(
      (message) => message.id && !localMessages.current.find((localMessage) => localMessage.id === message.id)
    );

    if (toastNotification.current) {
      newMessages.length && toastNotification.current.show(newMessages);
    }

    localMessages.current = toastMessages;
  }, [toastNotificationsQueue]);

  const handleClose = (toastId: string | undefined) => {
    toastId && dispatch(notificationsActions.hideToast(toastId));
  };

  return <Toast ref={toastNotification} position="top-right" onRemove={(el) => handleClose(el?.id)} />;
};
