import { useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { NotificationStep } from '../../common/meeting/notificationStep/NotificationStep';

export const BookingTemplateNotificationsStep = () => {
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);
  return (
    <NotificationStep selectors={bookingTemplatesSelectors} actions={bookingTemplatesActions} isReadOnly={isReadOnly} />
  );
};
