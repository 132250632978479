import { t } from '../../../../i18n/i18n';

export default {
  modalDescPart1: t('ViewAsUser:MODAL_DESCRIPTION_PART1'),
  modalDescPart2: t('ViewAsUser:MODAL_DESCRIPTION_PART2'),
  modalDescPart3: t('ViewAsUser:MODAL_DESCRIPTION_PART3'),
  modalDescPart4: t('ViewAsUser:MODAL_DESCRIPTION_PART4'),
  reset: t('Common:RESET'),
  viewAsUserMode: t('ViewAsUser:VIEW_AS_USER_MODE'),
};
