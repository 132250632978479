export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `You may connect only 1 calendar`, // To increase this limit, contact sales to upgrade your license tier.
  DESCRIPTION: `Connect your calendar so SUMO knows when you're available and updates your calendar as appointments are booked.`,
  DISCONNECT_MESSAGE: `Are you sure you would like to disconnect your calendar?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Appointments will not be deleted or canceled.`,
  DISCONNECT_TITLE: `Disconnect Calendar`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016, or 2019`,
  EXCHANGE_CALENDAR_TITLE: `Exchange Calendar`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com, or Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Office 365 Calendar`,
  SELECT_CALENDAR_TITLE: `Select Calendar`,
  TITLE: `Calendar Connections`,
  TOOLTIP: `Connect your Google or Microsoft calendar to SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Agree and Close`,
  DESCRIPTION: `When you visit a website, it has the ability to store or retrieve certain information on your browser. This information, often in the form of cookies, can be about your preferences, device, or other details. Its primary purpose is to ensure that the website functions as you would expect. While this information does not typically identify you directly, it can enhance your web browsing experience by personalizing content. We understand and value your right to privacy, which is why we provide you with the option to control and customize your cookie preferences. By clicking on the various category headings, you can learn more about each type of cookie and modify our default settings. Please note that blocking certain types of cookies may impact your overall experience on the site and limit the services we can offer you.`,
  DESCRIPTION_FIRST_PART: `When you visit a website, it has the ability to store or retrieve certain information on your browser. This information, often in the form of cookies, can be about your preferences, device, or other details. Its primary purpose is to ensure that the website functions as you would expect.`,
  DESCRIPTION_SECOND_PART: `While this information does not typically identify you directly, it can enhance your web browsing experience by personalizing content. We understand and value your right to privacy, which is why we provide you with the option to control and customize your cookie preferences.`,
  DESCRIPTION_THIRD_PART: `By clicking on the various category headings, you can learn more about each type of cookie and modify our default settings. Please note that blocking certain types of cookies may impact your overall experience on the site and limit the services we can offer you.`,
  FUNCTIONAL_NAME: `Functional Cookies`,
  MANAGE_TITLE: `Manage Consent Preferences`,
  NECESSARY_ONLY: `Strictly Necessary Only`,
  PERFORMANCE_NAME: `Performance Cookies`,
  STRICTLY_NAME: `Strictly Necessary Cookies`,
  TARGETING_NAME: `Targeting Cookies`,
  TITLE: `Cookie Settings`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Google Account`,
  GOOGLE_ACCOUNTS_SWITCH: `Switch Google Accounts`,
  GOOGLE_SWITCH: `Login with Google`,
  GOOGLE_SWITCH_DESC: `Change from Microsoft authentication to Google`,
  MICROSOFT_ACCOUNT: `Microsoft Account`,
  MICROSOFT_ACCOUNTS_SWITCH: `Switch Microsoft Accounts`,
  MICROSOFT_SWITCH: `Login with Microsoft`,
  MICROSOFT_SWITCH_DESC: `Change from Google authentication to Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Your SUMO1 account will lose access to data only available to you through your original provider. You may also need to re-grant access to integrations and calendars.`,
  SWITCH_DESCRIPTION_PART1: `Change from `,
  SWITCH_DESCRIPTION_PART2: ` authentication to `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Your current role`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Date Format`,
  LANGUAGE: `Language`,
  NAME: `Name`,
  WELCOME_MESSAGE: `Welcome Message`,
  PHONE_NUMBER: `Phone Number`,
  PROFILE_IMAGE: `Profile Image`,
  PHONE_DETAILS: `Phone Details`,
  PHONE_DETAILS_PLACEHOLDER: `Add additional info or instructions here.\r\nEx: I look forward to meeting. SMS Text me anytime.`,
  TIME_FORMAT: `Time Format`,
  TIME_ZONE: `Time Zone`,
  COUNTRY: `Country`,
});

export const Profile = Object.freeze({
  TITLE: `Profile`,
  ACCOUNT_SETTINGS: `Account Settings`,
  ACCOUNT_DESCRIPTION: `Basic information and settings`,
  LOGIN: `Login`,
  LOGIN_DESCRIPTION: `Setup connected account`,
  MY_ROLE: `My Role`,
  MY_ROLE_DESCRIPTION: `Your current role and permissions`,
  COOKIE_SETTINGS: `Cookie Settings`,
  COOKIE_DESCRIPTION: `Necessary, Performance, Functional, Targeting Cookies`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `New calendar request fail`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `New calendar successfully connected!`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE_: `Zoom connected request fail`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `Zoom connected successfully!`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Settings save request fail`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `Your settings successfully saved!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `You successfully switch the account`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Switch account failed. Please try again later`,
});
