export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Você pode conectar apenas 1 calendário`, // Para aumentar esse limite, entre em contato com vendas para atualizar seu nível de licença.
  DESCRIPTION: `Conecte seu calendário para que o SUMO saiba quando você está disponível e atualize seu calendário à medida que os compromissos são agendados.`,
  DISCONNECT_MESSAGE: `Você tem certeza de que deseja desconectar seu calendário?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Os compromissos não serão excluídos ou cancelados.`,
  DISCONNECT_TITLE: `Desconectar Calendário`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 ou 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendário Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendário Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com ou Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Calendário Office 365`,
  SELECT_CALENDAR_TITLE: `Selecionar Calendário`,
  TITLE: `Conexões de Calendário`,
  TOOLTIP: `Conecte seu calendário Google ou Microsoft ao SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Concordar e Fechar`,
  DESCRIPTION: `Quando você visita um site, ele tem a capacidade de armazenar ou recuperar certas informações no seu navegador. Essas informações, muitas vezes na forma de cookies, podem ser sobre suas preferências, dispositivo ou outros detalhes. Seu principal objetivo é garantir que o site funcione como você espera. Embora essas informações não o identifiquem diretamente, elas podem melhorar sua experiência de navegação personalizando o conteúdo. Entendemos e valorizamos seu direito à privacidade, por isso oferecemos a você a opção de controlar e personalizar suas preferências de cookies. Ao clicar nos vários títulos de categoria, você pode aprender mais sobre cada tipo de cookie e modificar nossas configurações padrão. Observe que bloquear certos tipos de cookies pode afetar sua experiência geral no site e limitar os serviços que podemos oferecer.`,
  DESCRIPTION_FIRST_PART: `Quando você visita um site, ele tem a capacidade de armazenar ou recuperar certas informações no seu navegador. Essas informações, muitas vezes na forma de cookies, podem ser sobre suas preferências, dispositivo ou outros detalhes. Seu principal objetivo é garantir que o site funcione como você espera.`,
  DESCRIPTION_SECOND_PART: `Embora essas informações não o identifiquem diretamente, elas podem melhorar sua experiência de navegação personalizando o conteúdo. Entendemos e valorizamos seu direito à privacidade, por isso oferecemos a você a opção de controlar e personalizar suas preferências de cookies.`,
  DESCRIPTION_THIRD_PART: `Ao clicar nos vários títulos de categoria, você pode aprender mais sobre cada tipo de cookie e modificar nossas configurações padrão. Observe que bloquear certos tipos de cookies pode afetar sua experiência geral no site e limitar os serviços que podemos oferecer.`,
  FUNCTIONAL_NAME: `Cookies Funcionais`,
  MANAGE_TITLE: `Gerenciar Preferências de Consentimento`,
  NECESSARY_ONLY: `Apenas Estritamente Necessários`,
  PERFORMANCE_NAME: `Cookies de Desempenho`,
  STRICTLY_NAME: `Cookies Estritamente Necessários`,
  TARGETING_NAME: `Cookies de Segmentação`,
  TITLE: `Configurações de Cookies`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Conta do Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Trocar Contas do Google`,
  GOOGLE_SWITCH: `Entrar com Google`,
  GOOGLE_SWITCH_DESC: `Mudar da autenticação da Microsoft para Google`,
  MICROSOFT_ACCOUNT: `Conta da Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Trocar Contas da Microsoft`,
  MICROSOFT_SWITCH: `Entrar com Microsoft`,
  MICROSOFT_SWITCH_DESC: `Mudar da autenticação do Google para Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Sua conta SUMO1 perderá acesso a dados disponíveis apenas através do seu provedor original. Você também pode precisar reautorizar o acesso a integrações e calendários.`,
  SWITCH_DESCRIPTION_PART1: `Mudar de `,
  SWITCH_DESCRIPTION_PART2: ` autenticação para `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Seu papel atual`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Formato de Data`,
  LANGUAGE: `Idioma`,
  NAME: `Nome`,
  WELCOME_MESSAGE: `Mensagem de Boas-Vindas`,
  PHONE_NUMBER: `Número de Telefone`,
  PROFILE_IMAGE: `Imagem de Perfil`,
  PHONE_DETAILS: `Detalhes do Telefone`,
  PHONE_DETAILS_PLACEHOLDER: `Adicione informações adicionais ou instruções aqui.\r\nEx: Estou ansioso para te conhecer. Envie-me uma mensagem de texto a qualquer momento.`,
  TIME_FORMAT: `Formato de Hora`,
  TIME_ZONE: `Fuso Horário`,
  COUNTRY: `País`,
});

export const Profile = Object.freeze({
  TITLE: `Perfil`,
  ACCOUNT_SETTINGS: `Configurações da Conta`,
  ACCOUNT_DESCRIPTION: `Informações básicas e configurações`,
  LOGIN: `Entrar`,
  LOGIN_DESCRIPTION: `Configurar conta conectada`,
  MY_ROLE: `Meu Papel`,
  MY_ROLE_DESCRIPTION: `Seu papel e permissões atuais`,
  COOKIE_SETTINGS: `Configurações de Cookies`,
  COOKIE_DESCRIPTION: `Cookies Necessários, de Desempenho, Funcionais, de Segmentação`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `Falha na solicitação de novo calendário`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `Novo calendário conectado com sucesso!`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE: `Falha na solicitação de conexão do Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `Zoom conectado com sucesso!`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Falha na solicitação de salvamento das configurações`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `Suas configurações foram salvas com sucesso!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `Conta trocada com sucesso`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Falha ao trocar de conta. Por favor, tente novamente mais tarde`,
});
