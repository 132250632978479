import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const CHANGE_SEATS_MODAL: ModalName = 'BillingChangeSeatsModal';
export const changeSeatsModalActions = modalsActionsFactory(CHANGE_SEATS_MODAL);
export const changeSeatsModalSelectors = modalsSelectorsFactory(CHANGE_SEATS_MODAL);

export const CHANGE_TERM_MODAL: ModalName = 'BillingChangeTermModal';
export const changeTermModalActions = modalsActionsFactory(CHANGE_TERM_MODAL);
export const changeTermModalSelectors = modalsSelectorsFactory(CHANGE_TERM_MODAL);

export const BUY_LICENSE_MODAL: ModalName = 'BuyLicenseModal';
export const buyLicenseModalActions = modalsActionsFactory(BUY_LICENSE_MODAL);
export const buyLicenseModalSelectors = modalsSelectorsFactory(BUY_LICENSE_MODAL);
