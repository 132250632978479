import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import labels from './labels';

import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_NAME } from '../../../types/constants';
import { InputTextarea } from 'primereact/inputtextarea';
import { ColorPicker, SumoTooltip } from '../../common';

export const BookingTemplateWhatStep = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingTemplatesSelectors.selectWhatName);
  const instructions = useSelector(bookingTemplatesSelectors.selectInstructions);
  const color = useSelector(bookingTemplatesSelectors.selectWhatColor);
  const isNameDuplicate = useSelector(bookingTemplatesSelectors.selectIsNameDuplicate);
  const isNameValid = useSelector(bookingTemplatesSelectors.selectIsNameValid);
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);

  const [nameValue, setNamevalue] = useState(name);
  const [localInstructions, setLocalInstructions] = useState(instructions);

  const handleNameChange = (name: string) => {
    setNamevalue(name);
  };

  const handleNameBlur = () => {
    dispatch(bookingTemplatesActions.updateWhatStep({ customName: nameValue || '' }));
  };

  const handleInstructionsChange = (instructions: string) => {
    setLocalInstructions(instructions);
  };

  const handleInstructionsBlur = () => {
    dispatch(bookingTemplatesActions.updateWhatStep({ instructions: localInstructions }));
  };

  const handleColorChange = (color: string) => {
    dispatch(bookingTemplatesActions.updateWhatStep({ color }));
  };

  return (
    <div className="flex flex-column gap-28px">
      <div className="flex">
        <div className="w-6 pr-10px flex flex-column gap-8px">
          <div className="flex-left-center gap-8px">
            <div className="text-title-xs-med text-heavy-100">{labels.name}</div>
            <SumoTooltip text={labels.nameTooltip} />
          </div>
          <div className="p-fluid">
            <InputText
              type="text"
              placeholder={labels.namePlaceholder}
              value={nameValue || ''}
              onChange={(e) => handleNameChange(e.target.value.trimStart())}
              onBlur={handleNameBlur}
              className={`${!isNameValid ? 'p-invalid' : ''}`}
              maxLength={MAX_LENGTH_NAME}
              disabled={isReadOnly}
            />
          </div>
          {isNameDuplicate && <div className="text-body-s-reg text-tomato-main">{labels.duplicate}</div>}
        </div>
        <div className="w-6 pl-10px flex flex-column gap-8px">
          <div className="flex-left-center gap-8px">
            <div className="text-title-xs-med text-heavy-100">{labels.color}</div>
            <SumoTooltip text={'add color tooltip text'} />
          </div>
          <div>
            <ColorPicker color={color} onColorChange={handleColorChange} disabled={isReadOnly}/>
          </div>
        </div>
      </div>

      <div className="flex flex-column gap-8px">
        <div className="flex-left-center gap-8px">
          <div className="text-title-xs-med text-heavy-100">{labels.meetingInstructions}</div>
          <SumoTooltip text={labels.meetingInstructionsTooltip} />
        </div>
        <div className="p-fluid">
          <InputTextarea
            autoResize
            rows={4}
            value={localInstructions || ''}
            onChange={(e) => handleInstructionsChange(e.target.value.trimStart())}
            onBlur={handleInstructionsBlur}
            placeholder={labels.meetingInstructionsPlaceholder}
            maxLength={MAX_LENGTH_DESCRIPTION}
            disabled={isReadOnly}
          />
        </div>
      </div>
    </div>
  );
};
