import { t } from '../../../../i18n/i18n';

export default {
  amountPerUser: t('OrgConvertTrialModal:AMOUNT_PER_USER'),
  annualContract: t('OrgConvertTrialModal:ANNUAL_CONTRACT'),
  billing: t('OrgConvertTrialModal:BILLING_TITLE'),
  cancel: t('Common:CANCEL'),
  contractEndDate: t('OrgConvertTrialModal:CONTRACT_END_DATE'),
  contractStartDate: t('OrgConvertTrialModal:CONTRACT_START_DATE'),
  convertToCustomer: t('OrgConvertTrialModal:CONVERT_TO_CUSTOMER'),
  featurePricingTier: t('OPSConsoleOrgDetails:FEATURE_PRICING_TIER'),
  immediateProcess: t('OrgConvertTrialModal:IMMEDIATE_PROCESS_TITLE'),
  immediateProcessDesc: t('OrgConvertTrialModal:IMMEDIATE_PROCESS_DESCRIPTION'),
  invoiceDueDate: t('OrgConvertTrialModal:INVOICE_DUE_DATE'),
  manualProcess: t('OrgConvertTrialModal:MANUAL_PROCESS_TITLE'),
  manualProcessDesc: [
    t('OrgConvertTrialModal:MANUAL_PROCESS_DESCRIPTION_PART1'),
    t('OrgConvertTrialModal:MANUAL_PROCESS_DESCRIPTION_PART2'),
    t('OrgConvertTrialModal:MANUAL_PROCESS_DESCRIPTION_PART3'),
  ],
  netTerms: t('OrgConvertTrialModal:NET_TERMS'),
  rules: t('OrgConvertTrialModal:RULES_TITLE'),
  salesforceOpportunity: t('OrgConvertTrialModal:SALESFORCE_OPPORTUNITY'),
  salesforceOpportunityPlaceholder: t('OrgConvertTrialModal:SALESFORCE_OPPORTUNITY_PLACEHOLDER'),
  title: t('OrgConvertTrialModal:TITLE'),
  totalAmount: t('OrgConvertTrialModal:TOTAL_AMOUNT'),
  userLicensesNumber: t('OrgConvertTrialModal:USER_LICENSES_NUMBER'),
  warningMessage: t('OrgConvertTrialModal:WARNING_MESSAGE'),
};
