export const OPSConsoleAccountDetails = Object.freeze({
  ACCOUNT_NAME: `Nome da Conta`,
  ACCOUNT_NOTE: `Nota da Conta`,
  CREATED_AT: `Criado em`,
  CREATED_BY: `Criado por`,
  DUPLICATE_NAME_MESSAGE: `Conta com este nome já existe`,
  TENANT_COLUMN_ADOPTION: `% de Adoção`,
  TENANT_COLUMN_ID: `ID da Org.`,
  TENANT_COLUMN_OWNED: `# de Licenças`,
  TENANT_COLUMN_TERM: `Prazo`,
  TENANT_COLUMN_TYPE: `Tipo`,
  TENANTS: `Orgs`,
});

export const OPSConsoleAccounts = Object.freeze({
  BUTTON_CREATE_ACCOUNT: `Criar Conta`,
  BUTTON_EXPORT: `Exportar`,
  BUTTON_MERGE: `Mesclar`,
  COLUMN_ACCOUNT_NAME: `Nome da Conta`,
  COLUMN_CREATED_BY: `Criado por`,
  COLUMN_CREATED_DATE: `Data de Criação`,
  DELETE_ACCOUNT_ERROR_TOAST: `Não foi possível excluir a conta`,
  DELETE_ACCOUNT_SUCCESS_TOAST: `Conta excluída.`,
  SAVE_ACCOUNT_ERROR_TOAST: `Não foi possível salvar a conta`,
  SAVE_ACCOUNT_SUCCESS_TOAST: `Conta salva.`,
  SEARCH_PLACEHOLDER: `Pesquisar por nome de conta ou domínio`,
  TITLE: `Contas`,
  GET_ACCOUNT_ERROR_TOAST: `Falha na solicitação da conta`,
  GET_ACCOUNTS_ERROR_TOAST: `Falha na solicitação das contas`,
});

export const OPSConsoleAccountsDeleteModal = Object.freeze({
  DESCRIPTION: `Excluir a conta não pode ser desfeito.`,
  TITLE: `Excluir Conta`,
});

export const OPSConsoleAccountsMergeModal = Object.freeze({
  MERGE_TITLE_PART_1: `Mesclar`,
  MERGE_TITLE_PART_2: `com:`,
  NOTE: `Mesclar duas contas não pode ser desfeito.`,
  RULE_1: `Feito para mesclar contas do tipo @gmail.com sob uma marca.`,
  RULE_2: `Não é permitido mesclar dois domínios principais.`,
  RULES: `Regras`,
  SEARCH_PLACEHOLDER: `Buscar nome da conta`,
  TITLE: `Mesclar Contas`,
});
