import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from "../../modals";
export const EXTEND_TRIAL_MODAL_NAME: ModalName = 'ExtendTrialModal';
export const extendTrialModalActions = modalsActionsFactory(EXTEND_TRIAL_MODAL_NAME);
export const extendTrialModalSelectors = modalsSelectorsFactory(EXTEND_TRIAL_MODAL_NAME);

export const EXPIRE_TRIAL_MODAL_NAME: ModalName = 'ExpireTrialModal';
export const expireTrialModalActions = modalsActionsFactory(EXPIRE_TRIAL_MODAL_NAME);
export const expireTrialModalSelectors = modalsSelectorsFactory(EXPIRE_TRIAL_MODAL_NAME);

export const CONVERT_TRIAL_MODAL_NAME: ModalName = 'ConvertTrialModal';
export const convertTrialModalActions = modalsActionsFactory(CONVERT_TRIAL_MODAL_NAME);
export const convertTrialModalSelectors = modalsSelectorsFactory(CONVERT_TRIAL_MODAL_NAME);

export const DELETE_ORG_MODAL_NAME: ModalName = 'DeleteOrgModal';
export const deleteOrgModalActions = modalsActionsFactory(DELETE_ORG_MODAL_NAME);
export const deleteOrgModalSelectors = modalsSelectorsFactory(DELETE_ORG_MODAL_NAME);

export const PAY_LATER_MODAL_NAME: ModalName = 'PayLaterModal';
export const payLaterModalActions = modalsActionsFactory(PAY_LATER_MODAL_NAME);
export const payLaterModalSelectors = modalsSelectorsFactory(PAY_LATER_MODAL_NAME);
