import { Address, AddressInput, PhysicalLocation } from '../../API';
import { CURRENT_TIME_ZONE } from '../../types/constants';
import { Coordinates, DistanceMiKmType } from './types';

export const convertAddressToGoogleMapLink = (address: Address | AddressInput) => {
  let encodedAddress = '';
  let prop: keyof Address;
  for (prop in address) {
    if (prop !== '__typename' && prop !== 'lat' && prop !== 'lng') {
      const value = address[prop];
      encodedAddress += value
        ? value
            .replaceAll(' ', '+')
            .replaceAll(',', '%2C')
            .replaceAll('"', '%22')
            .replaceAll('<', '%3C')
            .replaceAll('>', '%3E')
            .replaceAll('#', '%23')
            .replaceAll('%', '%25')
            .replaceAll('|', '%7C') + '+'
        : '';
    }
  }

  return 'https://www.google.com/maps/search/?api=1&query=' + encodedAddress.slice(0, -1);
};

export const getFullAddress = (address?: Address | AddressInput | null) => {
  return [address?.name, address?.city, address?.zip, address?.state, address?.country]
    .filter((el) => Boolean(el?.replaceAll(' ', '')))
    .join(', ');
};

export const isEqualAddress = (location: PhysicalLocation, address?: AddressInput | null) => {
  return location.address?.lat === address?.lat && location.address?.lng === address?.lng;
};

export const isEqualAddressNoMap = (location: PhysicalLocation, address?: AddressInput | null) => {
  return (
    (location.address?.name?.trim() || location.name?.trim()) === address?.name?.trim() &&
    location.address?.city === address?.city &&
    location.address?.state === address?.state &&
    location.address?.zip === address?.zip &&
    location.address?.country === address?.country
  );
};

export const getAddressCoordinates = (address?: AddressInput | null) => {
  return { lat: +(address?.lat || ''), lng: +(address?.lng || '') };
};

export const getDistanceBetweenPoints = (pointA: Coordinates, pointB: Coordinates, inMiles?: boolean) => {
  const radius = 6371; // Earth Radius in km
  const p = Math.PI / 180;

  const a =
    0.5 -
    Math.cos((pointB.lat - pointA.lat) * p) / 2 +
    (Math.cos(pointA.lat * p) * Math.cos(pointB.lat * p) * (1 - Math.cos((pointB.lng - pointA.lng) * p))) / 2;
  const factor = inMiles ? 0.621371 : 1;

  return 2 * radius * Math.asin(Math.sqrt(a)) * factor;
};

export const getDistanceBetweenPointsMiKm = (pointA: Coordinates, pointB: Coordinates) => {
  const distanceKM = getDistanceBetweenPoints(pointA, pointB);
  const factor = 0.621371;

  return { distanceKM: Math.round(distanceKM), distanceMI: Math.round(distanceKM * factor) } as DistanceMiKmType;
};

export const getCoordinatesByTimeZone = (
  geocoder: google.maps.Geocoder,
  setUserLocation: (coordinates: Coordinates) => void
) => {
  geocoder.geocode(
    {
      address: CURRENT_TIME_ZONE,
    },
    (results, status) => {
      if (status === 'OK' && results && results[0]) {
        const place = results[0];
        setUserLocation({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
      }
    }
  );
};
export const requestGeolocation = (
  geocoder: google.maps.Geocoder,
  setUserLocation: (coordinates: Coordinates) => void,
  setNavigatorInUse?: (value: boolean) => void
) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      setNavigatorInUse && setNavigatorInUse(true);
      const { latitude, longitude } = position.coords;
      setUserLocation({
        lat: latitude,
        lng: longitude,
      });
    },
    () => {
      setNavigatorInUse && setNavigatorInUse(false);
      getCoordinatesByTimeZone(geocoder, setUserLocation);
    }
  );
};

export const getAddressComponent = (
  addressComponents: google.maps.GeocoderAddressComponent[] | undefined,
  type: string
) => {
  return addressComponents?.find((component) => component.types.includes(type))?.long_name || '';
};
