import { useDispatch, useSelector } from 'react-redux';
import { eventActions, eventSelectors, HostData } from '../../../store/publicBookingPage';
import { AppDispatch } from '../../../store/rootStore';
import { Button } from 'primereact/button';
import { ArrowLeftIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import labels from './labels';
import { Avatar } from 'primereact/avatar';
import { ScrollPanel } from 'primereact/scrollpanel';

export const PublicBookingPagePotentialHosts = () => {
  const dispatch = useDispatch<AppDispatch>();
  const potentialHosts = useSelector(eventSelectors.selectPotentialHosts);
  const chosenHostMemberId = useSelector(eventSelectors.selectChosenHostMemberId);
  const showBackButton = useSelector(eventSelectors.selectShowBackButton);

  const handleSelectCard = (value: string) => {
    dispatch(eventActions.setChosenHostMemberId(value));
  };

  const handleBack = () => {
    dispatch(eventActions.setPreviousStep());
  };

  const handleSelect = () => {
    dispatch(eventActions.getAgendaRequest());
  };

  const generateCard = (host: HostData) => {
    if (!host) {
      return null;
    }

    const isSelected = chosenHostMemberId === host?.id;

    return (
      <div
        key={host.id}
        className="w-12 md:w-6 py-4px px-14px"
        onClick={() => !isSelected && handleSelectCard(host.id || '')}
      >
        <div
          className={`h-full p-10px flex-left-center gap-8px border-1 border-radius-10px hover-border-blue-dark cursor-pointer ${
            isSelected ? 'border-blue-main' : 'border-heavy-20'
          }`}
        >
          {host?.avatar ? (
            <Avatar image={host.avatar} size="xlarge" />
          ) : (
            <div className="w-40px h-40px border-radius-8px bg-heavy-1 text-heavy-60">
              <UserCircleIcon className="icon-40px" />
            </div>
          )}

          <div className="flex flex-column gap-4px">
            <div className="text-label-s-med text-heavy-80">{host.name}</div>
            <div className="text-label-xs-reg text-heavy-60">{host.email}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-column">
      <div className="flex-1 overflow-hidden">
        <ScrollPanel>
          <div className="potential-hosts-container">
            <div className="flex flex-wrap -my-4px -mx-14px">{potentialHosts?.map((host) => generateCard(host))}</div>
          </div>
        </ScrollPanel>
      </div>

      <div className="potential-hosts-buttons">
        {showBackButton && (
          <Button className="button-xl min-w-120px flex-center gap-8px" outlined onClick={handleBack}>
            <ArrowLeftIcon className="icon-18px" />
            <div>{labels.back}</div>
          </Button>
        )}
        <Button
          className="button-xl min-w-120px"
          label={labels.select}
          disabled={!chosenHostMemberId}
          onClick={handleSelect}
        />
      </div>
    </div>
  );
};
