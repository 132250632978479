import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { WorkspaceInput, WorkspaceIntegrationType } from '../../API';
import { workspacesActionTypes, workspacesActions } from './actions';
import { authenticationActions, authenticationSagas, authenticationSelectors } from '../authentication';
import { workspacesSelectors } from './selectors';
import {
  GetWorkspacesResponse,
  GetWorkspaceResponse,
  SaveWorkspaceResponse,
  WorkspaceData,
  UpsertWorkspaceResponse,
  DeteleWorkspaceResponse,
} from './types';
import { usersActions, usersSelectors } from '../users';
import { UserAdminDataFullRecord } from '../users/types';
import { notificationsActions } from '../notifications';
import {
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_TOAST,
  ACTIVATE_WORKSPACE_SUCCESS_TOAST,
  CLONE_WORKSPACE_ERROR_TOAST,
  CLONE_WORKSPACE_SUCCESS_TOAST,
  DEFAULT_BOOKING_PAGE_INDEX,
  DELETE_WORKSPACE_ERROR_TOAST,
  DELETE_WORKSPACE_SUCCESS_TOAST,
  GET_WORKSPACES_ERROR_TOAST,
  INACTIVATE_WORKSPACE_SUCCESS_TOAST,
  REMOVE_INTEGRATION_SUCCESS_TOAST,
  SAVE_WORKSPACE_ERROR_TOAST,
  SAVE_WORKSPACE_SUCCESS_TOAST,
} from './constants';
import { deleteWorkspace, getWorkspace, getWorkspaces, saveWorkspace } from './service';
import { userSettingsActions, userSettingsSelectors } from '../userSettings';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { createSelector } from 'reselect';
import { handleServiceError } from '../utils/reduxUtils';

const filterUserRecords = (mainList: UserAdminDataFullRecord[], subList: UserAdminDataFullRecord[]) =>
  mainList.filter((record) => !subList.some((subrecord) => subrecord.email === record.email));

const selectCloneWorkspaceRequest = createSelector(
  workspacesSelectors.selectWorkspace,
  workspacesSelectors.selectCloneName,
  (workspace, cloneName) => ({
    ...workspace,
    id: '',
    name: cloneName.trim(),
    bookingPageIndex: DEFAULT_BOOKING_PAGE_INDEX,
  })
);

function* getWorkspacesSaga() {
  try {
    const tenantId: string = yield select(authenticationSelectors.selectTenantId);
    const [response]: [GetWorkspacesResponse] = yield all([
      call(getWorkspaces, tenantId),
      call(authenticationSagas.getTenant), // to get the full list of workspaces
    ]);
    let workspaces: WorkspaceInput[] = [];
    if (response.workspaces) {
      workspaces = response.workspaces.filter((workspace): workspace is WorkspaceInput => Boolean(workspace));
    }

    const prevWorkspaces: WorkspaceInput[] = yield select(workspacesSelectors.selectWorkspaces);
    const isSuperAdmin: boolean = yield select(userSettingsSelectors.selectIsSuperAdmin);
    // if the list of workspaces updated
    if (isSuperAdmin && prevWorkspaces.length && prevWorkspaces.length !== workspaces.length) {
      const usersWorkspacesFilter: string[] = yield select(usersSelectors.selectFilterWorkspaces);
      // and the default workspace filter is in use on Users page
      if (usersWorkspacesFilter.length === prevWorkspaces.length) {
        yield put(usersActions.setFilter({ workspaceIds: workspaces.map((workspace) => workspace.id) }));
      }
    }

    yield put(workspacesActions.getWorkspacesSuccess(workspaces));
  } catch (error: unknown) {
    yield put(workspacesActions.getWorkspacesFail(error?.toString()));
    yield call(handleServiceError, error, GET_WORKSPACES_ERROR_TOAST, true);
  }
}

function* getWorkspaceSaga(action: ReturnType<typeof workspacesActions.getWorkspaceRequest>) {
  try {
    if (action.type === workspacesActionTypes.GET_WORKSPACE_REQUEST) {
      const response: GetWorkspaceResponse = yield call(getWorkspace, action.id);
      if (response.workspace) {
        yield put(workspacesActions.getWorkspaceSuccess(response.workspace));
        yield put(workspacesActions.updateAccordionIndexes({ what: true }));
      } else {
        throw new Error('Workspace not found');
      }
    }
  } catch (error: unknown) {
    yield put(workspacesActions.getWorkspacesFail(error?.toString()));
    yield call(handleServiceError, error, GET_WORKSPACES_ERROR_TOAST, true);
  }
}

function* saveWorkspaceSaga() {
  try {
    const workspaceData: WorkspaceData = yield select(workspacesSelectors.selectWorkspace);

    if (workspaceData.id) {
      //update workspace record
      yield call(updateWorkspace, workspaceData);
    } else {
      //create workspace record
      yield call(createWorkspace, workspaceData);
    }

    yield call(navigationService.navigateTo, Path.Workspaces);

    yield put(workspacesActions.saveWorkspaceSuccess());
    yield put(notificationsActions.showToast(SAVE_WORKSPACE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(workspacesActions.saveWorkspaceFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_WORKSPACE_ERROR_TOAST);
  }
}

function* deleteWorkspaceSaga() {
  try {
    const workspaceData: WorkspaceData = yield select(workspacesSelectors.selectWorkspace);
    const id = workspaceData.id;
    const usersRemoveFromWorkspace = [...workspaceData.adminList, ...workspaceData.userList].map((userRecord) => ({
      ...userRecord,
      workspaceIds: userRecord.workspaceIds?.filter((workspaceId) => workspaceId !== id),
    }));

    const workspaceInput: DeteleWorkspaceResponse = {
      workspaceId: id,
      usersToDelete: usersRemoveFromWorkspace.map((user) => user.email),
    };

    yield call(deleteWorkspace, workspaceInput);

    yield call(updateCurrentUserWorkspacesList, usersRemoveFromWorkspace, id, 'delete');

    yield call(navigationService.navigateTo, Path.Workspaces);
    yield put(workspacesActions.deleteWorkspacesSuccess());
    yield put(workspacesActions.getWorkspacesRequest());
    yield put(usersActions.getUsersRequest());
    yield put(notificationsActions.showToast(DELETE_WORKSPACE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(workspacesActions.deleteWorkspacesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_WORKSPACE_ERROR_TOAST);
  }
}

function* activateWorkspaceSaga(action: ReturnType<typeof workspacesActions.activateWorkspaceRequest>) {
  try {
    if (action.type === workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST) {
      const id: string = action.payload;
      const workspace: WorkspaceInput | undefined = yield select(workspacesSelectors.selectWorkspaceById(id));
      if (workspace) {
        const updatedWorkspace = {
          workspace: {
            ...workspace,
            isActive: !workspace.isActive,
          },
        };
        yield call(saveWorkspace, updatedWorkspace);

        yield call(navigationService.navigateTo, Path.Workspaces);

        yield put(workspacesActions.activateWorkspaceSuccess());
        yield put(workspacesActions.getWorkspacesRequest());
        yield put(
          notificationsActions.showToast(
            updatedWorkspace.workspace.isActive ? ACTIVATE_WORKSPACE_SUCCESS_TOAST : INACTIVATE_WORKSPACE_SUCCESS_TOAST
          )
        );
      }
    }
  } catch (error: unknown) {
    yield put(workspacesActions.activateWorkspaceFail(error?.toString()));
    yield call(handleServiceError, error, ACTIVATE_INACTIVATE_WORKSPACE_ERROR_TOAST);
  }
}

function* removeIntegrationsSaga(action: ReturnType<typeof workspacesActions.removeIntegrationRequest>) {
  try {
    if (action.type === workspacesActionTypes.REMOVE_INTEGRATION_REQUEST) {
      const integrationType: WorkspaceIntegrationType = action.integrationType;
      const workspace: WorkspaceInput | undefined = yield select(authenticationSelectors.selectCurrentWorkspace);

      if (workspace) {
        const updatedWorkspace = {
          workspace: {
            ...workspace,
            integrations: workspace.integrations?.filter((integration) => integration?.type !== integrationType) || [],
          },
        };
        yield call(saveWorkspace, updatedWorkspace);

        yield put(authenticationActions.getTenantRequest());
        yield put(workspacesActions.removeIntegrationSuccess());
        yield put(notificationsActions.showToast(REMOVE_INTEGRATION_SUCCESS_TOAST));
      }
    }
  } catch (error: unknown) {
    yield put(workspacesActions.removeIntegrationFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_WORKSPACE_ERROR_TOAST);
  }
}

function* createWorkspace(workspaceData: WorkspaceData) {
  const userList = [...workspaceData.adminList, ...workspaceData.userList].map((userRecord) => ({
    ...userRecord,
    workspaceIds: userRecord.workspaceIds?.length ? [...userRecord.workspaceIds, workspaceData.id] : [workspaceData.id],
  }));

  const workspaceInput: UpsertWorkspaceResponse = {
    workspace: {
      id: '',
      name: workspaceData.name,
      isActive: workspaceData.isActive,
      isPhoneRequired: workspaceData.isPhoneRequired,
      noCustomerData: workspaceData.noCustomerData,
      bookingPageIndex: DEFAULT_BOOKING_PAGE_INDEX,
      style: workspaceData.style,
      labels: workspaceData.labels,
    },
    users: userList.map((user) => user.email),
  };

  const response: SaveWorkspaceResponse = yield call(saveWorkspace, workspaceInput);

  yield call(updateCurrentUserWorkspacesList, userList, response.workspace.id, 'add');
}

function* updateWorkspace(workspaceData: WorkspaceData) {
  const workspaceUsers: UserAdminDataFullRecord[] = yield select(
    usersSelectors.selectUsersByWorkspace(workspaceData.id)
  );
  const userList = [...workspaceData.adminList, ...workspaceData.userList];
  // email from workspaceUsers not in userList
  const usersRemoveFromWorkspace = filterUserRecords(workspaceUsers, userList).map((userRecord) => ({
    ...userRecord,
    workspaceIds: userRecord.workspaceIds?.filter((workspaceId) => workspaceId !== workspaceData.id),
  }));

  // email from userList not in workspaceUsers
  const usersAddToWorkspace = filterUserRecords(userList, workspaceUsers).map((userRecord) => ({
    ...userRecord,
    workspaceIds: userRecord.workspaceIds?.length ? [...userRecord.workspaceIds, workspaceData.id] : [workspaceData.id],
  }));

  const workspaceInput: UpsertWorkspaceResponse = {
    workspace: {
      id: workspaceData.id,
      name: workspaceData.name,
      isActive: workspaceData.isActive,
      isPhoneRequired: workspaceData.isPhoneRequired,
      noCustomerData: workspaceData.noCustomerData,
      bookingPageIndex: workspaceData.bookingPageIndex,
      style: workspaceData.style,
      labels: workspaceData.labels,
    },
    users: usersAddToWorkspace.map((user) => user.email),
    usersToDelete: usersRemoveFromWorkspace.map((user) => user.email),
  };

  yield call(saveWorkspace, workspaceInput);

  yield call(updateCurrentUserWorkspacesList, usersAddToWorkspace, workspaceData.id, 'add');
  yield call(updateCurrentUserWorkspacesList, usersRemoveFromWorkspace, workspaceData.id, 'delete');
}

function* updateCurrentUserWorkspacesList(
  usersList: UserAdminDataFullRecord[],
  workspaceId: string,
  action: 'add' | 'delete'
) {
  const userId: string = yield select(authenticationSelectors.selectUserId);
  const userWorkspaces: string[] = yield select(userSettingsSelectors.selectUserWorkspaces);
  const currentWorkspace: string = yield select(authenticationSelectors.selectWorkspaceId);

  if (usersList.some((user) => user.userId === userId)) {
    const userNewWorkspaces =
      action === 'add' ? [...userWorkspaces, workspaceId] : userWorkspaces.filter((id) => id !== workspaceId);
    yield put(userSettingsActions.updateUserDetails({ workspaceIds: userNewWorkspaces }));
    if (action === 'delete' && currentWorkspace === workspaceId) {
      yield put(authenticationActions.updateUserDataCore({ workspaceId: userNewWorkspaces[0] }));
    }
  }
}

function* cloneWorkspaceSaga() {
  try {
    const workspaceData: WorkspaceData = yield select(selectCloneWorkspaceRequest);
    yield call(createWorkspace, workspaceData);

    yield put(workspacesActions.cloneWorkspaceSuccess());
    yield put(notificationsActions.showToast(CLONE_WORKSPACE_SUCCESS_TOAST));
    yield put(usersActions.getUsersRequest());
    yield put(workspacesActions.getWorkspacesRequest());
  } catch (error: unknown) {
    yield put(workspacesActions.cloneWorkspaceFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_WORKSPACE_ERROR_TOAST);
  }
}

export function* watchWorkspacesSaga() {
  yield takeLatest(workspacesActionTypes.GET_WORKSPACES_REQUEST, getWorkspacesSaga);
  yield takeLatest(workspacesActionTypes.GET_WORKSPACE_REQUEST, getWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.ACTIVATE_WORKSPACE_REQUEST, activateWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.SAVE_WORKSPACE_REQUEST, saveWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.DELETE_WORKSPACES_REQUEST, deleteWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.CLONE_WORKSPACE_REQUEST, cloneWorkspaceSaga);
  yield takeLatest(workspacesActionTypes.REMOVE_INTEGRATION_REQUEST, removeIntegrationsSaga);
}

export const workspacesSaga = {
  getWorkspaces: getWorkspacesSaga,
};
