export const OPSConsoleUsers = Object.freeze({
  ACCOUNT_ID: `Account ID`,
  EXPORT: `Export`,
  SEARCH_PLACEHOLDER: `Search by name or email`,
  SUBHEADER_TEXT: `Most Active Users`,
  VIEW_AS_USER: `View as User`,
  REGISTRATION_DATE: `1st Registration Date`,
  RENEWAL_DATE: `Renewal Date`,
  USER_INFO_TITLE: `User Info`,
  LICENSE_STATUS_TITLE: `License Status`,
  CUSTOM_ROLE: `Custom Role`,
});

export const OPSConsoleUsersToastsNotifications = Object.freeze({
  ACTIVATE_USER_SUCCESS_MESSAGE: `User successfully activated`,
  DEACTIVATE_USER_FAIL_MESSAGE: `Deactivate/Activate user request fail`,
  DEACTIVATE_USER_SUCCESS_MESSAGE: `User successfully deactivated`,
  DELETE_USER_FAIL_MESSAGE: `Delete user request fail`,
  DELETE_USER_SUCCESS_MESSAGE: `User successfully deleted`,
  GET_USER_DETAILS_FAIL_MESSAGE: `Get user details request fail`,
  GET_USERS_FAIL_MESSAGE: `Get users request fail`,
});

export const ViewAsUser = Object.freeze({
  BACK_TO_OPS_CONSOLE: `Back to OPS Console`,
  MODAL_DESCRIPTION_PART1: `View As User mode is active.`,
  MODAL_DESCRIPTION_PART2: `To continue using the OPS Console, please finish the View As User session by selecting one of the following
  options:`,
  MODAL_DESCRIPTION_PART3: `Click the Back to OPS Console button.`,
  MODAL_DESCRIPTION_PART4: `Press the Reset option here.`,
  VIEW_AS_USER_MODE: `View As User Mode`,
  VIEWING_SUMO1_TITLE: `Viewing SUMO1 as user.`,
});
