export const SmartAlerts = Object.freeze({
  TITLE: `Smarte Benachrichtigungen`,
  CREATE_ALERT: `Benachrichtigung erstellen`,
  CLONE_ALERT_TITLE: `Name der geklonten smarten Benachrichtigung`,
  CLONE_TEMPLATE_TITLE: `Name der geklonten smarten Benachrichtigungsvorlage`,
  DIALOG_PLACEHOLDER: `Erinnerung an den Eingeladenen, Danke senden, etc...`,
  EMPTY_LIST_MESSAGE: `Erstellen Sie eine Benachrichtigung oder passen Sie Ihre Suche oder Filter an.`,
  PERSONAL_LABEL: `Persönlich`,
  SHARED_LABEL: `Geteilt`,
  TEMPLATES_LABEL: `Vorlagen`,
  HIDDEN_LABEL: `Versteckt`,
  UNHIDDEN_LABEL: `Eingeblendet`,
  BASIC_ALERTS: `Grundlegende Benachrichtigungen`,
  ADD_SMART_ALERT: `+ Intelligente Benachrichtigung hinzufügen`,
  DELETE_DESCRIPTION: `Sind Sie sicher, dass Sie löschen möchten`,
  BULK_DELETE_DESCRIPTION: `ausgewählte Smart-Benachrichtigungen`,
  DELETE_WARNING_ONE: `Warnung: Das Löschen dieses Smart Alerts wird es auch von den Buchungsseiten und Buchungsvorlagen entfernen, in denen es verwendet wird.`,
  DELETE_WARNING_MULTIPLE: `Warnung: Das Löschen dieser Smart Alerts wird sie auch von den Buchungsseiten und Buchungsvorlagen entfernen, in denen sie verwendet werden.`,
});

export const SmartAlertsToastsNotifications = Object.freeze({
  GET_SMART_TYPES_ERROR_MESSAGE: `Abruf der intelligenten Benachrichtigungen fehlgeschlagen`,
  CLONE_SMART_TYPE_ERROR_MESSAGE: `Klonen der intelligenten Benachrichtigungen fehlgeschlagen`,
  CLONE_SMART_ALERT_SUCCESS_MESSAGE: `Intelligente Benachrichtigung erfolgreich geklont`,
  CLONE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Vorlage für intelligente Benachrichtigungen erfolgreich geklont`,
  DELETE_SMART_TYPES_SUCCESS_MESSAGE: `Intelligente Benachrichtigungen erfolgreich gelöscht`,
  DELETE_SMART_TYPES_ERROR_MESSAGE: `Löschen der intelligenten Benachrichtigungen fehlgeschlagen`,
  GET_SMART_ALERT_ERROR_MESSAGE: `Abruf der intelligenten Benachrichtigung fehlgeschlagen`,
  GET_SMART_TEMPLATE_ERROR_MESSAGE: `Abruf der Vorlage für intelligente Benachrichtigungen fehlgeschlagen`,
  UPSERT_SMART_TYPE_ERROR_MESSAGE: `Einfügen oder Aktualisieren der intelligenten Benachrichtigungen fehlgeschlagen`,
  CREATE_SMART_ALERT_SUCCESS_MESSAGE: `Smart-Benachrichtigung erfolgreich erstellt`,
  CREATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Smart-Benachrichtigungsvorlage erfolgreich erstellt`,
  UPDATE_SMART_ALERT_SUCCESS_MESSAGE: `Smart-Benachrichtigung erfolgreich aktualisiert`,
  UPDATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Smart-Benachrichtigungsvorlage erfolgreich aktualisiert`,
});

export const EditSmartAlert = Object.freeze({
  CREATE_TITLE: `Alarm erstellen`,
  CREATE_CUSTOM_TITLE: `+ Erstellen Sie Ihren benutzerdefinierten Alarm`,
  DESCRIPTION: `Erstellen Sie Ihren benutzerdefinierten Alarm oder verwenden Sie Vorlagen für eine schnelle Alarmerstellung`,
  EDIT_TITLE: `Alarm bearbeiten`,
  ICON: `Icon`,
  NAME: `Name`,
  NAME_PLACEHOLDER: `Alarmname eingeben`,
  HIDE: `Ausblenden`,
  HIDE_DESCRIPTION: `Nicht in der Smart Alerts Liste anzeigen`,
  ALERT: `Alarm`,
  TEMPLATE: `Vorlage`,
  APPLY_TO: `Anwenden auf`,
  SHARE_WITH: `Teilen mit`,
  WORKSPACES: `Arbeitsbereiche`,
  WHEN_TITLE: `Wenn dies passiert`,
  DO_THIS: `Mache dies`,
  PERSONALIZE: `Personalisieren`,
  ADD_VARIABLE: `Variable hinzufügen`,
  SUBJECT: `Betreff`,
  BODY: `Inhalt`,
  USE_TEMPLATE: `Vorlage verwenden`,
  HOW_LONG_AFTER: `Wie lange danach`,
  IMMEDIATELY: `Sofort`,
  CUSTOM_NAME: `Benutzerdefinierter Name`,
  TEMPLATE_DESCRIPTION_PLACEHOLDER: `Beschreibung der intelligenten Vorlage eingeben`,
  USE_ALERT: `Benachrichtigung verwenden`,
  SELECT_SMART_TITLE: `Benachrichtigung auswählen`,
  SELECT_ALERT_DESCRIPTION: `Wählen Sie eine intelligente Benachrichtigung aus oder erstellen Sie Ihre eigene`,
  BOOKING_PAGES: `Buchungsseiten`,
  BOOKING_TEMPLATES: `Buchungsvorlagen`,
  SMS_TO_HOST_TOOLTIP: `Wenn der Gastgeber die Telefonnummer nicht hat, wird die Textbenachrichtigung nicht gesendet`,
  NEEDS_YOUR_ATTENTION: `Benötigt Ihre Aufmerksamkeit`,
});

export const SmartAlertsPersonalize = Object.freeze({
  EMAIL_TO_INVITE_SUBJECT: `Erinnerung: &ltMeeting Name&gt mit &ltHost Name&gt um &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_INVITE_BODY: `Hallo &ltInvitee Name&gt,<br><br>Dies ist eine freundliche Erinnerung, dass Ihr &ltMeeting Name&gt mit &ltHost Name&gt um &ltMeeting Time&gt, &ltMeeting Date&gt ist.<br><br><b>Ort</b><br>&ltMeeting Location&gt<br><br>&ltMeeting Link ansehen oder bearbeiten&gt`,
  EMAIL_TO_HOST_SUBJECT: `Erinnerung: &ltMeeting Name&gt mit &ltInvitee Name&gt um &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_HOST_BODY: `Hallo &ltHost Name&gt,<br><br>Dies ist eine freundliche Erinnerung, dass Ihr &ltMeeting Name&gt mit &ltInvitee Name&gt um &ltMeeting Time&gt, &ltMeeting Date&gt ist.<br><br><b>Ort</b><br>&ltMeeting Location&gt<br><br>&ltMeeting Link ansehen oder bearbeiten&gt`,
  SMS_TO_INVITE: `Dies ist eine freundliche Erinnerung, dass Ihr &ltMeeting Name&gt mit &ltHost Name&gt um &ltMeeting Time&gt, &ltMeeting Date&gt ist. Ansehen oder bearbeiten &ltMeeting Link&gt`,
  SMS_TO_HOST: `Dies ist eine freundliche Erinnerung, dass Ihr &ltMeeting Name&gt mit &ltInvitee Name&gt um &ltMeeting Time&gt, &ltMeeting Date&gt ist. Ansehen oder bearbeiten &ltMeeting Link&gt`,
  TITLE_EMAIL_TO_HOST: `E-Mail an Gastgeber personalisieren`,
  TITLE_EMAIL_TO_INVITE: `E-Mail an Eingeladenen personalisieren`,
  TITLE_SMS_TO_HOST: `SMS-Erinnerung an Gastgeber personalisieren`,
  TITLE_SMS_TO_INVITE: `SMS-Erinnerung an Eingeladenen personalisieren`,
});

export const SmartAlertsWhen = Object.freeze({
  HOW_LONG_MEETING_BOOKED: `Wie lange nach der Buchung eines neuen Meetings?`,
  MEETING_ENDS: `Meeting endet`,
  MEETING_BEFORE_START: `Vor Beginn des Meetings`,
  MEETING_CANCELLED: `Meeting ist abgesagt`,
  MEETINGS_STARTS: `Meeting beginnt`,
  MEETING_RESCHEDULED: `Meeting wurde verschoben`,
  HOW_LONG_MEETING_ENDS: `Wie lange nach dem Ende des Meetings?`,
  HOW_LONG_MEETING_BEFORE_START: `Wie lange vor Beginn des Meetings?`,
  HOW_LONG_MEETING_CANCELLED: `Wie lange nach der Absage des Meetings?`,
  HOW_LONG_MEETINGS_STARTS: `Wie lange nach Beginn des Meetings?`,
  HOW_LONG_MEETING_RESCHEDULED: `Wie lange nach der Verschiebung des Meetings?`,
  MINUTE: `Minute`,
  MINUTES: `Minuten`,
  HOUR: `Stunde`,
  HOURS: `Stunden`,
  DAY: `Tag`,
  DAYS: `Tage`,
  WHEN_MEETING_BOOKED: `Wenn ein neues Meeting gebucht wird`,
  WHEN_MEETING_STARTS: `Wenn das Meeting beginnt`,
  WHEN_MEETING_ENDS: `Wenn das Meeting endet`,
  WHEN_MEETING_CANCELED: `Wenn das Meeting abgesagt wird`,
  WHEN_MEETING_RESCHEDULED: `Wenn das Meeting verschoben wird`,
  AFTER: `nach `,
});

export const SmartAlertsWhom = Object.freeze({
  EMAIL_TO_INVITEE: `E-Mail an den Eingeladenen senden`,
  EMAIL_TO_HOST: `E-Mail an den Gastgeber senden`,
  SMS_TO_INVITEE: `SMS-Erinnerung an den Eingeladenen senden`,
  SMS_TO_HOST: `SMS-Erinnerung an den Gastgeber senden`,
  SEND_ALL: `E-Mail und SMS-Erinnerung an Eingeladenen und Gastgeber senden`,
  SEND_EMAIL_SMS_INVITEE: `E-Mail und SMS-Erinnerung an den Eingeladenen senden`,
  SEND_EMAIL_SMS_HOST: `E-Mail und SMS-Erinnerung an den Gastgeber senden`,
});

export const SmartAlertsWarningModal = Object.freeze({
  WARNING_BP_AND_BT: `Warnung: Diese Änderung betrifft die nächsten Buchungsseiten und Buchungsvorlagen:`,
  WARNING_BP: `Warnung: Diese Änderung betrifft die nächsten Buchungsseiten:`,
  WARNING_BT: `Warnung: Diese Änderung betrifft die nächsten Buchungsvorlagen:`,
  TITLE: `Warnung`,
  UPDATE_ALL: `Alle aktualisieren`,
  CREATE_NEW: `Neue Smart-Benachrichtigung erstellen`,
});
