import { CloudArrowUpIcon, DocumentTextIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FileUpload } from 'primereact/fileupload';
import labels from './labels';
import { Button } from 'primereact/button';

type CSVFileUploadProps = {
  uploadedFile: File | null;
  setUploadedFile: (value: File | null) => void;
};

export const CSVFileUpload = ({ uploadedFile, setUploadedFile }: CSVFileUploadProps) => {
  const importTemplate = () => (
    <div className="sumo-card-bg flex-center h-full">
      <div className="flex-center gap-8px">
        <div className="w-48px h-48px text-heavy-60">
          <CloudArrowUpIcon width={48} height={48} />
        </div>
        <div className="flex flex-column gap-4px">
          <label htmlFor="import" className="text-label-xs-reg text-blue-main hover-text-blue-dark cursor-pointer">
            {labels.descriptionPart1}
          </label>
          <input
            id="import"
            name="import"
            type="file"
            accept=".csv"
            className="hidden"
            onChange={(e) => e.target.files && setUploadedFile(e.target.files[0])}
          />
          <div className="text-label-xs-reg text-heavy-60">{labels.descriptionPart2}</div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <FileUpload
        name="locationsImport"
        accept=".csv"
        headerClassName="hidden"
        contentClassName="p-0 border-0 h-200px"
        auto
        customUpload
        uploadHandler={(e) => {
          setUploadedFile(e.files[0]);
        }}
        itemTemplate={importTemplate}
        emptyTemplate={importTemplate}
        pt={{
          progressbar: { root: { className: 'hidden' } },
          message: { root: { className: 'hidden' } },
          file: { className: 'h-200px' },
        }}
      />
      {uploadedFile && (
        <div className="mt-16px -mb-16px w-6 flex-center gap-6px">
          <div className="w-full flex-left-center border-1 border-heavy-20 border-radius-6px p-8px gap-8px">
            <div className="w-32px h-32px bg-heavy-1 text-heavy-60 border-radius-6px flex-center">
              <DocumentTextIcon className="icon-20px" />
            </div>
            <div className="flex flex-column gap-4px">
              <div className="text-label-xs-med text-heavy-80">{uploadedFile.name}</div>
              <div className="text-label-xs-reg text-heavy-60">{uploadedFile.size} KB</div>
            </div>
          </div>
          <Button onClick={() => setUploadedFile(null)} text>
            <XMarkIcon className="icon-16px" />
          </Button>
        </div>
      )}
    </>
  );
};
