import { XCircleIcon } from '@heroicons/react/24/outline';
import { Path } from '../../../routing/pathes';
import { navigationService } from '../../../services/NavigationService';
import labels from './labels';

export const InviteExpiredToast = () => {
  const handleSignUp = () => {
    navigationService.navigateTo(Path.Landing);
    window.location.reload();
  };

  return (
    <div className="flex gap-12px">
      <XCircleIcon className="p-toast-message-icon" />
      <div className="flex-1 flex flex-column gap-4px">
        <div className="text-title-xs-med">{labels.title}</div>
        <div className="text-body-s-reg text-heavy-80">
          {labels.messagePart1}
          <span className="underline cursor-pointer" onClick={handleSignUp}>
            {labels.messagePart2}
          </span>
          .
        </div>
      </div>
    </div>
  );
};
