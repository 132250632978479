export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Hintergrund`,
  BUTTONS_LABEL: `Schaltflächen`,
  FONT_COLORS_TITLE: `Schriftfarben`,
  FONT_FAMILIES_TITLE: `Schriftarten`,
  HEADER_FONT_LABEL: `Header-Schrift`,
  HEADER_LABEL: `Kopfzeile`,
  MAIN_TEXT_FONT_LABEL: `Hauptschriftart`,
  MAIN_TEXT_LABEL: `Haupttext`,
  PRIMARY_LABEL: `Primär`,
  SITE_COLORS_TITLE: `Website-Farben`,
  SUB_HEADER_FONT_LABEL: `Unterkopfzeile Schriftart`,
  SUB_HEADER_LABEL: `Unterkopfzeile`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `SUMO wird automatisch Ihren Kalender auf beschäftigte Zeiten überprüfen und neue Besprechungen hinzufügen, sobald sie geplant sind. Sie können Ihren Kalender später jederzeit trennen.`,
  INSTRUCTION_STEP_A1: `Wir werden `,
  INSTRUCTION_STEP_A2: ` auf Konflikte überprüfen.`,
  INSTRUCTION_STEP_B: `Wir werden Besprechungen zum Kalender hinzufügen für `,
  INSTRUCTION_STEP_C: `Wir werden alle Daten während der Übertragung verschlüsseln und Ihnen Optionen bezüglich Ihrer Daten geben.`,
  INSTRUCTION_TITLE: `So wird SUMO mit `,
  STEP_MESSAGE: `Lass uns das tun!`,
  TITLE: `Ihr Kalender ist verbunden!`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Ich akzeptiere die`,
  ACCEPT_TERMS_PART2: `Nutzungsbedingungen`,
  ACCEPT_TERMS_PART3: `und`,
  ACCEPT_TERMS_PART4: `Datenschutzrichtlinie`,
  BENEFITS_DESCRIPTION: `Die beste SUMO1-Erfahrung ist die Verwendung von SUMO direkt in Ihrem Posteingang, indem Sie die Chrome-Erweiterung installieren. Verwenden Sie SUMO in:`,
  BENEFITS_LIST_PART1: `Google Gmail & Kalender`,
  BENEFITS_LIST_PART2: `Microsoft Posteingang & Kalender`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Vorteile`,
  CHROME_EXTENSION: `Chrome-Erweiterung`,
  REQUIREMENTS_DESCRIPTION: `Sie müssen den Google Chrome-Browser verwenden.`,
  REQUIREMENTS_TITLE: `Anforderungen`,
  SUMO1_FOR_GOOGLE: `Installieren Sie "SUMO1 für Gmail"`,
  SUMO1_FOR_MICROSOFT: `Installieren Sie "SUMO1 für Outlook"`,
  TITLE: `Chrome-Erweiterung installieren`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  TITLE_ADMIN: `Teamverfügbarkeit festlegen`,
  TITLE_USER: `Verfügbarkeit festlegen`,
  MESSAGE_ADMIN: `Erstellen Sie den anfänglichen Standardschaltplan, der angibt, wann Ihr Team normalerweise für Termine verfügbar ist.`,
  MESSAGE_USER: `Erstellen Sie den anfänglichen Standardschaltplan, der angibt, wann Sie normalerweise für Termine verfügbar sind.`,
  MINIMUM_DAYS_ERROR_TEXT: `Bitte wählen Sie mindestens einen Tag mit verfügbaren Stunden`,
  SETUP_DAYS_INPUT_LABEL: `Verfügbare Tage`,
  SETUP_HOURS_INPUT_LABEL: `Verfügbare Stunden`,
  STEP_MESSAGE: `Weiter so!`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Für geplante Anrufe, welche Nummer sollten Gäste anrufen? Die meisten Benutzer geben ihre Mobilnummer für SMS-Benachrichtigungen an, aber Sie können dies später ändern.`,
  PHONE_DETAILS_LABELS: `Zusätzliche Informationen oder Anweisungen`,
  PHONE_DETAILS_PLACEHOLDER: `Z.B.: Ich freue mich auf das Treffen. SMS jederzeit an mich senden.`,
  PHONE_PLACEHOLDER: `Telefonnummer`,
  STEP_MESSAGE: `Sie sind fast da!`,
  TITLE: `Telefonnummer festlegen`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Fehler bei der Speicherung der Schnellkonfiguration`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Sie sind jetzt bereit für eine einfache Terminplanung.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Einrichtung abgeschlossen!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  DEFAULT_DESCRIPTION: `Standard-Videokonferenz`,
  DESCRIPTION: `Optional, aber empfohlen für virtuelle Meetings.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  STEP_MESSAGE: `Lass uns das tun!`,
  TITLE: `Videokonferenz-Konto verbinden`,
  ZOOM_TITLE: `ZOOM-Meetings`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  DESCRIPTION: `Laden Sie Ihr Logo hoch, um auf allen Buchungsseiten angezeigt zu werden. Weitere Branding-Einstellungen können später im Arbeitsbereich vorgenommen werden.`,
  SAVE_BUTTON: `Speichern & Einrichtung abschließen `,
  STEP_MESSAGE: `Sie sind fast da!`,
  TITLE: `Fügen Sie Ihr Logo hinzu`,
});
