export const EditTeam = Object.freeze({
  AVAILABLE_FOR_USE: `Disponible para su uso`,
  AVAILABLE_FOR_USE_TOOLTIP: `Esto activará o desactivará este equipo en todas las herramientas de reserva, incluidas las páginas de reserva, las plantillas de reserva y más.`,
  EDIT_TEAM: `Editar equipo`,
  MEMBERS_LABEL: `Agregar miembros del equipo`,
  MEMBERS_PLACEHOLDER: `Buscar y agregar miembro del equipo`,
  ROUND_ROBIN_DESC_PART1: `Round-Robin`,
  ROUND_ROBIN_DESC_PART2: `Para que SUMO1 asigne automáticamente un anfitrión a una reunión, debe crear un equipo (también llamado pool de recursos) de usuarios SUMO1. Luego, los usuarios y los administradores pueden agregar equipos a las reuniones a través de las páginas de reserva o el buzón de SUMO (extensión de Chrome).`,
  TEAM_NAME: `Mostrar nombre`,
  TEAM_NAME_TOOLTIP: `Este texto se mostrará a los invitados en la página pública de reservas. Por ejemplo: «Equipo de ingenieros de ventas» o «Equipo de dentistas» serían buenos nombres.`,
  TEAM_NAME_PLACEHOLDER: `Ingrese un nombre de equipo`,
  WORKSPACES: `Espacios de trabajo`,
  WORKSPACES_LABEL: `Espacio de trabajo predeterminado`,
  WORKSPACES_PLACEHOLDER: `Buscar y agregar espacio de trabajo`,
  DEFAULT_WORKSPACE_TOOLTIP: `Espacio de trabajo donde se creó el equipo y se puede modificar o eliminar.`,
});

export const TeamsToastsNotifications = Object.freeze({
  ACTIVATE_TEAM_SUCCESS_MESSAGE: `Equipo activado con éxito`,
  DEACTIVATE_ACTIVATE_TEAM_ERROR_MESSAGE: `Error al desactivar/activar el equipo`,
  DEACTIVATE_TEAM_SUCCESS_MESSAGE: `Equipo desactivado con éxito`,
  DELETE_TEAM_ERROR_MESSAGE: `Error al eliminar el equipo`,
  DELETE_TEAM_SUCCESS_MESSAGE: `Equipo eliminado con éxito`,
  GET_TEAMS_FAIL_MESSAGE: `Error al solicitar los equipos`,
  SAVE_TEAM_ERROR_MESSAGE: `Error al solicitar guardar el equipo`,
  SAVE_TEAM_SUCCESS_MESSAGE: `Equipo guardado con éxito`,
});

export const Teams = Object.freeze({
  CREATE_TEAM: `Crear equipo`,
  CREATED_BY: `Creado por`,
  CREATED_DATE: `Fecha de creación`,
  DEACTIVATE_TEAM_DESCRIPTION: `¿Estás seguro de que quieres desactivar`,
  DEACTIVATE_TEAM_TITLE: `Desactivar equipo`,
  DELETE_TEAM_DESCRIPTION: `¿Estás seguro de que quieres eliminar`,
  DELETE_TEAM_TITLE: `Eliminar equipo`,
  MEMBERS: `Miembros`,
  NAME: `Nombre`,
  TITLE: `Equipos`,
  TOOLTIP: `Los equipos son importantes para definir si desea que SUMO asigne automáticamente un anfitrión o coanfitrión a una reunión, según la lógica de asignación (Round-Robin, etc.).`,
  WORKSPACE: `Espacio de trabajo`,
});
