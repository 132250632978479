import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { countriesTimeZonesService } from './CountriesTimeZoneService';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface timeZoneItem {
  city: string;
  offsetHours: number;
  utcOffsetStr: string;
  time: string;
  timeZone: string;
}

export interface groupedTimeZone {
  region: string;
  zones: Array<timeZoneItem>;
}

interface TimeZonesData {
  [key: string]: timeZoneItem;
}

class TimeZoneService {
  private timeZones = countriesTimeZonesService.getAllTimeZones();
  private regions = [
    'Africa',
    'America',
    'Antarctica',
    'Arctic',
    'Asia',
    'Atlantic',
    'Australia',
    'Europe',
    'Indian',
    'Pacific',
  ];
  private validTimeZones = this.timeZones.filter((timeZone) => {
    const region = timeZone.name.split('/')[0];
    return this.regions.includes(region);
  });

  private formatOffsetToString(offset: number): string {
    const hours = Math.floor(Math.abs(offset));
    const minutes = Math.abs(offset % 1) * 60;
    const sign = offset >= 0 ? '+' : '-';

    return `UTC${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  getCommonTimeZonesByRegion(): groupedTimeZone[] {
    const groupedTimeZones = this.validTimeZones.reduce<groupedTimeZone[]>((result, timeZone) => {
      const nameList = timeZone.name.split('/');
      const region = nameList[0]; // Get the region name
      const city = (nameList[2] ? nameList[2] : nameList[1]).replaceAll('_', ' '); // Get the city name
      const previous = result[result.length - 1] || {}; // previous item in result list
      const offset = dayjs().tz(timeZone.name).utcOffset() / 60; // Get timezone offset in hours
      const zone = {
        city,
        offsetHours: offset,
        utcOffsetStr: this.formatOffsetToString(offset),
        timeZone: timeZone.name,
        time: '',
      };

      return region === previous.region
        ? result.map((item) =>
            region === item.region
              ? { ...item, zones: [...item.zones, zone].sort((a, b) => a.offsetHours - b.offsetHours) } // Sort time zones by their offset
              : item
          )
        : [...result, { region, zones: [zone] }];
    }, []);

    return groupedTimeZones;
  }

  getTimeZonesDataByName(): TimeZonesData {
    return this.validTimeZones.reduce<TimeZonesData>((result, timeZone) => {
      const nameList = timeZone.name.split('/');
      const offset = dayjs().tz(timeZone.name).utcOffset() / 60; // Get timezone offset in hours
      return {
        ...result,
        [timeZone.name]: {
          city: (nameList[2] ? nameList[2] : nameList[1]).replaceAll('_', ' '), // Get the city name
          offsetHours: offset,
          utcOffsetStr: this.formatOffsetToString(offset),
          timeZone: timeZone.name,
          time: dayjs().tz(timeZone.name).format('HH:mm'),
        },
      };
    }, {});
  }

  getTimeZonesDataList(): timeZoneItem[] {
    return Object.values(this.getTimeZonesDataByName()).sort((a, b) => a.offsetHours - b.offsetHours);
  }

  getCurrentTimeZone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return countriesTimeZonesService.getTimezoneByName(timeZone)?.aliasOf || timeZone;
  }

  getTimeForTimeZone(zone: string) {
    if (!this.timeZones.some((timeZone) => timeZone.name === zone)) throw new Error(`Invalid timezone: ${zone}`);
    return dayjs().tz(zone).format('HH:mm');
  }

  isUserFromUSOrCanada(): boolean {
    const currentUserTimeZone = this.getCurrentTimeZone();
    return this.timeZones.some(timeZone => {
      const region = timeZone.name.split('/')[0];
      return (region === 'America' && timeZone.name.includes(currentUserTimeZone));
    });
  }
}

export const timeZoneService = new TimeZoneService();
