import { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import labels from './labels';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import {
  WorkspaceWhoStep,
  cannotDeleteWorkspaceNameModalActions,
  deleteWorkspaceNameModalActions,
} from '../../../store/workspaces';
import { workspacesActions } from '../../../store/workspaces/actions';
import { Path } from '../../../routing';
import { usersSelectors } from '../../../store/users';
import { WorkspaceInput } from '../../../API';
import { navigationService } from '../../../services/NavigationService';
import { userSettingsSelectors } from '../../../store/userSettings';
import { AppDispatch } from '../../../store/rootStore';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

interface IWorkspaceCard {
  workspace: WorkspaceInput;
}

export const WorkspaceCard = memo(({ workspace }: IWorkspaceCard) => {
  const { id, name, isActive } = workspace;
  const dispatch = useDispatch<AppDispatch>();
  const menu = useRef<Menu>(null);
  const adminList = useSelector(usersSelectors.selectAdminUsersByWorkspace(id));
  const userList = useSelector(usersSelectors.selectStandardUsersByWorkspace(id));
  const isWorkspacesCreate = useSelector(userSettingsSelectors.selectWorkspacesCreate);
  const isWorkspacesEdit = useSelector(userSettingsSelectors.selectWorkspacesEdit);
  const isWorkspacesDelete = useSelector(userSettingsSelectors.selectWorkspacesDelete);

  let blockCardClick = false;

  const workspaceInfo: WorkspaceWhoStep = {
    adminList,
    userList,
  };

  const handleEdit = () => {
    if (blockCardClick) {
      blockCardClick = false;
      return;
    }
    dispatch(workspacesActions.getWorkspaceRequest(id));
    dispatch(workspacesActions.setWorkspace({ ...workspace, ...workspaceInfo }));
    navigationService.navigateTo(Path.EditWorkspace);
  };

  const handleActivate = () => {
    dispatch(workspacesActions.activateWorkspaceRequest(workspace.id));
  };

  const handleActivateButton = () => {
    blockCardClick = true;
    handleActivate();
  };

  const isDeletable = () => {
    return (
      !adminList.some((admin) => admin.workspaceIds && admin.workspaceIds.length <= 1) &&
      !userList.some((user) => user.workspaceIds && user.workspaceIds.length <= 1)
    );
  };

  const handleDelete = () => {
    dispatch(workspacesActions.setWorkspace({ ...workspace, ...workspaceInfo }));
    if (isDeletable()) {
      dispatch(deleteWorkspaceNameModalActions.openModal());
    } else {
      dispatch(cannotDeleteWorkspaceNameModalActions.openModal());
    }
  };

  const handleClone = () => {
    dispatch(workspacesActions.setWorkspace({ ...workspace, ...workspaceInfo }));
    dispatch(workspacesActions.cloneWorkspaceThunk());
  };

  const menuItems = [
    { label: labels.edit, icon: 'pi pi-fw pi-pencil', command: handleEdit, visible: isWorkspacesEdit },
    {
      label: isActive ? labels.deactivate : labels.activate,
      icon: isActive ? 'pi pi-fw pi-lock' : 'pi pi-fw pi-lock-open',
      command: handleActivate,
      visible: isWorkspacesEdit,
    },
    {
      label: labels.clone,
      icon: 'pi pi-fw pi-clone',
      command: handleClone,
      visible: isWorkspacesCreate,
    },
    { label: labels.delete, icon: 'pi pi-fw pi-trash', command: handleDelete, visible: isWorkspacesDelete },
  ];

  const getAdminInfo = () => {
    if (adminList.length) {
      const name = adminList.find((record) => record.fullName)?.fullName;
      return `${labels.admin}: ${name ? name : adminList[0].email} ${
        adminList.length > 1 ? ` + ${adminList.length - 1}` : ''
      }`;
    } else {
      return `${labels.admin}: ${labels.none}`;
    }
  };

  return (
    <div className="sumo-card action-card flex flex-column h-full" onClick={handleEdit}>
      <div className="flex pt-22px px-18px">
        <div className="flex-1 text-title-lg-med">{name}</div>
        <div className="flex gap-2px -my-1px h-24px">
          {!isActive && (
            <div className="text-label-s-med text-heavy-60 bg-heavy-10 border-radius-6px py-6px px-8px">
              {labels.inactive}
            </div>
          )}
          {(isWorkspacesEdit || isWorkspacesDelete) && (
            <div
              className="-my-4px -mr-16px action-button"
              onClick={(e) => {
                blockCardClick = true;
                menu.current?.toggle(e);
              }}
            >
              <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
              <EllipsisVerticalIcon className="icon-20px" />
            </div>
          )}
        </div>
      </div>

      {/* <div className="pt-4px px-18px text-body-s-reg text-heavy-60">
        {`${when?.duration?.count} ${getTimeUnitLabel(when?.duration?.timeUnit)}`}
      </div> */}

      <div className="flex flex-wrap py-12px px-18px">
        <div className="text-label-s-med text-heavy-80 bg-heavy-1 border-radius-6px py-10px px-8px">
          {getAdminInfo()}
        </div>
      </div>

      <div className="flex-1 card-delimeter" />

      <div className="flex justify-content-between align-items-center p-10px pl-18px">
        <div className="flex gap-4px"></div>

        <div className="flex align-items-center gap-8px h-38px">
          {isActive ? (
            <Button className="button-blue card-button" label={isWorkspacesEdit ? labels.edit : labels.view} text />
          ) : (
            isWorkspacesEdit && (
              <Button label={labels.activate} outlined severity="success" onClick={handleActivateButton} />
            )
          )}
        </div>
      </div>
    </div>
  );
});

WorkspaceCard.displayName = 'WorkspaceCard';
