import { t } from '../../../i18n/i18n';

export default {
  homeButton: t('ErrorPage:HOME_BUTTON'),
  title403: t('ErrorPage:TITLE_403'),
  title404: t('ErrorPage:TITLE_404'),
  title500: t('ErrorPage:TITLE_500'),
  message403_1: t('ErrorPage:MESSAGE_403_1'),
  message403_2: t('ErrorPage:MESSAGE_403_2'),
  message404_1: t('ErrorPage:MESSAGE_404_1'),
  message404_2: t('ErrorPage:MESSAGE_404_2'),
  message500_1: t('ErrorPage:MESSAGE_500_1'),
  message500_2: t('ErrorPage:MESSAGE_500_2'),
  message500_3: t('ErrorPage:MESSAGE_500_3'),
};
