import { Avatar } from 'primereact/avatar';
import { getInitials } from '../../../services/utils';

interface IUserAvatar {
  name?: string;
  avatarLink?: string | null;
  className?: string;
  size?: "large" | "xlarge" | "normal";
}

export const UserAvatar: React.FC<IUserAvatar> = ({ name, avatarLink, className, size }) => {

  return (
    <Avatar
      image={avatarLink || undefined}
      label={getInitials(name || '')}
      size={size}
      className={className}
    />
  );
};
