import { useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { AdditionalRulesStep } from '../../common/meeting/additionalRulesStep/AdditionalRulesStep';

export const BookingTemplateAdditionalRulesStep = () => {
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);
  return (
    <AdditionalRulesStep
      selectors={bookingTemplatesSelectors}
      actions={bookingTemplatesActions}
      isReadOnly={isReadOnly}
    />
  );
};
