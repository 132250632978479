import { useDispatch, useSelector } from 'react-redux';
import { JourneyCard } from '../../../API';
import { journeysActions, journeysSelectors } from '../../../store/journeyBuilder';
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_NAME } from '../../../types/constants';
import { InputTextarea } from 'primereact/inputtextarea';
import { IconPicker } from '../../common/iconPicker/IconPikcer';
import { XMarkIcon } from '@heroicons/react/24/outline';
import labels from './labels';

type AddPageCardModalProps = {
  card: JourneyCard;
  visible: boolean;
  onHide: () => void;
  isEdit?: boolean;
};

export const AddPageCardModal = ({ card, visible, onHide, isEdit }: AddPageCardModalProps) => {
  const dispatch = useDispatch();
  const pageCards = useSelector(journeysSelectors.selectPageCards);

  const [localCard, setLocalCard] = useState(card);
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setLocalCard(card);
    setIsInvalid(false);
  }, [card]);

  useEffect(() => {
    isInvalid && validateField();
  }, [localCard]);

  const onConfirm = () => {
    if (validateField()) {
      const cards = isEdit
        ? pageCards.map((el) => (el?.title === card.title ? localCard : el))
        : [...pageCards, localCard];
      dispatch(journeysActions.updatePageStep({ cards }));
      onHide();
    }
  };

  const validateField = () => {
    const isInvalid = !localCard.title || !localCard.details;
    setIsInvalid(isInvalid);
    return !isInvalid;
  };

  const handleTitleChange = (title: string) => {
    setLocalCard((prev) => ({ ...prev, title }));
  };

  const handleDetailsChange = (details: string) => {
    setLocalCard((prev) => ({ ...prev, details }));
  };

  const handleIconChange = (icon: string | null) => {
    setLocalCard((prev) => ({ ...prev, icon }));
  };

  return (
    <Dialog
      visible={visible}
      className="card-modal overflow-hidden"
      contentClassName="p-20px"
      draggable={false}
      showHeader={false}
      position="right"
      footer={
        <div>
          <Button label={isEdit ? labels.save : labels.add} onClick={onConfirm} disabled={isInvalid} />
          <Button onClick={onHide} text label={labels.cancel} />
        </div>
      }
      blockScroll
      onHide={onHide}
    >
      <div className="flex flex-column gap-16px">
        <div className="text-title-xs-med text-heavy-100">{labels.selectionBox}</div>
        <div className="flex flex-row">
          <IconPicker value={localCard.icon} onChange={handleIconChange} />
          {localCard.icon && (
            <Button
              className="ml-4px h-fit text-heavy-50 hover-text-heavy-80"
              text
              onClick={() => handleIconChange(null)}
            >
              <XMarkIcon className="icon-24px" />
            </Button>
          )}
        </div>
        <InputText
          value={localCard.title || ''}
          onChange={(e) => handleTitleChange(e.target.value)}
          className={`w-full ${isInvalid && !localCard.title ? 'p-invalid' : ''}`}
          placeholder={labels.titlePlaceholder}
          maxLength={MAX_LENGTH_NAME}
        />
        <InputTextarea
          value={localCard.details || ''}
          onChange={(e) => handleDetailsChange(e.target.value)}
          className={`w-full ${isInvalid && !localCard.details ? 'p-invalid' : ''}`}
          placeholder={labels.detailsPlaceholder}
          maxLength={MAX_LENGTH_DESCRIPTION}
          autoResize
        />
      </div>
    </Dialog>
  );
};
