import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  cancel: t('Common:CANCEL'),
  cancelYes: t('Common:YES_CANCEL'),
  cancelNo: t('Common:NO'),
  cancelTextPart1: t('Common:ARE_YOU_SURE'),
  cancelTextPart2: t('Common:UNSAVED_CHANGES'),
  convertToCustomer: t('OPSConsoleOrgDetails:CONVERT_TO_CUSTOMER'),
  convertToTest: t('OPSConsoleOrgDetails:CONVERT_TO_TEST'),
  delete: t('Common:DELETE'),
  editOrg: t('OPSConsoleOrgDetails:EDIT_ORG'),
  expireTrial: t('OPSConsoleOrgs:EXPIRE_TRIAL'),
  extendTrial: t('OPSConsoleOrgs:EXTEND_TRIAL'),
  orgTitle: t('OPSConsoleOrgDetails:ORG_TITLE'),
  save: t('Common:SAVE'),
  title: t('OPSConsoleOrgs:TITLE'),
};
