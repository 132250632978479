import { useSelector } from 'react-redux';
import { Preloader } from '../components/common';
import { userSettingsSelectors } from '../store/userSettings';
import { Outlet } from 'react-router-dom';

export const AdminRoute = () => {
  const isRoleLoaded = useSelector(userSettingsSelectors.selectIsRoleLoaded);

  return isRoleLoaded ? <Outlet /> : <Preloader />;
};
