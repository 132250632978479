import { createSelector } from 'reselect';
import { State } from '../../rootStore';
import { formatDateTimeThroughGap } from '../../../services/DateService';
import { validateEmail } from '../../../services/EmailService';
import { globalSelectors } from '../../global';

const staffState = (state: State) => state.OPSConsoleStaff;

const selectIsFetching = createSelector(staffState, (state) => state.isFetching);
const selectError = createSelector(staffState, (state) => state.error);
const selectStaff = createSelector(staffState, (state) => state.staff);
const selectStaffRecord = createSelector(staffState, (state) => state.staffRecord);
const selectIsEdited = createSelector(staffState, (state) => state.isEdited);
const selectIsAddNew = createSelector(staffState, (state) => state.isAddNew);

const selectSearchStaff = createSelector(selectStaff, globalSelectors.selectLowercasedSearchString, (staff, searchString) =>
  staff.filter(
    (staff) =>
      staff.userName?.toLowerCase().includes(searchString) ||
      staff.email.toLowerCase().includes(searchString)
  )
);

const selectStaffName = createSelector(selectStaffRecord, (staffRecord) => {
  return staffRecord?.userName || '';
});
const selectStaffEmail = createSelector(selectStaffRecord, (staffRecord) => {
  return staffRecord?.email || '';
});
const selectStaffStatus = createSelector(selectStaffRecord, (staffRecord) => {
  return staffRecord?.status || '';
});
const selectStaffRoleName = createSelector(selectStaffRecord, (staffRecord) => {
  return staffRecord?.roleName || '';
});
const selectStaffUserId = createSelector(selectStaffRecord, (staffRecord) => {
  return staffRecord?.userId || '';
});

const selectStaffNameOrEmail = createSelector(selectStaffName, selectStaffEmail, (name, email) => {
  return name ? name : email;
});

const selectLastLogin = createSelector(selectStaffRecord, (staffRecord) =>
  staffRecord?.lastLogin ? formatDateTimeThroughGap(staffRecord?.lastLogin) : ''
);
const selectIsEmailValid = createSelector(selectStaffEmail, (email) => email?.length && validateEmail(email));
const selectIsDuplicateEmail = createSelector(selectIsAddNew, selectStaff, selectStaffEmail, (isAddNew, staff, email) =>
  isAddNew
    ? staff.some((record) => record.email === email)
    : staff.filter((record) => record.email === email).length > 1
);

const selectIsPaginatorEnabled = createSelector(selectSearchStaff, (staff) => staff.length > 10);

// TODO: rename to OPSConsoleStaffSelectors
export const staffSelectors = {
  selectIsFetching,
  selectError,
  selectStaff,
  selectStaffRecord,
  selectIsEdited,
  selectIsAddNew,

  selectSearchStaff,
  selectStaffName,
  selectStaffEmail,
  selectStaffStatus,
  selectStaffRoleName,
  selectStaffUserId,
  selectStaffNameOrEmail,
  selectLastLogin,
  selectIsEmailValid,
  selectIsDuplicateEmail,
  selectIsPaginatorEnabled,
};
