import { Position } from '@xyflow/react';
import { PlayIcon } from '@heroicons/react/24/outline';
import { JourneyHandle } from '../journeyHandle/JourneyHandle';
import labels from './labels';

export const JourneyStartNode = () => {
  return (
    <div className="start-node">
      <PlayIcon className="icon-24px" />
      <div className="text-button-md-med">{labels.startNodeTitle}</div>
      <JourneyHandle type="source" position={Position.Bottom} />
    </div>
  );
};
