import { useSelector } from 'react-redux';
import { IntegrationDataType, VIDEO_CONFERENCES, integrationActions } from '../../../store/integration';
import { userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { useDispatch } from 'react-redux';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { generateVideoConference } from '../../../store/integration/utils';
import { Button } from 'primereact/button';
import { CheckIcon } from '@heroicons/react/24/outline';
import { DefaultVideoConference } from '../../quickSetup';
import { IntegrationType } from '../../../API';
import { authenticationSelectors } from '../../../store/authentication';

type IntegrationCardProps = {
  integration: IntegrationDataType;
};
export const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration }) => {
  const dispatch = useDispatch();
  const isPersonalIntegrationConnected = useSelector(
    userSettingsSelectors.selectIsIntegrationConnected(integration.type)
  );
  const isWorkspaceIntegrationConnected = useSelector(
    authenticationSelectors.selectIsWorkspaceIntegrationConnected(integration.type)
  );
  const isConnected = isPersonalIntegrationConnected || isWorkspaceIntegrationConnected;

  const isVideoIntegration = VIDEO_CONFERENCES.includes(integration.type as IntegrationType);
  const videoConferenceType = isVideoIntegration ? generateVideoConference(integration.type as IntegrationType) : null;

  const handleConnect = () => {
    dispatch(integrationActions.setConnectIntegrationType(integration.type));
    navigationService.navigateTo(Path.ConnectIntegration);
  };

  return (
    <div className="sumo-card action-card flex flex-column h-full" onClick={handleConnect}>
      <div className="flex gap-16px pt-22px px-18px">
        <div className="flex-1 text-title-lg-med">
          <div>{integration.label}</div>
          <div className="text-body-s-reg text-heavy-60 pt-12px">{integration.description}</div>
        </div>
        <img src={integration.icon} className="w-48px h-48px" />
      </div>

      <div className="flex justify-content-between align-items-center p-10px pl-18px">
        <div>
          {isConnected && videoConferenceType && (
            <DefaultVideoConference videoConferenceType={videoConferenceType} hasLabel />
          )}
        </div>
        <div className="flex align-items-center gap-8px h-38px">
          {isConnected ? (
            <div className="flex align-items-center gap-4px text-button-md-med text-cyan-main">
              <CheckIcon className="icon-18px" />
              <div>{labels.connected}</div>
            </div>
          ) : (
            <Button className="button-blue card-button" label={labels.connect} text />
          )}
        </div>
      </div>
    </div>
  );
};
