import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationSelectors } from '../../../store/authentication';
import { Path } from '../../../routing';
import labels from './labels';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import {
  ArrowLeftCircleIcon,
  BellIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CreditCardIcon,
  CubeIcon,
  LockClosedIcon,
  MapPinIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { Dispatch, SetStateAction, useState } from 'react';

export const NavigationMenu = () => {
  const location = useLocation();
  const isOpConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isUsersRead = useSelector(userSettingsSelectors.selectUsersRead);
  const isBookingTemplatesRead = useSelector(userSettingsSelectors.selectBookingTemplatesRead);
  const isWorkspacesRead = useSelector(userSettingsSelectors.selectWorkspacesRead);
  const isTeamsRead = useSelector(userSettingsSelectors.selectTeamsRead);
  const isRoleLoaded = useSelector(userSettingsSelectors.selectIsRoleLoaded);
  const isPaidLicense = useSelector(authenticationSelectors.selectIsPaidLicense);
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);
  const isSumoONEAdmin = useSelector(authenticationSelectors.selectIsSumo1Admin);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);

  const [meetingsOpen, setMeetingsOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(true);
  const [peopleOpen, setPeopleOpen] = useState(true);
  const [organizationOpen, setOrganizationOpen] = useState(true);

  const titleClass = 'p-0 pb-8px text-label-s-med text-heavy-60';

  const getTitleTemplate = (isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>) => {
    const MenuTitleTemplate: MenuItem['template'] = (item) => (
      <div
        className="flex-between-center pb-8px text-label-s-med text-heavy-60 hover-text-heavy-100 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{item.label}</div>
        {isOpen ? <ChevronUpIcon className="icon-12px" /> : <ChevronDownIcon className="icon-12px" />}
      </div>
    );
    return MenuTitleTemplate;
  };

  const itemTemplate: MenuItem['template'] = (item) => (
    <Link
      to={item.url || ''}
      className={`text-title-xs-med ${isOpConsole ? 'hover-text-primary-white' : 'hover-text-heavy-80'} ${
        location.pathname.startsWith(item.url || '')
          ? isOpConsole
            ? 'text-primary-white'
            : 'text-heavy-100'
          : 'text-heavy-60'
      }`}
    >
      <div className="flex-left-center gap-10px py-8px">
        {item.data || <i className={item.icon}></i>}
        <div>{item.label}</div>
      </div>
    </Link>
  );

  const spaceTemplate: MenuItem['template'] = () => <div className="h-24px"></div>;

  const createModal = () => {
    if (isOpConsole) {
      return OPSConsoleModel;
    } else if (isLicenseActive === false) {
      return licenseExpiredModel;
    } else {
      return privateModel;
    }
  };

  const licenseExpiredModel: MenuItem[] = [
    ...(isSumoONEAdmin && !isViewAsUserMode
      ? [
          {
            label: labels.opsConsole,
            url: Path.OPSConsoleOrgs,
            template: itemTemplate,
            data: <WrenchScrewdriverIcon className="icon-20px" />,
          },
          { template: spaceTemplate },
        ]
      : []),
    ...(isSuperAdmin
      ? [
          {
            label: labels.peopleTitle,
            className: titleClass,
            items: [
              {
                label: labels.users,
                url: Path.Users,
                template: itemTemplate,
                data: <UsersIcon className="icon-20px" />,
              },
            ],
          },
          { template: spaceTemplate },
          ...(isPaidLicense
            ? [
              {
                label: labels.organizationTitle,
                className: titleClass,
                items: [
                  {
                    label: labels.billingTitle,
                    url: Path.Billing,
                    template: itemTemplate,
                    data: <CreditCardIcon className="icon-20px" />,
                  },
                ],
              },
              { template: spaceTemplate },
                  ]
            : []),
        ]
      : []),
  ];

  const privateModel: MenuItem[] = [
    ...(isSumoONEAdmin && !isViewAsUserMode
      ? [
          {
            label: labels.opsConsole,
            url: Path.OPSConsoleOrgs,
            template: itemTemplate,
            data: <WrenchScrewdriverIcon className="icon-20px" />,
          },
          { template: spaceTemplate },
        ]
      : []),

    {
      label: labels.meetingsTitle,
      template: getTitleTemplate(meetingsOpen, setMeetingsOpen),
    },
    ...(meetingsOpen
      ? [
          {
            label: labels.bookingPagesTitle,
            url: Path.BookingPages,
            template: itemTemplate,
            data: <Squares2X2Icon className="icon-20px" />,
          },
          {
            label: labels.bookedMeetingsTitle,
            url: Path.BookedMeetings,
            template: itemTemplate,
            data: <CalendarIcon className="icon-20px" />,
          },
        ]
      : []),
    { template: spaceTemplate },

    {
      label: labels.toolsTitle,
      template: getTitleTemplate(toolsOpen, setToolsOpen),
    },
    ...(toolsOpen
      ? [
          {
            visible: isBookingTemplatesRead,
            label: labels.bookingTemplatesTitle,
            url: Path.BookingTemplates,
            template: itemTemplate,
            data: <Square3Stack3DIcon className="icon-20px" />,
          },
          {
            label: labels.smartAlerts,
            url: Path.SmartAlerts,
            template: itemTemplate,
            data: <BellIcon className="icon-20px" />,
          },
          {
            label: labels.journeyBuilder,
            url: Path.Journeys,
            template: itemTemplate,
            data: <SquaresPlusIcon className="icon-20px" />,
          },
          {
            label: labels.integrationPageTitle,
            url: Path.Integration,
            template: itemTemplate,
            data: <Square2StackIcon className="icon-20px" />,
          },
        ]
      : []),
    { template: spaceTemplate },

    ...(isRoleLoaded && (isUsersRead || isTeamsRead)
      ? [
          {
            label: labels.peopleTitle,
            template: getTitleTemplate(peopleOpen, setPeopleOpen),
          },
          ...(peopleOpen
            ? [
                {
                  visible: isUsersRead,
                  label: labels.users,
                  url: Path.Users,
                  template: itemTemplate,
                  data: <UsersIcon className="icon-20px" />,
                },
                {
                  visible: isTeamsRead,
                  label: labels.teamsTitle,
                  url: Path.Teams,
                  template: itemTemplate,
                  data: <UserGroupIcon className="icon-20px" />,
                },
              ]
            : []),
          { template: spaceTemplate },

          {
            label: labels.organizationTitle,
            template: getTitleTemplate(organizationOpen, setOrganizationOpen),
          },
          ...(organizationOpen
            ? [
                {
                  visible: isSuperAdmin,
                  label: labels.roles,
                  url: Path.Roles,
                  template: itemTemplate,
                  data: <LockClosedIcon className="icon-20px" />,
                },
                {
                  visible: isWorkspacesRead,
                  label: labels.workspacesTitle,
                  url: Path.Workspaces,
                  template: itemTemplate,
                  data: <CubeIcon className="icon-20px" />,
                },
                {
                  label: labels.locationsTitle,
                  url: Path.Locations,
                  template: itemTemplate,
                  data: <MapPinIcon className="icon-20px" />,
                },
                ...(isPaidLicense && isSuperAdmin
                  ? [
                      {
                        label: labels.billingTitle,
                        url: Path.Billing,
                        template: itemTemplate,
                        data: <CreditCardIcon className="icon-20px" />,
                      },
                    ]
                  : []),
              ]
            : []),
          { template: spaceTemplate },
        ]
      : []),
  ];

  const OPSConsoleModel: MenuItem[] = [
    {
      label: labels.openMySumo1,
      url: isQuickSetupFinished ? Path.BookingPages : Path.QuickSetup,
      template: itemTemplate,
      data: <ArrowLeftCircleIcon className="icon-20px" />,
    },
    { template: spaceTemplate },

    {
      label: labels.supportTitle,
      className: titleClass,
      items: [
        {
          label: labels.accountsTitle,
          url: Path.OPSConsoleAccounts,
          template: itemTemplate,
          data: <Squares2X2Icon className="icon-20px" />,
        },
        {
          label: labels.orgsTitle,
          url: Path.OPSConsoleOrgs,
          template: itemTemplate,
          data: <BuildingOffice2Icon className="icon-20px" />,
        },
        {
          label: labels.users,
          url: Path.OPSConsoleUsers,
          template: itemTemplate,
          data: <UsersIcon className="icon-20px" />,
        },
      ],
    },
    { template: spaceTemplate },

    {
      label: labels.operationsTitle,
      className: titleClass,
      items: [
        // {
        //   label: t('AppMenu:BILLING_TITLE'),
        //   icon: 'pi pi-dollar',
        //   to: Path.OPSConsoleBilling,
        // },
        {
          label: labels.staffTitle,
          url: Path.OPSConsoleStaff,
          template: itemTemplate,
          data: <UserCircleIcon className="icon-20px" />,
        },
      ],
    },
    { template: spaceTemplate },
  ];

  return <Menu model={createModal()} className="w-full p-0 border-none" />;
};
