import { NoSymbolIcon } from '@heroicons/react/24/outline';

interface IErrorText {
  text: string;
  className?: string;
}

export const ErrorText: React.FC<IErrorText> = ({ text, className }) => {
  return (
    <div className={`flex align-items-center error-message ${className}`}>
      <div>
        <NoSymbolIcon className="icon-18px" />
      </div>
      <span>{text}</span>
    </div>
  );
};
