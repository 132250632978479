import { t } from '../i18n/i18n';
import { Path } from '.';

export const HOME_TAB = Object.freeze({ label: t('PrivateRoute:HOME'), value: Path.BookingPages });
export const AVAILABILITY_TAB = Object.freeze({ label: t('PrivateRoute:AVAILABILITY'), value: Path.Availability });
export const ADMIN_SETTINGS_TAB = Object.freeze({
  label: t('PrivateRoute:ADMIN_SETTINGS'),
  value: Path.OPSConsole,
});

export const DEFAULT_TABS = [HOME_TAB, AVAILABILITY_TAB];
