import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Path } from '../../routing';
import { AppDispatch } from '../../store/rootStore';
import {
  bookingPagesActions,
  bookingPageSelectors,
  deleteBookingPagesModalActions,
  addToWebsiteModalActions,
  AccordionIndexes,
} from '../../store/bookingPages';
import {
  BookingPageWhatStep,
  BookingPageHowStep,
  BookingPageCloneModal,
  BookingPageDeleteModal,
  BookingPageAddToWebsiteModal,
  BookingPageWhereStep,
  BookingPageWhenStep,
  BookingPageAdditionalRulesStep,
  BookingPageNotificationStep,
  BookingPageConfirmationStep,
  BookingPageWhoStep,
} from '../../components/bookingPages';
import { ConfirmationModal } from '../../components/common';
import labels from './labels';

import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import { LocationType } from '../../API';
import { timeHandlerService } from '../../services/TimeHandlerService';
import { navigationService } from '../../services/NavigationService';
import { BookingPageConfirmOverrideModal } from '../../components/bookingPages/bookingPageConfirmOverrideModal/BookingPageConfirmOverrideModal';
import { useSession } from '../../hooks/useSession';
import { smartAlertsSelectors } from '../../store/smartAlerts';
import {
  ArrowLeftIcon,
  BoltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  LinkIcon,
  LockClosedIcon,
  PhoneIcon,
  UserIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { InputSwitch } from 'primereact/inputswitch';
import { ProgressSpinner } from 'primereact/progressspinner';

export const EditBookingPage = () => {
  const { bookingPageId: bookingPageIdParam } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const isFetching = useSelector(bookingPageSelectors.selectIsFetching);
  const isFetchingSmartAlerts = useSelector(smartAlertsSelectors.selectIsFetching);
  const isEdited = useSelector(bookingPageSelectors.selectIsEdited);
  const isExistingBookingPage = useSelector(bookingPageSelectors.selectIsExistingBookingPage);
  const isActive = useSelector(bookingPageSelectors.selectIsActive);
  const bookingPage = useSelector(bookingPageSelectors.selectBookingPage);
  const bookingPageId = useSelector(bookingPageSelectors.selectId);
  const bookingPageName = useSelector(bookingPageSelectors.selectCustomName);
  const bookingTemplateName = useSelector(bookingPageSelectors.selectBookingTemplateName);
  const bookingPageDisplayId = useSelector(bookingPageSelectors.selectDisplayId);
  const bookingPageLink = useSelector(bookingPageSelectors.selectShortLink);
  const hostsInfo = useSelector(bookingPageSelectors.selectHostsInfo);
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const isNoHost = useSelector(bookingPageSelectors.selectIsNoHost);

  const isWhatStepValid = useSelector(bookingPageSelectors.selectIsWhatStepValid);
  const isWhenStepValid = useSelector(bookingPageSelectors.selectIsWhenStepValid);
  const isWhoStepValid = useSelector(bookingPageSelectors.selectIsWhoStepValid);
  const isWhereStepValid = useSelector(bookingPageSelectors.selectIsWhereStepValid);
  const isNotificationsStepValid = useSelector(bookingPageSelectors.selectIsNotificationsStepValid);
  const isConfirmationStepValid = useSelector(bookingPageSelectors.selectIsConfirmationStepValid);

  const accordionIndexes = useSelector(bookingPageSelectors.selectAccordionIndexes);
  const lockedTooltip = useSelector(bookingPageSelectors.selectLockedTooltip);

  const bookingTemplateId = useSelector(bookingPageSelectors.selectBookingTemplateId);
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);

  const isBookingPageCreate = useSelector(bookingPageSelectors.selectIsBookingPageCreate());
  const isBookingPageEdit = useSelector(bookingPageSelectors.selectIsBookingPageEdit());
  const isBookingPageDelete = useSelector(bookingPageSelectors.selectIsBookingPageDelete());

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    if (bookingPageIdParam && bookingPageIdParam !== 'new') {
      dispatch(bookingPagesActions.getBookingPageRequest({ id: bookingPageIdParam, isInitialVisit }));
    } else {
      // it will set default Booking Page
      dispatch(bookingPagesActions.getBookingPageRequest({ id: '', isInitialVisit }));
    }

    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, [isInitialVisit]);

  const handleActivate = () => {
    dispatch(
      bookingPagesActions.activateBookingPageRequest({
        ...bookingPage,
        what: { ...bookingPage.what, isActive: !isActive },
      })
    );
  };

  const handleClone = () => {
    dispatch(bookingPagesActions.cloneBookingPageThunk());
  };

  const handleDelete = () => {
    if (bookingPageId) {
      dispatch(bookingPagesActions.selectBookingPage(bookingPageId));
      dispatch(deleteBookingPagesModalActions.openModal());
    }
  };

  const handleViewBookingPage = () => {
    bookingPageLink && window.open(bookingPageLink, '_blank');
  };

  const handleAddToWebsite = () => {
    dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicBookingPage.replace(':bookingPageId', bookingPageId)));
    dispatch(addToWebsiteModalActions.openModal());
  };

  const handleCopyLink = () => {
    bookingPageLink && navigator.clipboard.writeText(bookingPageLink);
  };

  const handleActiveChange = (value: boolean) => {
    dispatch(bookingPagesActions.updateWhatStep({ isActive: value }));
  };

  const menuItems = [
    {
      label: isActive ? labels.deactivate : labels.activate,
      command: handleActivate,
      visible: isBookingPageEdit && !isNoHost,
    },
    { label: labels.clone, command: handleClone, visible: isBookingPageCreate && !isNoHost },
    { label: labels.delete, command: handleDelete, visible: isBookingPageDelete },
  ];

  const handleAccordionIndexChange = (value: Partial<AccordionIndexes>) => {
    dispatch(bookingPagesActions.updateAccordionIndexes(value));
  };

  const handleSave = (isGlobalSave: boolean) => {
    if (!isWhatStepValid) {
      handleAccordionIndexChange({ what: true });
      return;
    }
    if (!isWhoStepValid) {
      handleAccordionIndexChange({ who: true });
      return;
    }
    if (!isWhereStepValid) {
      handleAccordionIndexChange({ where: true });
      return;
    }
    if (!isWhenStepValid) {
      handleAccordionIndexChange({ when: true });
      return;
    }
    if (!isNotificationsStepValid) {
      handleAccordionIndexChange({ alerts: true });
      return;
    }
    if (!isConfirmationStepValid) {
      handleAccordionIndexChange({ after: true });
      return;
    }

    isExistingBookingPage
      ? dispatch(bookingPagesActions.saveBookingPageRequest(isGlobalSave))
      : dispatch(bookingPagesActions.createBookingPageRequest(isGlobalSave));
  };

  const handleCancel = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleBack();
    }
  };

  const handleBack = () => {
    navigationService.navigateTo(Path.BookingPages);
    dispatch(bookingPagesActions.clearBookingPage());
  };

  const handleOverride = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation(); // for no close the accordion
    dispatch(bookingPagesActions.overrideBookingTemplate());
  };

  const generateLocationTypeIcon = (locationType: LocationType | null) => {
    switch (locationType) {
      case LocationType.IN_PERSON:
        return <UserIcon className="icon-16px" />;
      case LocationType.PHONE_CALL:
        return <PhoneIcon className="icon-16px" />;
      case LocationType.VIDEO_CONFERENCE:
        return <VideoCameraIcon className="icon-16px" />;
      default:
        return <></>;
    }
  };

  const generateHeader = () => (
    <div className="sumo-card border-none">
      <div className="flex-between px-20px py-16px">
        <div className="flex-center gap-6px">
          {Boolean(lockedTooltip) && (
            <>
              <Tooltip target=".locked" />
              <LockClosedIcon
                className="icon-16px text-heavy-60 locked"
                data-pr-tooltip={lockedTooltip}
                data-pr-position="top"
              />
            </>
          )}

          <div className="text-title-lg-med">{isExistingBookingPage ? bookingPageName : labels.newBookingPage}</div>

          {isFetching || isFetchingSmartAlerts ? (
            <ProgressSpinner className="w-32px h-32px" strokeWidth="5" />
          ) : (
            (isBookingPageCreate || isBookingPageEdit || isBookingPageDelete) && (
              <div className="action-button" onClick={(e) => menu.current?.toggle(e)}>
                <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
                <EllipsisVerticalIcon className="icon-20px" />
              </div>
            )
          )}
        </div>
        <div className="flex-center gap-8px">
          <Button
            outlined
            className="button-icon"
            tooltip={labels.viewBookingPage}
            onClick={handleViewBookingPage}
            disabled={!bookingPageLink}
          >
            <EyeIcon className="icon-18px" />
          </Button>
          <Button
            outlined
            className="button-icon"
            tooltip={labels.copyLink}
            onClick={handleCopyLink}
            disabled={!bookingPageLink}
          >
            <LinkIcon className="icon-18px" />
          </Button>
          <Button outlined label={labels.addToWebsite} onClick={handleAddToWebsite} />
        </div>
      </div>
      <div className="flex flex-column gap-12px pt-12px pb-18px px-20px border-top-1 border-heavy-20">
        <div className="flex-left-center gap-6px text-body-s-reg text-heavy-60">
          <div>{bookingPageDisplayId}</div>
          {bookingTemplateName && (
            <>
              <div className="w-4px h-4px border-radius-2px bg-heavy-50" />
              <div>{bookingTemplateName}</div>
            </>
          )}
        </div>
        <div className="flex-between">
          <div className="flex gap-4px">
            <div className="p-8px bg-heavy-1 border-radius-6px">
              <div
                className="w-16px h-16px border-radius-4px"
                style={{ backgroundColor: bookingPage.what?.color || '' }}
              />
            </div>
            {bookingPage.where?.locationTypes?.map((locationType) => (
              <div key={locationType} className="flex-center w-32px h-32px bg-heavy-1 border-radius-6px text-heavy-60">
                {generateLocationTypeIcon(locationType)}
              </div>
            ))}
            {hostsInfo && (
              <div className="text-label-s-med text-heavy-80 bg-heavy-1 border-radius-6px py-10px px-8px ml-8px">
                {hostsInfo}
              </div>
            )}
          </div>
          <div className="flex align-items-center gap-10px">
            <InputSwitch
              checked={!!isActive}
              onChange={(e) => handleActiveChange(!!e.value)}
              disabled={isBookingPageLocked || isNoHost}
            />
            <div className="text-label-lg-med">{labels.active}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const generateAccordion = (
    title: string,
    description: string,
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    content: React.ReactNode,
    showBlockContent?: boolean
  ) => (
    <Accordion
      className="sumo-card border-none overflow-none"
      activeIndex={isOpen ? 0 : null}
      onTabChange={(e) => setIsOpen(e.index === 0)}
    >
      <AccordionTab
        header={
          <div className="flex-left-center px-20px py-8px">
            <div className="flex-1 flex flex-column pb-4px">
              <div className="text-title-s-med text-heavy-100">{title}</div>
              <div className="text-body-s-reg">{description}</div>
            </div>
            <div className="flex-none">
              {isOpen ? <ChevronUpIcon className="icon-20px" /> : <ChevronDownIcon className="icon-20px" />}
            </div>
          </div>
        }
      >
        <div className="px-20px pt-12px pb-24px">
          {showBlockContent && !lockedTooltip && hasTemplate && (
            <div className="flex-left-center gap-8px bg-heavy-1 border-radius-10px px-10px py-8px mb-24px">
              <LockClosedIcon className="icon-16px text-heavy-100" />
              <div className="text-body-s-reg text-heavy-100">
                <span>{labels.bookingTemplateLinkTooltip}</span>
                <Link
                  className="underline"
                  target="_blank"
                  to={Path.EditBookingTemplate.replace(':bookingTemplateId', bookingTemplateId || '')}
                >
                  {bookingTemplateName}
                </Link>
                <span>{' - '}</span>
                <span
                  onClick={(e) => handleOverride(e)}
                  className="underline text-blue-main hover-text-blue-dark cursor-pointer"
                >
                  {labels.bookingTemplateLinkTooltipOverride}
                </span>
              </div>
            </div>
          )}
          {content}
        </div>
        <div className="border-top-1 border-heavy-20" />
        <div className="flex-left-center gap-6px px-20px py-12px">
          <Button
            className="min-w-90px"
            label={labels.save}
            onClick={() => handleSave(false)}
            disabled={!!lockedTooltip || isFetching || isFetchingSmartAlerts}
          />
          <Button label={labels.cancel} text onClick={handleCancel} />
        </div>
      </AccordionTab>
    </Accordion>
  );

  return (
    <>
      {/* {(isFetching || isFetchingSmartAlerts) && <Preloader />} */}
      <BookingPageCloneModal />
      <BookingPageDeleteModal />
      <BookingPageAddToWebsiteModal />
      <BookingPageConfirmOverrideModal />

      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        confirmButtonProps={{ className: 'min-w-120px' }}
        onConfirm={handleBack}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <Button className="button-blue button-text-line mb-16px" text onClick={handleCancel}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column gap-16px">
        {generateHeader()}

        {generateAccordion(
          labels.whatTitle,
          labels.whatDescription,
          accordionIndexes.what,
          (isOpen) => handleAccordionIndexChange({ what: isOpen }),
          <BookingPageWhatStep />
        )}
        {generateAccordion(
          labels.whoTitle,
          labels.whoDescription,
          accordionIndexes.who,
          (isOpen) => handleAccordionIndexChange({ who: isOpen }),
          <BookingPageWhoStep />
        )}
        {generateAccordion(
          labels.whereTitle,
          labels.whereDescription,
          accordionIndexes.where,
          (isOpen) => handleAccordionIndexChange({ where: isOpen }),
          <BookingPageWhereStep />,
          true
        )}
        {generateAccordion(
          labels.whenTitle,
          labels.whenDescription,
          accordionIndexes.when,
          (isOpen) => handleAccordionIndexChange({ when: isOpen }),
          <BookingPageWhenStep />,
          true
        )}

        <div className="flex-left-center gap-6px text-heavy-60 pt-24px">
          <BoltIcon className="icon-20px" />
          <div className="text-label-s-med">{labels.additionalConfigurations}</div>
        </div>

        {generateAccordion(
          labels.howTitle,
          labels.howDescription,
          accordionIndexes.how,
          (isOpen) => handleAccordionIndexChange({ how: isOpen }),
          <BookingPageHowStep />,
          true
        )}
        {generateAccordion(
          labels.alertsTitle,
          labels.alertsDescription,
          accordionIndexes.alerts,
          (isOpen) => handleAccordionIndexChange({ alerts: isOpen }),
          <BookingPageNotificationStep />,
          true
        )}
        {generateAccordion(
          labels.inviteeTitle,
          labels.inviteeDescription,
          accordionIndexes.invitee,
          (isOpen) => handleAccordionIndexChange({ invitee: isOpen }),
          <BookingPageAdditionalRulesStep />,
          true
        )}
        {generateAccordion(
          labels.afterTitle,
          labels.afterDescription,
          accordionIndexes.after,
          (isOpen) => handleAccordionIndexChange({ after: isOpen }),
          <BookingPageConfirmationStep />,
          true
        )}
      </div>
    </>
  );
};
