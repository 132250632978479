export const Availability = Object.freeze({
  ACTIVE_ON: `Active On`,
  BOOKING_PAGE: `Booking Page`,
  BOOKING_PAGES: `Booking Pages`,
  CLONE_SCHEDULE_TITLE: `Cloned schedule name`,
  DATE_OVERRIDE_BUTTON: `New Override`,
  DATE_OVERRIDE_DESCRIPTION: `Add exceptions to this schedule below:`,
  DATE_OVERRIDE_MODAL_HOURS: `Hours of availability`,
  DATE_OVERRIDE_MODAL_PROMPT: `Select the date (or dates):`,
  DATE_OVERRIDE_MODAL_UNAVAILABLE: `Click save to block this date(s) as unavailable.`,
  DATE_OVERRIDE_TITLE: `Add date override`,
  DATES_OVERLAPPING_TEXT: `Some dates have already been overridden.`,
  DEFAULT_AVAILABILITY_NAME: `Working hours`,
  DELETE_DESCRIPTION: `Are you sure you would like to delete`,
  DELETE_MESSAGE: `will be set as the new default schedule.`,
  DESCRIPTION: `Choose a schedule below to edit or create a new one that you can apply to your booking pages`,
  DIALOG_EDIT_NAME_TITLE: `Edit schedule name`,
  DIALOG_NEW_NAME_TITLE: `New schedule name`,
  DIALOG_PLACEHOLDER: `Working Hours, Exclusive Hours, etc...`,
  DIALOG_TITLE: `Schedule name`,
  DUPLICATE_NAME_MESSAGE: `Schedule with this name already exists`,
  EDIT_NAME: `Edit name`,
  IS_DEFAULT: `Default schedule`,
  NEW_SCHEDULE: `New Schedule`,
  SAVE_AVAILABILITY: `Save availability`,
  SET_AS_DEFAULT: `Set as Default`,
  SET_WEEKLY_HOURS: `Define your weekly hours:`,
  TIME_INTERVAL_OVERLAPPING: `Time intervals cannot be overlapping.`,
  TIME_INVALID_TEXT: `Choose an end time later than the start time`,
  TIME_ZONE: `Time Zone`,
  TITLE: `Availability`,
  TOOLTIP: `Manage your availability with working schedules`,
  FIRST_ADMIN_MESSAGE: `The "Team Availability" schedule will be applied to all newly invited users. If you as the Admin would like to set your own schedule, then click "New Schedule" and create your own schedule.`,
  SCHEDULE: `Schedule`,
  OVERRIDES: `Overrides`,
  AVAILABLE_HOURS: `Available hours`,
  ACTIVE_ON_PLACEHOLDER: `Select booking pages`,
  AVAILABLE_DESCRIPTION: `Extra hours for this schedule`,
  UNAVAILABLE_DESCRIPTION: `Dates that will be unavailable`,
  DELETE_WARNING_MESSAGE: `This action cannot be undone.`,
  DELETE_TITLE: `Delete schedule`,
  DATE_OVERRIDE_AVAILABLE_TITLE: `Add available overrides`,
  DATE_OVERRIDE_UNAVAILABLE_TITLE: `Add unavailable dates`,
});

export const AvailabilityToastsNotifications = Object.freeze({
  CREATE_AVAILABILITY_ERROR_MESSAGE: `Create availability request fail`,
  CREATE_AVAILABILITY_SUCCESS_MESSAGE: `Availability successfully created`,
  DELETE_AVAILABILITY_ERROR_MESSAGE: `Delete availability request fail`,
  DELETE_AVAILABILITY_SUCCESS_MESSAGE: `Availability successfully deleted`,
  GET_AVAILABILITY_ERROR_MESSAGE: `Get availability request fail`,
  SAVE_AVAILABILITY_ERROR_MESSAGE: `Save availability request fail`,
  SAVE_AVAILABILITY_SUCCESS_MESSAGE: `Changes saved`,
  SET_DEFAULT_AVAILABILITY_ERROR_MESSAGE: `Set default availability request fail`,
  SET_DEFAULT_AVAILABILITY_SUCCESS_MESSAGE: `Changes saved`,
});

export const AvailabilityWeekDays = Object.freeze({
  MONDAY_SHORT: 'Mon',
  TUESDAY_SHORT: 'Tue',
  WEDNESDAY_SHORT: 'Wed',
  THURSDAY_SHORT: 'Thu',
  FRIDAY_SHORT: 'Fri',
  SATURDAY_SHORT: 'Sat',
  SUNDAY_SHORT: 'Sun',
});
