export const OPSConsoleOrgs = Object.freeze({
  ACCOUNT_TITLE: `Konto`,
  ACTIVE: `Aktiv`,
  ADDRESS_LINE_1: `Anschrift Zeile 1`,
  ADDRESS_LINE_2: `Anschrift Zeile 2`,
  ADOPTION_PERCENT: `% Übernahme`,
  AMOUNT_TITLE: `ARR`,
  BILLING_EMAIL: `Rechnungs-E-Mail`,
  BOOKED_NUMBER: `# Gebucht`,
  CUSTOMER: `Kunde`,
  DAYS_LEFT_TITLE: `Verbleibende Tage`,
  EXPIRED: `Abgelaufen`,
  EXPIRE_TRIAL: `Test beenden`,
  EXPORT: `Exportieren`,
  EXTEND_TRIAL: `Test verlängern`,
  FILTER_ALL_ORGS: `Alle Organisationen`,
  FILTER_EXPIRED_SOON: `Bald ablaufend`,
  FILTER_LOW_ADOPT: `Geringe Übernahme`,
  LICENSES_NUMBER: `# Lizenzen`,
  LAST_ACTIVE_TITLE: `Letzte Aktivität`,
  ORG_ID_TITLE: `Organisations-ID`,
  PAYMENT_LATE: `Zahlung ausstehend`,
  SEARCH_PLACEHOLDER: `Suche nach Kontonamen, Benutzer-E-Mail oder Org-ID`,
  STATUS_TITLE: `Status`,
  SUSPENDED: `Ausgesetzt`,
  SUBSCRIPTION_ID: 'Abonnement-ID',
  TERM_TITLE: `Laufzeit`,
  TERM_ALL: `Alle`,
  TERM_ANNUAL: `Jährlich`,
  TERM_TRIAL: `Tests`,
  TERM_MONTHLY: `Monatlich`,
  TEST: `Test`,
  TITLE: `Organisationen`,
  TRIAL: `Test`,
  TYPE_TITLE: `Typ`,
  VIEW_ORG: `Organisation anzeigen`,
});

export const OrgsToastsNotifications = Object.freeze({
  CREATE_PAY_LATER_LICENSE_SUCCESS_TOAST: `Abonnement erfolgreich erstellt`,
  CREATE_PAY_LATER_LICENSE_SUCCESS_MESSAGE: `Bitte warten Sie bis zu 1 Minute`,
  GET_ORGS_FAIL_MESSAGE: `Abruf der Organisationen fehlgeschlagen`,
  GET_ORG_DETAILS_FAIL_MESSAGE: `Abruf der Organisationseinzelheiten fehlgeschlagen`,
  SAVE_ORG_DETAILS_SUCCESS_MESSAGE: `Organisationseinzelheiten erfolgreich gespeichert`,
  SAVE_ORG_DETAILS_FAIL_MESSAGE: `Speichern der Organisationseinzelheiten fehlgeschlagen`,
  DELETE_ORG_SUCCESS_MESSAGE: `Organisation erfolgreich gelöscht`,
  DELETE_ORG_FAIL_MESSAGE: `Löschen der Organisation fehlgeschlagen`,
  EXTEND_LICENSE_SUCCESS_MESSAGE: `Lizenz erfolgreich verlängert`,
  EXTEND_LICENSE_FAIL_MESSAGE: `Verlängerung der Lizenz fehlgeschlagen`,
  EXPIRE_TRIAL_SUCCESS_MESSAGE: `Test erfolgreich beendet`,
  EXPIRE_TRIAL_FAIL_MESSAGE: `Beendigung des Tests fehlgeschlagen`,
  CONVERT_TO_TEST_SUCCESS_MESSAGE: `Erfolgreiche Umwandlung in den Testmodus`,
  CONVERT_TO_TEST_FAIL_MESSAGE: `Umwandlung in den Testmodus fehlgeschlagen`,
  CONVERT_TO_PAY_LATER_SUCCESS_MESSAGE: `Das Abonnement für die spätere Zahlung durch den Kunden wurde erfolgreich erstellt`,
  CONVERT_TO_PAY_LATER_FAIL_MESSAGE: `Anfrage für das Abonnement „Später zahlen" des Kunden ist fehlgeschlagen`,
});

export const OPSConsoleOrgDetails = Object.freeze({
  ACCOUNT_NAME: `Kontoname`,
  ADOPTION_PERCENT_WEEKLY: `% Wöchentliche Annahme`,
  ADOPTION_PERCENT_MONTHLY: `% Monatliche Annahme`,
  ACTIVE_USERS_NUMBER: `# der zugewiesenen Benutzer`,
  AMOUNT_PER_USER: `Pro Benutzer / Pro Monat`,
  BOOKED_NUMBER: `# gebuchte Meetings`,
  CONVERT_TO_CUSTOMER: `In Kunde umwandeln`,
  CONVERT_TO_TEST: `In Test umwandeln`,
  DAYS_REMAINING: `Verbleibende Tage`,
  EDIT_ORG: `Organisation bearbeiten`,
  EXPIRATION_DATE: `Ablaufdatum`,
  FEATURE_PRICING_TIER: `Funktion/Preisstufe`,
  FULL_ACCESS: `Voller Zugriff`,
  LICENSES_NUMBER: `# Lizenzen`,
  ORG_ID: `Organisations-ID`,
  ORG_NOTE: `Organisationsnotiz`,
  ORG_STATUS: `Organisationsstatus`,
  ORG_TITLE: `Organisation`,
  ORG_TYPE: `Organisationstyp`,
  PARTIAL_ACCESS: `Teilzugriff`,
  PAYMENT_TERM: `Zahlungsbedingung`,
  PAYMENT_TITLE: `Zahlung`,
  PAYMENT_TYPE: `Zahlungstyp`,
  START_DATE: `Startdatum`,
  TERM: `Laufzeit`,
  TERMS_TITLE: `Bedingungen`,
  TOTAL_AMOUNT: `ARR`,
  USER_ADOPTION: `Benutzerübernahme`,
});

export const OrgDetailsLicenses = Object.freeze({
  CREATE_LICENSE: `Lizenz erstellen`,
  LICENSES_NUMBER: `Anzahl der Lizenzen`,
  PURCHASED_DATE: `Kaufdatum`,
  PURCHASED_NAME: `Käufername`,
  TITLE: `Lizenzen`,
});

export const OrgDetailsUsers = Object.freeze({
  TERM_TRIAL: `Testversion`,
  VIEW: `Anzeigen`,
  VIEW_ALL_PART1: `Alle anzeigen`,
  VIEW_ALL_PART2: `Datensätze`,
  VIEW_LESS: `Weniger anzeigen`,
});

export const OrgExtendTrialModal = Object.freeze({
  EXTEND_DESCRIPTION: `Anzahl der Tage verlängern`,
  EXTEND_MESSAGE: `Kann nicht weiter verlängert werden. Die Testversion wurde bereits auf 30 Tage verlängert.`,
  EXTEND_TITLE: `Verlängern`,
  SAVE_AND_EXTEND: `Speichern und verlängern`,
  RULES_DESCRIPTION_PART1: `Kostenlose Testversionen gelten für 15 Tage.`,
  RULES_DESCRIPTION_PART2: `Support-Mitarbeiter können um weitere 15 Tage verlängern.`,
  RULES_DESCRIPTION_PART3: `Nach Ablauf kann der Kunde sich immer noch anmelden, sieht jedoch nur die Abrechnungsseite.`,
  RULES_DESCRIPTION_PART4: `Ermutigen Sie sie, einfach 1 Lizenz monatlich zu kaufen.`,
  RULES_TITLE: `Regeln`,
  TITLE: `Testversion verlängern`,
});

export const OrgExpireTrialModal = Object.freeze({
  EXPIRE_DESCRIPTION: `Ja, ich möchte diese Testversion erzwingen.`,
  EXPIRE_NOW: `Jetzt ablaufen lassen`,
  EXPIRE_TITLE: `Ablaufen lassen`,
  RULES_DESCRIPTION_PART1: `Wenn Sie feststellen, dass ein Wettbewerber oder jemand einen Test durchführt, der es nicht sollte, können Sie den Test erzwingen ablaufen lassen.`,
  RULES_DESCRIPTION_PART2: `Sie erhalten beim Anmelden einen Fehler und werden aufgefordert, sich an Vertrieb oder Support zu wenden.`,
  RULES_TITLE: `Regeln`,
  TITLE: `Testversion ablaufen lassen`,
});

export const OrgConvertTrialModal = Object.freeze({
  AMOUNT_PER_USER: `Pro Benutzer / Pro Monat`,
  ANNUAL_CONTRACT: `Jahresvertrag`,
  BILLING_TITLE: `Abrechnung - Rechnung Jahr 1`,
  CONTRACT_END_DATE: `Vertragsende Datum`,
  CONTRACT_START_DATE: `Vertragsstart Datum`,
  CONVERT_TO_CUSTOMER: `In zahlenden Kunden umwandeln`,
  IMMEDIATE_PROCESS_DESCRIPTION: `Ermutigen Sie Kunden, mit Kreditkarte in der App zu bezahlen, indem Sie auf "Lizenzen kaufen" in der Benutzerliste klicken, was sie automatisch umwandelt.`,
  IMMEDIATE_PROCESS_TITLE: `Sofortiger Prozess`,
  INVOICE_DUE_DATE: `Fälligkeitsdatum der Rechnung`,
  MANUAL_PROCESS_DESCRIPTION_PART1: `Nur Betriebsmitarbeiter können diese Funktion ausführen.`,
  MANUAL_PROCESS_DESCRIPTION_PART2: `Für >30 Lizenzen, Jahreslaufzeit, können Kunden per ACH, Überweisung oder Scheck bezahlen.`,
  MANUAL_PROCESS_DESCRIPTION_PART3: `Kunden müssen einen unterzeichneten Vertrag haben (und der PO ist optional).`,
  MANUAL_PROCESS_TITLE: `Manueller Prozess`,
  NET_TERMS: `Nettobedingungen`,
  RULES_TITLE: `Regeln`,
  SALESFORCE_OPPORTUNITY: `Salesforce Gelegenheit`,
  SALESFORCE_OPPORTUNITY_PLACEHOLDER: `Hyperlink hier eingeben`,
  TITLE: `Testversion in zahlenden Kunden umwandeln`,
  TOTAL_AMOUNT: `Gesamtbetrag jährlich`,
  USER_LICENSES_NUMBER: `Anzahl der Benutzerlizenzen`,
  WARNING_MESSAGE: `Diese Aktion kann nicht rückgängig gemacht werden.`,
});

export const OrgDeleteModal = Object.freeze({
  TITLE: `Organisation löschen`,
  DESCRIPTION: `Diese Organisation wird mit allen Daten gelöscht`,
});
