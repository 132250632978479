import { t } from '../../../../i18n/i18n';

export default {
  createLicense: t('OrgDetailsLicenses:CREATE_LICENSE'),
  licensesNumber: t('OrgDetailsLicenses:LICENSES_NUMBER'),
  name: t('Users:NAME'),
  purchasedDate: t('OrgDetailsLicenses:PURCHASED_DATE'),
  purchasedName: t('OrgDetailsLicenses:PURCHASED_NAME'),
  title: t('OrgDetailsLicenses:TITLE'),
};
