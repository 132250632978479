import { useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { WhenStep } from '../../common/meeting/whenStep/WhenStep';

export const BookingPageWhenStep = () => {
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);
  return (
    <WhenStep
      selectors={bookingPageSelectors}
      actions={bookingPagesActions}
      isReadOnly={isBookingPageLocked || hasTemplate}
    />
  );
};
