import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const INVITE_USERS_MODAL_NAME: ModalName = 'inviteUsersModal';
export const inviteUsersModalActions = modalsActionsFactory(INVITE_USERS_MODAL_NAME);
export const inviteUsersModalSelectors = modalsSelectorsFactory(INVITE_USERS_MODAL_NAME);

export const CONFIRM_INVITE_USERS_MODAL_NAME: ModalName = 'confirmInviteUsersModal';
export const confirmInviteUsersModalActions = modalsActionsFactory(CONFIRM_INVITE_USERS_MODAL_NAME);
export const confirmInviteUsersModalSelectors = modalsSelectorsFactory(CONFIRM_INVITE_USERS_MODAL_NAME);

export const EDIT_USERS_MODAL_NAME: ModalName = 'editUsersModal';
export const editUsersModalActions = modalsActionsFactory(EDIT_USERS_MODAL_NAME);
export const editUsersModalSelectors = modalsSelectorsFactory(EDIT_USERS_MODAL_NAME);

export const CONFIRM_USERS_MODAL_NAME: ModalName = 'confirmUsersModal';
export const confirmUsersModalActions = modalsActionsFactory(CONFIRM_USERS_MODAL_NAME);
export const confirmUsersModalSelectors = modalsSelectorsFactory(CONFIRM_USERS_MODAL_NAME);
