export const CannotDeleteWorkspace = Object.freeze({
  DESCRIPTION: `Les utilisateurs suivants sont assignés à utiliser uniquement cet espace de travail`,
  NOTES: `Vous devez d'abord assigner ces utilisateurs à un autre espace de travail avant de le supprimer.`,
  TITLE: `Réaffecter les utilisateurs`,
});

export const WorkspaceToastsNotifications = Object.freeze({
  ACTIVATE_INACTIVATE_WORKSPACE_ERROR_MESSAGE: `Échec de la requête d'activation/désactivation de l'espace de travail`,
  ACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail activé avec succès`,
  DELETE_WORKSPACE_ERROR_MESSAGE: `Échec de la requête de suppression de l'espace de travail`,
  DELETE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail supprimé avec succès`,
  GET_WORKSPACES_ERROR_MESSAGE: `Échec de la requête d'obtention des espaces de travail`,
  INACTIVATE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail désactivé avec succès`,
  REMOVE_INTEGRATION_SUCCESS_TOAST: `Intégration de l'espace de travail déconnectée avec succès`,
  SAVE_WORKSPACE_ERROR_MESSAGE: `Échec de la requête d'enregistrement de l'espace de travail`,
  SAVE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail enregistré avec succès`,
  CLONE_WORKSPACE_ERROR_MESSAGE: `Échec de la demande de clonage de l'espace de travail`,
  CLONE_WORKSPACE_SUCCESS_MESSAGE: `Espace de travail cloné avec succès`,
});

export const WorkspaceWhoStep = Object.freeze({
  ADMINS_TITLE: `Administrateurs`,
  ADMIN_DESCRIPTION: `Les personnes suivantes peuvent administrer cet espace de travail.`,
  INVITE_SETTINGS_TITLE: `Paramètres d'invitation`,
  NOTES_PART_1: `Vous pouvez également utiliser la section `,
  NOTES_PART_2: `Gestion des utilisateurs`,
  NOTES_PART_3: ` pour trouver un utilisateur et l'ajouter à un espace de travail.`,
  PHONE_NUMBER_DESCRIPTION: `Si vous prévoyez d'utiliser SUMO pour réserver des appels téléphoniques, il est recommandé de le laisser activé.`,
  PHONE_NUMBER_TITLE: `Rendre obligatoire le champ "Numéro de téléphone" des utilisateurs.`,
  SEARCH_ADD_ADMINS: `Rechercher et ajouter un administrateur`,
  SEARCH_ADD_USERS: `Rechercher et ajouter des utilisateurs`,
  SUPER_ADMINS_DESCRIPTION: `Les super-administrateurs seront toujours préremplis`,
  USERS_DESCRIPTION: `Les personnes suivantes peuvent utiliser cet espace de travail.`,
  USERS_TITLE: `Utilisateurs`,
});

export const Workspaces = Object.freeze({
  ACTIVE: `Actif`,
  ADMIN_TITLE: `Administrateur`,
  CLONE_MODAL_TITLE: `Cloner l'espace de travail`,
  DEFAULT_WORKSPACE: `Espace de travail par défaut`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  INACTIVE: `Inactif`,
  NAME_TITLE: `Nom de l'espace de travail`,
  NAME_PLACEHOLDER: `Entrez un nom d'espace de travail`,
  NEW_WORKSPACE: `Nouvel espace de travail`,
  SELECTED_WORKSPACES: `espaces de travail sélectionnés`,
  TITLE: `Espaces de travail`,
  BRANDING_TITLE: `Personnalisation de la page de marque`,
  BRANDING_DESCRIPTION: `Personnalisez l'apparence, les styles et les étiquettes pour les nouvelles pages de réservation.`,
});

export const EditWorkspace = Object.freeze({
  WHAT_TITLE: `Quoi`,
  WHAT_DESCRIPTION: `Nom et statut`,
  WHO_TITLE: `Qui`,
  WHO_DESCRIPTION: `Administrateurs et utilisateurs`,
  HOW_TITLE: `Image de marque`,
  HOW_DESCRIPTION: `Styles et présentations de marque`,
  ADDITIONAL_CONFIGURATIONS: `Configurations supplémentaires`,
});
