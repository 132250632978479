import { t } from '../../../i18n/i18n';

export default {
  statItemsLabels: [
    t('BookingStats:MEETINGS_BOOKED'),
    t('BookingStats:REMINDERS_SENT'),
    t('BookingStats:RESCHEDULES'),
    t('BookingStats:CANCELS'),
  ],
  title: t('BookingStats:TITLE'),
};
