import { t } from '../../i18n/i18n';
import { CanceledType, CreateUserEventInput } from '../../API';
import { DEFAULT_BOOKING_PAGE_DATA } from '../bookingPages/constants';
import { AgendaType, EventSteps, NavigationStep } from './types'; // HostData
import { SelectItemOptionsType } from 'primereact/selectitem';

export const DEFAULT_EVENT: CreateUserEventInput = {
  userId: '',
  tenant: '',
  eventDate: '',
  eventId: '',
  bookingPageId: '',
  bookingPageName: '',
  startTime: '',
  endTime: '',
  integrations: [],
  location: null,
  guestEmails: [], // additionalGuests
  note: '',
  canceled: {
    isCanceled: false,
    type: CanceledType.UNEXPECTED,
  },
};

export const DEFAULT_AGENDA: AgendaType = {
  bookingPage: DEFAULT_BOOKING_PAGE_DATA,
};

// export const PREVIEW_HOST: HostData = {
//   id: '',
//   name: '',
//   phone: '',
//   email: '',
//   videoIntegrations: [ 'MICROSOFT_TEAMS', 'GOOGLE_MEET', 'ZOOM' ],
// };

export const DEFAULT_VIEW_DATE: number = new Date().valueOf();

export const PREVIEW_AVAILABLE_TIMES: number[] = [
  new Date(2000, 0, 1, 9, 0).getTime(),
  new Date(2000, 0, 1, 9, 15).getTime(),
  new Date(2000, 0, 1, 9, 30).getTime(),
  new Date(2000, 0, 1, 9, 45).getTime(),
  new Date(2000, 0, 1, 10, 0).getTime(),
  new Date(2000, 0, 1, 10, 15).getTime(),
  new Date(2000, 0, 1, 10, 30).getTime(),
  new Date(2000, 0, 1, 10, 45).getTime(),
  new Date(2000, 0, 1, 11, 0).getTime(),
];

export const CANCEL_TYPE_OPTIONS: SelectItemOptionsType = [
  { value: CanceledType.UNEXPECTED, label: t('BookedMeetings:REASON_UNEXPECTED') },
  { value: CanceledType.INVITE_OTHERS, label: t('BookedMeetings:REASON_INVITE') },
  { value: CanceledType.TIME, label: t('BookedMeetings:REASON_TIME') },
  { value: CanceledType.OTHER, label: t('BookedMeetings:REASON_OTHER') },
];

// export const EVENT_STEPS_ORDER: string[] = Object.values(EventSteps);
export const DEFAULT_EVENT_STEPS: NavigationStep[] = Object.values(EventSteps).map((step) => ({
  step,
  active: false,
  visited: false,
}));

export const IN_PERSON_LABEL = t('PublicBookingPage:IN_PERSON_LABEL');
export const PHONE_LABEL = t('PublicBookingPage:PHONE_LABEL');
export const VIDEO_CONFERENCE_LABEL = t('PublicBookingPage:VIDEO_CONFERENCE_LABEL');
export const BOOKING_PAGE_EXPIRED_LABEL = t('PublicBookingPage:BOOKING_PAGE_EXPIRED');

export const NO_USERS_AVAILABLE_LABEL = t('PublicBookingPage:NO_USERS_AVAILABLE_LABEL');
export const DEACTIVATED_LABEL = t('PublicBookingPage:DEACTIVATED_LABEL');
export const MAX_LOCATION_DISTANCE = 100;
