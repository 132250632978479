import { useDispatch, useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import labels from './labels';
import { HostOption } from '../../../types/types';
import { InformationBlock, SumoMultiSelect } from '../../common';
import { HostCard } from '../../common/hostCard/HostCard';
import { MultiSelectChangeEvent } from 'primereact/multiselect';

export const BookingTemplateWhoStep = () => {
  const dispatch = useDispatch();
  const availableHostAndTeams = useSelector(bookingTemplatesSelectors.selectAvailableHostsAndTeams);
  const hostsAndTeams = useSelector(bookingTemplatesSelectors.selectAssignedHostsAndTeams);
  const isNoHost = useSelector(bookingTemplatesSelectors.selectIsNoHost);
  const isReadOnly = useSelector(bookingTemplatesSelectors.selectIsReadOnlyBookingTemplate);

  const handleHostsAndTeamsChange = (e: MultiSelectChangeEvent) => {
    const selectedValues: string[] = e.value;
    updateMembers(availableHostAndTeams.filter((member) => selectedValues.includes(member.value)));
  };

  const handleSelectAllChange = (checked: boolean) => {
    updateMembers(checked ? availableHostAndTeams : []);
  };

  const handleRemoveMember = (memberId: string) => {
    updateMembers(hostsAndTeams.filter((member) => member.value !== memberId));
  };

  const updateMembers = (members: HostOption[]) => {
    dispatch(
      bookingTemplatesActions.updatePotentialHosts(
        members.filter((member) => !member.isTeam).map((member) => member.value)
      )
    );
    dispatch(
      bookingTemplatesActions.updatePotentialTeams(
        members.filter((member) => member.isTeam).map((member) => member.value)
      )
    );
  };

  return (
    <div className="flex flex-column gap-16px">
      {isNoHost && <InformationBlock text={labels.unassigned} />}

      <div className="text-title-xs-med text-heavy-100">{labels.hostsAndTeams}</div>
      <div className="flex flex-wrap -mx-20px -my-6px">
        <div className="w-12 lg:w-6 px-20px py-6px">
          <SumoMultiSelect
            className="pr-24px"
            onSelectAllChange={handleSelectAllChange}
            options={availableHostAndTeams}
            value={hostsAndTeams.map((member) => member.value)}
            onChange={handleHostsAndTeamsChange}
            filterPlaceholder={labels.searchPlaceholder}
            disabled={isReadOnly}
            templateType="host"
          />
        </div>
        <div className="w-12 lg:w-6 px-20px py-6px"></div>
        {hostsAndTeams.map((host) => (
          <div key={host.value} className="w-12 lg:w-6 px-20px py-6px">
            <HostCard host={host} onRemove={isReadOnly ? undefined : handleRemoveMember} />
          </div>
        ))}
      </div>
    </div>
  );
};
