import { t } from '../../../i18n/i18n';

export default {
  title: t('BookingPages:CLONE_MODAL_TITLE'),
  name: t('BookingPageWhatStep:NAME'),
  namePlaceholder: t('BookingPageWhatStep:NAME_PLACEHOLDER'),
  duplicate: t('Common:NAME_IS_DUPLICATE'),
  cancel: t('Common:CANCEL'),
  save: t('Common:SAVE'),
  cloneNotification: t('BookingPages:CLONE_NOTIFICATION'),
};
