import { t } from '../../../i18n/i18n';

export default {
  accountName: t('OPSConsoleOrgDetails:ACCOUNT_NAME'),
  activeUsersNumber: t('OPSConsoleOrgDetails:ACTIVE_USERS_NUMBER'),
  back: t('Common:BACK'),
  bookedNumber: t('OPSConsoleOrgDetails:BOOKED_NUMBER'),
  featurePricingTier: t('OPSConsoleOrgDetails:FEATURE_PRICING_TIER'),
  lastActive: t('Users:LAST_ACTIVE'),
  licenseStatus: t('OPSConsoleUsers:LICENSE_STATUS_TITLE'),
  orgId: t('OPSConsoleOrgDetails:ORG_ID'),
  registrationDate: t('OPSConsoleUsers:REGISTRATION_DATE'),
  renewalDate: t('OPSConsoleUsers:RENEWAL_DATE'),
  role: t('Users:ROLE'),
  status: t('Users:STATUS'),
  term: t('OPSConsoleOrgDetails:TERM'),
  title: t('Common:USER'),
  userInfo: t('OPSConsoleUsers:USER_INFO_TITLE'),
  viewAsUser: t('OPSConsoleUsers:VIEW_AS_USER'),
};
