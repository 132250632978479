import { t } from '../../../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  expire: t('OrgExpireTrialModal:EXPIRE_TITLE'),
  expireDesc: t('OrgExpireTrialModal:EXPIRE_DESCRIPTION'),
  expireNow: t('OrgExpireTrialModal:EXPIRE_NOW'),
  rules: t('OrgExpireTrialModal:RULES_TITLE'),
  rulesDesc: [t('OrgExpireTrialModal:RULES_DESCRIPTION_PART1'), t('OrgExpireTrialModal:RULES_DESCRIPTION_PART2')],
  title: t('OrgExpireTrialModal:TITLE'),
};
