import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { UpdateBookingPageInput } from '../../../API';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../../store/groupBookingPages';
import labels from './labels';
import { SumoMultiSelect, SumoTooltip } from '../../common';
import { bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { BookingPageCard } from '../../common/bookingPageCard/BookingPageCard';

export const GroupBookingPageBasicSettings = () => {
  const dispatch = useDispatch();
  const groupBookingPage = useSelector(groupBookingPagesSelectors.selectGroupBookingPage);
  const isNameValid = useSelector(groupBookingPagesSelectors.selectIsGroupBookingPageNameValid);
  const bookingPageOptions = useSelector(bookingPageSelectors.selectFilteredBookingPages);
  const bookingPages = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPages);
  const bookingPageIds = useSelector(groupBookingPagesSelectors.selectGroupBookingPageBookingPageIds);
  const bookingPagesById = useSelector(bookingPageSelectors.selectBookingPagesById);
  const bookingTemplateNamesById = useSelector(bookingTemplatesSelectors.selectBookingTemplateNamesById);

  const handleNameChange = (name: string) => {
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ name }));
  };

  // const handleAdminOnlyChange = (adminOnly: boolean) => {
  //   dispatch(groupBookingPagesActions.updateGroupBookingPage({ adminOnly }));
  // };

  const handleBookingPageChange = (e: MultiSelectChangeEvent) => {
    const updatedOptions: UpdateBookingPageInput[] = e.target.value;
    dispatch(
      groupBookingPagesActions.updateGroupBookingPage({
        bookingPages: updatedOptions?.map((option) => ({ bookingPageId: option.id })),
      })
    );
  };

  const handleSelectAllChange = (checked: boolean) => {
    dispatch(
      groupBookingPagesActions.updateGroupBookingPage({
        bookingPages: checked ? bookingPageOptions.map((option) => ({ bookingPageId: option.id })) : [],
      })
    );
  };

  const handleBookingPageRemoveChange = (id: string) => {
    const newBookingPages = bookingPages.filter((record) => record?.bookingPageId && record.bookingPageId !== id);
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ bookingPages: newBookingPages }));
  };

  return (
    <div className="flex flex-column gap-24px">
      <div className="flex gap-8px py-12px px-16px w-full border-radius-8px bg-blue-soft text-blue-main">
        <InformationCircleIcon className="icon-18px flex-none" />
        <div className="flex-1 text-label-xs-reg">{labels.infoMessage}</div>
      </div>

      <div className="flex flex-wrap -mx-20px -my-6px">
        <div className="w-12 lg:w-12 lg:w-6 px-20px py-6px">
          <div className="flex flex-column gap-10px pr-24px">
            <div className="flex-left-center gap-8px">
              <div className="text-title-xs-med">{labels.nameTitle}</div>
              <SumoTooltip text={labels.nameTooltip} />
            </div>
            <InputText
              value={groupBookingPage.name || ''}
              onChange={(e) => handleNameChange(e.target.value)}
              placeholder={labels.namePlaceholder}
              maxLength={MAX_LENGTH_NAME}
              className={`${!isNameValid ? 'p-invalid' : ''}`}
            />
          </div>
        </div>
        {/* <div className="w-12 lg:w-12 lg:w-6 px-20px py-6px flex-bottom">
          <div className="text-title-xs-med flex-left-center gap-12px h-fit py-10px">
            <InputSwitch
              inputId="adminOnly"
              checked={groupBookingPage.adminOnly || false}
              onChange={(e) => handleAdminOnlyChange(!!e.value)}
            />
            <label htmlFor="adminOnly" className="cursor-pointer -mr-4px">
              {labels.adminOnly}
            </label>
            <SumoTooltip text={labels.adminOnlyTooltip} />
          </div>
        </div> */}
      </div>

      <div className="flex flex-wrap -mx-20px -my-6px">
        <div className="w-12 px-20px py-6px flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.bookingPages}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.bookingPagesDesc}</div>
        </div>

        <div className="w-12 lg:w-6 px-20px py-6px">
          <SumoMultiSelect
            className="pr-24px"
            onSelectAllChange={handleSelectAllChange}
            options={bookingPageOptions}
            optionValue="id"
            value={bookingPageOptions.filter((option) => bookingPageIds.includes(option.id))}
            filterBy="displayId,what.customName"
            onChange={handleBookingPageChange}
            templateType="bookingPage"
          />
        </div>
        <div className="w-12 lg:w-6 px-20px py-6px"></div>

        {bookingPageIds?.map((id) => {
          const page = bookingPagesById[id];
          const templateName =
            (page?.what?.bookingTemplateId && bookingTemplateNamesById[page?.what?.bookingTemplateId]) || undefined;
          return (
            page && (
              <div key={id} className="w-12 lg:w-6 px-20px py-6px">
                <BookingPageCard
                  bookingPage={page}
                  bookingTemplateName={templateName}
                  onRemove={handleBookingPageRemoveChange}
                />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
