export const OPSConsoleUsers = Object.freeze({
  ACCOUNT_ID: `ID de cuenta`,
  EXPORT: `Exportar`,
  SEARCH_PLACEHOLDER: `Buscar por nombre o correo electrónico`,
  SUBHEADER_TEXT: `Usuarios más activos`,
  VIEW_AS_USER: `Ver como usuario`,
  REGISTRATION_DATE: `Fecha de registro`,
  RENEWAL_DATE: `Fecha de renovación`,
  USER_INFO_TITLE: `Información del usuario`,
  LICENSE_STATUS_TITLE: `Estado de la licencia`,
  CUSTOM_ROLE: `Rol Personalizado`,
});

export const OPSConsoleUsersToastsNotifications = Object.freeze({
  ACTIVATE_USER_SUCCESS_MESSAGE: `Éxito en la solicitud de activación de usuario`,
  DEACTIVATE_USER_FAIL_MESSAGE: `Error en la solicitud de desactivación/activación de usuario`,
  DEACTIVATE_USER_SUCCESS_MESSAGE: `Éxito en la solicitud de desactivación de usuario`,
  DELETE_USER_FAIL_MESSAGE: `Error en la solicitud de eliminación de usuario`,
  DELETE_USER_SUCCESS_MESSAGE: `Éxito en la solicitud de eliminación de usuario`,
  GET_USER_DETAILS_FAIL_MESSAGE: `Error en la solicitud de detalles del usuario`,
  GET_USERS_FAIL_MESSAGE: `Error en la solicitud de usuarios`,
});

export const ViewAsUser = Object.freeze({
  BACK_TO_OPS_CONSOLE: `Volver a la Consola OPS`,
  MODAL_DESCRIPTION_PART1: `El modo "Ver como usuario" está activo.`,
  MODAL_DESCRIPTION_PART2: `Para continuar usando la Consola OPS, por favor, concluya la sesión "Ver como usuario" seleccionando una de las siguientes opciones:`,
  MODAL_DESCRIPTION_PART3: `Haga clic en el botón "Volver a la Consola OPS".`,
  MODAL_DESCRIPTION_PART4: `Presione la opción "Restablecer" aquí.`,
  VIEW_AS_USER_MODE: `Modo Ver como Usuario`,
  VIEWING_SUMO1_TITLE: `Viendo SUMO1 como usuario.`,
});
