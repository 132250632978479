import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import labels from './labels';
import { TabPanel, TabView } from 'primereact/tabview';
import { PublicBookingPageMeetingDetails } from '../publicBookingPageMeetingDetails/PublicBookingPageMeetingDetails';
import { PublicBookingPageBookedActions } from '../publicBookingPageBookedActions/PublicBookingPageBookedActions';
import { ChatBubbleOvalLeftIcon, InformationCircleIcon, UserIcon, UsersIcon } from '@heroicons/react/24/outline';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Label } from '../../common';
import { MAX_LENGTH_DESCRIPTION } from '../../../types/constants';
import { CustomFieldType, CustomType } from '../../../API';
import { formatPhoneString } from '../../../store/publicBookingPage/utils';

export const PublicBookingPageBookedInfo = () => {
  const dispatch = useDispatch();
  const isRedirect = useSelector(eventSelectors.selectIsRedirect);
  const externalLink = useSelector(eventSelectors.selectExternalLink);
  const name = useSelector(eventSelectors.selectAttendeeName);
  const email = useSelector(eventSelectors.selectAttendeeEmail);
  const inputFields = useSelector(eventSelectors.selectFilteredInputFields);
  const event = useSelector(eventSelectors.selectEvent);
  const detailsLabel = useSelector(eventSelectors.selectDetailsLabel);
  const guestsLabel = useSelector(eventSelectors.selectGuestsLabel);
  const bookedDescription = useSelector(eventSelectors.selectBookedDescription);
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const isRescheduled = useSelector(eventSelectors.selectIsRescheduled);
  const isCanceled = useSelector(eventSelectors.selectEventIsCanceled);

  useEffect(() => {
    if (isRedirect && externalLink) {
      window.location.href = externalLink;
    }
  }, []);

  const handleDescriptionLabelChange = (value: string) => {
    dispatch(
      eventActions.updatePreviewLabel(
        isRescheduled
          ? { rescheduleDescription: value }
          : isCanceled
          ? { cancelDescription: value }
          : { bookDescription: value }
      )
    );
  };

  const handleDetailsLabelChange = (detailsLabel: string) => {
    dispatch(eventActions.updatePreviewLabel({ detailsLabel }));
  };

  const handleGuestsLabelChange = (guestsLabel: string) => {
    dispatch(eventActions.updatePreviewLabel({ guestsLabel }));
  };

  return (
    <div className="flex flex-column gap-20px h-full">
      <div className="booked-message">
        <Label
          textClassName="text-title-xs-med"
          maxLength={MAX_LENGTH_DESCRIPTION}
          editable={isEditable}
          value={bookedDescription}
          onChange={handleDescriptionLabelChange}
        />
      </div>
      <TabView className="flex-1 h-0 tabview-embed">
        <TabPanel
          header={
            <Label
              textClassName="text-title-xs-med"
              editable={isEditable}
              value={detailsLabel}
              onChange={handleDetailsLabelChange}
            />
          }
          className="h-full"
        >
          <div className="booked-container">
            <PublicBookingPageMeetingDetails />
            <PublicBookingPageBookedActions />
          </div>
        </TabPanel>
        <TabPanel
          header={
            <Label
              textClassName="text-title-xs-med"
              editable={isEditable}
              value={guestsLabel}
              onChange={handleGuestsLabelChange}
            />
          }
          className="h-full"
        >
          <div className="booked-container">
            <div className="flex-1 pr-20px">
              <ScrollPanel>
                <div className="booked-guests">
                  <div className="flex-left-center gap-8px">
                    <div className="flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
                      <UserIcon className="icon-24px" />
                    </div>
                    <div className="flex flex-column gap-5px">
                      <div className="text-label-lg-med">{name}</div>
                      <div className="text-label-xs-reg text-heavy-80">{email}</div>
                    </div>
                  </div>

                  {event.guestEmails?.length ? (
                    <div className="flex gap-8px">
                      <div className="flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
                        <UsersIcon className="icon-24px" />
                      </div>
                      <div className="flex flex-column gap-5px pt-4px">
                        <div className="text-label-lg-med">{`Attendees (${event.guestEmails?.length})`}</div>
                        <div className="text-label-xs-reg text-heavy-80 flex flex-column gap-8px">
                          {(event.guestEmails || []).map((email, index) => (
                            <div key={index}>{email}</div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {event.note ? (
                    <div className="flex-left-center gap-8px">
                      <div className="flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
                        <ChatBubbleOvalLeftIcon className="icon-24px" />
                      </div>
                      <div className="flex flex-column gap-5px">
                        <div className="text-label-lg-med">{labels.noteForAttendees}</div>
                        <div className="text-label-xs-reg text-heavy-80">{event.note}</div>
                      </div>
                    </div>
                  ) : null}

                  {inputFields.length ? (
                    <div className="flex flex-column w-fit">
                      <div className="flex-left-center gap-8px text-label-lg-med pb-6px">
                        <div className="flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
                          <InformationCircleIcon className="icon-24px" />
                        </div>
                        <div>{labels.yourAnswers}</div>
                      </div>
                      {inputFields?.map((customField, index) => (
                        <div
                          key={customField.id}
                          className={`flex flex-column gap-8px ml-48px text-body-s-reg ${
                            index === 0 ? '' : 'border-top-1 border-heavy-20 pt-10px mt-16px'
                          }`}
                        >
                          <div className="text-heavy-80">{customField.label}</div>
                          <div>
                            {customField.fieldType === CustomFieldType.PHONE || customField.type === CustomType.PHONE
                              ? formatPhoneString(customField.value)
                              : customField.value}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </ScrollPanel>
            </div>
            <PublicBookingPageBookedActions />
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};
