import { useSelector } from 'react-redux';
import { bookingPagesActions, bookingPageSelectors } from '../../../store/bookingPages';
import { HowStep } from '../../common/meeting/howStep/HowStep';

export const BookingPageHowStep = () => {
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);

  return (
    <HowStep
      selectors={bookingPageSelectors}
      actions={bookingPagesActions}
      isReadOnly={isBookingPageLocked || hasTemplate}
    />
  );
};
