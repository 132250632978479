import { t } from '../../../../i18n/i18n';

export default {
  account: t('OPSConsoleOrgs:ACCOUNT_TITLE'),
  deactivateMessage: t('UsersConfirmDialog:DISABLE_DESCRIPTION'),
  deactivateTitle: t('UsersConfirmDialog:DISABLE_TITLE'),
  deleteMessage: t('UsersConfirmDialog:DELETE_DESCRIPTION'),
  deleteTitle: t('UsersConfirmDialog:DELETE_TITLE'),
  email: t('Users:EMAIL'),
  fullName: t('Users:FULL_NAME'),
  lastActive: t('Users:LAST_ACTIVE'),
  no: t('Common:NO'),
  never: t('Common:NEVER'),
  orgId: t('OPSConsoleOrgs:ORG_ID_TITLE'),
  role: t('Users:ROLE'),
  status: t('Users:STATUS'),
  yes: t('Common:YES'),
};
