import { t } from '../../../../i18n/i18n';

export default {
  accountName: t('OPSConsoleOrgDetails:ACCOUNT_NAME'),
  activeUsersNumber: t('OPSConsoleOrgDetails:ACTIVE_USERS_NUMBER'),
  weeklyAdoptionPercent: t('OPSConsoleOrgDetails:ADOPTION_PERCENT_WEEKLY'),
  monthlyAdoptionPercent: t('OPSConsoleOrgDetails:ADOPTION_PERCENT_MONTHLY'),
  amountPerUser: t('OPSConsoleOrgDetails:AMOUNT_PER_USER'),
  bookedNumber: t('OPSConsoleOrgDetails:BOOKED_NUMBER'),
  daysRemaining: t('OPSConsoleOrgDetails:DAYS_REMAINING'),
  expirationDate: t('OPSConsoleOrgDetails:EXPIRATION_DATE'),
  featurePricingTier: t('OPSConsoleOrgDetails:FEATURE_PRICING_TIER'),
  licensesNumber: t('OPSConsoleOrgDetails:LICENSES_NUMBER'),
  orgId: t('OPSConsoleOrgDetails:ORG_ID'),
  orgNote: t('OPSConsoleOrgDetails:ORG_NOTE'),
  orgStatus: t('OPSConsoleOrgDetails:ORG_STATUS'),
  orgType: t('OPSConsoleOrgDetails:ORG_TYPE'),
  payment: t('OPSConsoleOrgDetails:PAYMENT_TITLE'),
  paymentTerm: t('OPSConsoleOrgDetails:PAYMENT_TERM'),
  paymentType: t('OPSConsoleOrgDetails:PAYMENT_TYPE'),
  startDate: t('OPSConsoleOrgDetails:START_DATE'),
  term: t('OPSConsoleOrgDetails:TERM'),
  terms: t('OPSConsoleOrgDetails:TERMS_TITLE'),
  totalAmount: t('OPSConsoleOrgDetails:TOTAL_AMOUNT'),
  userAdoption: t('OPSConsoleOrgDetails:USER_ADOPTION'),
};
