import { t } from '../../i18n/i18n';

export default {
  cancel: t('Common:CANCEL'),
  title: t('Billing:TITLE'),
  yourSubscription: t('Billing:YOUR_SUBSCRIPTION'),
  teamsMonthly: t('Billing:TEAMS_MONTHLY'),
  teamsAnnual: t('Billing:TEAMS_ANNUAL'),
  seats: t('Billing:SEATS'),
  lastUpdated: t('Billing:LAST_UPDATED'),
  changeTerm: t('Billing:CHANGE_TERM'),
  addSeats: t('Billing:ADD_SEATS'),
  removeSeats: t('Billing:REMOVE_SEATS'),
  yourNextBill: t('Billing:YOUR_NEXT_BILL'),
  dueOn: t('Billing:DUE_ON'),
  billIsLatePart1: t('Billing:BILL_IS_LATE_PART_1'),
  billIsLatePart2Single: t('Billing:BILL_IS_LATE_PART_2_SINGLE'),
  billIsLatePart2Multiple: t('Billing:BILL_IS_LATE_PART_2_MULTIPLE'),
  billingDetails: t('Billing:BILLING_DETAILS'),
  chargeBeeButton: t('Billing:CHARGEBEE_BUTTON'),
  billingPortalDescription: t('Billing:BILLING_PORTAL_DESRIPTION'),
  scheduledChanges: t('Billing:SCHEDULED_CHANGES'),
  scheduledChangesDescription: t('Billing:SCHEDULED_CHANGES_DESRIPTION'),
  scheduledColumnLabel: t('Billing:SCHEDULED_COLUMN_LABEL'),
  scheduledColumnChangeBy: t('Billing:SCHEDULED_COLUMN_CHANGE_BY'),
  scheduledColumnDate: t('Billing:SCHEDULED_COLUMN_DATE'),
  scheduledColumnOptions: t('Billing:SCHEDULED_COLUMN_OPTIONS'),
  expireMessage1: t('Billing:EXPIRE_MESSAGE_PART_1'),
  expireMessage2: t('Billing:EXPIRE_MESSAGE_PART_2'),
  expireMessage3: t('Billing:EXPIRE_MESSAGE_PART_3'),
  payNow: t('Billing:PAY_NOW_TITLE'),
  trialLicenseExpired: t('Billing:TRIAL_LICENSE_EXPIRED'),
  scheduledChanges1Label: t('Billing:SCHEDULED_SEATS_CHANGE_PART_1'),
  scheduledChanges2Label: t('Billing:SCHEDULED_SEATS_CHANGE_PART_2'),
  termChangeLabel: t('Billing:SCHEDULED_TERM_CHANGE'),
  cancellationScheduled: t('Billing:CANCELLATION_SCHEDULED'),
  accountSuspendedPart1: t('Billing:ACCOUNT_SUSPENDED_PART1'),
  accountSuspendedPart2: t('Billing:ACCOUNT_SUSPENDED_PART2'),
  accountSuspendedPart3: t('Billing:ACCOUNT_SUSPENDED_PART3'),
};
