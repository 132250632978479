import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { ReactNode } from 'react';
import labels from './labels';

type BulkActionRowProps = {
  selectedCount: number;
  actionsContent: ReactNode;
  handleClose: () => void;
};
export const BulkActionRow = ({ selectedCount, actionsContent, handleClose }: BulkActionRowProps) => {
  return selectedCount ? (
    <div className="flex-center">
      <div className="sumo-bulk-action-row">
        <div className="flex-center gap-24px">
          <div className="text-body-s-reg text-blue-light">
            {selectedCount} {labels.selected}
          </div>
          <div className="sumo-bulk-action-content">{actionsContent}</div>
        </div>
        <Button text onClick={handleClose} className="button-secondary ml-20px p-5px">
          <XMarkIcon className="icon-20px" />
        </Button>
      </div>
    </div>
  ) : null;
};
