import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { UpdateBookingTemplateInput, VideoConferenceType, WorkspaceInput } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CLONE_BOOKING_TEMPLATE_ERROR_TOAST,
  CLONE_BOOKING_TEMPLATE_SUCCESS_TOAST,
  DEFAULT_BOOKING_TEMPLATE_DATA,
  DELETE_BOOKING_TEMPLATES_ERROR_TOAST,
  DELETE_BOOKING_TEMPLATES_SUCCESS_TOAST,
  GET_BOOKING_TEMPLATES_ERROR_TOAST,
  SAVE_BOOKING_TEMPLATE_ERROR_TOAST,
  SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST,
} from './constants';
import { BookingTemplatesActionTypes, bookingTemplatesActions } from './actions';
import { bookingTemplatesSelectors } from './selectors';
import { deleteBookingTemplates, getBookingTemplate, getBookingTemplates, postBookingTemplate } from './service';
import { authenticationSelectors } from '../authentication';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { userSettingsSelectors } from '../userSettings';
import { handleServiceError } from '../utils/reduxUtils';
import { usersSaga } from '../users/sagas';
import { teamsSaga } from '../teams/sagas';
import { workspacesSelectors } from '../workspaces';
import { GetBookingTemplatesResponse } from './types';
import { smartAlertsSagas } from '../smartAlerts/sagas';
import { locationsSagas } from '../locations/sagas';

const selectCreateBookingTemplateRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  bookingTemplatesSelectors.selectBookingTemplate,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingTemplate, lastModify) => ({
    ...bookingTemplate,
    workspaceId,
    lastModify,
  })
);

const selectCloneBookingTemplateRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  bookingTemplatesSelectors.selectBookingTemplate,
  bookingTemplatesSelectors.selectCloneName,
  userSettingsSelectors.selectNameOrEmail,
  (workspaceId, bookingTemplate, cloneName, lastModify) => ({
    ...bookingTemplate,
    id: '',
    workspaceId,
    lastModify,
    what: {
      ...bookingTemplate.what,
      customName: cloneName,
    },
  })
);

// TODO: find out how resolve typings error when thunk is dispatching from saga
function* getBookingTemplatesSaga() {
  try {
    const workspace: string = yield select(authenticationSelectors.selectWorkspaceId);
    const response: GetBookingTemplatesResponse = yield call(getBookingTemplates, workspace);

    yield put(bookingTemplatesActions.getBookingTemplatesSuccess(response.bookingTemplates));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplatesFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* getBookingTemplateSaga(action: ReturnType<typeof bookingTemplatesActions.getBookingTemplateRequest>) {
  try {
    if (action.type === BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST) {
      const id = action.payload;

      // prepopulate with existing data
      if (id) {
        const bookingTemplates: UpdateBookingTemplateInput[] = yield select(
          bookingTemplatesSelectors.selectBookingTemplates
        );
        const bookingTemplate = bookingTemplates.find((template) => template.id === id);
        if (bookingTemplate) {
          yield put(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
        }
      }

      // set default data for new record
      if (!id) {
        const currentWorkspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
        const currentWorkspace: WorkspaceInput = yield select(
          workspacesSelectors.selectWorkspaceById(currentWorkspaceId)
        );
        const userId: string = yield select(authenticationSelectors.selectUserId);
        const defaultVideoConference: VideoConferenceType = yield select(
          userSettingsSelectors.selectDefaultVideoIntegration
        );
        const bookingTemplate: UpdateBookingTemplateInput = {
          ...DEFAULT_BOOKING_TEMPLATE_DATA,
          workspaceId: currentWorkspaceId,
          labels: currentWorkspace.labels,
          style: currentWorkspace.style,
          potentialHosts: [userId],
          where: {
            ...DEFAULT_BOOKING_TEMPLATE_DATA.where,
            videoConferenceType: defaultVideoConference,
          },
        };
        yield put(bookingTemplatesActions.getBookingTemplateSuccess(bookingTemplate));
      }

      const requestsList = [
        ...(id ? [call(getBookingTemplate, id)] : []),
        call(usersSaga.getUsers),
        call(teamsSaga.getTeams),
        call(smartAlertsSagas.getSmartTypes),
        call(locationsSagas.getLocations),
      ];
      const [response]: [GetBookingTemplatesResponse] = yield all(requestsList);

      if (id && response?.bookingTemplates?.length) {
        yield put(bookingTemplatesActions.getBookingTemplateSuccess(response.bookingTemplates[0]));
        if (
          !response.bookingTemplates[0].potentialHosts?.length &&
          !response.bookingTemplates[0].potentialTeams?.length
        ) {
          // if no-host - open who section
          yield put(bookingTemplatesActions.updateAccordionIndexes({ who: true }));
        }
      } else if (id) {
        throw new Error('BookingTemaplate not found');
      }
    }
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.getBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, GET_BOOKING_TEMPLATES_ERROR_TOAST, true);
  }
}

function* createBookingTemplateSaga() {
  try {
    const createUserDataInput: UpdateBookingTemplateInput = yield select(selectCreateBookingTemplateRequest);

    yield call(postBookingTemplate, createUserDataInput);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.createBookingTemplatesuccess());
    yield put(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.createBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* cloneBookingTemplateSaga() {
  try {
    const createUserDataInput: UpdateBookingTemplateInput = yield select(selectCloneBookingTemplateRequest);

    yield call(postBookingTemplate, createUserDataInput);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.cloneBookingTemplatesuccess());
    yield put(notificationsActions.showToast(CLONE_BOOKING_TEMPLATE_SUCCESS_TOAST));
    yield put(bookingTemplatesActions.getBookingTemplatesRequest());
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.cloneBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

// TODO: find out how resolve typings error when saga has parameters
function* updateBookingTemplateSaga() {
  try {
    const bookingTemplate: UpdateBookingTemplateInput = yield select(bookingTemplatesSelectors.selectBookingTemplate);
    const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
    yield call(postBookingTemplate, { ...bookingTemplate, lastModify });

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.saveBookingTemplatesuccess());
    yield put(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.saveBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* activateBookingTemplateSaga(action: ReturnType<typeof bookingTemplatesActions.enableBookingTemplateRequest>) {
  try {
    if (action.type === BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST) {
      const bookingTemplate = action.payload;
      const lastModify: string = yield select(userSettingsSelectors.selectNameOrEmail);
      yield call(postBookingTemplate, { ...bookingTemplate, lastModify });

      yield put(bookingTemplatesActions.setBookingTemplate(bookingTemplate));
      yield put(bookingTemplatesActions.enableBookingTemplatesuccess());
      yield put(notificationsActions.showToast(SAVE_BOOKING_TEMPLATE_SUCCESS_TOAST));
      yield put(bookingTemplatesActions.getBookingTemplatesRequest());
    }
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.enableBookingTemplateFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_BOOKING_TEMPLATE_ERROR_TOAST);
  }
}

function* deleteBookingTemplatesSaga() {
  try {
    const ids: string[] = yield select(bookingTemplatesSelectors.selectSelectedBookingTemplates);
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);

    yield call(deleteBookingTemplates, ids, workspaceId);

    yield call(navigationService.navigateTo, Path.BookingTemplates);

    yield put(bookingTemplatesActions.deleteBookingTemplatesSuccess());
    yield put(notificationsActions.showToast(DELETE_BOOKING_TEMPLATES_SUCCESS_TOAST));
    yield put(bookingTemplatesActions.getBookingTemplatesRequest());
  } catch (error: unknown) {
    yield put(bookingTemplatesActions.deleteBookingTemplatesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_BOOKING_TEMPLATES_ERROR_TOAST);
  }
}

export function* watchBookingTemplatesSaga() {
  yield takeLatest(BookingTemplatesActionTypes.GET_BOOKING_TEMPLATES_REQUEST, getBookingTemplatesSaga);
  yield takeLatest(BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST, getBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.CREATE_BOOKING_TEMPLATE_REQUEST, createBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.CLONE_BOOKING_TEMPLATE_REQUEST, cloneBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.SAVE_BOOKING_TEMPLATE_REQUEST, updateBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.ENABLE_BOOKING_TEMPLATE_REQUEST, activateBookingTemplateSaga);
  yield takeLatest(BookingTemplatesActionTypes.DELETE_BOOKING_TEMPLATES_REQUEST, deleteBookingTemplatesSaga);
}

export const bookingTemplatesSagas = {
  getBookingTemplates: getBookingTemplatesSaga,
};
