import { t } from '../../../../i18n/i18n';

export default {
  noResults: t('Common:NO_RESULTS_FOUND'),
  tenantId: t('OPSConsoleAccountDetails:TENANT_COLUMN_ID'),
  tenantType: t('OPSConsoleAccountDetails:TENANT_COLUMN_TYPE'),
  tenantTerm: t('OPSConsoleAccountDetails:TENANT_COLUMN_TERM'),
  tenantLicensesOwned: t('OPSConsoleAccountDetails:TENANT_COLUMN_OWNED'),
  tenantLicensesAdoption: t('OPSConsoleAccountDetails:TENANT_COLUMN_ADOPTION'),
};
