import { useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { ConfirmationStep } from '../../common/meeting/confirmationStep/ConfirmationStep';

export const BookingPageConfirmationStep = () => {
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);
  return (
    <ConfirmationStep
      selectors={bookingPageSelectors}
      actions={bookingPagesActions}
      isReadOnly={isBookingPageLocked || hasTemplate}
    />
  );
};
