import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBookingTemplatesModalActions,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  FILTER_OPTIONS,
  FilterType,
} from '../../store/bookingTemplates';
import { usersActions, usersSelectors } from '../../store/users';
import { Path } from '../../routing';
import { BulkActionRow, CardSkeleton, EmptyListScreen, SectionHeader } from '../../components/common';
import labels from './labels';
import { Button } from 'primereact/button';
import {
  BookingTemplateCard,
  BookingTemplateCloneModal,
  BookingTemplateDeleteModal,
} from '../../components/bookingTemplates';
import { userSettingsSelectors } from '../../store/userSettings';
import { navigationService } from '../../services/NavigationService';
import { Dropdown } from 'primereact/dropdown';
import { bookingPagesActions } from '../../store/bookingPages';
import { useSession } from '../../hooks/useSession';
import { SelectItem } from 'primereact/selectitem';
import { TrashIcon, UserIcon, UsersIcon } from '@heroicons/react/24/outline';
import { SKELETON_LENGTH } from '../../types/constants';

export const BookingTemplates = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const isSpinnerFetching = useSelector(bookingTemplatesSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(bookingTemplatesSelectors.selectIsSkeletonFetching);
  const bookingTemplates = useSelector(bookingTemplatesSelectors.selectBookingTemplates); // all BT
  const searchedBookingTemplates = useSelector(bookingTemplatesSelectors.selectSearchedBookingTemplates); // filter by Search input
  const selectedBookingTemplates = useSelector(bookingTemplatesSelectors.selectSelectedBookingTemplates); // filter by Select checkboxes

  const isBookingTemplatesCreate = useSelector(userSettingsSelectors.selectBookingTemplatesCreate);
  const isMultipleUsers = useSelector(usersSelectors.selectIsMultipleUsers);
  const filterType = useSelector(bookingTemplatesSelectors.selectFilterType);
  const bookingTemplatesRead = useSelector(userSettingsSelectors.selectBookingTemplatesRead);
  const hostsInfo = useSelector(bookingTemplatesSelectors.selectHostsInfoById);

  useEffect(() => {
    if (!isInitialVisit) {
      dispatch(bookingTemplatesActions.getBookingTemplatesRequest());
      dispatch(usersActions.getUsersRequest());
      dispatch(bookingPagesActions.getBookingPagesRequest()); // required for delete modal
    }
  }, [isInitialVisit]);

  const handleCheckboxChange = (id: string, selected: boolean | undefined) => {
    selected
      ? dispatch(bookingTemplatesActions.selectBookingTemplate(id))
      : dispatch(bookingTemplatesActions.unselectBookingTemplate(id));
  };

  const handleNewBookingTemplate = () => {
    navigationService.navigateTo(Path.EditBookingTemplate.replace(':bookingTemplateId', 'new'));
  };

  const handleCancelSelection = () => {
    dispatch(bookingTemplatesActions.unselectAllBookingTemplate());
  };

  const handleOpenDeleteModal = () => {
    dispatch(deleteBookingTemplatesModalActions.openModal());
  };

  const handleFilterChange = (value: FilterType) => {
    dispatch(bookingTemplatesActions.setFilter(value));
  };

  const filterValueTemplate = (option: SelectItem) => (
    <div className="-m-12px p-4px">
      <div className="p-avatar p-avatar-xl">
        <div>{option.value === FilterType.MY ? labels.my : labels.all}</div>
      </div>
    </div>
  );

  const filterItemTemplate = (option: SelectItem) => (
    <div className="flex align-items-center gap-8px -ml-8px">
      <div className="p-avatar p-avatar-lg">
        {option.value === FilterType.MY ? <UserIcon className="icon-20px" /> : <UsersIcon className="icon-20px" />}
      </div>
      <div>{option.label}</div>
    </div>
  );

  const bulkContent = (
    <Button text className="gap-8px text-button-md-med" onClick={handleOpenDeleteModal}>
      <TrashIcon className="icon-18px" />
      {labels.delete}
    </Button>
  );

  return (
    <>
      <BookingTemplateCloneModal />
      <BookingTemplateDeleteModal />
      <BulkActionRow
        selectedCount={selectedBookingTemplates.length}
        handleClose={handleCancelSelection}
        actionsContent={bulkContent}
      />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isSpinnerFetching}
          title={labels.title}
          itemsLength={bookingTemplates.length}
          searchPaths={[Path.BookingTemplates, Path.EditBookingTemplate]}
          buttonLabel={labels.newBookingTemplate}
          onButtonClick={handleNewBookingTemplate}
          hideButton={!isBookingTemplatesCreate}
          extraContent={
            bookingTemplatesRead && isMultipleUsers ? (
              <Dropdown
                className="flex-none p-0 no-trigger p-button button-xl button-white"
                options={FILTER_OPTIONS}
                value={filterType}
                valueTemplate={filterValueTemplate}
                itemTemplate={filterItemTemplate}
                onChange={(e) => handleFilterChange(e.target.value)}
              />
            ) : undefined
          }
        />

        <div className="flex flex-wrap -m-8px">
          {isSkeletonFetching ? (
            [...Array(SKELETON_LENGTH)].map((value, index) => (
              <div className="w-12 lg:w-6 p-8px" key={index}>
                <CardSkeleton />
              </div>
            ))
          ) : searchedBookingTemplates.length ? (
            searchedBookingTemplates.map((bookingTemplate) => (
              <div className="w-12 lg:w-6 p-8px" key={bookingTemplate.id}>
                <BookingTemplateCard
                  bookingTemplate={bookingTemplate}
                  selectMode={Boolean(selectedBookingTemplates.length)}
                  selected={selectedBookingTemplates.includes(bookingTemplate.id)}
                  hostsInfo={hostsInfo[bookingTemplate.id]}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </div>
            ))
          ) : (
            <div className="w-12 p-8px">
              <EmptyListScreen />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
