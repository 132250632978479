import { useSelector } from 'react-redux';
import labels from './labels';
import { authenticationSelectors } from '../../store/authentication';
import urlConstants from '../../shared/JSON/urlConstants.json';
import { useEffect } from 'react';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { CalendarIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { userSettingsSelectors } from '../../store/userSettings';
import { usersSelectors } from '../../store/users';
import { Button } from 'primereact/button';

export const LicenseExpired = () => {
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isTrialLicense = useSelector(authenticationSelectors.selectIsTrialLicense);
  const activeSuperAdmins = useSelector(usersSelectors.selectActiveSuperAdminRecords);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);

  useEffect(() => {
    if (isLicenseActive) {
      if (isQuickSetupFinished) {
        navigationService.navigateTo(Path.BookingPages);
      } else {
        navigationService.navigateTo(Path.QuickSetup);
      }
    }
  }, []);

  const handleChatNowClick = () => {
    const crispChatButton = document.querySelector('.crisp-client a.cc-1m2mf');
    crispChatButton && (crispChatButton as HTMLElement).click();
  };

  const handleNavigateToBilling = () => {
    navigationService.navigateTo(isTrialLicense ? Path.Users : Path.Billing);
  };

  const getDescription = () => {
    if (isSuperAdmin) {
      return (
        <>
          {isTrialLicense ? labels.descAdminTrial : labels.descAdminPaid}{' '}
          <a href={urlConstants.SUMO_PRICING} target="blank">
            {labels.viewPricingPage}
          </a>
        </>
      );
    } else {
      return (
        <>
          {isTrialLicense ? labels.descPart1Trial : labels.descPart1Paid}{' '}
          {activeSuperAdmins.map((superAdmin, index) => (
            <>
              <a href={`mailto:${superAdmin.email}`} key={superAdmin.email}>
                {superAdmin.fullName || superAdmin.email}
              </a>
              {index !== activeSuperAdmins.length - 1 ? ', ' : ''}
            </>
          ))}{' '}
          {labels.descPart2}
        </>
      );
    }
  };

  return (
    <>
      <div className="flex-center-top flex-column h-screen -mt-28px -mb-24px" style={{ maxWidth: '580px' }}>
        <div className="text-display-md-med mb-2px"> {isTrialLicense ? labels.titleTrial : labels.title}</div>

        <div className="text-body-lg-reg mb-32px">{getDescription()}</div>

        {isSuperAdmin && (
          <div className="mb-60px">
            <Button
              className="button-xl"
              label={isTrialLicense ? labels.upgradeNow : labels.renewLicenses}
              onClick={handleNavigateToBilling}
            />
          </div>
        )}
        <div className="border-top-1 border-heavy-20" />

        <div className="my-24px text-title-s-med">{labels.contactTitle}</div>

        <div className="flex flex-wrap gap-20px">
          <div className="w-280px flex gap-8px">
            <div className="w-24px">
              <ChatBubbleLeftRightIcon className="icon-24px" />
            </div>
            <div className="flex flex-column gap-4px">
              <div
                className="text-title-xs-med text-blue-main hover-text-blue-dark cursor-pointer w-fit"
                onClick={handleChatNowClick}
              >
                {labels.chatTitle}
              </div>
              <div className="text-body-s-reg text-heavy-60">{labels.chatDesc}</div>
            </div>
          </div>

          <div className="w-280px flex gap-8px">
            <div className="w-24px">
              <CalendarIcon className="icon-24px" />
            </div>
            <div className="flex flex-column gap-4px">
              <a className="w-fit" href={urlConstants.EXPERTS_EMAIL_ADDRESS}>
                <div className="text-title-xs-med">{labels.emailTitle}</div>
              </a>
              <div className="text-body-s-reg text-heavy-60">{labels.emailDesc}</div>
            </div>
          </div>

          <div className="w-280px flex gap-8px">
            <div className="w-24px">
              <ChatBubbleLeftRightIcon className="icon-24px" />
            </div>
            <div className="flex flex-column gap-4px">
              <a className="w-fit" href={urlConstants.TALK_TO_AN_EXPERT_URL} target="blank">
                <div className="text-title-xs-med">{labels.bookMeetingTitle}</div>
              </a>
              <div className="text-body-s-reg text-heavy-60">{labels.bookMeetingDesc}</div>
            </div>
          </div>

          <div className="w-280px flex gap-8px">
            <div className="w-24px">
              <CalendarIcon className="icon-24px" />
            </div>
            <div className="flex flex-column gap-4px">
              <a className="w-fit" href={urlConstants.CALL_US}>
                <div className="text-title-xs-med">{labels.callTitle}</div>
              </a>
              <div className="text-body-s-reg text-heavy-60">
                {labels.callDescPart1} <br /> {labels.callDescPart2}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
