import { ProgressSpinner } from 'primereact/progressspinner';

export const Preloader = () => (
  <div
    className="fixed top-0 right-0 bottom-0 left-0 w-full h-full flex-center p-component-overlay"
    style={{ zIndex: Number.MAX_SAFE_INTEGER }} // TODO fix overlapping by modals/toasts with autoZIndex = true
  >
    <ProgressSpinner />
  </div>
);
