export const OPSConsoleAccountDetails = Object.freeze({
  ACCOUNT_NAME: `Nom du compte`,
  ACCOUNT_NOTE: `Note du compte`,
  CREATED_AT: `Créé à`,
  CREATED_BY: `Créé par`,
  DUPLICATE_NAME_MESSAGE: `Un compte avec ce nom existe déjà`,
  TENANT_COLUMN_ADOPTION: `% Adoption`,
  TENANT_COLUMN_ID: `ID de l'org.`,
  TENANT_COLUMN_OWNED: `# de licences`,
  TENANT_COLUMN_TERM: `Terme`,
  TENANT_COLUMN_TYPE: `Type`,
  TENANTS: `Orgs`,
});

export const OPSConsoleAccounts = Object.freeze({
  BUTTON_CREATE_ACCOUNT: `Créer un compte`,
  BUTTON_EXPORT: `Exporter`,
  BUTTON_MERGE: `Fusionner`,
  COLUMN_ACCOUNT_NAME: `Nom du compte`,
  COLUMN_CREATED_BY: `Créé par`,
  COLUMN_CREATED_DATE: `Date de création`,
  DELETE_ACCOUNT_ERROR_TOAST: `Impossible de supprimer le compte`,
  DELETE_ACCOUNT_SUCCESS_TOAST: `Compte supprimé.`,
  SAVE_ACCOUNT_ERROR_TOAST: `Impossible d'enregistrer le compte`,
  SAVE_ACCOUNT_SUCCESS_TOAST: `Compte enregistré.`,
  SEARCH_PLACEHOLDER: `Rechercher par nom de compte ou de domaine`,
  TITLE: `Comptes`,
  GET_ACCOUNT_ERROR_TOAST: `Échec de la requête de récupération du compte`,
  GET_ACCOUNTS_ERROR_TOAST: `Échec de la requête de récupération des comptes`,
});

export const OPSConsoleAccountsDeleteModal = Object.freeze({
  DESCRIPTION: `La suppression du compte est irréversible.`,
  TITLE: `Supprimer le compte`,
});

export const OPSConsoleAccountsMergeModal = Object.freeze({
  MERGE_TITLE_PART_1: `Fusionner`,
  MERGE_TITLE_PART_2: `avec :`,
  NOTE: `La fusion de deux comptes est irréversible.`,
  RULE_1: `Conçu pour fusionner les comptes de type @gmail.com sous une marque.`,
  RULE_2: `La fusion de deux domaines principaux n'est pas autorisée.`,
  RULES: `Règles`,
  SEARCH_PLACEHOLDER: `Rechercher le nom du compte`,
  TITLE: `Fusionner les comptes`,
});
