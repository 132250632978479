export const Common = Object.freeze({
  ACCEPT_INVITE: `Accepter l'invitation`,
  ACCESS_RESTRICT: `Désolé, vous n'êtes pas autorisé à accéder à cette page`,
  ACTIVATE: `Activer`,
  ACTIVE: `Actif`,
  ALL: `Tous`,
  ARE_YOU_SURE: `Êtes-vous sûr ?`,
  AUTHORISE: `Autoriser`,
  AVAILABLE: `Disponible`,
  BACK: `Retour`,
  CALENDAR_DAYS: `Jours du calendrier`,
  CANCEL: `Annuler`,
  CHOOSE_DATE: `Choisir une date`,
  CLEAR: `Effacer`,
  CLEAR_FILTER: `Effacer le filtre`,
  CLICK_HERE: `Cliquez ici`,
  CLONE: `Dupliquer`,
  CLONE_NAME_POSTFIX: ` (copie)`,
  CLOSE: `Fermer`,
  COMMON: `Commun`,
  COMPLETE: `Terminer`,
  CONNECT: `Connecter`,
  CONNECTED: `Connecté`,
  CONFIRM: `Confirmer`,
  CONFIRM_DELETE: `Confirmer la suppression`,
  COMING_SOON: `Bientôt disponible`,
  COPY: `Copier`,
  COPY_LINK: `Copier le lien`,
  COPIED_LINK: `Copié !`,
  COUNTINUE: `Continuer`,
  COUNTRY: `Pays`,
  CITY: `Ville`,
  CREATE: `Créer`,
  CUSTOM: `Personnalisé`,
  CUSTOMIZE: `Personnaliser`,
  DATE: `Date`,
  DATE_RANGE: `Plage de dates`,
  DAYS: `Jours`,
  DEACTIVATE: `Désactiver`,
  DEFAULT: `Par défaut`,
  DELETE: `Supprimer`,
  DELETED: `SUPPRIMÉ`,
  DESCRIPTION: `Description`,
  DISABLE: `Désactiver`,
  DISCONNECT: `Déconnecter`,
  DOWNLOAD: `Télécharger`,
  EDIT: `Modifier`,
  EMAIL: `Courriel`,
  EMAIL_ALL_SMALL: `courriel`,
  EMPTY_ERROR: `Ne peut pas être vide`,
  EMPTY_LIST: `Il semble que cette liste soit vide.`,
  ENABLE: `Activer`,
  ERROR: 'Erreur',
  EXPORT: 'Exporter',
  FILTER: `Filtrer`,
  FIRST_NAME: `Prénom`,
  FRIDAYS: `Vendredis`,
  FROM: `de`,
  GOOGLE: `Google`,
  HOURS: `Heures`,
  HOUR_SHORTENED: `h`,
  INACTIVE: `Inactif`,
  INFO: 'Info',
  INTERNAL: `Interne`,
  LAST_NAME: `Nom de famille`,
  LEARN_MORE: `En savoir plus`,
  LOCKED: `Verrouillé`,
  LOGIN: `Connexion`,
  LOG_OUT: `Déconnexion`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `min`,
  MINUTES: `Minutes`,
  MONDAYS: `Lundis`,
  MY: `Mon`,
  MY_ACCOUNT: `Mon compte`,
  NAME_IS_DUPLICATE: `Oups ! Ce nom a déjà été utilisé. Veuillez en saisir un différent.`,
  NEXT: `Suivant`,
  NONE: `Aucun`,
  NO: `Non`,
  NO_CANCEL: `Non, annuler`,
  NO_RESULTS_FOUND: `Aucun résultat trouvé`,
  NEVER: 'Jamais',
  OFF: `Désactivé`,
  ON: `Activé`,
  ONLY_ONE_ACCOUNT: `Vous ne pouvez avoir qu'un seul compte SUMO1 par adresse e-mail.`,
  PASSWORD: `Mot de passe`,
  PHONE_NUMBER: `Numéro de téléphone`,
  PICTURE_FORMATS: `svg, jpg, gif, png`,
  PICTURE_SIZE: `Taille maximale 5 MB`,
  PREVIEW: `Aperçu`,
  QUICK_SETUP: `Configuration rapide`,
  REGISTER: `S'inscrire`,
  RESET: `Réinitialiser`,
  RESET_FILTER: `Réinitialiser le filtre`,
  ROLES: `Rôles`,
  SATURDAYS: `Samedis`,
  SAVE: `Enregistrer`,
  SAVE_CHANGES: `Enregistrer les modifications`,
  SEARCH: `Rechercher`,
  SECOND_SHORTENED: `s`,
  SELECT: `Sélectionner`,
  SELECT_ALL: `Tout sélectionner`,
  SELECTED: `Sélectionné`,
  SET_UP: `Configurer`,
  SHARE: `Partager`,
  SIGN_OUT: `Déconnexion`,
  SIGN_UP: `Inscription`,
  STATE: `État`,
  STATUS: `Statut`,
  SUCCESS: 'Succès',
  SUNDAYS: `Dimanches`,
  TIMEZONE: `Fuseau horaire`,
  THURSDAYS: `Jeudis`,
  TIMES: `Horaires`,
  TODAY: `Aujourd'hui`,
  TO: `à`,
  TUESDAYS: `Mardis`,
  TYPE: `Type`,
  UNAVAILABLE: `Indisponible`,
  UNLINK: `Dissocier`,
  UNSAVED_CHANGES: `Toutes les modifications non enregistrées seront perdues.`,
  UNSELECT: `Désélectionner`,
  UPLOAD: `Télécharger`,
  UPLOAD_PICTURE: `Télécharger une image`,
  USER: `Utilisateur`,
  USERS: `Utilisateurs`,
  UPDATE: `Mettre à jour`,
  VIEW: `Voir`,
  YES: `Oui`,
  YES_CANCEL: `Oui, Annuler`,
  YES_DELETE: `Oui, supprimer`,
  WARNING: 'Avertissement',
  WEDNESDAYS: `Mercredis`,
  WEEKDAYS: `Jours de la semaine`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Erreur interdite`,
  TITLE_404: `404 - Page non trouvée`,
  TITLE_500: `500 - Erreur interne du serveur`,
  MESSAGE_403_1: `Vous n'avez pas la permission d'accéder à `,
  MESSAGE_403_2: ` sur ce serveur.`,
  MESSAGE_404_1: `L'URL de la demande `,
  MESSAGE_404_2: ` n'a pas été trouvée sur ce serveur.`,
  MESSAGE_500_1: `Il y a eu une erreur. C'est tout ce que nous savons. Veuillez attendre et `,
  MESSAGE_500_2: `réessayer de recharger`,
  MESSAGE_500_3: ` à nouveau.`,
  HOME_BUTTON: `Accueil`,
});

export const PrivateRoute = Object.freeze({
  ACCOUNT: `Compte`,
  ACCOUNT_SETTINGS: `Paramètres du compte`,
  PROFILE: `Profil`,
  ADMIN_SETTINGS: `Paramètres d'administration`,
  AVAILABILITY: `Disponibilité`,
  CALENDAR_CONNECTIONS: `Connexions de calendrier`,
  HELP_CENTER: `Centre d'aide`,
  HOME: `Accueil`,
  USER_SETUP: `Configuration de l'utilisateur`,
  USER_SETTINGS: `Paramètres de l'utilisateur`,
  INTEGRATION: `Intégration`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Copyright 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc.  Tous droits réservés.`,
  PRIVACY_POLICY: `Politique de confidentialité`,
  SECURITY_POLICY: `Politique de sécurité`,
  TERMS: `Conditions`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Comptes`,
  BILLING_TITLE: `Facturation`,
  OPERATIONS_TITLE: `Opérations`,
  ORGS_TITLE: `Organisations`,
  SMART_ALERTS: `Alertes Intelligentes`,
  STAFF_TITLE: `Personnel`,
  SUPPORT_TITLE: `Support`,
  TOOLS_TITLE: `Outils`,
  OPEN_MY_SUMO1: `Ouvrir Mon SUMO1`,
  OPS_CONSOLE: `Console OPS`,
  JOURNEY_TITLE: `Créateur de Parcours`,
  MEETINGS_TITLE: `Réunions`,
  PEOPLE_TITLE: `Personnes`,
  ORGANIZATION_TITLE: `Organisation`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Planifiez un appel ou une réunion virtuelle avec un expert`,
  BOOK_MEETING_TITLE: `Réservez une réunion`,
  CALL_DESCRIPTION_PART1: `Contactez-nous au 800-708-1790,`,
  CALL_DESCRIPTION_PART2: `du lundi au vendredi, de 8h à 17h EST`,
  CALL_TITLE: `Appelez-nous`,
  CHAT_DESCRIPTION: `Cliquez sur l'icône "Chattez avec nous" en bas à droite pour commencer la conversation`,
  CHAT_TITLE: `Discutez maintenant`,
  CONTACT_TITLE: `Contactez-nous pour obtenir de l'aide`,
  DESCRIPTION_ADMIN_TRIAL: `La période d'essai gratuit de votre organisation est terminée. Vous pouvez la prolonger en cliquant sur l'option ci-dessous ou nous contacter pour plus d'informations.`,
  DESCRIPTION_ADMIN_PAID: `Votre licence a expiré. Pour continuer à utiliser SUMO1 sans interruption, veuillez acheter une nouvelle licence.`,
  DESCRIPTION_PART1_TRIAL: `La période d'essai gratuit de votre organisation est terminée. Contactez l'administrateur de votre organisation`,
  DESCRIPTION_PART1_PAID: `La période de licence de votre organisation est terminée. Contactez l'administrateur de votre organisation`,
  DESCRIPTION_PART2: `pour plus d'assistance.`,
  EMAIL_DESCRIPTION: `Contactez experts@sumoscheduler.com pour obtenir de l'aide`,
  EMAIL_TITLE: `Envoyez-nous un e-mail`,
  EXPIRED: `Expiré`,
  RENEW_LICENSES: `Renouveler les licences`,
  TITLE: `Licence expirée`,
  TITLE_TRIAL: `Essai gratuit expiré`,
  TRIAL_IS_OVER: `L'essai est terminé`,
  UPGRADE_NOW: `Mettre à niveau maintenant`,
  VIEW_PRICING_PAGE: `Voir la page des tarifs`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Annulations`,
  MEETINGS_BOOKED: `Réunions réservées`,
  REMINDERS_SENT: `Rappels envoyés`,
  RESCHEDULES: `Reportages`,
  TITLE: `Mes Statistiques`,
  INSTALL_INBOX_PART1: `Installez l'`,
  INSTALL_INBOX_PART2: `extension Chrome`,
  INSTALL_INBOX_PART3: ` ou le `,
  INSTALL_INBOX_PART4: `module complémentaire Edge`,
  INSTALL_INBOX_PART5: ` pour envoyer des liens d'invitation depuis Gmail ou Outlook Web.`,
  INVITE_PEOPLE_DESCRIPTION: `Ajoutez des utilisateurs à SUMO1 pour profiter de la coanimation, de la réservation d'équipe, de la réservation pour le compte de, et plus encore.`,
  INVITE_PEOPLE_TITLE: `Inviter des personnes`,
  CALENDAR_WARNING_DESCRIPTION: `Votre calendrier n'est pas connecté. Vous risquez de double-réservation si vous ne connectez pas un calendrier.`,
  CALENDAR_WARNING_BUTTON: `Connecter le calendrier`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Ajoutez un lien "Réservez avec moi" à votre signature électronique.`,
  STEP1_TITLE: `Configuration de la signature électronique`,
  STEP2_DESCRIPTION: `Envoyez une invitation à une réunion à quelqu'un avec l'extension Chrome.`,
  STEP2_TITLE: `Envoyer une invitation en un clic`,
  STEP3_DESCRIPTION: `Que devraient-ils tester d'autre dès le premier jour ?`,
  STEP3_TITLE: `Tester une autre fonctionnalité`,
  TITLE: `Commencez facilement à réserver`,
});

export const Table = Object.freeze({
  PAGE: `Page`,
  OF: `de`,
  OUT_OF: `sur`,
});

export const IconPicker = Object.freeze({
  SELECT_TITLE: `Sélectionner une icône`,
  OPTIONAL: `optionnel`,
});
