import { XMarkIcon } from '@heroicons/react/24/outline';
import { UpdateBookingPageInput } from '../../../API';
import { getTimeUnitLabel } from '../../../services/utils';

type BookingPageCardProps = {
  bookingPage?: UpdateBookingPageInput;
  bookingTemplateName?: string;
  onRemove?: (value: string) => void;
};

export const BookingPageCard = ({ bookingPage, bookingTemplateName, onRemove }: BookingPageCardProps) => {
  return (
    <div className="flex-left-center gap-4px">
      <div className="flex-1 border-1 border-heavy-20 border-radius-10px flex-left-center gap-8px p-8px">
        <div className="p-8px bg-heavy-1 border-radius-6px">
          <div
            className="w-16px h-16px border-radius-4px"
            style={{ backgroundColor: bookingPage?.what?.color || '' }}
          />
        </div>
        <div className="flex flex-column gap-4px text-overflow-ellipsis">
          <div className="text-label-s-med text-heavy-80">{bookingPage?.what?.customName}</div>
          <div className="flex-left-center text-label-xs-reg text-heavy-60">
            <div>{bookingPage?.displayId}</div>
            <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
            <div>{`${bookingPage?.when?.duration?.count} ${getTimeUnitLabel(
              bookingPage?.when?.duration?.timeUnit
            )}`}</div>
            {bookingTemplateName && (
              <>
                <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
                <div>{bookingTemplateName}</div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-20px flex-center">
        {onRemove && (
          <div className="action-button-xs" onClick={() => onRemove(bookingPage?.id || '')}>
            <XMarkIcon className="icon-16px" />
          </div>
        )}
      </div>
    </div>
  );
};
