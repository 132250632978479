import { createSelector } from 'reselect';
import { State } from '../../rootStore';

const opsConsoleState = (state: State) => state.OPSConsoleCommon;

const selectFiltering = createSelector(opsConsoleState, (opsConsole) => opsConsole.filtering);
const selectPaths = createSelector(selectFiltering, (filtering) => filtering.paths);
const selectTableState = createSelector(selectFiltering, (filtering) => filtering.tableState);
const selectSearchString = createSelector(selectFiltering, (filtering) => filtering.searchString || '');

const selectTableFirst = createSelector(selectTableState, (tableState) => tableState.first);
const selectTableRows = createSelector(selectTableState, (tableState) => tableState.rows);
const selectTableSortField = createSelector(selectTableState, (tableState) => tableState.sortField);
const selectTableSortOrder = createSelector(selectTableState, (tableState) => tableState.sortOrder);

const selectTableFirstByPathName = (path: string) =>
  createSelector(selectPaths, selectTableFirst, (paths, first) =>
    paths && paths.some((name) => path.startsWith(name)) ? first : 0
  );
const selectTableRowsByPathName = (path: string) =>
  createSelector(selectPaths, selectTableRows, (paths, rows) =>
    paths && paths.some((name) => path.startsWith(name)) ? rows : 10
  );
const selectTableSortFieldByPathName = (path: string) =>
  createSelector(selectPaths, selectTableSortField, (paths, sortField) =>
    paths && paths.some((name) => path.startsWith(name)) ? sortField : ''
  );
const selectTableSortOrderByPathName = (path: string) =>
  createSelector(selectPaths, selectTableSortOrder, (paths, sortOrder) =>
    paths && paths.some((name) => path.startsWith(name)) ? sortOrder : null
  );

export const opsConsoleSelectors = {
  selectFiltering,
  selectPaths,
  selectTableState,
  selectSearchString,

  selectTableFirst,
  selectTableRows,
  selectTableSortField,
  selectTableSortOrder,

  selectTableFirstByPathName,
  selectTableRowsByPathName,
  selectTableSortFieldByPathName,
  selectTableSortOrderByPathName,
};
