import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import labels from './labels';
import { Path } from '../../../../routing';

type UserRowActionsProps = {
  tenantId: string;
  userId: string;
};

export const UserRowActions = ({ tenantId, userId }: UserRowActionsProps) => {
  const menu = useRef<Menu | null>(null);

  // Define menu items
  const menuItems = [
    {
      label: labels.loginAs,
      command: () => {
        window.open(
          window.location.origin + Path.OPSConsoleViewAsUser.replace(':userId', userId).replace(':tenantId', tenantId),
          '_blank'
        );
      },
    },
    /* {
      label: userRecord.status === UserStatus.Active ? labels.deactivate : labels.activate,
      command: () => {
        dispatch(usersActions.setUserRecord(userRecord));
        if (userRecord.status === UserStatus.Active) {
          dispatch(deactivateUserModalActions.openModal());
        } else {
          dispatch(usersActions.changeUserStatusRequest());
        }
      },
      visible: userRecord.status !== UserStatus.InviteSent,
    },
    {
      label: labels.delete,
      command: () => {
        dispatch(usersActions.setUserRecord(userRecord));
        dispatch(deleteUserModalActions.openModal());
      },
      visible: isMainAdminOrOperations,
    }, */
  ];

  return (
    <>
      <Button
        icon="pi pi-ellipsis-h text-color"
        text
        onClick={(e) => {
          return menu.current?.toggle && menu.current.toggle(e);
        }}
      />
      <Menu model={menuItems} popup ref={menu} />
    </>
  );
};
