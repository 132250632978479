import { useSelector } from 'react-redux';
import { bookingPageSelectors, bookingPagesActions } from '../../../store/bookingPages';
import { NotificationStep } from '../../common/meeting/notificationStep/NotificationStep';

export const BookingPageNotificationStep = () => {
  const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked());
  const hasTemplate = useSelector(bookingPageSelectors.selectHasTemplate);
  return (
    <NotificationStep
      selectors={bookingPageSelectors}
      actions={bookingPagesActions}
      isReadOnly={isBookingPageLocked || hasTemplate}
    />
  );
};
