import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DataTable, DataTableProps, DataTableValueArray } from 'primereact/datatable';
import { Column, ColumnBodyOptions, ColumnProps } from 'primereact/column';
import labels from './labels';
import {
  ArrowDownIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { globalActions, globalSelectors } from '../../../store/global';
import { useSelector } from 'react-redux';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { Skeleton } from 'primereact/skeleton';
import { EmptyListScreen } from '../emptyListScreen/EmptyListScreen';

type SumoTableProps = DataTableProps<DataTableValueArray> & {
  actionsBody?: React.ReactNode | ((data: any, options: ColumnBodyOptions) => React.ReactNode);
  bulkActions?: boolean;
  loading?: boolean;
  emptyDescription?: string;
};

const TABLE_LOADING_ITEMS = [{ id: '1' }, { id: '2' }, { id: '3' }];

export const SumoTable: React.FC<SumoTableProps> = ({
  children,
  actionsBody,
  bulkActions,
  loading,
  emptyDescription,
  ...props
}) => {
  const dispatch = useDispatch();
  const fullScreen = useSelector(globalSelectors.selectFullScreen);

  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState<number | null>(1);
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(undefined);

  const pageInputTimer = useRef<NodeJS.Timeout | null>(null);

  const rows = useMemo(() => props.rows || 10, [props.rows]);
  const totalPages = useMemo(() => Math.ceil((props.value?.length || 0) / rows), [props.value, rows]);
  const currentPageStart = useMemo(() => (page - 1) * rows + 1, [page, rows]);
  const currentPageEnd = useMemo(
    () => (page * rows > (props.value?.length || 0) ? props.value?.length || 0 : page * rows),
    [props.value, page, rows]
  );
  useEffect(() => {
    if (page > totalPages) {
      handlePageChange(1);
    }
  }, [totalPages, page]);

  const sortedData = useMemo(() => {
    if (!sortField) {
      return props.value || [];
    }
    return [...(props.value || [])].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortOrder === 1 ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortOrder === 1 ? 1 : -1;
      return 0;
    });
  }, [props.value, sortField, sortOrder]);

  const handleSort = (field: string | null | undefined) => {
    setSortField(field || undefined);
    setSortOrder(sortField === field && sortOrder === 1 ? -1 : 1);
  };

  const getSortIcon = (sortOrder: 0 | 1 | -1 | null | undefined) =>
    sortOrder === 1 ? (
      <ArrowUpIcon className="p-sortable-column-icon icon-8px" />
    ) : sortOrder === -1 ? (
      <ArrowDownIcon className="p-sortable-column-icon icon-8px" />
    ) : (
      <div className="p-sortable-column-icon w-8px h-8px" />
    );

  const getFullScreenButton = () => (
    <div className="action-button" onClick={() => dispatch(globalActions.setFullScreen(!fullScreen))}>
      {fullScreen ? <ArrowsPointingInIcon className="icon-20px" /> : <ArrowsPointingOutIcon className="icon-20px" />}
    </div>
  );

  const handlePageInputChange = (e: InputNumberChangeEvent) => {
    setPageInput(e.value);

    if (e.value == null) {
      return;
    }

    const updatedPage = Math.min(e.value || 1, totalPages);

    if (pageInputTimer.current !== null) {
      clearTimeout(pageInputTimer.current);
    }
    pageInputTimer.current = setTimeout(() => {
      setPage(updatedPage);
    }, 500);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setPageInput(page);
  };

  return loading ? (
    <DataTable {...props} className="sumo-card overflow-hidden border-none" value={TABLE_LOADING_ITEMS}>
      {bulkActions && <Column selectionMode="multiple" className="w-48px" />}
      {React.Children.map(
        children,
        (child) =>
          React.isValidElement<ColumnProps>(child) && (
            <Column
              header={child.props.header}
              bodyClassName={child.props.bodyClassName}
              body={<Skeleton className="h-16px my-8px mr-10px" />}
            />
          )
      )}
      {actionsBody && <Column header={getFullScreenButton()} className="w-48px" />}
    </DataTable>
  ) : props.value?.length ? (
    <div className="sumo-card border-none overflow-hidden flex flex-column">
      <DataTable
        removableSort
        resizableColumns
        emptyMessage={labels.noResults}
        sortIcon={(e) => getSortIcon(e.sortOrder)}
        onSort={(e) => handleSort(e.sortField)}
        {...props}
        sortField={sortField}
        sortOrder={sortOrder}
        value={sortedData.slice(currentPageStart - 1, currentPageEnd)}
      >
        {bulkActions && <Column selectionMode="multiple" frozen className="w-48px" />}
        {children}
        {actionsBody && <Column header={getFullScreenButton()} body={actionsBody} className="w-48px" />}
      </DataTable>
      {totalPages > 1 && (
        <div className="flex-between-center px-16px py-12px text-body-s-reg text-heavy-60">
          <div className="flex-left-center gap-8px">
            <div>{labels.page}</div>
            <InputNumber
              inputClassName="w-40px h-32px text-body-s-reg"
              value={pageInput}
              onChange={handlePageInputChange}
              min={1}
              max={totalPages}
            />
            <div className="mr-8px">{`${labels.of} ${totalPages}`}</div>
            <div
              className={`action-button ${page === 1 ? 'p-disabled' : ''}`}
              onClick={() => page > 1 && handlePageChange(page - 1)}
            >
              <ChevronLeftIcon className="icon-20px" />
            </div>
            <div
              className={`action-button ${page === totalPages ? 'p-disabled' : ''}`}
              onClick={() => page < totalPages && handlePageChange(page + 1)}
            >
              <ChevronRightIcon className="icon-20px" />
            </div>
          </div>
          <div>{`${currentPageStart}-${currentPageEnd} ${labels.outOf} ${props.value?.length}`}</div>
        </div>
      )}
    </div>
  ) : (
    <EmptyListScreen description={emptyDescription} />
  );
};
