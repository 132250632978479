import { memo } from 'react';
import { Button } from 'primereact/button';

interface IZoomAuthButton {
  label?: string;
  outlined?: boolean;
  severity: string;
  className?: string;
  onRedirect?: (url: string) => void;
}

export const ZoomAuthButton = memo(({ label, outlined, severity, className, onRedirect }: IZoomAuthButton) => {
  const handleAuth = () => {
    onRedirect && onRedirect(window.location.href);
  };

  return (
    <Button
      label={label}
      className={className}
      outlined={outlined}
      severity={severity as 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help'}
      onClick={handleAuth}
    />
  );
});

ZoomAuthButton.displayName = 'ZoomAuthButton';
