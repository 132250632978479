import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, DataTableProps, DataTableValueArray } from 'primereact/datatable';
import { opsConsoleSelectors } from '../../../store/opsConsole/common/selectors';
import { DataTableStateType } from '../../../store/opsConsole/common/types';
import { opsConsoleActions } from '../../../store/opsConsole/common/actions';
import { Column, ColumnProps } from 'primereact/column';
import { isEqualArrays } from '../../../services/utils';
import labels from './labels';

type TableComponentProps = DataTableProps<DataTableValueArray> & {
  paths: string[];
};

export const Table: React.FC<TableComponentProps> = ({ children, paths, ...props }) => {
  const dispatch = useDispatch();
  const statePaths = useSelector(opsConsoleSelectors.selectPaths);
  const tableRows = useSelector(opsConsoleSelectors.selectTableRowsByPathName(location.pathname));
  const tableFirst = useSelector(opsConsoleSelectors.selectTableFirstByPathName(location.pathname));
  const tableSortField = useSelector(opsConsoleSelectors.selectTableSortFieldByPathName(location.pathname));
  const tableSortOrder = useSelector(opsConsoleSelectors.selectTableSortOrderByPathName(location.pathname));

  const handleStateSave = (state: DataTableStateType) => {
    const convertedPaths = paths.map((path) => path.replace(/\/:[^/].+$/g, '/'));
    if (
      ((!statePaths && convertedPaths.some((name) => location.pathname.startsWith(name))) ||
        (statePaths && isEqualArrays(convertedPaths, statePaths))) &&
      (tableRows !== state.rows ||
        tableFirst !== state.first ||
        tableSortField !== state.sortField ||
        tableSortOrder !== state.sortOrder)
    ) {
      dispatch(opsConsoleActions.setFiltering({ tableState: state, paths: convertedPaths }));
    }
  };

  return (
    <DataTable
      size="small"
      showGridlines
      removableSort
      rows={tableRows || 10}
      first={tableFirst || 0}
      sortField={tableSortField}
      sortOrder={tableSortOrder}
      rowsPerPageOptions={[10, 25, 50]}
      stateStorage="custom"
      customSaveState={handleStateSave}
      emptyMessage={labels.noResults}
      {...props}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement<ColumnProps>(child) && child.type === Column) {
          return child;
        }
        return null;
      })}
    </DataTable>
  );
};
