import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../API';
import { ConnectIntegrationRequest } from '../../generated-sources/internal-api/models/ConnectIntegrationRequest';

export type IntegrationDataType = {
  type: IntegrationType | WorkspaceIntegrationType | AppType;
  label: string;
  icon: string;
  description: string;
};

export enum IntegrationFilter {
  ALL = 'ALL',
  CALENDARS = 'CALENDARS',
  // EXTENSIONS = 'EXTENSIONS', <-- uncomment when Inbox is ready
  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
  WORKSPACE_INTEGRATIONS = 'WORKSPACE_INTEGRATIONS',
}

export type GetIntegrationURLParamsType = {
  code: string | null;
  type: IntegrationType | WorkspaceIntegrationType | null;
  workspaceId: string | null;
  customParameters?: any | null;
};

export type ConnectIntegrationResponse = GetIntegrationURLParamsType & {
  redirectURI: string;
};

export type WorkspaceIntegrationRequest = ConnectIntegrationRequest & {
  type: WorkspaceIntegrationType;
  workspaceId: string;
};

export type HubSpotCustomParameters = {
  bookContacts: string;
  bookLeads: string;
  createEvents: string;
};

export type GoogleAnalyticsCustomParameters = {
  trackingId: string;
  measurementId: string;
};

export type SalesforceCustomParameters = HubSpotCustomParameters;
