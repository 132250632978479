import { t } from '../../../i18n/i18n';

export default {
  title: t('OPSConsoleAccounts:TITLE'),
  searchPlaceholder: t('OPSConsoleAccounts:SEARCH_PLACEHOLDER'),
  createAccount: t('OPSConsoleAccounts:BUTTON_CREATE_ACCOUNT'),
  export: t('OPSConsoleAccounts:BUTTON_EXPORT'),
  accountName: t('OPSConsoleAccountDetails:ACCOUNT_NAME'),
  accountNote: t('OPSConsoleAccountDetails:ACCOUNT_NOTE'),
  accountOrgs: t('OPSConsoleAccountDetails:TENANTS'),
  createdBy: t('OPSConsoleAccounts:COLUMN_CREATED_BY'),
  createdDate: t('OPSConsoleAccounts:COLUMN_CREATED_DATE'),
};
