import { API } from 'aws-amplify';
import {
  ENCRYPTED_USER_ID,
  ROLE_DEACTIVATED_ERROR_TOAST,
  ACCOUNT_ALREADY_EXIST_ERROR_TOAST,
  TRIAL_LIMIT_ERROR_TOAST,
  USER_DEACTIVATED_ERROR_TOAST,
  authenticationActions,
  USER_CONTEXT_PARAM,
  GET_USER_LOGIN_ERROR_TOAST,
} from '../authentication';
import { ToastNotificationOptions, notificationsActions } from '../notifications';
import { put } from 'redux-saga/effects';
import { DELETE_ROLE_ASSIGNED_ERROR_TOAST } from '../roles';
import { SERVER_ERROR_CODES } from '../../types/constants';
import { errorActions } from '../error/actions';
import { ErrorCodes } from '../error/types';
import { Path } from '../../routing';

export async function handleAPIRequest<T>(requestPath: string, requestData: any, needToEncrypt = true): Promise<T> {
  const requestBody = requestData;

  // ViewAsUser functionality
  if (needToEncrypt) {
    const encryptedUserId = localStorage.getItem(ENCRYPTED_USER_ID);

    if (encryptedUserId && !requestPath.includes('/opsconsole/')) {
      requestBody.encryptedUserId = encryptedUserId;
    }
  }

  requestBody[USER_CONTEXT_PARAM] = localStorage.getItem(USER_CONTEXT_PARAM) || '';

  try {
    const result = await API.post('publicApi', requestPath, {
      body: requestBody,
    });

    if (result[USER_CONTEXT_PARAM]) {
      localStorage.setItem(USER_CONTEXT_PARAM, result[USER_CONTEXT_PARAM]);
    }

    return result as T;
  } catch (error: unknown) {
    console.error(`Error making API request to ${requestPath}:`, error);
    throw error;
  }
}

export function* handleServiceError(error: unknown, toast: ToastNotificationOptions, isGlobalError?: boolean) {
  const errorMessage = error?.toString() || '';
  if (errorMessage.includes(SERVER_ERROR_CODES.LicenseExpired)) {
    // expire license
    yield put(authenticationActions.expireLicense());
  } else if (errorMessage.includes(SERVER_ERROR_CODES.NoUserWhenLogin)) {
    // no user found when try to login (not registration)
    yield put(notificationsActions.showToast(GET_USER_LOGIN_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.RoleDeactivated)) {
    // role deactivated
    yield put(authenticationActions.logoutUserRequest(Path.Landing));
    yield put(notificationsActions.showToast(ROLE_DEACTIVATED_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.UserDeactivate)) {
    // user deactivated
    yield put(authenticationActions.logoutUserRequest(Path.Landing));
    yield put(notificationsActions.showToast(USER_DEACTIVATED_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.DeleteRole)) {
    // try to delete role with users assigned
    yield put(notificationsActions.showToast(DELETE_ROLE_ASSIGNED_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.TrialLimit)) {
    // trial limit reached
    yield put(notificationsActions.showToast(TRIAL_LIMIT_ERROR_TOAST));
  } else if (errorMessage.includes(SERVER_ERROR_CODES.SwitchAccount)) {
    // switch account, another account already associated with this email
    yield put(notificationsActions.showToast(ACCOUNT_ALREADY_EXIST_ERROR_TOAST));
  } else {
    // regular errors
    if (isGlobalError) {
      if (errorMessage.includes(SERVER_ERROR_CODES.Forbidden)) {
        yield put(errorActions.setTheError(ErrorCodes.CODE_403));
      } else if (errorMessage.includes(SERVER_ERROR_CODES.ServerError)) {
        yield put(errorActions.setTheError(ErrorCodes.CODE_500));
      } else {
        yield put(errorActions.setTheError(ErrorCodes.CODE_404));
      }
    }
    yield put(notificationsActions.showToast(toast));
  }
}
