import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions, globalSelectors } from '../../../store/global';
import { isEqualArrays } from '../../../services/utils';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';

interface ISearch {
  placeholder: string;
  paths: string[];
  maxTextLength: number;
  collapsible?: boolean;
  openWidth?: string;
}

export const Search = ({ placeholder, paths, maxTextLength, collapsible, openWidth }: ISearch) => {
  const dispatch = useDispatch();
  const searchString = useSelector(globalSelectors.selectSearchString);
  const searchPaths = useSelector(globalSelectors.selectSearchPaths);
  const [isOpen, setIsOpen] = useState(Boolean(searchString) || !collapsible);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (value: string) => {
    const convertedPaths = paths.map((path) => path.replace(/\/:[^/].+$/g, '/'));

    const isPathMatching = searchPaths
      ? isEqualArrays(convertedPaths, searchPaths)
      : convertedPaths.some((name) => location.pathname.startsWith(name));

    if (isPathMatching) {
      dispatch(globalActions.setSearch({ searchString: value, paths: convertedPaths }));
    }
  };

  const handleBlur = (value: string) => {
    if (!value && collapsible) {
      setIsOpen(false);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    inputRef.current?.focus();
  };

  const handleClose = () => {
    collapsible && setIsOpen(false);
    handleChange('');
  };

  return (
    <div
      className={`flex flex-none p-0 button-white border-radius-10px overflow-hidden transition-width ${
        isOpen ? openWidth || 'w-240px' : 'w-48px'
      }`}
    >
      <span className="flex-center flex-none w-48px text-heavy-60 cursor-pointer" onClick={handleOpen}>
        <MagnifyingGlassIcon className="icon-24px" />
      </span>
      <InputText
        ref={inputRef}
        className="flex-1 w-0 p-0 border-none text-overflow-ellipsis"
        placeholder={placeholder}
        value={searchString}
        maxLength={maxTextLength}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
      />
      {Boolean(searchString) && (
        <span
          className="flex-center flex-none w-48px text-heavy-60 cursor-pointer hover-bg-heavy-1"
          onClick={handleClose}
        >
          <XMarkIcon className="icon-24px" />
        </span>
      )}
    </div>
  );
};
