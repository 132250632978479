export const OPSConsoleAccountDetails = Object.freeze({
  ACCOUNT_NAME: `Kontoname`,
  ACCOUNT_NOTE: `Kontonotiz`,
  CREATED_AT: `Erstellt am`,
  CREATED_BY: `Erstellt von`,
  DUPLICATE_NAME_MESSAGE: `Ein Konto mit diesem Namen existiert bereits`,
  TENANT_COLUMN_ADOPTION: `% Übernahme`,
  TENANT_COLUMN_ID: `Org.-ID`,
  TENANT_COLUMN_OWNED: `Anzahl Lizenzen`,
  TENANT_COLUMN_TERM: `Laufzeit`,
  TENANT_COLUMN_TYPE: `Typ`,
  TENANTS: `Orgs`,
});

export const OPSConsoleAccounts = Object.freeze({
  BUTTON_CREATE_ACCOUNT: `Konto erstellen`,
  BUTTON_EXPORT: `Exportieren`,
  BUTTON_MERGE: `Zusammenführen`,
  COLUMN_ACCOUNT_NAME: `Kontoname`,
  COLUMN_CREATED_BY: `Erstellt von`,
  COLUMN_CREATED_DATE: `Erstellungsdatum`,
  DELETE_ACCOUNT_ERROR_TOAST: `Konto konnte nicht gelöscht werden`,
  DELETE_ACCOUNT_SUCCESS_TOAST: `Konto gelöscht.`,
  SAVE_ACCOUNT_ERROR_TOAST: `Konto konnte nicht gespeichert werden`,
  SAVE_ACCOUNT_SUCCESS_TOAST: `Konto gespeichert.`,
  SEARCH_PLACEHOLDER: `Nach Kontoname oder Domain suchen`,
  TITLE: `Konten`,
  GET_ACCOUNT_ERROR_TOAST: `Abruf des Kontos fehlgeschlagen`,
  GET_ACCOUNTS_ERROR_TOAST: `Abruf der Konten fehlgeschlagen`,
});

export const OPSConsoleAccountsDeleteModal = Object.freeze({
  DESCRIPTION: `Das Löschen des Kontos kann nicht rückgängig gemacht werden.`,
  TITLE: `Konto löschen`,
});

export const OPSConsoleAccountsMergeModal = Object.freeze({
  MERGE_TITLE_PART_1: `Zusammenführen`,
  MERGE_TITLE_PART_2: `mit:`,
  NOTE: `Das Zusammenführen von zwei Konten kann nicht rückgängig gemacht werden.`,
  RULE_1: `Konzipiert für das Zusammenführen von Konten vom Typ @gmail.com unter einer Marke.`,
  RULE_2: `Das Zusammenführen von zwei Hauptdomänen ist nicht zulässig.`,
  RULES: `Regeln`,
  SEARCH_PLACEHOLDER: `Nach Kontoname suchen`,
  TITLE: `Konten zusammenführen`,
});
