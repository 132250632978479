import { t } from '../../../i18n/i18n';

export default {
  acceptInvite: t('Common:ACCEPT_INVITE'),
  acceptText: t('DialogLeaveAccount:ACCEPT_TEXT'),
  cancel: t('Common:CANCEL'),
  inviteTextPart1: t('DialogLeaveAccount:INVITE_TEXT_PART_1'),
  inviteTextPart2: t('DialogLeaveAccount:INVITE_TEXT_PART_2'),
  lastSuperAdmin: t('DialogLeaveAccount:LAST_SUPER_ADMIN_NOTE'),
  onlyOneAccount: t('Common:ONLY_ONE_ACCOUNT'),
  title: t('DialogLeaveAccount:TITLE'),
};
