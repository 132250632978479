import { MapPinIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { PhysicalLocation } from '../../../../API';
import { convertAddressToGoogleMapLink, getFullAddress } from '../../../../store/locations';

type OrganizationLocationCardProps = {
  location: PhysicalLocation;
  handleRemove: (locationId: string) => void;
  isReadOnly?: boolean;
};
export const OrganizationLocationCard = ({ location, handleRemove, isReadOnly }: OrganizationLocationCardProps) => {
  return (
    <div className="flex-left-center gap-4px">
      <div className="flex-1 flex-left-center gap-8px border-1 border-heavy-20 border-radius-10px p-8px">
        <div className="flex-none flex-center h-32px w-32px bg-blue-light text-blue-main border-radius-4px">
          <MapPinIcon className="icon-16px" />
        </div>
        <div className="flex flex-column gap-4px mr-auto">
          <div className="text-label-md-med text-heavy-80">{location.name}</div>
          <div className="text-label-xs-reg">
            <a
              target="_blank"
              rel="noreferrer"
              href={location.address ? convertAddressToGoogleMapLink(location.address) : ''}
            >
              {getFullAddress(location.address)}
            </a>
          </div>
        </div>
      </div>
      <div
        className={`flex-none action-button-xs ${isReadOnly ? 'p-disabled' : ''}`}
        onClick={() => !isReadOnly && handleRemove(location.id)}
      >
        <XMarkIcon className="icon-16px" />
      </div>
    </div>
  );
};
