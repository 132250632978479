import { t } from '../../../../i18n/i18n';

export default {
  activate: t('Common:ACTIVATE'),
  edit: t('Common:EDIT'),
  deactivate: t('Common:DEACTIVATE'),
  delete: t('Common:DELETE'),
  loginAs: t('OPSConsoleUsers:VIEW_AS_USER'),
  view: t('OrgDetailsUsers:VIEW'),
};
