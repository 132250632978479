import { ArrowUturnDownIcon, DocumentIcon, FlagIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { NodeProps, Position } from '@xyflow/react';
import { JourneyPlusNodeType, journeysActions, NEW_DEFAULT_STEPS } from '../../../store/journeyBuilder';
import { JourneyHandle } from '../journeyHandle/JourneyHandle';
import { useDispatch } from 'react-redux';
import { JourneyStepType } from '../../../API';
import labels from './labels';

export const JourneyPlusNode = ({ data }: NodeProps<JourneyPlusNodeType>) => {
  const dispatch = useDispatch();
  const menu = data.ref || useRef<Menu>(null);

  const itemTemplate: MenuItem['template'] = (item, options) => (
    <a className={`p-menuitem-link ${item.disabled ? 'p-disabled' : ''}`} onClick={options.onClick}>
      <span className="mr-8px w-20px h-20px">{item.data}</span>
      <span className="p-menuitem-text">{item.label}</span>
    </a>
  );

  const handleAddStep = (stepType: JourneyStepType) => {
    dispatch(journeysActions.addJourneyStep({ parentId: data.parent.id || '', step: NEW_DEFAULT_STEPS[stepType] }));
    dispatch(journeysActions.setEditSidebar(true));
  };

  const menuItems = [
    {
      label: labels.page,
      command: () => handleAddStep(JourneyStepType.PAGE),
      template: itemTemplate,
      data: <DocumentIcon />,
    },
    {
      label: labels.condition,
      command: () => handleAddStep(JourneyStepType.CONDITION),
      template: itemTemplate,
      data: <ArrowUturnDownIcon />,
      disabled: !data.parent?.id,
    },
    {
      label: labels.destination,
      command: () => handleAddStep(JourneyStepType.DESTINATION),
      template: itemTemplate,
      data: <FlagIcon />,
      disabled: !data.parent?.id,
    },
  ];

  return (
    <div
      className={`bg-primary-white border-radius-20px ${
        data.parent?.children?.length ? 'w-24px h-24px' : 'w-34px h-34px'
      } ${data.isReadOnly ? 'plus-node-disabled' : 'cursor-pointer'}
      `}
      onClick={(e) => (!data.isReadOnly ? menu.current?.toggle(e) : undefined)}
    >
      {!!data.parent?.id && <JourneyHandle type="target" position={Position.Top} style={{}} />}
      {data.parent?.children?.length ? (
        <>
          <div className={`w-24px h-24px plus-button ${data.isReadOnly ? 'plus-node-disabled' : ''}`}>
            <div className="w-20px h-20px text-blue-main">
              <PlusIcon className="icon-20px" />
            </div>
          </div>
          <JourneyHandle type="source" position={Position.Bottom} style={{}} />
        </>
      ) : (
        <div className={`w-34px h-34px plus-button ${data.isReadOnly ? 'plus-node-disabled' : ''}`}>
          <div className="bg-blue-main w-24px h-24px border-radius-20px flex-center">
            <div className="w-20px h-20px text-primary-white">
              <PlusIcon className="icon-20px" />
            </div>
          </div>
        </div>
      )}

      <Menu ref={menu} model={menuItems} popup />
    </div>
  );
};
