export type DataTableStateType = {
  first: 0;
  rows: 10;
  sortField?: string;
  sortOrder?: null | 0 | 1 | -1;
};

export type FilteringType = {
  tableState: DataTableStateType;
  paths: string[] | null;
  searchString?: string;
};

export const DEFAULT_FILTERING: FilteringType = {
  tableState: {
    first: 0,
    rows: 10,
    sortField: '',
    sortOrder: null,
  },
  paths: null,
};
