import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import {
  DEFAULT_CUSTOM_FEILD,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
} from '../../../../store/bookingTemplates';
import { CustomFieldInput, CustomFieldType } from '../../../../API';
import labels from './labels';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { EditCustomField } from '../editCustomField/EditCustomField';
import { v4 as getId } from 'uuid';
import { InputSwitch } from 'primereact/inputswitch';
import { PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Checkbox } from 'primereact/checkbox';
import { SumoTooltip } from '../../sumoTooltip/SumoTooltip';
import { CUSTOM_FIELDS_OPTIONS } from '../editCustomField/constants';
import Sortable from 'sortablejs';
import { ReactComponent as DragAndDrop } from '../../../../assets/icons/20-drag-and-drop.svg';

type AdditionalRulesStepProps = {
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  isReadOnly: boolean;
};

export const AdditionalRulesStep = ({ selectors, actions, isReadOnly }: AdditionalRulesStepProps) => {
  const dispatch = useDispatch();
  const inputFields = useSelector(selectors.selectInputFields);
  const inviteOthers = useSelector(selectors.selectInviteOthers);
  const enterNote = useSelector(selectors.selectEnterNote);

  const [selectedCustomField, setSelectedCustomField] = useState<CustomFieldInput | null>(null);

  const list = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sortable =
      !isReadOnly &&
      list.current &&
      new Sortable(list.current, {
        handle: '.drag-and-drop',
        onSort: onSort,
      });

    return () => {
      sortable && sortable.destroy();
    };
  }, [inputFields]);

  const onSort = (e: Sortable.SortableEvent) => {
    const newOrder = [...inputFields];
    const [movedItem] = newOrder.splice(e.oldIndex || 0, 1);
    newOrder.splice(e.newIndex || 0, 0, movedItem);
    dispatch(actions.setCustomFields(newOrder));
  };

  const handleRequiredToggle = (customField: CustomFieldInput) => {
    dispatch(actions.updateCustomField({ ...customField, enabled: !customField.enabled }));
  };

  const handleSave = (customField: CustomFieldInput) => {
    if (customField.id) {
      dispatch(actions.updateCustomField(customField));
    } else {
      customField.id = getId();
      dispatch(actions.addCustomField(customField));
    }
    setSelectedCustomField(null);
  };

  const handleDelete = (id: string) => {
    dispatch(actions.removeCustomField(id));
    setSelectedCustomField(null);
  };

  const handleInviteOthersChange = (value: boolean) => {
    dispatch(actions.updateRecord({ inviteOthers: value }));
  };

  const handleEnterNoteChange = (value: boolean) => {
    dispatch(actions.updateRecord({ enterNote: value }));
  };

  return (
    <div className="flex flex-column gap-32px">
      <div className="flex flex-column gap-20px">
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.questions}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.questionsDescription}</div>
        </div>

        <div className="flex flex-column gap-6px -mx-20px" ref={list}>
          {inputFields?.map(
            (customField) =>
              customField && (
                <div key={customField?.id} className="flex-left-center gap-6px px-20px">
                  <div
                    className={`flex-center w-24px h-48px pr-4px -mr-6px drag-and-drop ${
                      isReadOnly ? 'p-disabled' : ''
                    }`}
                  >
                    <DragAndDrop className="icon-20px" />
                  </div>

                  <div className="flex-1 flex-between-center border-1 border-heavy-10 border-radius-8px py-12px px-10px">
                    <div className="flex flex-column gap-6px">
                      <div className="flex gap-3px">
                        <div className="text-label-md-med text-heavy-100">{customField.label}</div>
                        {customField.required && <div className="text-label-md-med text-tomato-main">*</div>}
                      </div>
                      <div className="text-label-xs-reg text-heavy-50">
                        {CUSTOM_FIELDS_OPTIONS.find((option) => option.value === customField.type)?.label}
                      </div>
                    </div>
                    <InputSwitch
                      checked={Boolean(customField.enabled)}
                      disabled={customField.fieldType !== CustomFieldType.CUSTOM}
                      onChange={() => handleRequiredToggle(customField)}
                    />
                  </div>

                  <div className="flex gap-4px">
                    <Button
                      icon={<PencilIcon className="icon-16px" />}
                      onClick={() => setSelectedCustomField(customField)}
                      className="action-button-sm"
                      disabled={isReadOnly}
                    />
                    <div className="w-24px h-24px">
                      {customField.fieldType === CustomFieldType.CUSTOM && (
                        <Button
                          icon={<XMarkIcon className="icon-16px" />}
                          onClick={() => customField.id && handleDelete(customField.id)}
                          className="action-button-sm"
                          disabled={isReadOnly}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
          <div style={{ padding: '0 78px 0 44px' }}>
            <Button
              onClick={() => setSelectedCustomField(DEFAULT_CUSTOM_FEILD)}
              className="w-full add-button-input"
              disabled={isReadOnly}
            >
              <PlusIcon className="icon-16px" />
            </Button>
          </div>
        </div>
      </div>

      <div className="border-top-1 border-heavy-20" />

      <div className="flex flex-column gap-20px">
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.permissions}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.permissionsDescription}</div>
        </div>

        <div className="flex flex-column gap-10px">
          <div className="flex-left-center gap-6px">
            <Checkbox
              inputId="inviteOthers"
              checked={Boolean(inviteOthers)}
              onChange={(e) => handleInviteOthersChange(Boolean(e.checked))}
              disabled={isReadOnly}
            />
            <label
              htmlFor="inviteOthers"
              className="text-body-lg-reg text-heavy-100 cursor-pointer hover-text-heavy-80 pl-4px"
            >
              {labels.optionInviteOthers}
            </label>
            <SumoTooltip text={labels.optionInviteOthersTooltip} />
          </div>
          <div className="flex-left-center gap-6px">
            <Checkbox
              inputId="enterNote"
              checked={Boolean(enterNote)}
              onChange={(e) => handleEnterNoteChange(Boolean(e.checked))}
              disabled={isReadOnly}
            />
            <label
              htmlFor="enterNote"
              className="text-body-lg-reg text-heavy-100 cursor-pointer hover-text-heavy-80 pl-4px"
            >
              {labels.optionEnterNote}
            </label>
            <SumoTooltip text={labels.optionEnterNoteTooltip} />
          </div>
        </div>
      </div>

      <Dialog
        visible={Boolean(selectedCustomField)}
        className="w-460px max-w-screen overflow-hidden"
        contentClassName="p-0"
        showHeader={false}
        draggable={false}
        position="center"
        blockScroll
        onHide={() => setSelectedCustomField(null)}
      >
        <EditCustomField
          customFieldInput={selectedCustomField || DEFAULT_CUSTOM_FEILD}
          onSave={handleSave}
          onCancel={() => setSelectedCustomField(null)}
          // onDelete={handleDelete}
        ></EditCustomField>
      </Dialog>
    </div>
  );
};
