export const OPSConsoleAccountDetails = Object.freeze({
  ACCOUNT_NAME: `Nombre de la cuenta`,
  ACCOUNT_NOTE: `Nota de la cuenta`,
  CREATED_AT: `Creado en`,
  CREATED_BY: `Creado por`,
  DUPLICATE_NAME_MESSAGE: `Ya existe una cuenta con este nombre`,
  TENANT_COLUMN_ADOPTION: `% de Adopción`,
  TENANT_COLUMN_ID: `ID de la Org.`,
  TENANT_COLUMN_OWNED: `# de Licencias`,
  TENANT_COLUMN_TERM: `Plazo`,
  TENANT_COLUMN_TYPE: `Tipo`,
  TENANTS: `Orgs`,
});

export const OPSConsoleAccounts = Object.freeze({
  BUTTON_CREATE_ACCOUNT: `Crear cuenta`,
  BUTTON_EXPORT: `Exportar`,
  BUTTON_MERGE: `Combinar`,
  COLUMN_ACCOUNT_NAME: `Nombre de la cuenta`,
  COLUMN_CREATED_BY: `Creado por`,
  COLUMN_CREATED_DATE: `Fecha de creación`,
  DELETE_ACCOUNT_ERROR_TOAST: `No se pudo eliminar la cuenta`,
  DELETE_ACCOUNT_SUCCESS_TOAST: `Cuenta eliminada.`,
  SAVE_ACCOUNT_ERROR_TOAST: `No se pudo guardar la cuenta`,
  SAVE_ACCOUNT_SUCCESS_TOAST: `Cuenta guardada.`,
  SEARCH_PLACEHOLDER: `Buscar por nombre de cuenta o dominio`,
  TITLE: `Cuentas`,
  GET_ACCOUNT_ERROR_TOAST: `Error al solicitar la cuenta`,
  GET_ACCOUNTS_ERROR_TOAST: `Error al solicitar las cuentas`,
});

export const OPSConsoleAccountsDeleteModal = Object.freeze({
  DESCRIPTION: `Eliminar la cuenta no se puede deshacer.`,
  TITLE: `Eliminar cuenta`,
});

export const OPSConsoleAccountsMergeModal = Object.freeze({
  MERGE_TITLE_PART_1: `Combinar`,
  MERGE_TITLE_PART_2: `con:`,
  NOTE: `Combinar dos cuentas no se puede deshacer.`,
  RULE_1: `Diseñado para combinar cuentas del tipo @gmail.com bajo una marca.`,
  RULE_2: `No se permite combinar dos dominios principales.`,
  RULES: `Reglas`,
  SEARCH_PLACEHOLDER: `Buscar nombre de la cuenta`,
  TITLE: `Combinar cuentas`,
});
