import { call, put, select, takeLatest } from 'redux-saga/effects';
import { CreateUserDataInput, IntegrationInput, IntegrationType, UserRecordType } from '../../API';
import { saveUserSettings, uploadProtectedFile, connectToZoom } from './service';
import { UserSettingsActionTypes, userSettingsActions } from './actions';
import { userSettingsSelectors } from './selectors';
import { authenticationActions, authenticationSelectors, ThirdPartyLambdaResponse } from '../authentication';
import { notificationsActions } from '../notifications';
import {
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST,
  CONNECT_ZOOM_ERROR_TOAST,
  CONNECT_ZOOM_SUCCESS_TOAST,
  SAVE_USER_SETTINGS_ERROR_TOAST,
  SAVE_USER_SETTINGS_SUCCESS_TOAST,
  SWITCH_ACCOUNT_ERROR_TOAST,
  SWITCH_ACCOUNT_SUCCESS_TOAST,
} from './constants';
import { createSelector } from 'reselect';
import { handleServiceError } from '../utils/reduxUtils';
import { Path } from '../../routing';
import { getAuthResponse, handleAuthResponse } from '../authentication/inviteSagas';
import { getUserDataById } from '../global/services';

const selectSaveUserDataInputRequest = createSelector(
  authenticationSelectors.selectTenantId,
  authenticationSelectors.selectUserId,
  authenticationSelectors.selectLink,
  userSettingsSelectors.selectUserSettings,
  (tenantId, userId, link, userSettings) => ({
    userId,
    tenant: tenantId,
    link,
    recordType: UserRecordType.PROFILE,
    userSettings: userSettings,
  })
);

function* getUserSettingsSaga() {
  try {
    const response: CreateUserDataInput[] = yield call(getUserDataById);
    const profileRecord = response.find((record) => record.recordType === UserRecordType.PROFILE);
    if (profileRecord?.statistics) {
      yield put(userSettingsActions.setStatistics(profileRecord.statistics));
    }
    if (profileRecord?.userSettings) {
      const userSettingsWithParsedAndFilteredIntegrations = {
        ...profileRecord.userSettings,
        integrations:
          profileRecord.userSettings.integrations
            ?.filter((integration) => integration?.type !== undefined) // Explicitly filter out undefined types
            .map((integration) => ({
              type: integration?.type as IntegrationType, // Cast type to IntegrationType, as it's now guaranteed to be defined
              settings: integration?.settings && integration.settings,
            })) || [],
      };

      yield put(userSettingsActions.getUserSettingsSuccess(userSettingsWithParsedAndFilteredIntegrations));
    } else {
      throw new Error('UserSettings not found');
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.getUserSettingsFail(error?.toString()));
    yield put(authenticationActions.logoutUserRequest(Path.Landing));
  }
}

function* saveUserSettingsSaga() {
  try {
    const input: CreateUserDataInput = yield select(selectSaveUserDataInputRequest);

    yield call(saveUserSettings, input);

    yield put(userSettingsActions.saveUserSettingsSuccess());
    yield put(notificationsActions.showToast(SAVE_USER_SETTINGS_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(userSettingsActions.saveUserSettingsFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_USER_SETTINGS_ERROR_TOAST);
  }
}

function* connectExternalCalendarSaga(action: ReturnType<typeof userSettingsActions.connectExternalCalendarRequest>) {
  if (action.type === UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_REQUEST) {
    try {
      const authResponse: ThirdPartyLambdaResponse = yield call(getAuthResponse, action.payload);
      yield call(handleAuthResponse, authResponse, action.payload);
      yield call(userSettingsSagas.getUserSettings);
      yield put(userSettingsActions.connectExternalCalendarSuccess());
      yield put(
        notificationsActions.showToast(
          action.payload.switchAccount ? SWITCH_ACCOUNT_SUCCESS_TOAST : CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST
        )
      );
    } catch (error: unknown) {
      yield put(userSettingsActions.connectExternalCalendarFail(error?.toString()));
      yield call(
        handleServiceError,
        error,
        action.payload.switchAccount ? SWITCH_ACCOUNT_ERROR_TOAST : CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST
      );
    }
  }
}

function* uploadAvatarFileSaga(action: ReturnType<typeof userSettingsActions.uploadAvatarFileRequest>) {
  try {
    if (action.type === UserSettingsActionTypes.UPLOAD_AVATAR_FILE_REQUEST) {
      const avatarLink: string = yield call(uploadProtectedFile, action.payload);
      yield put(userSettingsActions.uploadAvatarFileSuccess(avatarLink));
      yield put(userSettingsActions.saveUserSettingsRequest());
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.uploadAvatarFileFail(error?.toString()));
  }
}

function* clearAvatarFileSaga() {
  try {
    const avatarLink: string | null | undefined = yield select(userSettingsSelectors.selectAvatar);
    if (avatarLink) {
      // yield call(removeFile, avatarLink);
      yield put(userSettingsActions.clearAvatarFileSuccess());
      yield put(userSettingsActions.saveUserSettingsRequest());
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.clearAvatarFileFail(error?.toString()));
  }
}

function* connectZoomSaga(action: ReturnType<typeof userSettingsActions.connectZoomRequest>) {
  try {
    if (action.type === UserSettingsActionTypes.CONNECT_ZOOM_REQUEST) {
      const code = action.payload.code;
      const redirectURI = action.payload.redirectURI;

      const zoomIntegration: IntegrationInput = yield call(connectToZoom, code, redirectURI);

      const parseZoomIntegration: IntegrationInput = {
        type: IntegrationType.ZOOM,
        settings: zoomIntegration.settings,
      };

      yield put(userSettingsActions.connectZoomSuccess(parseZoomIntegration));
      yield put(notificationsActions.showToast(CONNECT_ZOOM_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(userSettingsActions.connectZoomFail(error?.toString()));
    yield call(handleServiceError, error, CONNECT_ZOOM_ERROR_TOAST);
  }
}

function* saveUserSettingsNoToastSaga() {
  try {
    const input: CreateUserDataInput = yield select(selectSaveUserDataInputRequest);
    yield call(saveUserSettings, input);

    yield put(userSettingsActions.saveUserSettingsNoToastSuccess());
  } catch (error: unknown) {
    yield put(userSettingsActions.saveUserSettingsNoToastFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_USER_SETTINGS_ERROR_TOAST);
  }
}

export function* watchUserSettingsSaga() {
  yield takeLatest(UserSettingsActionTypes.GET_USER_SETTINGS_REQUEST, getUserSettingsSaga);
  yield takeLatest(UserSettingsActionTypes.SAVE_USER_SETTINGS_REQUEST, saveUserSettingsSaga);
  yield takeLatest(UserSettingsActionTypes.CONNECT_EXTERNAL_CALENDAR_REQUEST, connectExternalCalendarSaga);
  yield takeLatest(UserSettingsActionTypes.UPLOAD_AVATAR_FILE_REQUEST, uploadAvatarFileSaga);
  yield takeLatest(UserSettingsActionTypes.CLEAR_AVATAR_FILE_REQUEST, clearAvatarFileSaga);
  yield takeLatest(UserSettingsActionTypes.CONNECT_ZOOM_REQUEST, connectZoomSaga);
  yield takeLatest(UserSettingsActionTypes.SAVE_USER_SETTINGS_NO_TOAST_REQUEST, saveUserSettingsNoToastSaga);
}

export const userSettingsSagas = {
  getUserSettings: getUserSettingsSaga,
  saveUserSettings: saveUserSettingsSaga,
};
