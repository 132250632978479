import { useDispatch, useSelector } from 'react-redux';
import { Journey } from '../../../API';
import { AppDispatch } from '../../../store/rootStore';
import {
  deactivateJourneysModalActions,
  deleteJourneysModalActions,
  journeysActions,
  journeysSelectors,
} from '../../../store/journeyBuilder';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { CodeBracketIcon, EllipsisVerticalIcon, LinkIcon } from '@heroicons/react/24/outline';
import labels from './labels';
import { MenuItem } from 'primereact/menuitem';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { getUrlOrigin } from '../../../services/URLService';
import { addToWebsiteModalActions, bookingPagesActions } from '../../../store/bookingPages';
import { formatDateJourneyLastDate } from '../../../services/DateService';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing/pathes';
import { userSettingsSelectors } from '../../../store/userSettings';

type JourneyCardProps = {
  journey: Journey;
};

export const JourneyCard = ({ journey }: JourneyCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedJourneys: string[] = useSelector(journeysSelectors.selectSelectedJourneys);
  const isSelected = useSelector(journeysSelectors.selectIsSelectedJourney(journey.id || ''));
  const isCreate = useSelector(userSettingsSelectors.selectJourneyBuilderCreate);
  const isEdit = useSelector(userSettingsSelectors.selectJourneyBuilderEdit);
  const isDelete = useSelector(userSettingsSelectors.selectJourneyBuilderDelete);

  let blockCardClick = false;
  const menu = useRef<Menu>(null);

  const handleEdit = () => {
    dispatch(journeysActions.setJourney(journey));
    navigationService.navigateTo(Path.EditJourney.replace(':journeyId', journey.id));
  };

  const handleClone = () => {
    dispatch(journeysActions.setJourney(journey));
    dispatch(journeysActions.cloneJourneyThunk());
  };

  const handleDelete = () => {
    dispatch(journeysActions.setJourney(journey));
    dispatch(deleteJourneysModalActions.openModal());
  };

  const handleDeactivate = () => {
    dispatch(journeysActions.setJourney(journey));
    dispatch(deactivateJourneysModalActions.openModal());
  };

  const handleActivate = () => {
    dispatch(journeysActions.setJourney(journey));
    dispatch(journeysActions.deactivateJourneyRequest());
  };

  const menuItems: MenuItem[] = [
    { label: isEdit ? labels.edit : labels.view, command: handleEdit },
    {
      label: journey.active ? labels.deactivate : labels.activate,
      command: () => (journey.active ? handleDeactivate() : handleActivate()),
      visible: isEdit,
    },
    { label: labels.clone, command: handleClone, visible: isCreate },
    { label: labels.delete, command: handleDelete, visible: isDelete },
  ];

  const handleSelectJourney = (checked: boolean) => {
    const ids = checked ? [...selectedJourneys, journey.id] : selectedJourneys.filter((item) => item !== journey.id);
    dispatch(journeysActions.selectJourneys(ids));
  };

  const handleCardClick = () => {
    if (blockCardClick) {
      blockCardClick = false;
      return;
    }
    selectedJourneys.length ? isDelete && handleSelectJourney(!isSelected) : handleEdit();
  };

  const handleCopyLink = () => {
    blockCardClick = true;
    const journeyUrl = journey.shortLink || getUrlOrigin() + Path.PublicJourneyPage.replace(':journeyId', journey.id);
    navigator.clipboard.writeText(journeyUrl);
  };

  const handleAddToWebsite = () => {
    blockCardClick = true;
    dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicJourneyPage.replace(':journeyId', journey.id)));
    dispatch(addToWebsiteModalActions.openModal());
  };

  return (
    <div
      className={`sumo-card action-card flex flex-column h-full ${isSelected ? 'selected' : ''}`}
      onClick={handleCardClick}
    >
      <div className="flex pt-22px px-18px">
        <div className="flex-1 text-title-lg-med">{journey.name}</div>
        <div className="flex gap-4px -my-1px h-24px">
          {isDelete && (
            <Checkbox
              checked={isSelected}
              onChange={(e) => {
                e.stopPropagation();
                handleSelectJourney(!!e.checked);
              }}
              className={`card-selector ${selectedJourneys.length ? '' : 'card-selector-hidden'}`}
            />
          )}
          <div
            className="-my-4px -mr-16px action-button"
            onClick={(e) => {
              e.stopPropagation();
              menu.current?.toggle(e);
            }}
          >
            <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
            <EllipsisVerticalIcon className="icon-20px" />
          </div>
        </div>
      </div>
      <div className="flex-left-center gap-6px pt-12px px-18px text-body-s-reg text-heavy-60">
        <div className="w-4px h-4px border-radius-2px bg-heavy-50" />
        <div>
          {journey.eventsCount} {journey.eventsCount === 1 ? labels.response : labels.responses}
        </div>
      </div>
      <div className="flex-left-center gap-6px pt-6px px-18px text-body-s-reg text-heavy-60">
        <div className="w-4px h-4px border-radius-2px bg-heavy-50" />
        <div>
          {labels.last}:{' '}
          {journey.lastEventDate ? formatDateJourneyLastDate(journey.lastEventDate) : labels.none.toLowerCase()}
        </div>
      </div>

      <div className="card-delimeter flex-1 mt-24px" />
      <div className="flex-right-center p-10px pl-18px">
        <div className="flex-center gap-8px h-38px">
          {journey?.active ? (
            <>
              <Button outlined onClick={handleAddToWebsite} className="p-10px">
                <CodeBracketIcon className="icon-18px" />
              </Button>
              <Button className="button-icon gap-8px" outlined onClick={handleCopyLink}>
                <LinkIcon className="icon-18px" />
                {labels.copyLink}
              </Button>
            </>
          ) : (
            <Button
              label={labels.activate}
              outlined
              severity="success"
              onClick={(e) => {
                e.stopPropagation();
                handleActivate();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
