import { t } from '../../../i18n/i18n';

export default {
  yearly: t('Billing:ANNUAL_TITLE'),
  addSeats: t('Billing:ADD_SEATS'),
  addDescriptionPart1: t('Billing:ADD_SEATS_DESCRIPTION_PART_1'),
  addDescriptionPart2: t('Billing:ADD_SEATS_DESCRIPTION_PART_2'),
  addDescriptionPart3: t('Billing:ADD_SEATS_DESCRIPTION_PART_3'),
  addDescriptionPart4: t('Billing:ADD_SEATS_DESCRIPTION_PART_4'),
  addProratedPart1: t('Billing:ADD_SEATS_PRORATED_PART_1'),
  addProratedPart2: t('Billing:ADD_SEATS_PRORATED_PART_2'),
  addProratedPart3: t('Billing:ADD_SEATS_PRORATED_PART_3'),
  cancel: t('Common:CANCEL'),
  monthly: t('Billing:MONTHLY_TITLE'),
  removeSeats: t('Billing:REMOVE_SEATS'),
  removeDescriptionPart1: t('Billing:REMOVE_SEATS_DESCRIPTION_PART_1'),
  removeDescriptionPart2: t('Billing:REMOVE_SEATS_DESCRIPTION_PART_2'),
  removeDescriptionPart3: t('Billing:REMOVE_SEATS_DESCRIPTION_PART_3'),
  removeDescriptionPart4: t('Billing:REMOVE_SEATS_DESCRIPTION_PART_4'),
  removeNotePart1: t('Billing:REMOVE_SEATS_NOTE_PART1'),
  removeNotePart2: t('Billing:REMOVE_SEATS_NOTE_PART2'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
};
