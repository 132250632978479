import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../store/userSettings';
import { CookieConsent, Preloader } from '../../components/common';
import { timeHandlerService } from '../../services/TimeHandlerService';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { AccountSettings, MyLogin, MyRole } from '../../components/account';
import labels from './labels';

export const Account = () => {
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const [index1, setIndex1] = useState<number | null>(0);
  const [index2, setIndex2] = useState<number | null>(0);
  const [index3, setIndex3] = useState<number | null>(0);
  const [index4, setIndex4] = useState<number | null>(0);

  useEffect(() => {
    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, []);

  const generateAccordion = (
    title: string,
    description: string,
    index: number | null,
    setIndex: (index: number) => void,
    content: React.ReactNode
  ) => (
    <Accordion className="sumo-card border-none" activeIndex={index} onTabChange={(e) => setIndex(e.index as number)}>
      <AccordionTab
        contentClassName="px-20px pt-12px pb-24px"
        header={
          <div className="flex-left-center px-20px py-8px">
            <div className="flex-1 flex flex-column gap-2px text-title-xs-med pb-4px">
              <div className="text-title-s-med text-heavy-100">{title}</div>
              <div className="text-body-s-reg">{description}</div>
            </div>
            <div className="flex-none">
              {index == 0 ? <ChevronUpIcon className="icon-20px" /> : <ChevronDownIcon className="icon-20px" />}
            </div>
          </div>
        }
      >
        {content}
      </AccordionTab>
    </Accordion>
  );

  return (
    <div className="sumo-card-bg flex flex-column gap-16px">
      {isFetching && <Preloader />}

      <div className="text-title-xl-med">{labels.title}</div>

      {generateAccordion(labels.accountSettings, labels.accountDescription, index1, setIndex1, <AccountSettings />)}
      {generateAccordion(labels.login, labels.loginDescription, index2, setIndex2, <MyLogin />)}
      {generateAccordion(labels.myRole, labels.myRoleDescription, index3, setIndex3, <MyRole />)}
      {generateAccordion(labels.cookieSettings, labels.cookieDescription, index4, setIndex4, <CookieConsent />)}
    </div>
  );
};
