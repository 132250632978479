import React, { useMemo } from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { countriesTimeZonesService } from '../../../services/CountriesTimeZoneService';

interface CountryDropdownProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

export const CountryDropdown: React.FC<CountryDropdownProps> = ({
  value,
  onChange,
  disabled = false,
  className = '',
  placeholder = '',
}) => {
  const { i18n } = useTranslation();
  const countriesOptions = countriesTimeZonesService.getCountriesOptions(i18n.language);

  const sortedCountriesOptions = useMemo(() => {
    const usOption = countriesOptions.find((option) => option.value === 'US');
    if (usOption) {
      return [usOption, ...countriesOptions.filter((option) => option.value !== 'US')];
    }
    return countriesOptions;
  }, [countriesOptions]);

  const handleChange = (e: DropdownChangeEvent) => {
    onChange(e.value);
  };

  return (
    <Dropdown
      id="inputcountry"
      value={value}
      optionLabel="label"
      optionValue="value"
      options={sortedCountriesOptions}
      onChange={handleChange}
      className={className}
      disabled={disabled}
      filter
      filterBy="label"
      placeholder={placeholder}
    />
  );
};
