import { t } from '../../i18n/i18n';
import {
  AssignmentMode,
  BackgroundType,
  BookingPageLabelsInput,
  BookingPageStyleInput,
  CalendarFormat,
  ConfirmationsPageInput,
  ConfirmationsPageType,
  CustomFieldInput,
  CustomFieldType,
  CustomType,
  DateRangeType,
  InPersonType,
  LocationType,
  NotificationsInput,
  PhoneCallType,
  RescheduleWith,
  TimeFormat,
  TimeUnit,
  TimeZoneType,
  UpdateBookingPageInput,
  WhenInput,
  WhereInput,
} from '../../API';
import {
  TIME_ZONE_OPTIONS,
  TOAST_ERROR_COLOR,
  TOAST_SUCCESS_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';
import urlConstants from '../../shared/JSON/urlConstants.json';
import { VideoConferencesEnum } from '../../shared/backend/constants';
import { AccordionIndexes, BackgroundOption } from './types';
import { v4 as getId } from 'uuid';

// TODO: do not import constants from other stores (cross reference errors), need to create some /constants folder for this
export const COLORS: string[] = [
  '#7986CB',
  '#33B679',
  '#8E24AA',
  '#E67C73',
  '#F6BF26',
  '#F4511E',
  '#039BE5',
  '#616161',
  '#3F51B5',
  '#0B8043',
  '#D50000',
];
export const DEFAULT_COLOR = COLORS[6];
export const DEFAULT_SMS_TIMING = 1440;
export const MAX_SMS_REMINDERS_COUNT = 3;
export const MULTISELECT_DELIMITER = ';';

export const DEFAULT_INVITEE_NAME_LABEL = t('EditBookingTemplateQuestionsStep:NAME_LABEL');
export const DEFAULT_INVITEE_EMAIL_LABEL = t('EditBookingTemplateQuestionsStep:EMAIL_LABEL');
export const DEFAULT_INVITEE_PHONE_LABEL = t('Common:PHONE_NUMBER');
export const DEFAULT_INVITEE_LOCATION_LABEL = t('BookedMeetings:LOCATION_TITLE');
export const DEFAULT_TEMPLATE_NAME = t('DefaultBookingTemplate:NAME');

/* export const DEFAULT_CONFIRMATION_SUBJECT = t('DefaultBookingTemplate:CONFIRMATION_SUBJECT');
export const DEFAULT_RESCHEDULE_SUBJECT = t('DefaultBookingTemplate:RESCHEDULE_SUBJECT');
export const DEFAULT_REMINDER_SUBJECT = t('DefaultBookingTemplate:REMINDER_SUBJECT');
export const DEFAULT_CANCELATION_SUBJECT = t('DefaultBookingTemplate:CANCELATION_SUBJECT');
export const DEFAULT_FOLLOW_UP_SUBJECT = t('DefaultBookingTemplate:FOLLOW_UP_SUBJECT');
export const DEFAULT_CONFIRMATION_BODY = t('DefaultBookingTemplate:CONFIRMATION_BODY');
export const DEFAULT_RESCHEDULE_BODY = t('DefaultBookingTemplate:RESCHEDULE_BODY');
export const DEFAULT_REMINDER_BODY = t('DefaultBookingTemplate:REMINDER_BODY');
export const DEFAULT_CANCELATION_BODY = t('DefaultBookingTemplate:CANCELATION_BODY');
export const DEFAULT_FOLLOW_UP_BODY = t('DefaultBookingTemplate:FOLLOW_UP_BODY');
export const DEFAULT_SMS_TEXT = t('DefaultBookingTemplate:SMS_TEXT'); */

export const DEFAULT_CONFIRMATION_SUBJECT =
  'New Meeting: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_CONFIRMATION_BODY =
  'Hi <Invitee Name>,\n\n' +
  'Your new meeting <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date> is scheduled.\n\n' +
  '<Meeting Location>\n\n' +
  '<Note from Host>\n\n' +
  '<Note from Invitee>\n\n' +
  '<Additional Attendees>\n\n' +
  '<Invitee Questions>\n\n' +
  '<Meeting Button>\n\n' +
  '<Cancel Policy>';
export const DEFAULT_RESCHEDULE_SUBJECT =
  'Rescheduled: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_RESCHEDULE_BODY =
  'Hi <Invitee Name>,\n\n' +
  'Your meeting <Meeting Name> with <Host Name> is rescheduled.\n\n' +
  '<b>Updated Time and Date</b>\n' +
  '<Meeting Time>, <Meeting Date>\n\n' +
  '<Meeting Location>\n\n' +
  '<Meeting Button>';
export const DEFAULT_REMINDER_SUBJECT = 'Reminder: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_REMINDER_BODY =
  'Hi <Invitee Name>,\n\n' +
  'This is a friendly reminder that your <Meeting Name> with <Host Name> is at <Meeting Time>, <Meeting Date>.\n\n' +
  '<Meeting Location>\n\n' +
  '<Meeting Button>';
export const DEFAULT_CANCELATION_SUBJECT =
  'Cancelled: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_CANCELATION_BODY =
  'Hi <Invitee Name>,\n\n' +
  'Your meeting <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date> has been cancelled.';
export const DEFAULT_FOLLOW_UP_SUBJECT = 'Follow-Up: <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date>';
export const DEFAULT_FOLLOW_UP_BODY =
  'Hi <Invitee Name>,\n\n' +
  'We hope your meeting <Meeting Name> with <Host Name> at <Meeting Time>, <Meeting Date> was productive.\n\n' +
  'If you have any feedback or need further assistance, please feel free to reach out to us.\n\n';
export const DEFAULT_SMS_TEXT =
  'This is a friendly reminder that your <Meeting Name> with <Host Name> is at <Meeting Time>, <Meeting Date>. ' +
  'View or edit meeting <Meeting Link>';

export const DEFAULT_WHEN: WhenInput = {
  duration: {
    timeUnit: TimeUnit.MINUTE,
    count: 30,
  },
  scheduleBuffer: {
    timeUnit: TimeUnit.HOUR,
    count: 12,
  },
  dateRange: {
    type: DateRangeType.DAYS_IN_FUTURE,
    count: 30,
  },
  afterEndTime: null,
  beforeStartTime: null,
  expiresAt: null,
};
export const DEFAULT_WHERE: WhereInput = {
  locationTypes: [LocationType.PHONE_CALL, LocationType.VIDEO_CONFERENCE],
  defaultLocationType: LocationType.VIDEO_CONFERENCE,
  inPersonType: InPersonType.CUSTOM_ADDRESS,
  customAddress: null,
  locations: null,
  phoneCallType: PhoneCallType.HOST_PHONE_NUMBER,
  customPhone: '',
  skipTheWhereStep: true,
};
export const DEFAULT_INPUT_FIELDS: CustomFieldInput[] = [
  {
    id: getId(),
    fieldType: CustomFieldType.NAME,
    type: CustomType.TEXT,
    enabled: true,
    required: true,
    label: DEFAULT_INVITEE_NAME_LABEL,
  },
  {
    id: getId(),
    fieldType: CustomFieldType.EMAIL,
    type: CustomType.TEXT,
    enabled: true,
    required: true,
    label: DEFAULT_INVITEE_EMAIL_LABEL,
  },
];

export const DEFAULT_INPUT_SMS_FIELD: CustomFieldInput = {
  id: getId(),
  fieldType: CustomFieldType.PHONE,
  type: CustomType.PHONE,
  enabled: true,
  required: false,
  label: DEFAULT_INVITEE_PHONE_LABEL,
};

export const DEFAULT_INPUT_PHONE_FIELD: CustomFieldInput = {
  id: getId(),
  fieldType: CustomFieldType.PHONE,
  type: CustomType.PHONE,
  enabled: true,
  required: true,
  label: DEFAULT_INVITEE_PHONE_LABEL,
};

export const DEFAULT_INPUT_LOCATION_FIELD: CustomFieldInput = {
  id: getId(),
  fieldType: CustomFieldType.LOCATION,
  type: CustomType.TEXT,
  enabled: true,
  required: true,
  label: DEFAULT_INVITEE_LOCATION_LABEL,
};

export const DEFAULT_NOTIFICATIONS: NotificationsInput = {
  confirmations: {
    enabled: true,
    subject: DEFAULT_CONFIRMATION_SUBJECT,
    body: DEFAULT_CONFIRMATION_BODY,
  },
  reschedule: {
    enabled: true,
    subject: DEFAULT_RESCHEDULE_SUBJECT,
    body: DEFAULT_RESCHEDULE_BODY,
  },
  reminders: {
    enabled: false,
    subject: DEFAULT_REMINDER_SUBJECT,
    body: DEFAULT_REMINDER_BODY,
    timing: [DEFAULT_SMS_TIMING],
  },
  cancelations: {
    enabled: true,
    subject: DEFAULT_CANCELATION_SUBJECT,
    body: DEFAULT_CANCELATION_BODY,
  },
  followUp: {
    enabled: false,
    subject: DEFAULT_FOLLOW_UP_SUBJECT,
    body: DEFAULT_FOLLOW_UP_BODY,
    timing: [DEFAULT_SMS_TIMING],
  },
  smsReminder: {
    enabled: false,
    body: DEFAULT_SMS_TEXT,
    timing: [DEFAULT_SMS_TIMING],
  },
};
export const DEFAULT_CONFIRMATIONS: ConfirmationsPageInput = {
  type: ConfirmationsPageType.OUR_PAGE,
  externalLink: null,
  anotherEventEnabled: true,
  rescheduleEnabled: true,
  cancelEnabled: true,
  customLinks: null,
};

export const TIME_FORMAT_OPTIONS = {
  [TimeFormat.HOUR_12]: t('BookingPageCalendarModal:HOUR_12'),
  [TimeFormat.HOUR_24]: t('BookingPageCalendarModal:HOUR_24'),
};

export const DEFAULT_INTERVAL_COUNT = 15;
export const DEFAULT_BOOKING_PAGE_NAME = t('DefaultBookingPage:NAME');
export const DEFAULT_BOOKING_PAGE_HOW: BookingPageLabelsInput = {
  eventNameLabel: t('DefaultBookingPage:BOOK_A_MEETING'),
  detailsLabel: t('DefaultBookingPage:MEETING_DETAILS'),
  guestsLabel: t('DefaultBookingPage:GUESTS_INFO'),
  cancelLabel: t('DefaultBookingPage:CANCEL'),
  bookButtonLabel: t('DefaultBookingPage:BOOK_MEETING_BUTTON'),
  updateButtonLabel: t('DefaultBookingPage:SAVE_MEETING_BUTTON'),
  bookAnotherButtonLabel: t('DefaultBookingPage:BOOK_ANOTHER_MEETING_BUTTON'),
  cancelButtonLabel: t('DefaultBookingPage:CANCEL_MEETING_BUTTON'),
  confirmCancelButtonLabel: t('DefaultBookingPage:CONFIRM_CANCEL_BUTTON'),
  bookTitle: t('DefaultBookingPage:MEETING_BOOKED_TITLE'),
  bookDescription: t('DefaultBookingPage:MEETING_BOOKED_DESCRIPTION'),
  rescheduleTitle: t('DefaultBookingPage:MEETING_RESCHEDULED_TITLE'),
  rescheduleDescription: t('DefaultBookingPage:MEETING_RESCHEDULED_DESCRIPTION'),
  cancelTitle: t('DefaultBookingPage:MEETING_CANCELED_TITLE'),
  cancelDescription: t('DefaultBookingPage:MEETING_CANCELED_DESCRIPTION'),
};
export const DEFAULT_STYLE: BookingPageStyleInput = {
  logoImage: null,
  backgroundImage: null,
  backgroundColor: '#f7f7f7',
  backgroundType: BackgroundType.BLANK,
  primaryColor: '#0066e0',
  css: null,
  footerHtml: `<a href="${urlConstants.NAME_URL_SITE}" target="_blank">Powered by SUMO</a>`,
};
// TODO: do not import constants from other stores (cross reference errors), need to create some /constants folder for this - end

export const DEFAULT_BOOKING_PAGE_DATA: UpdateBookingPageInput = {
  id: '',
  workspaceId: '',
  inviteOthers: false,
  enterNote: false,
  adminOnly: false,
  labels: DEFAULT_BOOKING_PAGE_HOW,
  style: DEFAULT_STYLE,
  calendar: {
    timeFormat: TimeFormat.HOUR_12,
    calendarFormat: CalendarFormat.MONTH_VIEW,
    timeInterval: DEFAULT_INTERVAL_COUNT,
    topOfInterval: false,
    timeZoneType: TimeZoneType.DETECT_INVITEE,
    selectedTimeZone: [],
    defaultTimeZone: null,
  },
  what: {
    customName: DEFAULT_BOOKING_PAGE_NAME,
    isActive: true,
    color: DEFAULT_COLOR,
    bookingTemplateId: undefined,
  },
  when: DEFAULT_WHEN,
  where: DEFAULT_WHERE,
  who: {
    hostAssignmentMode: AssignmentMode.ROUND_ROBIN,
    hostRescheduleWith: RescheduleWith.ASSIGNED_HOST,
  },
  inputFields: DEFAULT_INPUT_FIELDS,
  notifications: DEFAULT_NOTIFICATIONS,
  confirmationsPage: DEFAULT_CONFIRMATIONS,
  smartAlertIds: [],
};

export const EMPTY_ACCORDION_INDEXES: AccordionIndexes = {
  what: false,
  who: false,
  where: false,
  when: false,
  how: false,
  alerts: false,
  invitee: false,
  after: false,
};
export const DEFAULT_ACCORDION_INDEXES: AccordionIndexes = {
  ...EMPTY_ACCORDION_INDEXES,
  what: true,
};
export const FILTER_OPTION_TEAMS = 'teams';
export const FILTER_OPTION_UNASSIGNED = 'unassigned';
export const FILTER_UNASSIGNED = { value: FILTER_OPTION_UNASSIGNED, label: t('EditBookingPageWhoStep:UNASSIGNED') };

export const VIDEO_CONFERENCE_OPTIONS = {
  [VideoConferencesEnum.ZOOM]: t('EditBookingTemplateWhereStep:ZOOM'),
  [VideoConferencesEnum.GOOGLE_MEET]: t('EditBookingTemplateWhereStep:GOOGLE_MEET'),
  [VideoConferencesEnum.MICROSOFT_TEAMS]: t('EditBookingTemplateWhereStep:MICROSOFT_TEAMS'),
};

export const CLONE_BOOKING_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingPagesToastsNotifications:CLONE_BOOKING_PAGES_ERROR_MESSAGE'),
});
export const CLONE_BOOKING_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BookingPagesToastsNotifications:CLONE_BOOKING_PAGES_SUCCESS_MESSAGE'),
});
export const DELETE_BOOKING_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingPagesToastsNotifications:DELETE_BOOKING_PAGES_ERROR_MESSAGE'),
});
export const DELETE_BOOKING_PAGES_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BookingPagesToastsNotifications:DELETE_BOOKING_PAGES_SUCCESS_MESSAGE'),
});
export const GET_BOOKING_PAGES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingPagesToastsNotifications:GET_BOOKING_PAGES_ERROR_MESSAGE'),
});
export const GET_BOOKING_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingPagesToastsNotifications:GET_BOOKING_PAGE_ERROR_MESSAGE'),
});
export const SAVE_BOOKING_PAGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookingPagesToastsNotifications:SAVE_BOOKING_PAGE_ERROR_MESSAGE'),
});
export const SAVE_BOOKING_PAGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BookingPagesToastsNotifications:SAVE_BOOKING_PAGE_SUCCESS_MESSAGE'),
});

export const AVAILABLE_TIME_ZONE_OPTIONS = TIME_ZONE_OPTIONS.flatMap(({ zones }) =>
  zones.map(({ city, time, timeZone }) => ({ name: `${city} - ${time}`, value: timeZone }))
);

export const BACKGROUND_OPTIONS = [
  { label: t('BookingPageBrandingModal:BACKGROUND_OPTION_BLANK'), value: BackgroundOption.BLANK },
  { label: t('BookingPageBrandingModal:BACKGROUND_OPTION_WALLPAPER'), value: BackgroundOption.WALLPAPER },
  { label: t('BookingPageBrandingModal:BACKGROUND_OPTION_Color'), value: BackgroundOption.COLOR },
];
