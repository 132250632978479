import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { adminRoles } from '../../shared/backend/constants';
import { SelectItem } from 'primereact/selectitem';
import { DEFAULT_FILTER } from './constants';
import { globalSelectors } from '../global';
import { isOldData } from '../../services/utils';

const rolesState = (state: State) => state.roles;

const selectIsFetching = createSelector(rolesState, (state) => state.isFetching);
const selectError = createSelector(rolesState, (state) => state.error);
const selectRoles = createSelector(rolesState, (state) => state.roles);
const selectSelectedRole = createSelector(rolesState, (state) => state.role);

const selectFilter = createSelector(rolesState, (state) => state.filter);
const selectSelectedRoles = createSelector(rolesState, (state) => state.selectedRoles);

const selectLastLoadTime = createSelector(rolesState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectIsDefaultFilter = createSelector(selectFilter, (filter) => filter === DEFAULT_FILTER);
// const selectFilterCreatedBy = createSelector(selectFilter, (filter) => filter.createdBy);
const selectFilterStatuses = createSelector(selectFilter, (filter) => filter.statuses);
const selectIsFilterInUse = createSelector(
  selectFilter,
  (filter) => filter.statuses.length < DEFAULT_FILTER.statuses.length
);

const selectFilteredRoles = createSelector(
  selectRoles,
  selectFilter,
  globalSelectors.selectLowercasedSearchString,
  (roles, filter, searchString) =>
    roles
      .filter(
        (role) =>
          filter.statuses?.includes(String(role.isActive)) &&
          Boolean(!searchString || role.name?.toLowerCase().includes(searchString))
      )
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
);

const selectRoleOptions = createSelector(selectRoles, (roles) =>
  roles.map((role) => ({ value: role.id, label: role.name } as SelectItem))
);

const selectStandardRoles = createSelector(selectRoles, (roles) => roles.filter((role) => !role.isCustom));

// Default Standard User - used in User Invite
const selectStandardUser = createSelector(selectStandardRoles, (roles) =>
  roles.find((role) => role.name === adminRoles.standardUser)
);
const selectStandardUserId = createSelector(selectStandardUser, (role) => role?.id || '');

const selectWorkspaceAdmins = createSelector(selectRoles, (roles) =>
  roles.filter((role) => !role.isAccessToAllWorkspaces && role.workspaceAdminActions && role.workspaceAdminActions.length > 0)
);
const selectWorkspaceIds = createSelector(selectWorkspaceAdmins, (roles) => roles.map((role) => role.id));

const selectSuperAdmins = createSelector(selectRoles, (roles) => roles.filter((role) => role.isAccessToAllWorkspaces));
const selectSuperAdminIds = createSelector(selectSuperAdmins, (roles) => roles.map((role) => role.id));

const selectAdminsRolesId = createSelector(selectWorkspaceIds, selectSuperAdminIds, (workspaceIds, superAdminIds) => [
  ...workspaceIds,
  ...superAdminIds,
]);

export const rolesSelectors = {
  selectIsFetching,
  selectError,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectRoles,
  selectRoleOptions,
  selectSelectedRole,
  selectSelectedRoles,
  selectIsDefaultFilter,
  // selectFilterCreatedBy,
  selectFilterStatuses,
  selectIsFilterInUse,
  selectFilteredRoles,
  selectStandardUser,
  selectStandardUserId,
  selectWorkspaceIds,
  selectSuperAdminIds,
  selectAdminsRolesId,
};
