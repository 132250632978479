import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonProps } from 'primereact/button';
import { WorkspaceIntegrationType } from '../../../API';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import labels from './labels';
import {
  ConnectFormIntegration,
  ConnectFormIntegrationSaveResponse,
} from '../connectFormIntegration/ConnectFormIntegration';
import { integrationActions } from '../../../store/integration';
import { authenticationSelectors } from '../../../store/authentication';

interface IGoogleAnalyticsIntegration {
  integrationType: WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const GoogleAnalyticsIntegration = ({
  integrationType,
  handleDisconnect: handleDisconnectFromProps,
}: IGoogleAnalyticsIntegration) => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const isWorkspaceIntegrationConnected = useSelector(
    authenticationSelectors.selectIsWorkspaceIntegrationConnected(integrationType)
  );

  const [showForm, setShowForm] = useState(false);

  const toggleShowForm = () => {
    setShowForm((prevState) => !prevState);
  };

  const handleSave = (dataForm: ConnectFormIntegrationSaveResponse) => {
    dispatch(
      integrationActions.connectWorkspaceIntegrationRequest({
        type: integrationType,
        workspaceId: workspaceId,
        customParameters: {
          trackingId: dataForm['trackingId']?.value,
          measurementId: dataForm['measurementId']?.value,
        },
      })
    );
  };

  const handleDisconnect = () => {
    setShowForm(false);
    handleDisconnectFromProps();
  };

  // TODO: move it to /components
  const connectButton = ({ label, outlined, severity, className, disabled }: ButtonProps) => {
    return (
      <Button
        label={label}
        className={className}
        outlined={outlined}
        severity={severity as 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help'}
        onClick={toggleShowForm}
        disabled={disabled}
      />
    );
  };

  if (showForm && !isWorkspaceIntegrationConnected) {
    return (
      <ConnectFormIntegration
        integrationType={integrationType}
        handleSave={handleSave}
        handleCancel={toggleShowForm}
        contentForm={[
          {
            type: 'input',
            uniqueName: 'trackingId',
            placeholder: labels.trackingIdPlaceholder,
            visibleName: labels.trackingIdVisibleName,
          },
          {
            type: 'input',
            uniqueName: 'measurementId',
            placeholder: labels.measurementIdPlaceholder,
            visibleName: labels.measurementIdVisibleName,
          },
        ]}
      />
    );
  } else {
    return (
      <ConnectIntegration
        integrationType={integrationType}
        handleDisconnect={handleDisconnect}
        ConnectButton={connectButton}
      />
    );
  }
};
