import { useSelector } from 'react-redux';
import errorImage from '../../../assets/images/error_desert.png';
import { eventSelectors } from '../../../store/publicBookingPage';
import { Button } from 'primereact/button';
import labels from './labels';

export const PublicBookingPageError = () => {
  const error = useSelector(eventSelectors.selectError);

  const handleHome = () => {
    window.location.reload();
  };

  return (
    <div className="flex-1 flex-center gap-60px px-28px">
      <div className="w-7">
        <img className="w-full" src={errorImage} />
      </div>
      <div className="flex-1 flex flex-column gap-32px">
        <div className="text-label-input-reg text-heavy-80">{error}</div>
        {!error?.includes('inactive') && ( // hide reload button if page is inactive
          <Button onClick={handleHome} className="button-xl min-w-120px w-fit" label={labels.backToHome} />
        )}
      </div>
    </div>
  );
};
