export const GroupBookingPage = Object.freeze({
  GROUP_TITLE: `Grupo`,
  GROUPS_TITLE: `Grupos`,
  GROUP_PAGE: `Página de grupo`,
  SELECT_MEETING_TYPE: `Seleccione un tipo de reunión`,
});

export const GroupBookingPagesToastsNotifications = Object.freeze({
  CLONE_GROUP_PAGES_ERROR_MESSAGE: `Error en la solicitud de clonación de la página de reserva de grupo`,
  CLONE_GROUP_PAGES_SUCCESS_MESSAGE: `Página de reserva de grupo clonada con éxito`,
  DELETE_GROUP_PAGES_ERROR_MESSAGE: `Error en la solicitud de eliminación de la página de reserva de grupo`,
  DELETE_GROUP_PAGES_SUCCESS_MESSAGE: `Página de reserva de grupo eliminada con éxito`,
  GET_GROUP_PAGE_ERROR_MESSAGE: `No se pudo recuperar la página de reserva de grupo, podría haber sido eliminada`,
  GET_GROUP_PAGES_ERROR_MESSAGE: `Error en la solicitud de obtención de páginas de reserva de grupo`,
  SAVE_GROUP_PAGE_ERROR_MESSAGE: `Error en la solicitud de guardado de la página de reserva de grupo`,
  SAVE_GROUP_PAGE_SUCCESS_MESSAGE: `Página de reserva de grupo guardada con éxito`,
  ACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Página de reserva de grupo activada con éxito`,
  DEACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Página de reserva de grupo desactivada con éxito`,
});

export const EditGroupBookingPage = Object.freeze({
  ADD_DESCRIPTION: `Agregar descripción`,
  ADMIN_ONLY_TITLE: `Solo Administrador`,
  BASIC_SETTING_INFO_MESSAGE: `Tenga en cuenta que esta página de reserva de grupo se basa en las páginas de reserva seleccionadas y cualquier cambio en estas páginas de reserva fuente se reflejará en este enlace de reserva de grupo.`,
  BASIC_SETTINGS_DESCRIPTION: `Páginas de reserva y nombre del grupo`,
  BASIC_SETTINGS_TITLE: `Configuraciones básicas`,
  BOOKING_PAGES_DESCRIPTION: `Puede elegir páginas de reserva`,
  BOOKING_PAGES_TITLE: `Páginas de reserva`,
  BRANDING_DESCRIPTION: `Descripción, estilos de marca y pantallas`,
  BRANDING_TITLE: `¿Cómo debería verse?`,
  BRANDING_WARNING: `Advertencia: Tienes diferentes estilos de marca para las páginas de reserva.`,
  CLONE_TITLE: `Nombre de la página de grupo clonada`,
  DEACTIVATE_DESCRIPTION: `¿Está seguro de que desea desactivar `,
  DEACTIVATE_TITLE: `Desactivar página de reserva de grupo`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar `,
  DELETE_TITLE: `Eliminar página de reserva de grupo`,
  MEETING_DESCRIPTION_TITLE: `Descripción de la reunión`,
  NAME_PLACEHOLDER: `Nombre personalizado`,
  NAME_TITLE: `Mostrar nombre del grupo`,
  USE_BRANDING_TITLE: `Usar configuración de marca de:`,
  VIEW_GROUP_PAGE: `Ver página de grupo`,
  MEETING_DESCRIPTION_TOOLTIP: `El texto de la descripción de la reunión se muestra solo en la página pública de reservas del grupo, para el invitado.`,
});
