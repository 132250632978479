import { t } from '../../../i18n/i18n';

export default {
  active: t('EditUser:ACTIVE'),
  access: t('EditUser:ACCESS_TITLE'),
  activeTooltip: t('EditUser:ACTIVE_TOOLTIP'),
  cancel: t('Common:CANCEL'),
  contactDetail: t('EditUser:CONTACT_DETAILS_TITLE'),
  deactivateHimself: t('EditUser:DEACTIVATE_HIMSELF'),
  email: t('Common:EMAIL'),
  fullName: t('Users:FULL_NAME'),
  higherRoleDesc: t('EditUser:HIGHER_ROLE_DESCRIPTION'),
  inviteSentTooltip: t('EditUser:INVITE_SENT_TOOLTIP'),
  bookingTemplates: t('EditUser:BOOKING_TEMPLATES'),
  bookingTemplatesMess: t('EditUser:BOOKING_TEMPLATES_MESSAGE'),
  bookingTemplatesTooltip: t('EditUser:BOOKING_TEMPLATES_TOOLTIP'),
  assignBookingTemplatesPlaceholder: t('Users:ASSIGN_BOOKING_TEMPLATES_PLACEHOLDER'),
  noPermissionDesc: t('EditUser:NO_PERMISSION_DESCRIPTION'), // ONLY_ONE_SUPER_ADMIN
  onlyOneSuperAdmin: t('EditUser:ONLY_ONE_SUPER_ADMIN'),
  phoneNumber: t('Common:PHONE_NUMBER'),
  readonlyDesc: t('EditUser:READONLY_DESCRIPTION'),
  role: t('Users:ROLE'),
  save: t('Common:SAVE'),
  superAdminAccessDescription: t('Users:SUPER_ADMIN_ACCESS_DESCRIPTION'),
  teams: t('EditUser:TEAMS'),
  teamsMess: t('EditUser:TEAMS_MESSAGE'),
  teamsPlaceholder: t('Users:ADD_TO_TEAM_PLACEHOLDER'),
  title: t('EditUser:TITLE'),
  userOfWorkspace: t('EditUser:USER_OF_WORKSPACE_TITLE'),
  workspace: t('Users:WORKSPACE'),
  workspaceMess: t('EditUser:WORKSPACE_MESSAGE'),
};
