import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { deleteAvailability, upsertAvailability } from './service';
import { UserAvailabilityActionTypes, UserAvailabilityActions } from './actions';
import { CreateUserDataInput, DeleteUserDataInput, UserRecordType } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CREATE_AVAILABILITY_ERROR_TOAST,
  CREATE_AVAILABILITY_SUCCESS_TOAST,
  DELETE_AVAILABILITY_ERROR_TOAST,
  DELETE_AVAILABILITY_SUCCESS_TOAST,
  SAVE_AVAILABILITY_ERROR_TOAST,
  SAVE_AVAILABILITY_SUCCESS_TOAST,
  SET_DEFAULT_AVAILABILITY_ERROR_TOAST,
  SET_DEFAULT_AVAILABILITY_SUCCESS_TOAST,
} from './constants';
import { v4 as uuidv4 } from 'uuid';
import { userAvailabilitySelectors } from './selectors';
import { authenticationSelectors } from '../authentication';
import { userAvailabilityActions } from '.';
import { UserDataInputCreatedAt } from '../global/types';
import { handleServiceError } from '../utils/reduxUtils';
import { getUserDataById } from '../global/services';

const selectCreateAvailabilityRequest = createSelector(
  authenticationSelectors.selectUserId,
  authenticationSelectors.selectTenantId,
  userAvailabilitySelectors.selectUserAvailability,
  (userId, tenant, availability) => ({
    userId,
    tenant,
    link: uuidv4(),
    recordType: UserRecordType.AVAILABILITY,
    availabilityData: {
      ...availability.availabilityData,
      id: uuidv4(),
    },
  })
);

const selectCloneAvailabilityRequest = createSelector(
  authenticationSelectors.selectUserId,
  authenticationSelectors.selectTenantId,
  userAvailabilitySelectors.selectUserAvailability,
  userAvailabilitySelectors.selectCloneName,
  (userId, tenant, availability, cloneName) => ({
    userId,
    tenant,
    link: uuidv4(),
    recordType: UserRecordType.AVAILABILITY,
    availabilityData: {
      ...availability.availabilityData,
      id: uuidv4(),
      name: cloneName,
      isDefault: false,
    },
  })
);

// TODO: find out how to describe function generator with typeScript
// TODO: rename to getAvailabilitySaga
function* getUserAvailabilitySaga() {
  try {
    const availabilityRecords: UserDataInputCreatedAt[] = yield call(getUserDataById);
    const availabilities: UserDataInputCreatedAt[] = availabilityRecords
      .filter((record) => record.recordType === UserRecordType.AVAILABILITY)
      .map((record) => ({
        ...record,
        createdAt: record.createdAt || 'default-date', // Provide a default or fetch the correct date if possible
      }));

    if (availabilities.length === 0) {
      throw new Error('Availabilities not found');
    }

    yield put(UserAvailabilityActions.getUserAvailabilitySuccess(availabilities));
  } catch (error: unknown) {
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST, true);
    yield put(UserAvailabilityActions.getUserAvailabilityFail(error?.toString()));
  }
}

// TODO: rename to createAvailabilitySaga
function* createUserAvailabilitySaga() {
  try {
    const request: CreateUserDataInput = yield select(selectCreateAvailabilityRequest);
    yield call(upsertAvailability, request);

    yield put(notificationsActions.showToast(CREATE_AVAILABILITY_SUCCESS_TOAST));
    yield put(UserAvailabilityActions.createUserAvailabilitySuccess({ ...request, createdAt: '' }));
    yield put(userAvailabilityActions.getUserAvailabilityRequest());
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.createUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST);
  }
}

// TODO: rename to cloneAvailabilitySaga
function* cloneUserAvailabilitySaga() {
  try {
    const request: CreateUserDataInput = yield select(selectCloneAvailabilityRequest);
    yield call(upsertAvailability, request);

    yield put(notificationsActions.showToast(CREATE_AVAILABILITY_SUCCESS_TOAST));
    yield put(UserAvailabilityActions.cloneUserAvailabilitySuccess({ ...request, createdAt: '' }));
    yield put(userAvailabilityActions.getUserAvailabilityRequest());
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.cloneUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST);
  }
}

// TODO: rename to updateAvailabilitySaga
function* updateUserAvailabilitySaga() {
  try {
    const request: UserDataInputCreatedAt = yield select(userAvailabilitySelectors.selectUserAvailability);
    yield call(upsertAvailability, request);

    yield put(UserAvailabilityActions.saveUserAvailabilitySuccess(request));
    // yield put(UserAvailabilityActions.getUserAvailabilityRequest()); seems, it doesn't work well with autosave
    yield put(notificationsActions.showToast(SAVE_AVAILABILITY_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.saveUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_AVAILABILITY_ERROR_TOAST);
  }
}

function* deleteAvailabilitySaga(action: ReturnType<typeof UserAvailabilityActions.deleteUserAvailabilityRequest>) {
  try {
    if (action.type === UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST) {
      const userId: string = yield select(authenticationSelectors.selectUserId);
      const tenant: string = yield select(authenticationSelectors.selectTenantId);
      const link: string = action.payload;
      const defaultAvailability: UserDataInputCreatedAt = yield select(
        userAvailabilitySelectors.selectDefaultAvailability
      );

      const input = {
        userId,
        tenant,
        link,
      } as DeleteUserDataInput;

      yield call(deleteAvailability, input);
      yield put(UserAvailabilityActions.deleteUserAvailabilitySuccess(link));
      yield put(notificationsActions.showToast(DELETE_AVAILABILITY_SUCCESS_TOAST));

      if (defaultAvailability && defaultAvailability.link === link) {
        const oldestRecord: UserDataInputCreatedAt = yield select(
          userAvailabilitySelectors.selectOldestAvailability(link)
        );
        yield put(UserAvailabilityActions.setUserAvailability(oldestRecord));
        yield put(UserAvailabilityActions.setDefaultAvailabilityRequest(false));
      } else {
        if (defaultAvailability) {
          yield put(UserAvailabilityActions.setUserAvailability(defaultAvailability));
        }
        yield put(UserAvailabilityActions.getUserAvailabilityRequest());
      }
    }
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.deleteUserAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_AVAILABILITY_ERROR_TOAST);
  }
}

function* setDefaultAvailabilitySaga(action: ReturnType<typeof userAvailabilityActions.setDefaultAvailabilityRequest>) {
  try {
    if (action.type === UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST) {
      const currentDefault: UserDataInputCreatedAt | undefined = yield select(
        userAvailabilitySelectors.selectDefaultAvailability
      );

      if (action.payload && currentDefault) {
        yield call(upsertAvailability, {
          userId: currentDefault.userId,
          tenant: currentDefault.tenant,
          link: currentDefault.link,
          recordType: UserRecordType.AVAILABILITY,
          createdAt: currentDefault.createdAt,
          availabilityData: { ...currentDefault.availabilityData, isDefault: false },
        } as UserDataInputCreatedAt);
      }

      const selected: UserDataInputCreatedAt = yield select(userAvailabilitySelectors.selectUserAvailability);

      yield call(upsertAvailability, {
        userId: selected.userId,
        tenant: selected.tenant,
        link: selected.link,
        recordType: UserRecordType.AVAILABILITY,
        createdAt: selected.createdAt,
        availabilityData: { ...selected.availabilityData, isDefault: true },
      } as UserDataInputCreatedAt);

      yield put(UserAvailabilityActions.setDefaultAvailabilitySuccess());
      yield put(UserAvailabilityActions.getUserAvailabilityRequest());
      yield put(notificationsActions.showToast(SET_DEFAULT_AVAILABILITY_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(UserAvailabilityActions.setDefaultAvailabilityFail(error));
    yield call(handleServiceError, error, SET_DEFAULT_AVAILABILITY_ERROR_TOAST);
  }
}

// TODO: rename to watchavailabilitySaga
export function* watchUserAvailabilitySaga() {
  yield takeLatest(UserAvailabilityActionTypes.GET_USER_AVAILABILITY_REQUEST, getUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.CREATE_USER_AVAILABILITY_REQUEST, createUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.CLONE_USER_AVAILABILITY_REQUEST, cloneUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.SAVE_USER_AVAILABILITY_REQUEST, updateUserAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.DELETE_USER_AVAILABILITY_REQUEST, deleteAvailabilitySaga);
  yield takeLatest(UserAvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST, setDefaultAvailabilitySaga);
}

// TODO: rename to availabilitySagas
export const userAvailabilitySagas = {
  getUserAvailability: getUserAvailabilitySaga,
  updateUserAvailability: updateUserAvailabilitySaga,
};
