import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useSelector } from 'react-redux';
import { Path } from '../../../../routing';
import { TenantItem, accountsSelectors } from '../../../../store/opsConsole/accounts';
import labels from './labels';
import { navigationService } from '../../../../services/NavigationService';

export const TenantsTable = () => {
  const tenants = useSelector(accountsSelectors.selectAccountTenants);
  const isPaginatorEnabled = useSelector(accountsSelectors.selectIsTenantsPaginatorEnabled);

  return (
    <DataTable
      className="cursor-pointer"
      size="small"
      rows={10}
      rowsPerPageOptions={[10, 25, 50]}
      showGridlines
      removableSort
      rowHover
      emptyMessage={labels.noResults}
      value={tenants}
      paginator={isPaginatorEnabled}
      onRowClick={(e) => navigationService.navigateTo(Path.OPSConsoleOrgDetails.replace(':orgId', e.data.tenantId))}
    >
      <Column field="tenantId" header={labels.tenantId} sortable />

      <Column field="type" header={labels.tenantType} sortable />

      <Column field="term" header={labels.tenantTerm} sortable />

      <Column field="owned" header={labels.tenantLicensesOwned} sortable />

      <Column
        header={labels.tenantLicensesAdoption}
        sortable
        body={(data: TenantItem) =>
          data.assigned && data.owned ? `${Math.round((data.assigned / data.owned) * 100)}%` : 0
        }
      />
    </DataTable>
  );
};
