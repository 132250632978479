import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect';
import { ReactNode } from 'react';

interface IMultiSelectWithChips<T> extends MultiSelectProps {
  value: Array<string | null>;
  options: T[];
  onChange: (event: MultiSelectChangeEvent) => void;
  placeholder?: string;
  className?: string;
  itemTemplate?: (option: T) => ReactNode;
  selectedItemContent?: (value: string) => ReactNode;
  onRemove: (value: string) => void;
  isRemovable?: (value: string) => boolean;
  disabled?: boolean;
}

export const MultiSelectWithChips = <T,>({
  value,
  options,
  onChange,
  placeholder,
  className,
  itemTemplate,
  selectedItemContent,
  onRemove,
  isRemovable,
  disabled,
  ...props
}: IMultiSelectWithChips<T>) => {
  const handleRemove = (e: React.MouseEvent<HTMLElement>, value: string) => {
    e.stopPropagation();
    onRemove(value);
  };
  const selectedItemTemplate = (value: string) => {
    return (
      <div className="p-multiselect-token" data-pc-section="token">
        <span className="p-multiselect-token-label" data-pc-section="tokenlabel">
          {selectedItemContent ? selectedItemContent(value) : value}
        </span>
        {(!isRemovable || isRemovable(value)) && (
          <span
            className="p-multiselect-token-icon"
            data-pc-section="removetokenicon"
            onClick={(e) => handleRemove(e, value)}
          >
            <XMarkIcon className="icon-16px" />
          </span>
        )}
      </div>
    );
  };

  return (
    <MultiSelect
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      className={`p-multiselect-chip ${className}`}
      itemTemplate={itemTemplate}
      dropdownIcon={<ChevronDownIcon className="icon-18px" />}
      selectedItemTemplate={value.length ? selectedItemTemplate : undefined}
      {...props}
    />
  );
};
