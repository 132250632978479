import { t } from '../../../../../i18n/i18n';

export default {
  scheduleAvailability: t('EditBookingPageDateRangeStep:SCHEDULE_AVAILABILITY'),
  minimumNoticePeriod: t('EditBookingPageDateRangeStep:MINIMUM_NOTICE_PERIOD'),
  minimumNoticePeriodTooltip: t('EditBookingPageDateRangeStep:MINIMUM_NOTICE_PERIOD_TOOLTIP'),
  optionDaysIntoTheFuture: t('EditBookingPageDateRangeStep:OPTION_DAYS_INTO_THE_FUTURE'),
  optionDateRange: t('EditBookingPageDateRangeStep:OPTION_DATE_RANGE'),
  optionIndefinitely: t('EditBookingPageDateRangeStep:OPTION_INDEFINITELY'),
};
