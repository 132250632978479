export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `New Booking Page`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `Are you sure you want to delete this booking page?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `Are you sure you want to delete these booking pages?`,
  TITLE: `Booking Pages`,
  ADD_TO_SITE: `Add to Website`,
  CLONE_MODAL_TITLE: `Clone Booking Page`,
  DEFAULT_FILTER: `My Booking Pages`,
  SHARED_LABEL: `Shared`,
  CLONE_NOTIFICATION: `Please be aware that the "Admin Only" property is switched off by default for a cloned record.`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Clone booking page request fail`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Booking page successfully cloned`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Delete booking pages request fail`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Booking page successfully deleted`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `Could not retrieve booking page, it could be deleted`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Get booking pages request fail`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Save booking page request fail`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Booking page successfully saved`,
});

export const DefaultBookingPage = Object.freeze({
  NAME: `30 Minute Meeting`,
  BOOK_A_MEETING: `Book a Meeting`,
  MEETING_DETAILS: `Meeting Details`,
  GUESTS_INFO: `Invitee info`,
  BOOK_MEETING_BUTTON: `Confirm`,
  BOOK_ANOTHER_MEETING_BUTTON: `Book Another Meeting`,
  CANCEL: `Cancel`,
  CANCEL_MEETING_BUTTON: `Cancel Meeting`,
  CONFIRM_CANCEL_BUTTON: `Confirm`,
  SAVE_MEETING_BUTTON: `Reschedule`,
  MEETING_BOOKED_TITLE: `You're booked`,
  MEETING_BOOKED_DESCRIPTION: `An email confirmation has been sent to your inbox.`,
  MEETING_RESCHEDULED_TITLE: `You're rescheduled`,
  MEETING_RESCHEDULED_DESCRIPTION: `An email confirmation has been sent to your inbox.`,
  MEETING_CANCELED_TITLE: `You're canceled`,
  MEETING_CANCELED_DESCRIPTION: `An email confirmation has been sent to your inbox.`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE: `What`,
  WHAT_DESCRIPTION: `Booking templates and details`,
  WHO_TITLE: `Who`,
  WHO_DESCRIPTION: `Meeting host`,
  WHERE_TITLE: `Where`,
  WHERE_DESCRIPTION: `Event details and locations`,
  WHEN_TITLE: `When`,
  WHEN_DESCRIPTION: `Duration and availability`,
  HOW_TITLE: `How should it look?`,
  HOW_DESCRIPTION: `Branding styles and displays`,
  ALERTS_TITLE: `Alerts`,
  ALERTS_DESCRIPTION: `Emails and reminders`,
  INVITEE_TITLE: `Invitee Settings`,
  INVITEE_DESCRIPTION: `Booking form and invitee permission`,
  AFTER_TITLE: `After booking`,
  AFTER_DESCRIPTION: `Confirmation page and Cancelation Policy`,
  ADDITIONAL_CONFIGURATIONS: `Additional Configurations`,
  VIEW_BOOKING_PAGE: `View Booking Page`,
  COPY_LINK: `Copy Link`,
  ADD_TO_WEBSITE: `Add to Website`,
  ACTIVE: `Active`,
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Record Locked. These settings are from the booking template titled: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Click here to Override these settings`,
  LOCKED_ADMIN_ONLY: `Only an Admin may modify these settings. Last Modified by:`,
  LOCKED_NO_PERMISSIONS: `You don't have permissions to edit this Booking Page. Last Modified by:`,
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Name`,
  NAME_PLACEHOLDER: `Custom Name for this Booking Page`,
  NAME_TOOLTIP: `This text will be displayed to the meeting attendees on their calendar and in emails. For example "30min Meeting" or "Dental Exam".`,
  COLOR: `Color`,
  COLOR_TOOLTIP: `Choose a color for this booking page and the created meeting.`,
  BOOKING_TEMPLATE: `Booking Template`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Select Booking Template`,
  BOOKING_TEMPLATE_TOOLTIP: `Select a booking template to automatically apply all settings for the current booking page.`,
  ADMIN_ONLY: `Admin Only`,
  ADMIN_ONLY_TOOLTIP: `Only an Admin may modify these settings`,
  MEETING_INSTRUCTIONS: `Meeting Instructions`,
  MEETING_INSTRUCTIONS_PLACEHOLDER: `Enter meeting instructions`,
  MEETING_INSTRUCTIONS_TOOLTIP: `Write a summary and any details your invitee should know about the event.`,
});

export const EditBookingPageWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Hosts And Teams`,
  HOSTS_TOOLTIP: `Choose one person, multiple people, or a team (where one member will be selected via round-robin) to attend this meeting.`,
  SEARCH_PLACEHOLDER: `Search Hosts and Teams`,
  UNASSIGNED: `Unassigned`,
  WRONG_HOST: `You must be the main host to save the booking page`,
});

export const EditBookingPageDurationStep = Object.freeze({
  DURATION: `Duration`,
  DURATION_DESCRIPTION: `Set how long your meeting will be. Not to exceed 12 hours.`,
  BUFFER_TIME: `Buffer time`,
  BUFFER_TIME_TOOLTIP: `Set before and after buffer times`,
  AFTER_CHECKBOX_LABEL: `after event`,
  BEFORE_CHECKBOX_LABEL: `before event`,
});

export const EditBookingPageDateRangeStep = Object.freeze({
  SCHEDULE_AVAILABILITY: `Schedule availability`,
  MINIMUM_NOTICE_PERIOD: `Minimum notice period`,
  MINIMUM_NOTICE_PERIOD_TOOLTIP: `This setting determines the minimum amount of time required before a meeting can be scheduled. It helps ensure that you have enough notice to prepare for upcoming meetings.`,
  OPTION_DAYS_INTO_THE_FUTURE: `Days into the future`,
  OPTION_DATE_RANGE: `Within a date range`,
  OPTION_INDEFINITELY: `Indefinitely into the future`,
});

export const EditBookingPageInviteeStep = Object.freeze({
  QUESTIONS: `Booking form questions`,
  QUESTIONS_DESCRIPTION: `Enhance the booking form with invitation questions`,
  NEW_QUESTION: `New question`,
  EDIT_QUESTION: `Edit question`,
  PERMISSIONS: `Invitee Permissions`,
  PERMISSIONS_DESCRIPTION: `Define the actions available to your invitees`,
  OPTION_INVITE_OTHERS: `Invitees can invite others`,
  OPTION_INVITE_OTHERS_TOOLTIP: `Enabling this feature will display a "Invite Others" field on the Booking Page letting the invitee add other people to the participant list.`,
  OPTION_ENTER_NOTE: `Invitees can enter a note for all invitees`,
  OPTION_ENTER_NOTE_TOOLTIP: `Enabling this feature will display a "Note for Attendees" field, letting the invitee enter in a note to be displayed on the booked calendar appointment for all participants to view.`,
});

export const EditBookingPageAfterStep = Object.freeze({
  CONFIRMATION_PAGE: `Confirmation page`,
  OPTION_DISAPLY_CONFIRMATION_PAGE: `Display SUMO confirmation page`,
  OPTION_REDIRECT_TO_EXTERNAL_PAGE: `Redirect to specific external page`,
  EXTERNAL_LINK_PLACEHOLDER: `External redirect URL`,
  AVAILABLE_ACTIONS: `Available actions`,
  AVAILABLE_ACTIONS_DESCRIPTION: `Accessible actions for invitees from this confirmation page.`,
  OPTION_RESCHEDULE: `Reschedule`,
  OPTION_CANCEL: `Cancel meeting`,
  OPTION_BOOK_ANOTHER: `Book another meeting`,
  CUSTOM_LINKS: `Additional customer links`,
  CUSTOM_LINKS_DESCRIPTION: `Add custom links on the confirmation page, such as a survey or additional resource.`,
  ADD_CUSTOM_LINK: `Add custom link`,
  CHANGE_CUSTOM_LINK: `Change custom link`,
  LINK_URL: `Link URL`,
  CUSTOM_LINK_LABEL: `Custom link label`,
  CUSTOM_LINK_LABEL_PLACEHOLDER: `Meeting agreement`,
  CANCELATION_POLICY: `Cancelation Policy`,
  CANCELATION_POLICY_DESCRIPTION: `Provide details about your cancellation policy to clarify consequences for changes.`,
  CANCELATION_POLICY_PLACEHOLDER: `Cancelation policy text`,
});

export const BookingPageHowStep = Object.freeze({
  BRANDING_TITLE: `Booking page customization`,
  BRANDING_DESCRIPTION_BOOKING_PAGE: `Customize the appearance, styles, and labels for this booking page.`,
  BRANDING_DESCRIPTION_BOOKING_TEMPLATE: `Customize the appearance, styles, and labels for this booking template.`,
  CALENDAR_TITLE: `Calendar settings`,
  CALENDAR_DESCRIPTION: `Customize the calendar, date, and time zone display format.`,
});

export const BookingPageBrandingModal = Object.freeze({
  TITLE: `Booking page customization`,
  DESCRIPTION_BOOKING_PAGE: `This booking page uses the workspace's default branding settings, but you can customize them below.`,
  DESCRIPTION_BOOKING_TEMPLATE: `This booking template uses the workspace's default branding settings, but you can customize them below.`,
  LOGO: `Logo`,
  BACKGROUND: `Background`,
  BACKGROUND_OPTION_BLANK: `Blank`,
  BACKGROUND_OPTION_WALLPAPER: `Wallpaper`,
  BACKGROUND_OPTION_Color: `Color`,
  BACKGROUND_TYPE: `Background type`,
  BACKGROUND_TYPE_TOP: `Banner`,
  BACKGROUND_TYPE_FULL: `Full pagex`,
  BACKGROUND_TYPE_LEFT: `Left side`,
  BACKGROUND_TYPE_RIGHT: `Right side`,
  BACKGROUND_COLOR: `Background Color`,
  MAIN_COLOR: `Main Color`,
  FONT_COLOR: `Font Color`,
  CUSTOM_CSS: `Custom CSS`,
  CUSTOM_CSS_PLACEHOLDER: `You may add custom CSS code`,
  FOOTER_HTML: `Footer HTML`,
  FOOTER_HTML_PLACEHOLDER: `You may add footer HTML or simple text`,
  RESET_DEFAULT_STYLES: `Reset to default styles`,
  STEP_JOURNEY: `Journey`,
  STEP_BOOKING_PAGE: `Booking page`,
  STEP_BOOKING_FORM: `Booking form`,
  STEP_BOOKED: `Booked`,
  STEP_RESCHEDULED: `Rescheduled`,
  STEP_CANCELED: `Canceled`,
  STEP_CANCEL: `Cancel`,
});

export const BookingPageCalendarModal = Object.freeze({
  TITLE: `Calendar settings`,
  TIME_FORMAT: `Time Format`,
  HOUR_12: `12 hours`,
  HOUR_12_EXAMPLE: `1:00 pm`,
  HOUR_24: `24 hours`,
  HOUR_24_EXAMPLE: `13:00`,
  START_TIME_INTERVALS: `Time Intervals`,
  START_TIME_INTERVALS_DESCRIPTION: `Select the interval duration you want to display when scheduling an appointment.`,
  START_TIME_INTERVALS_TOOLTIP: `Choose the duration of intervals you wish to show when scheduling an appointment. By default, we have 15 minutes selected to maximize slot availability. This option will display available slots by this interval.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Start at the top of interval`,
  TOP_OF_THE_INTERVAL_DESCRIPTION: `Enable this to start all appointments at the top of each interval (e.g., 8am, 9am)`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Enable "Top of the Interval" if you require all appointments to always start at the top of the interval regardless of Booking Template duration. For example, slot durations would be available at 8:00am, 9:00am, 10:00am, etc. NOTE: Enabling this feature will not allow user to maximize availability.`,
  TIME_ZONES: `Time Zones`,
  TIME_ZONES_TOOLTIP: `The following Time Zones will be available on the customer facing Booking Page. If you only choose one time zone, then it will be fixed, so the time zone field will be grayed out and the user will be unable to change it.`,
  TIME_ZONE_DETECT: `Automatically detect and display time slots in the invitee's time zone.`,
  TIME_ZONE_DISPLAY: `Only display the following time-zones (ideal for in-person events)`,
  DEFAULT_TIME_ZONE: `Default Time Zone`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Bottom Left`,
  BOTTOM_RIGHT: `Bottom Right`,
  BUTTON_POPUP_TITLE: `Button`,
  BUTTON_POPUP_LABEL: `Schedule a meeting`,
  COPY_CODE_EMBED_DESCRIPTION: `Embed your SUMO widget into your website by pasting the HTML code at the desired location.`,
  COPY_CODE_LINK_DESCRIPTION: `Display a floating booking button on the pages you desire by placing this code in your HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Display your booking link anywhere by placing this HTML code in your pages, email signatures, and more.`,
  COPY_CODE_TITLE: `Copy Code`,
  CUSTOMIZE_DESCRIPTION: `Customize the look to fit seamlessly into your website.`,
  CUSTOMIZE_TITLE: `Customize`,
  EMBED_DESCRIPTION: `Display your SUMO1 booking page in an iFrame on your website.`,
  EMBED_TITLE: `Embed`,
  FLOATING_BUTTON_DESCRIPTION: `Floating Button`,
  HIDE_PAGE_DETAILS: `Hide Page Details`,
  HIDE_COOKIE_BANNER: `Hide Cookie Banner`,
  LABEL: `Label`,
  LINK_DESCRIPTION: `Add a text link to your site that will launch your SUMO1 booking page in a popup.`,
  LINK_TITLE: `Text Link`,
  POSITION: `Position`,
  POPUP_DESCRIPTION: `Display your SUMO1 booking page in a popup when a button is clicked.`,
  POPUP_TITLE: `Popup`,
  TITLE: `How will you add SUMO to your website?`,
  TOP_LEFT: `Top Left`,
  TOP_RIGHT: `Top Right`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirm Override`,
  DESCRIPTION: `When overriding the settings of a Booking Template, you are technically no longer using this Booking Template, and are instead creating a Custom Booking Page, which requires a name.`,
});
