import { useSelector } from 'react-redux';
import { usersSelectors } from '../../../store/users';
import { useDispatch } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { UserAdminDataFullRecord } from '../../../store/users/types';
import { workspacesSelectors } from '../../../store/workspaces';
import { workspacesActions } from '../../../store/workspaces/actions';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { userSettingsSelectors } from '../../../store/userSettings';
import { SumoMultiSelect } from '../../common';
import { HostCard } from '../../common/hostCard/HostCard';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import labels from './labels';

export const WorkspaceWhoStep = () => {
  const dispatch = useDispatch();
  const adminUsers = useSelector(usersSelectors.selectAdminUsers);
  const standardUsers = useSelector(usersSelectors.selectStandardUsers);
  const adminList = useSelector(workspacesSelectors.selectAdminList);
  const userList = useSelector(workspacesSelectors.selectUserList);
  const isPhoneRequired = useSelector(workspacesSelectors.selectIsPhoneRequired);
  const isExistingWorkspace = useSelector(workspacesSelectors.selectIsExistingWorkspace);
  const id = useSelector(workspacesSelectors.selectId);
  const superAdminUsers = useSelector(usersSelectors.selectSuperAdmins);
  const currentUser = useSelector(usersSelectors.selectCurrentUser);
  const isWorkspacesReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyWorkspace);

  const handleAdminListChange = (e: MultiSelectChangeEvent) => {
    dispatch(workspacesActions.updateWorkspace({ adminList: e.value }));
  };

  const handleUserListChange = (e: MultiSelectChangeEvent) => {
    dispatch(workspacesActions.updateWorkspace({ userList: e.value }));
  };

  const handleSelectAllAdmins = (checked: boolean) => {
    dispatch(workspacesActions.updateWorkspace({ adminList: checked ? adminUsers : adminList.filter(admin => !isRemovable(admin)) }));
  };

  const handleSelectAllUsers = (checked: boolean) => {
    dispatch(workspacesActions.updateWorkspace({ userList: checked ? standardUsers : userList.filter(user => !isRemovable(user)) }));
  };

  const removeAdminListChange = (value: string) => {
    dispatch(workspacesActions.updateWorkspace({ adminList: adminList.filter((record) => record.email !== value) }));
  };

  const removeUserListChange = (value: string) => {
    dispatch(workspacesActions.updateWorkspace({ userList: userList.filter((record) => record.email !== value) }));
  };

  const isRemovable = (value: UserAdminDataFullRecord) => {
    const hasMultipleWorkspaces = value.workspaceIds && value.workspaceIds?.length > 1;
    const isAssignedToCurrentWorkspace = value.workspaceIds?.includes(id);
    const isSuperAdmin = superAdminUsers.some((superAdmin) => superAdmin.roleId === value.roleId);
    const isCurrentUser = value.userId === currentUser.userId;

    return (
      !isSuperAdmin &&
      !isCurrentUser &&
      (!isExistingWorkspace || hasMultipleWorkspaces || !isAssignedToCurrentWorkspace)
    );
  };

  return (
    <div className="flex flex-column gap-32px">
      <div className="flex flex-column gap-16px">
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.adminTitle}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.adminDesc}</div>
        </div>
        <div className="flex flex-wrap -mx-20px -my-6px">
          <div className="w-12 lg:w-6 px-20px py-6px">
            <SumoMultiSelect
              className="pr-24px"
              onSelectAllChange={handleSelectAllAdmins}
              options={adminUsers}
              optionValue="email"
              value={adminList}
              onChange={handleAdminListChange}
              filterPlaceholder={labels.searchAddAdmins}
              disabled={isWorkspacesReadOnly}
              templateType="user"
              optionDisabled={(user: UserAdminDataFullRecord) => !isRemovable(user)}
            />
          </div>
          <div className="w-12 lg:w-6 px-20px py-6px"></div>
          {adminList.map((user) => (
            <div key={user.email} className="w-12 lg:w-6 px-20px py-6px">
              <HostCard
                host={{
                  label: user.fullName || '',
                  value: user.email,
                  email: user.email,
                  avatar: user.avatar,
                  isTeam: false,
                  hasPhoneNumber: Boolean(user.phoneNumber),
                  integrationTypes: user.integrationTypes || [],
                }}
                onRemove={isRemovable(user) ? removeAdminListChange : undefined}
              />
            </div>
          ))}
        </div>
        {!isExistingWorkspace && <div className="text-body-s-reg text-heavy-60">{labels.superAdminsDescription}</div>}
      </div>

      <div className="border-top-1 border-heavy-20" />

      <div className="flex flex-column gap-16px">
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.usersTitle}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.usersDesc}</div>
        </div>
        <div className="flex flex-wrap -mx-20px -my-6px">
          <div className="w-12 lg:w-6 px-20px py-6px">
            <SumoMultiSelect
              className="pr-24px"
              onSelectAllChange={handleSelectAllUsers}
              options={standardUsers}
              optionValue="email"
              value={userList}
              onChange={handleUserListChange}
              filterPlaceholder={labels.searchAddUsers}
              disabled={isWorkspacesReadOnly}
              templateType="user"
              optionDisabled={(user: UserAdminDataFullRecord) => !isRemovable(user)}
            />
          </div>
          <div className="w-6 px-20px py-6px"></div>
          {userList.map((user) => (
            <div key={user.email} className="w-6 px-20px py-6px">
              <HostCard
                host={{
                  label: user.fullName || '',
                  value: user.email,
                  email: user.email,
                  avatar: user.avatar,
                  isTeam: false,
                  hasPhoneNumber: Boolean(user.phoneNumber),
                  integrationTypes: user.integrationTypes || [],
                }}
                onRemove={isRemovable(user) ? removeUserListChange : undefined}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex-left-center gap-6px bg-blue-soft border-radius-6px p-6px text-blue-main">
        <InformationCircleIcon className="icon-18px" />
        <div className="text-label-xs-reg">
          {labels.notesPart1}
          <Link to={Path.Users} className="underline">
            {labels.notesPart2}
          </Link>
          {labels.notesPart3}
        </div>
      </div>

      <div className="border-top-1 border-heavy-20" />

      <div className="flex flex-column gap-16px">
        <div className="flex flex-column gap-4px">
          <div className="text-title-xs-med text-heavy-100">{labels.inviteSettingsTitle}</div>
          <div className="text-body-s-reg text-heavy-60">{labels.phoneNumberDesc}</div>
        </div>
        <div className="flex-left-center gap-10px">
          <InputSwitch
            checked={isPhoneRequired}
            onChange={() => dispatch(workspacesActions.updateWorkspace({ isPhoneRequired: !isPhoneRequired }))}
            disabled={isWorkspacesReadOnly}
          />
          <div
            className="text-title-xs-med text-heavy-100 hover-text-heavy-80 cursor-pointer"
            onClick={() => dispatch(workspacesActions.updateWorkspace({ isPhoneRequired: !isPhoneRequired }))}
          >
            {labels.phoneNumberTitle}
          </div>
        </div>
      </div>
    </div>
  );
};
