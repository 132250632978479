import { t } from '../../../i18n/i18n';

export default {
  active: t('EditRole:ACTIVE'),
  cancel: t('Common:CANCEL'),
  primaryTitle: t('EditRole:PRIMARY_TITLE'),
  roleName: t('EditRole:ROLE_NAME'),
  save: t('Common:SAVE'),
  editTitle: t('EditRole:EDIT_TITLE'),
  newTitle: t('EditRole:NEW_TITLE'),
};
