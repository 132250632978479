import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/rootStore';
import { Coordinates, getCoordinatesByTimeZone, locationsActions, locationsSelectors } from '../../store/locations';
import { Button } from 'primereact/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import labels from './labels';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { MAX_LENGTH_NAME } from '../../types/constants';
import { InputSwitch } from 'primereact/inputswitch';
import { Address } from '../../API';
import { ProgressSpinner } from 'primereact/progressspinner';
import { EditLocationNoMap } from '../../components/locations';

export const EditLocation = () => {
  const { locationId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(locationsSelectors.selectIsFetching);
  const location = useSelector(locationsSelectors.selectLocation);

  const isReadOnly = useSelector(locationsSelectors.selectLocationReadOnly);

  const [userLocation, setUserLocation] = useState<Coordinates | null>(null);
  const [initialAddress, setInitialAddress] = useState<Address | null>(null);
  const [isValid, setIsValid] = useState(true);

  const handleBack = () => {
    navigationService.navigateTo(Path.Locations);
  };

  useEffect(() => {
    if (locationId) {
      dispatch(locationsActions.getLocationRequest(locationId));
    }
  }, []);

  useEffect(() => {
    if (initialAddress === null && location.address) {
      setInitialAddress(location.address);
    }
  }, [location.address]);

  useEffect(() => {
    if (isValid === false) {
      setIsValid(handleValidate());
    }
  }, [location]);

  const onMapLoad = () => {
    if (locationId === 'new') {
      const geocoder = new google.maps.Geocoder();
      getCoordinatesByTimeZone(geocoder, setUserLocation);
    }
  };

  const handleValidate = () => {
    return (
      !!location.name?.trim() &&
      !!location.address?.name?.trim() &&
      !!location.address.country?.trim() &&
      !!location.address.city?.trim() &&
      !!location.address.state?.trim() &&
      !!location.address.zip?.trim()
    );
  };
  const handleSave = () => {
    const isValid = handleValidate();
    setIsValid(isValid);
    if (!isValid) return;
    dispatch(locationsActions.upsertLocationRequest());
  };

  const handleNameChange = (name: string) => {
    dispatch(locationsActions.updateLocation({ name }));
  };

  const handleActiveChange = (active: boolean) => {
    dispatch(locationsActions.updateLocation({ active }));
  };

  return (
    <>
      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column p-24px">
        <div className="flex-left-center gap-8px">
          <div className="text-title-xl-med">{labels.addLocation}</div>
          {isFetching && <ProgressSpinner className="w-32px h-32px mx-0 -my-6px" strokeWidth="5" />}
        </div>

        <div className="sumo-card p-20px pt-24px mt-16px">
          <div className="grid -mb-2">
            <div className="col-12 lg:col-6 flex flex-column gap-8px">
              <div className="text-title-xs-med">{labels.name}</div>
              <InputText
                value={location.name || ''}
                onChange={(e) => handleNameChange(e.target.value)}
                placeholder={labels.namePlaceholder}
                maxLength={MAX_LENGTH_NAME}
                className={`${!isValid && !location.name ? 'p-invalid' : ''}`}
                disabled={isReadOnly}
              />
            </div>
            <div className="col-12 lg:col-6 flex-bottom">
              <div className="text-title-xs-med flex-left-center gap-12px h-fit py-10px">
                <InputSwitch
                  inputId="active"
                  checked={location.active || false}
                  className="size-small"
                  onChange={(e) => handleActiveChange(!!e.value)}
                  disabled={isReadOnly}
                />
                <label htmlFor="active" className="cursor-pointer">
                  {labels.active}
                </label>
              </div>
            </div>
          </div>
          <EditLocationNoMap location={location} isReadOnly={isReadOnly} isValid={isValid} />

          <div className="border-top-1 border-heavy-20 mt-16px flex-left-center gap-6px pt-16px -mb-4px -mx-22px px-22px">
            <Button
              className="button-xl min-w-120px"
              label={labels.save}
              onClick={handleSave}
              disabled={!isValid || isReadOnly || isFetching}
            />
            <Button className="button-xl" label={labels.cancel} text onClick={handleBack} />
          </div>
        </div>
      </div>
    </>
  );
};
