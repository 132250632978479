import { store } from '../store/rootStore';
import { userSettingsActions } from '../store/userSettings';

class TimeHandlerService {
  private timeoutHandler: NodeJS.Timeout | null = null;

  startTimeTrack() {
    const handleCurrentTime = () => {
      const date = new Date();
      const ss = date.getUTCSeconds();
      store.dispatch(userSettingsActions.setUTC(date.getTime()));
      this.timeoutHandler = setTimeout(handleCurrentTime, (60 - ss) * 1000);
    };
    handleCurrentTime();
  }

  stopTimeTrack() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
  }
}

export const timeHandlerService = new TimeHandlerService();
