export const OPSConsoleUsers = Object.freeze({
  ACCOUNT_ID: `ID da Conta`,
  EXPORT: `Exportar`,
  SEARCH_PLACEHOLDER: `Pesquisar por nome ou e-mail`,
  SUBHEADER_TEXT: `Usuários mais ativos`,
  VIEW_AS_USER: `Visualizar como usuário`,
  REGISTRATION_DATE: `Data de Registro`,
  RENEWAL_DATE: `Data de Renovação`,
  USER_INFO_TITLE: `Informações do Usuário`,
  LICENSE_STATUS_TITLE: `Status da Licença`,
  CUSTOM_ROLE: `Função Personalizada`,
});

export const OPSConsoleUsersToastsNotifications = Object.freeze({
  ACTIVATE_USER_SUCCESS_MESSAGE: `Solicitação de ativação de usuário bem-sucedida`,
  DEACTIVATE_USER_FAIL_MESSAGE: `Falha na solicitação de desativação/ativação de usuário`,
  DEACTIVATE_USER_SUCCESS_MESSAGE: `Solicitação de desativação de usuário bem-sucedida`,
  DELETE_USER_FAIL_MESSAGE: `Falha na solicitação de exclusão de usuário`,
  DELETE_USER_SUCCESS_MESSAGE: `Solicitação de exclusão de usuário bem-sucedida`,
  GET_USER_DETAILS_FAIL_MESSAGE: `Falha na solicitação de detalhes do usuário`,
  GET_USERS_FAIL_MESSAGE: `Falha na solicitação de usuários`,
});

export const ViewAsUser = Object.freeze({
  BACK_TO_OPS_CONSOLE: `Voltar para a Console OPS`,
  MODAL_DESCRIPTION_PART1: `O modo de "Visualização como usuário" está ativo.`,
  MODAL_DESCRIPTION_PART2: `Para continuar usando a Console OPS, conclua a sessão "Visualizar como usuário" selecionando uma das seguintes opções:`,
  MODAL_DESCRIPTION_PART3: `Clique no botão "Voltar para a Console OPS".`,
  MODAL_DESCRIPTION_PART4: `Pressione a opção "Redefinir" aqui.`,
  VIEW_AS_USER_MODE: `Modo Visualizar como Usuário`,
  VIEWING_SUMO1_TITLE: `Visualizando SUMO1 como usuário.`,
});
