import { t } from '../../../i18n/i18n';

export default {
  adminTitle: t('WorkspaceWhoStep:ADMINS_TITLE'),
  adminDesc: t('WorkspaceWhoStep:ADMIN_DESCRIPTION'),
  usersTitle: t('WorkspaceWhoStep:USERS_TITLE'),
  usersDesc: t('WorkspaceWhoStep:USERS_DESCRIPTION'),
  inviteSettingsTitle: t('WorkspaceWhoStep:INVITE_SETTINGS_TITLE'),
  phoneNumberTitle: t('WorkspaceWhoStep:PHONE_NUMBER_TITLE'),
  phoneNumberDesc: t('WorkspaceWhoStep:PHONE_NUMBER_DESCRIPTION'),
  notesPart1: t('WorkspaceWhoStep:NOTES_PART_1'),
  notesPart2: t('WorkspaceWhoStep:NOTES_PART_2'),
  notesPart3: t('WorkspaceWhoStep:NOTES_PART_3'),
  searchAddAdmins: t('WorkspaceWhoStep:SEARCH_ADD_ADMINS'),
  searchAddUsers: t('WorkspaceWhoStep:SEARCH_ADD_USERS'),
  superAdminsDescription: t('WorkspaceWhoStep:SUPER_ADMINS_DESCRIPTION'),
};
