import { useRef, useState } from 'react';
import { MessageTemplateInput, TimeInput, TimeUnit } from '../../../../API';
import {
  DEFAULT_SMS_TIMING,
  MAX_SMS_REMINDERS_COUNT,
  MERGE_FIELD_OPTIONS,
  NotificationTypes,
  SCHEDULE_BUFFER_OPTIONS,
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  getDefaultBodyByNotificationType,
  getDefaultSubjectByNotificationType,
} from '../../../../store/bookingTemplates';
import { Nullable } from 'primereact/ts-helpers';
import labels from './labels';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { bookingPageSelectors, bookingPagesActions } from '../../../../store/bookingPages';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_NAME } from '../../../../types/constants';
import { Dialog } from 'primereact/dialog';
import { InformationCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ScrollPanel } from 'primereact/scrollpanel';

type NotificationItemProps = {
  type: NotificationTypes;
  value: MessageTemplateInput;
  onSave: (value: MessageTemplateInput) => void;
  disabled?: boolean;
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
};

export const NotificationItem = ({ type, value, onSave, disabled }: NotificationItemProps) => {
  const subjectRef = useRef<HTMLInputElement>(null);
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const [notification, setNotification] = useState(value);
  const [subjectValue, setSubjectValue] = useState(value.subject);
  const [bodyValue, setBodyValue] = useState(value.body);
  const [cursorPosition, setCursorPosition] = useState<Nullable<number>>(undefined);
  const [subjectOrBody, setSubjectOrBody] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const getIsSubjectValid = () => type === NotificationTypes.SMS || Boolean(notification.subject);

  const getIsBodyValid = () => Boolean(notification.body);

  const getHasTiming = () =>
    type === NotificationTypes.REMINDER || type === NotificationTypes.FOLLOW_UP || type === NotificationTypes.SMS;

  const getHasAddTimingButton = () =>
    !disabled && type !== NotificationTypes.FOLLOW_UP && (notification.timing?.length || 0) < 3;

  const getTimerDuplicateIndexes = () =>
    notification.timing?.reduce(
      (result, timer, index, timers) =>
        timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
      [] as number[]
    ) || [];

  const getSaveDisabled = () => !getIsSubjectValid() || !getIsBodyValid() || Boolean(getTimerDuplicateIndexes().length);

  const getTimers = () =>
    notification.timing?.map((time) => {
      const timeUnit = !time || time % 1440 === 0 ? TimeUnit.DAY : time % 60 === 0 ? TimeUnit.HOUR : TimeUnit.MINUTE;
      const count = !time ? 0 : timeUnit === TimeUnit.DAY ? time / 1440 : timeUnit === TimeUnit.HOUR ? time / 60 : time;
      return { count, timeUnit } as TimeInput;
    }) || [];

  const convertTimeInputToTiming = (timeInput: TimeInput) =>
    !timeInput.count
      ? 0
      : timeInput.count * (timeInput.timeUnit === TimeUnit.DAY ? 1440 : timeInput.timeUnit === TimeUnit.HOUR ? 60 : 1);

  const handleClose = () => {
    setNotification(value);
    setSubjectValue(value.subject);
    setBodyValue(value.body);
    setIsModalOpened(false);
  };

  const handleSave = () => {
    onSave(notification);
    setIsModalOpened(false);
  };

  const handleEnabledChange = (value: boolean) => {
    const updatedNotification = { ...notification, enabled: value };
    setNotification(updatedNotification);
    onSave(updatedNotification);
  };

  const handleSubjectChange = (value: string) => {
    setSubjectValue(value);
  };

  const handleSubjectBlur = () => {
    setNotification({ ...notification, subject: subjectValue });
    setCursorPosition(subjectRef.current?.selectionStart);
    setSubjectOrBody(true);
  };

  const handleBodyChange = (value: string) => {
    setBodyValue(value);
  };

  const handleBodyBlur = () => {
    setNotification({ ...notification, body: bodyValue });
    setCursorPosition(bodyRef.current?.selectionStart);
    setSubjectOrBody(false);
  };

  const handleResetSubject = () => {
    const subject = getDefaultSubjectByNotificationType(type);
    setSubjectValue(subject);
    setNotification({ ...notification, subject });
  };

  const handleResetBody = () => {
    const body = getDefaultBodyByNotificationType(type);
    setBodyValue(body);
    setNotification({ ...notification, body });
  };

  const handleTimingValueChange = (value: Nullable<number>, index: number) => {
    setNotification({
      ...notification,
      timing: [
        ...(notification.timing || []).slice(0, index),
        convertTimeInputToTiming({ ...getTimers()[index], count: value }),
        ...(notification.timing || []).slice(index + 1),
      ],
    });
  };

  const handleTimeUnitChange = (value: TimeUnit, index: number) => {
    setNotification({
      ...notification,
      timing: [
        ...(notification.timing || []).slice(0, index),
        convertTimeInputToTiming({ ...getTimers()[index], timeUnit: value }),
        ...(notification.timing || []).slice(index + 1),
      ],
    });
  };

  const handleAddTiming = () => {
    if ((notification.timing || []).length >= MAX_SMS_REMINDERS_COUNT) {
      return;
    }
    setNotification({
      ...notification,
      timing: [...(notification.timing || []), DEFAULT_SMS_TIMING],
    });
  };

  const handleRemoveTiming = (index: number) => {
    setNotification({
      ...notification,
      timing: [...(notification.timing || []).slice(0, index), ...(notification.timing || []).slice(index + 1)],
    });
  };

  const addMergeVariable = (value: string) => {
    if (!cursorPosition && cursorPosition !== 0) {
      return;
    }

    const mergeValue = '<' + value + '>';
    const newCursorPosition = cursorPosition + mergeValue.length;

    if (subjectOrBody) {
      const subject = subjectValue?.substring(0, cursorPosition) + mergeValue + subjectValue?.substring(cursorPosition);
      setSubjectValue(subject);
      subjectRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
      setNotification({ ...notification, subject });
    } else {
      const body = bodyValue?.substring(0, cursorPosition) + mergeValue + bodyValue?.substring(cursorPosition);
      setBodyValue(body);
      bodyRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
      setNotification({ ...notification, body });
    }

    setCursorPosition(newCursorPosition);
  };

  const getTitle = () => {
    switch (type) {
      case NotificationTypes.CONFIRMATION:
        return labels.confirmationTitle;
      case NotificationTypes.RESCHEDULE:
        return labels.rescheduleTitle;
      case NotificationTypes.REMINDER:
        return labels.reminderTitle;
      case NotificationTypes.CANCELATION:
        return labels.cancelationTitle;
      case NotificationTypes.FOLLOW_UP:
        return labels.followUpTitle;
      case NotificationTypes.SMS:
        return labels.smsTitle;
    }
  };

  const getDescription = () => {
    switch (type) {
      case NotificationTypes.CONFIRMATION:
        return labels.confirmationDescription;
      case NotificationTypes.RESCHEDULE:
        return labels.rescheduleDescription;
      case NotificationTypes.REMINDER:
        return labels.reminderDescription;
      case NotificationTypes.CANCELATION:
        return labels.cancelationDescription;
      case NotificationTypes.FOLLOW_UP:
        return labels.followUpDescription;
      case NotificationTypes.SMS:
        return labels.smsDescription;
    }
  };

  const generateDescription = () => (
    <div className="flex flex-column gap-16px">
      <div className="text-body-s-reg text-heavy-60">{getDescription()}</div>
      {type === NotificationTypes.SMS && (
        <div className="flex-left-center gap-6px bg-blue-soft border-radius-6px p-6px text-blue-main">
          <InformationCircleIcon className="icon-18px" />
          <div className="text-label-xs-reg">
            {labels.smsLabelPart1} - {labels.smsLabelPart2}
          </div>
        </div>
      )}
    </div>
  );

  const generateTimers = () => (
    <>
      {getHasTiming() && (
        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{labels.timing}</div>
          {getTimers().map((time, index, { length }) => (
            <div key={index} className="flex-left-center gap-4px">
              <InputNumber
                className={getTimerDuplicateIndexes().includes(index) ? 'p-invalid' : ''}
                inputClassName="w-60px"
                showButtons
                min={1}
                max={999}
                value={time?.count}
                onValueChange={(e) => handleTimingValueChange(e.target.value, index)}
                disabled={disabled}
              ></InputNumber>
              <Dropdown
                className={`w-120px ${getTimerDuplicateIndexes().includes(index) ? 'p-invalid' : ''}`}
                options={SCHEDULE_BUFFER_OPTIONS}
                optionLabel="label"
                value={time?.timeUnit}
                onChange={(e) => handleTimeUnitChange(e.target.value, index)}
                disabled={disabled}
              />
              <div className="text-label-input-reg pl-4px">
                {type === NotificationTypes.FOLLOW_UP ? labels.afterEvent : labels.beforeEvent}
              </div>
              {!disabled && length > 1 && (
                <div className="action-button-sm" onClick={() => handleRemoveTiming(index)}>
                  <XMarkIcon className="icon-16px" />
                </div>
              )}
            </div>
          ))}
          {getHasAddTimingButton() && (
            <div className="add-button w-220px" onClick={handleAddTiming}>
              <PlusIcon className="icon-16px" />
            </div>
          )}
        </div>
      )}
    </>
  );

  const generateSubjectAndBody = () => (
    <div className="flex gap-24px">
      <div className="flex-1 flex-between flex-column gap-28px">
        {type !== NotificationTypes.SMS && (
          <div className="flex flex-column gap-10px">
            <div className="flex-between">
              <div className="text-title-xs-med">{labels.emailSubject}</div>
              <Button
                className="button-text-line -my-1px"
                text
                label={labels.reset}
                onClick={handleResetSubject}
                disabled={disabled}
              />
            </div>
            <InputText
              ref={subjectRef}
              type="text"
              value={subjectValue || ''}
              onChange={(e) => handleSubjectChange(e.target.value.trimStart())}
              onBlur={handleSubjectBlur}
              className={`${!getIsSubjectValid() && 'p-invalid'}`}
              maxLength={MAX_LENGTH_NAME}
              disabled={disabled}
            />
          </div>
        )}
        <div className="flex flex-column gap-10px">
          <div className="flex-between">
            <div className="text-title-xs-med">
              {type === NotificationTypes.SMS ? labels.smsBody : labels.emailBody}
            </div>
            <Button
              className="button-text-line -my-1px"
              text
              label={labels.reset}
              onClick={handleResetBody}
              disabled={disabled}
            />
          </div>
          <InputTextarea
            ref={bodyRef}
            autoResize
            rows={type === NotificationTypes.SMS ? 6 : 18}
            value={bodyValue || ''}
            onChange={(e) => handleBodyChange(e.target.value.trimStart())}
            onBlur={handleBodyBlur}
            className={`${!getIsBodyValid() && 'p-invalid'}`}
            maxLength={MAX_LENGTH_DESCRIPTION}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="flex flex-column gap-10px border-left-1 border-heavy-20 pl-12px h-440px">
        <div className="text-title-xs-med pl-12px">{labels.mergeVariables}</div>
        <ListBox
          className="flex flex-column overflow-hidden"
          onChange={(e) => addMergeVariable(e.value.name)}
          options={MERGE_FIELD_OPTIONS}
          optionLabel="name"
          filter
          disabled={disabled}
        />
      </div>
    </div>
  );

  return (
    <div className="flex gap-10px">
      <InputSwitch
        checked={Boolean(notification.enabled)}
        onChange={(e) => handleEnabledChange(Boolean(e.value))}
        disabled={disabled}
      />
      <div className="flex-1 pt-4px">
        <div className="text-title-xs-med text-heavy-100">{getTitle()}</div>
        <div className="text-body-s-reg text-heavy-60" style={{ whiteSpace: 'pre-wrap' }}>
          {getDescription()}
        </div>
        <Button
          text
          className="mt-12px button-text-line button-blue"
          label={labels.personalize}
          onClick={() => setIsModalOpened(true)}
          disabled={disabled}
        />
      </div>

      <Dialog
        visible={isModalOpened}
        className="w-900px max-w-900px h-680px max-h-screen overflow-hidden"
        contentClassName="p-0"
        onHide={handleClose}
        showHeader={false}
        resizable={false}
        focusOnShow={false}
      >
        <div className="flex flex-column h-full">
          <div className="flex-between-center py-14px pl-20px pr-10px border-bottom-1 border-heavy-20">
            <div className="text-title-lg-med ">{`${labels.personalize}: ${getTitle()}`}</div>
            <div className="action-button" onClick={handleClose}>
              <XMarkIcon className="icon-20px" />
            </div>
          </div>

          <div className="flex-1 overflow-y-hidden">
            <ScrollPanel>
              <div className="flex flex-column gap-32px p-20px">
                {generateDescription()}
                {generateTimers()}
                {generateSubjectAndBody()}
              </div>
            </ScrollPanel>
          </div>

          <div className="flex-left-center gap-6px py-12px px-20px border-top-1 border-heavy-20">
            <Button
              className="button-xl min-w-120px"
              label={labels.save}
              onClick={handleSave}
              disabled={getSaveDisabled()}
            />
            <Button className="button-xl" text label={labels.cancel} onClick={handleClose} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
