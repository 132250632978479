import { t } from '../../../i18n/i18n';

export default {
  title: t('Common:CONFIRM_DELETE'),
  confirmDelete: t('BookingTemplates:DELETE_MODAL_CONFIRM_TEXT'),
  confirmMultipleDelete: t('BookingTemplates:DELETE_MULTIPLE_MODAL_CONFIRM_TEXT'),
  usedBookingPages: t('BookingTemplates:DELETE_MODAL_USED_PAGES_TEXT'),
  cancel: t('Common:CANCEL'),
  delete: t('Common:DELETE'),
};
