import { useDispatch, useSelector } from 'react-redux';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { Label } from '../../common';

export const PublicBookingPageHeader = () => {
  const dispatch = useDispatch();
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const pageTitle = useSelector(eventSelectors.selectPageTitle);
  const logo = useSelector(eventSelectors.selectLogo);
  const showBookedState = useSelector(eventSelectors.selectShowBookedState);
  const showJourneyState = useSelector(eventSelectors.selectShowJourneyState);
  const showCancelState = useSelector(eventSelectors.selectShowCancel);
  const isRescheduled = useSelector(eventSelectors.selectIsRescheduled);
  const isCanceled = useSelector(eventSelectors.selectEventIsCanceled);

  const handleEventNameLabelChange = (value: string) => {
    dispatch(
      eventActions.updatePreviewLabel(
        showBookedState
          ? isRescheduled
            ? { rescheduleTitle: value }
            : isCanceled
            ? { cancelTitle: value }
            : { bookTitle: value }
          : showCancelState
          ? { cancelLabel: value }
          : { eventNameLabel: value }
      )
    );
  };

  return (
    <div className="booking-header">
      <div className="booking-title">
        <Label
          textClassName="text-heading-lg-med"
          iconClassName="icon-28px-bold ml-8px"
          editable={isEditable && !showJourneyState}
          value={pageTitle}
          onChange={handleEventNameLabelChange}
        />
      </div>
      {logo && <img src={logo} className="booking-logo" />}
    </div>
  );
};
