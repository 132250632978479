import { InformationCircleIcon } from '@heroicons/react/24/outline';

type InformationBlockProps = {
  text: string;
};
export const InformationBlock = ({ text }: InformationBlockProps) => {
  return (
    <div className="flex-left-center gap-10px text-saffron-dark border-radius-6px py-10px px-8px bg-heavy-1">
      <InformationCircleIcon className="icon-24px" />
      <div className="text-label-s-med">{text}</div>
    </div>
  );
};
