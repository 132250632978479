import { useDispatch, useSelector } from 'react-redux';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { AppDispatch } from '../../../store/rootStore';
import labels from './labels';
import { DescriptionBookingPageInput } from '../../../API';
import { getTimeUnitLabel } from '../../../services/utils';
import { ScrollPanel } from 'primereact/scrollpanel';

export const PublicGroupBookingPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const groupBookingPage = useSelector(eventSelectors.selectGroupBookingPage);
  const bookingPages = useSelector(eventSelectors.selectGroupBookingPages);
  const bookingPageId = useSelector(eventSelectors.selectBookingPageId);

  const handleCardClick = (id: string) => {
    dispatch(eventActions.setBookingPageId(id || ''));
    if (id !== bookingPageId) {
      dispatch(eventActions.resetBookingPageSteps());
    }
    dispatch(eventActions.getAgendaRequest());
  };

  const generateCard = (groupDescription: DescriptionBookingPageInput | null) => {
    const bookingPageId = groupDescription?.bookingPageId;
    const bookingPage = bookingPages?.find((bookingPage) => bookingPage.id === bookingPageId);

    if (!bookingPage) {
      return null;
    }

    return (
      <div key={bookingPage.id} className="w-12 md:w-6 py-4px px-20px">
        <div
          className="sumo-card action-card border-none px-20px py-16px h-full"
          onClick={() => handleCardClick(bookingPage.id)}
        >
          <div className="flex gap-8px">
            <div
              className="w-24px h-24px border-radius-4px"
              style={{ backgroundColor: bookingPage.what?.color || '' }}
            />
            <div className="flex flex-column gap-4px text-body-s-reg text-heavy-60">
              <div className="text-title-s-med text-heavy-100">{bookingPage.what?.customName}</div>
              <div>{`${bookingPage.when?.duration?.count} ${getTimeUnitLabel(
                bookingPage.when?.duration?.timeUnit
              )}`}</div>
              <div className="white-space-pre-line">{groupDescription?.description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 overflow-hidden">
      <ScrollPanel>
        <div className="flex flex-column gap-16px px-28px py-20px">
          <div className="text-title-lg-med text-heavy-80">{labels.title}</div>
          <div className="flex flex-wrap -my-4px -mx-20px">
            {groupBookingPage?.bookingPages?.map((description) => generateCard(description))}
          </div>
        </div>
      </ScrollPanel>
    </div>
  );
};
