export const Availability = Object.freeze({
  ACTIVE_ON: `Actif le`,
  BOOKING_PAGE: `Page de Réservation`,
  BOOKING_PAGES: `Pages de Réservation`,
  CLONE_SCHEDULE_TITLE: `Nom de l'horaire cloné`,
  DATE_OVERRIDE_BUTTON: `Nouvelle Annulation`,
  DATE_OVERRIDE_DESCRIPTION: `Ajouter des exceptions à cet horaire ci-dessous :`,
  DATE_OVERRIDE_MODAL_HOURS: `Heures de disponibilité`,
  DATE_OVERRIDE_MODAL_PROMPT: `Sélectionnez la date (ou les dates) :`,
  DATE_OVERRIDE_MODAL_UNAVAILABLE: `Cliquez sur Enregistrer pour bloquer cette ou ces dates comme non disponibles.`,
  DATE_OVERRIDE_TITLE: `Ajouter une annulation de date`,
  DATES_OVERLAPPING_TEXT: `Certaines dates ont déjà été annulées.`,
  DEFAULT_AVAILABILITY_NAME: `Heures de travail`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  DELETE_MESSAGE: `sera défini comme le nouvel horaire par défaut.`,
  DESCRIPTION: `Choisissez un horaire ci-dessous pour le modifier ou créez-en un nouveau que vous pouvez appliquer à vos pages de réservation`,
  DIALOG_EDIT_NAME_TITLE: `Modifier le nom de l'horaire`,
  DIALOG_NEW_NAME_TITLE: `Nouveau nom de l'horaire`,
  DIALOG_PLACEHOLDER: `Heures de travail, Heures exclusives, etc...`,
  DIALOG_TITLE: `Nom de l'horaire`,
  DUPLICATE_NAME_MESSAGE: `Un horaire avec ce nom existe déjà`,
  EDIT_NAME: `Modifier le nom`,
  IS_DEFAULT: `Horaire par défaut`,
  NEW_SCHEDULE: `Nouvel Horaire`,
  SAVE_AVAILABILITY: `Enregistrer la disponibilité`,
  SET_AS_DEFAULT: `Définir comme par défaut`,
  SET_WEEKLY_HOURS: `Définissez vos heures hebdomadaires :`,
  TIME_INTERVAL_OVERLAPPING: `Les intervalles de temps ne peuvent pas se chevaucher.`,
  TIME_INVALID_TEXT: `Choisissez une heure de fin ultérieure à l'heure de début`,
  TIME_ZONE: `Fuseau horaire`,
  TITLE: `Disponibilité`,
  TOOLTIP: `Gérez votre disponibilité grâce à des horaires de travail`,
  FIRST_ADMIN_MESSAGE: `Le planning de "Disponibilité de l'équipe" sera appliqué à tous les utilisateurs nouvellement invités. Si, en tant qu'administrateur, vous souhaitez définir votre propre planning, cliquez sur "Nouveau planning" et créez votre propre planning.`,
  SCHEDULE: `Horaire`,
  OVERRIDES: `Remplacements`,
  AVAILABLE_HOURS: `Heures disponibles`,
  ACTIVE_ON_PLACEHOLDER: `Sélectionner les pages de réservation`,
  AVAILABLE_DESCRIPTION: `Heures supplémentaires pour cet horaire`,
  UNAVAILABLE_DESCRIPTION: `Dates qui ne seront pas disponibles`,
  DELETE_WARNING_MESSAGE: `Cette action est irréversible.`,
  DELETE_TITLE: `Supprimer l'horaire`,
  DATE_OVERRIDE_AVAILABLE_TITLE: `Ajouter des exceptions disponibles`,
  DATE_OVERRIDE_UNAVAILABLE_TITLE: `Ajouter des dates non disponibles`,
});

export const AvailabilityToastsNotifications = Object.freeze({
  CREATE_AVAILABILITY_ERROR_MESSAGE: `Échec de la demande de création de disponibilité`,
  CREATE_AVAILABILITY_SUCCESS_MESSAGE: `Disponibilité créée avec succès`,
  DELETE_AVAILABILITY_ERROR_MESSAGE: `Échec de la demande de suppression de disponibilité`,
  DELETE_AVAILABILITY_SUCCESS_MESSAGE: `Disponibilité supprimée avec succès`,
  GET_AVAILABILITY_ERROR_MESSAGE: `Échec de la demande de récupération de disponibilité`,
  SAVE_AVAILABILITY_ERROR_MESSAGE: `Échec de la demande d'enregistrement de disponibilité`,
  SAVE_AVAILABILITY_SUCCESS_MESSAGE: `Modifications enregistrées`,
  SET_DEFAULT_AVAILABILITY_ERROR_MESSAGE: `Échec de la demande de définition de la disponibilité par défaut`,
  SET_DEFAULT_AVAILABILITY_SUCCESS_MESSAGE: `Modifications enregistrées`,
});

export const AvailabilityWeekDays = Object.freeze({
  MONDAY_SHORT: 'Lun',
  TUESDAY_SHORT: 'Mar',
  WEDNESDAY_SHORT: 'Mer',
  THURSDAY_SHORT: 'Jeu',
  FRIDAY_SHORT: 'Ven',
  SATURDAY_SHORT: 'Sam',
  SUNDAY_SHORT: 'Dim',
});
