import { useState } from 'react';
import { Button } from 'primereact/button';
import { UsersTable } from '../../users/usersTable/UsersTable';
import { useSelector } from 'react-redux';
import { OPSConsoleUsersSelectors } from '../../../../store/opsConsole/users';
import labels from './labels';
import { orgsSelectors } from '../../../../store/opsConsole/orgs';

export const OrgDetailsUsers = () => {
  const [isShowLess, setIsShowLess] = useState(false);
  const usersNum = useSelector(OPSConsoleUsersSelectors.selectUsersNumber);
  const orgsIsFetching = useSelector(orgsSelectors.selectIsFetching);

  return (
    <>
      <div className="w-full text-lg py-2 px-3 border-round uppercase mt-4 mb-3 sumo-header-bg-dark">
        {labels.title}
        {orgsIsFetching && <i className="pi pi-spin pi-spinner" style={{ marginLeft: '10px' }} />}
      </div>
      <UsersTable isShowLess={isShowLess} isFromOrgDetails={true} />
      {usersNum > 3 && (
        <div className="flex justify-content-center pt-3">
          <Button
            label={isShowLess ? `${labels.viewAllPart1} ${usersNum - 3} ${labels.viewAllPart2}` : labels.viewLess}
            onClick={() => setIsShowLess((prev) => !prev)}
            outlined
            style={{ minWidth: '11rem' }}
          />
        </div>
      )}
    </>
  );
};
