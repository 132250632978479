export const ConnectIntegrationPage = Object.freeze({
  LEADS: `Leads`,
  CONTACTS: `Contacts`,
  CREATE: `Create`,
  BENEFITS_TITLE: `Benefits`,
  DISCONNECT_INTEGRATION: `Disconnect Integration`,
  DISCONNECT_GOOGLE_CALENDAR_DESCRIPTION: `Are you sure you would like to disconnect Google Calendar?`,
  DISCONNECT_GOOGLE_MEET_DESCRIPTION: `Are you sure you would like to disconnect Google Meet?
  
  This might impact the booking pages that were created earlier using this integration.`,
  DISCONNECT_MICROSOFT_CALENDAR_DESCRIPTION: `Are you sure you would like to disconnect Microsoft Office365?`,
  DISCONNECT_TEAMS_DESCRIPTION: `Are you sure you would like to disconnect Microsoft Teams?
  
  This might impact the booking pages that were created earlier using this integration.`,
  DISCONNECT_ZOOM_DESCRIPTION: `Are you sure you would like to disconnect Zoom Meeting?
  
  This might impact the booking pages that were created earlier using this integration.`,
  DISCONNECT_HUBSPOT_DESCRIPTION: `Are you sure you would like to disconnect HubSpot?`,
  DISCONNECT_SALESFORCE_DESCRIPTION: `Are you sure you would like to disconnect Salesforce?`,
  DISCONNECT_GOOGLE_ANALYTICS_DESCRIPTION: `Are you sure you would like to disconnect Google Analytics?`,
  GOOGLE_ANALYTICS_BENEFITS: `Gather insightful information about who visits your public booking pages. /pMeasure conversion rates and how many people book events. /pIntegrate your SUMO account with Google Analytics`,
  GOOGLE_ANALYTICS_REQUIREMENTS: `Google Analytics account`,
  GOOGLE_ANALYTICS_SETUP_DESC: `Enter a Universal Analytics tracking ID or a Google Analytics 4 measurement ID to connect SUMO with your property. If you enter both, we'll track conversions in both of your Google Analytics properties.`,
  GOOGLE_ANALYTICS_PLACEHOLDER_TRACKING_ID: `UA-12345678-9`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_TRACKING_ID: `Universal Analytics tracking ID`,
  GOOGLE_ANALYTICS_PLACEHOLDER_MEASUREMENT_ID: `G-1234567890`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_MEASUREMENT_ID: `Google Analytics 4 measurement ID`,
  GOOGLE_CALENDAR_BENEFITS: `Automatically book and reschedule meetings on your Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `You need to grant access to your Google Calendar.`,
  GOOGLE_MAPS_BENEFITS: `/pProvide to your customers map with your locations /pIntegrate your SUMO account with Google Maps`,
  GOOGLE_MAPS_REQUIREMENTS: `Google Maps Platform account`,
  GOOGLE_MEET_BENEFITS: `Automatically create Google Meet meetings at the time an event is scheduled. Instantly share unique conferencing details upon confirmation.`,
  GOOGLE_MEET_REQUIREMENTS: `Depending on your configuration, your Google Meet account administrator may be required to pre-approve SUMO1 in the Google Marketplace.`,
  HUBSPOT_BENEFITS: `Auto-create HubSpot Events and book with customers as HubSpot leads, contacts.`,
  HUBSPOT_REQUIREMENTS: `HubSpot account \n HubSpot admin privileges`,
  HUBSPOT_CREATE_EVENTS: `Create Hubspot Events`,
  HUBSPOT_BOOK_USERS: `Allow booking with Hubspot records from SUMO Inbox`,
  INBOX_BENEFITS: `The best SUMO1 Experience is using SUMO from within your enviroment, by installing Chrome Extension / Edge Add-on. Use Sumo in:\n\u2022 Google Gmail in browser\n\u2022 Microsoft Office Web in browser`,
  INBOX_REQUIREMENTS: `Either one of these must be connected to SUMO1:\n\u2022 Google Account\n\u2022 Microsoft Office365 Account`,
  INBOX_INSTALL_CHROME_EXTENSION: `Install Chrome Extension`,
  INBOX_INSTALL_EDGE_ADD_ON: `Install Edge Add-on`,
  MICROSOFT_CALENDAR_BENEFITS: `Automatically book and reschedule meetings on your Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `You need to grant access to your Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Automatically create Microsoft Teams meetings at the time an event is scheduled. Instantly share unique conferencing details upon confirmation.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Depending on your configuration, your Microsoft Teams account administrator may be required to pre-approve SUMO1 in the Microsoft Marketplace.`,
  NO_CANCEL: `No, cancel`,
  REQUIREMENTS_TITLE: `Requirements`,
  SALESFORCE_BENEFITS: `Auto-create Salesforce Events and book with customers as Salesforce leads, contacts, or person accounts.`, // Book with "SUMO for Salesforce" (CEP) users. This feature can aggregate availability of both "SUMO1" and "SUMO for Salesforce" users, only displaying slots when both are available.
  SALESFORCE_REQUIREMENTS: `Salesforce account \n Salesforce admin privileges`,
  SALESFORCE_CREATE_EVENTS: `Create Salesforce Events`,
  SALESFORCE_BOOK_USERS: `Allow booking with Salesforce records from SUMO Inbox`,
  YES_DISCONNECT: `Yes, disconnect`,
  ZOOM_BENEFITS: `Automatically create Zoom meetings at the time an event is scheduled. Instantly share unique conferencing details upon confirmation.`,
  ZOOM_REQUIREMENTS: `Depending on your configuration, your Zoom account administrator may be required to pre-approve SUMO1 in the Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Save more time by automating video conferencing, calendaring, and more.`,
  FILTER_ALL: `All`,
  FILTER_CALENDARS: `Calendars`,
  FILTER_VIDEO_CONFERENCE: `Video Conference`,
  FILTER_WORKSPACE_INTEGRATIONS: `Workspace Integrations`,
  FILTER_EXTENSIONS: `Extensions`,
  GOOGLE_ANALYTICS_DESC: `Track engagement with your booking pages`,
  GOOGLE_ANALYTICS_TITLE: `Google Analytics`,
  GOOGLE_CALENDAR_DESC: `Automatically book and reschedule meetings on your Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MAPS_DESC: `Add locations to the map to provide a better In-person meeting experience`,
  GOOGLE_MAPS_TITLE: `Google Maps`,
  GOOGLE_MEET_DESC: `Automatically add Google Meet details to your meetings.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  HUBSPOT_DESC: `Automatically add SUMO1 created meeting to HubSpot CRM`,
  HUBSPOT_TITLE: `HubSpot`,
  INBOX_TITLE: `SUMO Inbox`,
  INBOX_DESC: `Install the Chrome extension or Edge add-on to send invite links from Gmail or Outlook Web.`,
  MICROSOFT_CALENDAR_DESC: `Automatically book and reschedule meetings on your Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Automatically add Microsoft Teams details to your meetings.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `No Items found.`,
  REQUIREMENTS_TITLE: `Requirements`,
  SALESFORCE_DESC: `Book Salesforce users, CRM records and auto-add Salesforce meetings`,
  SALESFORCE_TITLE: `Salesforce`,
  TITLE: `Integration`,
  ZOOM_DESC: `Automatically add Zoom details in your SUMO meetings.`,
  ZOOM_TITLE: `Zoom Meeting`,
  FILL_ONE_FIELD: `Fill in at least one field`,
});

export const IntegrationToastsNotifications = Object.freeze({
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS_MESSAGE: `Workspace integration successfully connected`,
  CONNECT_WORKSPACE_INTEGRATION_ERROR_MESSAGE: `Workspace integration request fail`,
});
