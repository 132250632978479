import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../../../../store/authentication';
import { Button } from 'primereact/button';
import labels from './labels';

type ViewAsUserInfoModalProps = {
  visible: boolean;
  spinner: boolean;
};
export const ViewAsUserInfoModal = ({ visible, spinner }: ViewAsUserInfoModalProps) => {
  const dispatch = useDispatch();

  const handleResetViewAsUserMode = () => {
    dispatch(authenticationActions.resetViewAsUserRequest({ removeLicenseType: true }));
  };

  return (
    <Dialog
      visible={visible}
      onHide={handleResetViewAsUserMode}
      header={<div className="text-center text-3xl">{labels.viewAsUserMode}</div>}
      footer={
        <Button
          onClick={handleResetViewAsUserMode}
          outlined
          className="w-120px flex-center"
          severity="danger"
          disabled={spinner}
        >
          {spinner && <span className="pi pi-spinner pi-spin absolute" style={{ left: '20px' }} />}
          <span>{labels.reset}</span>
        </Button>
      }
      resizable={false}
      draggable={false}
      focusOnShow={false}
      closable={false}
      className="w-30rem"
    >
      <div>
        <p>{labels.modalDescPart1}</p>
        <p>{labels.modalDescPart2}</p>
        <ul>
          <li>{labels.modalDescPart3}</li>
          <li>{labels.modalDescPart4}</li>
        </ul>
      </div>
    </Dialog>
  );
};
