import { createSelector } from 'reselect';
import { State } from '../rootStore';

const globalState = (state: State) => state.global;

const selectIsFetching = createSelector(globalState, (global) => global.isFetching);

const selectIsError = createSelector(globalState, (global) => global.error);

const selectSearch = createSelector(globalState, (global) => global.search);
const selectSearchString = createSelector(selectSearch, (search) => search.searchString);
const selectLowercasedSearchString = createSelector(selectSearchString, (searchString) => searchString.trim().toLowerCase());
const selectSearchPaths = createSelector(selectSearch, (search) => search.paths);

const selectFullScreen = createSelector(globalState, (global) => global.fullScreen);

export const globalSelectors = {
  selectIsFetching,
  selectIsError,
  selectSearch,
  selectSearchString,
  selectLowercasedSearchString,
  selectSearchPaths,
  selectFullScreen,
};
