import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CodeResponse } from '@react-oauth/google';
import { IntegrationType } from '../../API';
import { MicrosoftCodeResponse, ThirdPartyType } from '../../store/authentication';
import { userSettingsActions, userSettingsSelectors } from '../../store/userSettings';
import { ConfirmationModal, Preloader } from '../../components/common';
import { GoogleAuthButton, MicrosoftAuthButton } from '../../components/public';
import labels from './labels';
import { CalendarGroup } from '../../components/calendarConnections';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ReactComponent as GoogleIcon } from '../../assets/icons/32-google.svg';
import { ReactComponent as MicrosoftIcon } from '../../assets/icons/32-microsoft.svg';

export const CalendarConnections = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const isGoogleCalendarConnected = useSelector(userSettingsSelectors.selectIsGoogleCalendarConnected);
  const isMicrosoftCalendarConnected = useSelector(userSettingsSelectors.selectIsMicrosoftCalendarConnected);
  const email = useSelector(userSettingsSelectors.selectConnectedEmail);

  const [currentCalendarType, setCurrentCalendarType] = useState<IntegrationType | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleGoogleAuthSuccess = (codeResponse: CodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        type: ThirdPartyType.GOOGLE,
        integrationType: IntegrationType.GOOGLE_CALENDAR,
        codeResponse,
        switchAccount: false,
        login: false,
        updateIntegration: true,
      })
    );
  };

  const handleMicrosoftAuthRedirect = (codeResponse: MicrosoftCodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        type: ThirdPartyType.MICROSOFT,
        integrationType: IntegrationType.MICROSOFT_CALENDAR,
        codeResponse,
        switchAccount: false,
        login: false,
        updateIntegration: true,
      })
    );
  };

  const disconnectCalendar = (calendarType: IntegrationType) => {
    setCurrentCalendarType(calendarType);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (currentCalendarType) {
      dispatch(userSettingsActions.removeIntegration(currentCalendarType));
      dispatch(userSettingsActions.saveUserSettingsRequest());
    }
    setIsConfirmModalOpen(false);
  };

  const calendarGroups: Array<{
    title: string;
    items: Array<{
      calendarIcon: ReactNode;
      calendarTitle: string;
      calendarDescription: string;
      isCalendarConnected: boolean;
      disconnectCalendar: () => void;
      AuthButton: React.ElementType;
      handleAuthSuccess?: (codeResponse: CodeResponse) => void;
      handleAuthRedirect?: (codeResponse: MicrosoftCodeResponse) => void;
    }>;
  }> = [
    {
      title: labels.google,
      items: [
        {
          calendarIcon: <GoogleIcon />,
          calendarTitle: labels.googleCalendarTitle,
          calendarDescription: labels.googleCalendarDescription,
          isCalendarConnected: isGoogleCalendarConnected,
          disconnectCalendar: () => disconnectCalendar(IntegrationType.GOOGLE_CALENDAR),
          AuthButton: GoogleAuthButton,
          handleAuthSuccess: handleGoogleAuthSuccess,
        },
      ],
    },
    {
      title: labels.microsoft,
      items: [
        {
          calendarIcon: <MicrosoftIcon />,
          calendarTitle: labels.microsoftCalendarTitle,
          calendarDescription: labels.microsoftCalendarDescription,
          isCalendarConnected: isMicrosoftCalendarConnected,
          disconnectCalendar: () => disconnectCalendar(IntegrationType.MICROSOFT_CALENDAR),
          AuthButton: MicrosoftAuthButton,
          handleAuthRedirect: handleMicrosoftAuthRedirect,
        },
      ],
    },
  ];

  return (
    <div className="sumo-card-bg flex flex-column">
      {isFetching && <Preloader />}

      <div className="text-title-xl-med mb-24px">{labels.title}</div>

      <div className="sumo-card border-none p-20px">
        <div className="text-title-s-med">{labels.selectCalendar}</div>
        <div className="text-body-s-reg text-heavy-60 mb-20px">{labels.description}</div>

        <div className="bg-blue-soft p-6px flex-left-center gap-6px border-radius-6px">
          <InformationCircleIcon className="icon-18px text-blue-main" />
          <div className="text-label-xs-reg text-blue-dark">{labels.calendarInfoMessage}</div>
        </div>

        {calendarGroups.map((group, index) => (
          <CalendarGroup key={index} title={group.title} items={group.items} email={email} />
        ))}
      </div>

      <ConfirmationModal
        visible={isConfirmModalOpen}
        title={labels.disconnectCalendar}
        description={labels.disconnectMessage}
        additionalText={[labels.disconnectMessageDescription]}
        confirmButtonLabel={labels.yesDisconnect}
        cancelButtonLabel={labels.noCancel}
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};
