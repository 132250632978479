import { createSelector } from 'reselect';
import { State } from '../rootStore';

const notificationState = (state: State) => state.notifications;

const selectToastNotificationsQueue = createSelector(
  notificationState,
  (notifications) => notifications.toastNotificationsQueue
);

export const notificationsSelectors = {
  selectToastNotificationsQueue,
};
