// import './App.css';
import { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsSelectors } from './store/notifications';
import { AdminRoute, OPSConsoleRoute, Path, PrivateRoute } from './routing';
import {
  Account,
  AccountDetails,
  Accounts,
  Availability,
  BookingPages,
  CalendarConnections,
  ConnectIntegrationPage,
  EditBookingPage,
  EditBookingTemplate,
  EditWorkspace,
  BookingTemplates,
  PublicBookingPage,
  PublicRoute,
  QuickSetup,
  Roles,
  BookedMeetings,
  Tests,
  ChargebeePoC,
  LicenseExpired,
  Workspaces,
  Users,
  Orgs,
  OrgDetails,
  StaffPage,
  StaffDetails,
  OPSConsoleUsers,
  UserDetails,
  ViewAsUser,
  Teams,
  Billing,
  Integration,
  SmartAlerts,
  EditSmartAlert,
  Locations,
  LocationsImport,
  EditLocation,
  Journeys,
  EditJourney,
  EditGroupBookingPage,
} from './pages';
import { ToastNotification, GlobalError } from './components/common';
import { MicrosoftAuthRedirect, GoogleAuthRedirect, Login, SignUp } from './components/public';
import { authenticationActions, authenticationSelectors } from './store/authentication';
import { navigationService } from './services/NavigationService';

export default function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const toastNotificationsQueue = useSelector(notificationsSelectors.selectToastNotificationsQueue);
  const isSumo1Admin = useSelector(authenticationSelectors.selectIsSumo1Admin);
  const isOpConsole = useSelector(authenticationSelectors.selectIsOpsConsole);

  // set up navigation service to use across application
  const navigate = useNavigate();
  useEffect(() => {
    navigationService.setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    const isOpConsoleNew = isSumo1Admin && location.pathname.startsWith(Path.OPSConsole);
    if (isOpConsoleNew !== isOpConsole) {
      // hide crispChat for OPS Console
      const crispChat = document.querySelector('.crisp-client');
      if (crispChat) {
        isOpConsoleNew ? crispChat.classList.add('hidden') : crispChat.classList.remove('hidden');
      }
      dispatch(authenticationActions.setIsOpsConsole(isOpConsoleNew));
    }
  }, [isSumo1Admin, location]);

  return (
    <>
      <Routes>
        <Route path={Path.OPSConsole} element={<OPSConsoleRoute />}>
          <Route path={Path.OPSConsoleAccounts} element={<Accounts />} />
          <Route path={Path.OPSConsoleAccount} element={<AccountDetails />} />
          <Route path={Path.OPSConsoleOrgs} element={<Orgs />} />
          <Route path={Path.OPSConsoleOrgDetails} element={<OrgDetails />} />
          <Route path={Path.OPSConsoleStaff} element={<StaffPage />} />
          <Route path={Path.OPSConsoleStaffDetails} element={<StaffDetails />} />
          <Route path={Path.OPSConsoleUsers} element={<OPSConsoleUsers />} />
          <Route path={Path.OPSConsoleUserDetails} element={<UserDetails />} />
          <Route path={Path.OPSConsoleBilling} />
          <Route path={Path.OPSConsoleViewAsUser} element={<ViewAsUser />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route index element={<SignUp />} />
          <Route path={Path.Login} element={<Login />} />
          <Route path={Path.MicrosoftAuthRedirect} element={<MicrosoftAuthRedirect />} />
          <Route path={Path.GoogleAuthRedirect} element={<GoogleAuthRedirect />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={Path.Account} element={<Account />}>
            {/* <Route index element={<Profile />} />
            <Route path={Path.MyLogin} element={<MyLogin />} />
            <Route path={Path.MyRole} element={<MyRole />} /> */}
          </Route>
          <Route element={<AdminRoute />}>
            <Route path={Path.Billing} element={<Billing />} />
            <Route path={Path.EditBookingTemplate} element={<EditBookingTemplate />} />
            <Route path={Path.EditWorkspace} element={<EditWorkspace />} />
            <Route path={Path.BookingTemplates} element={<BookingTemplates />} />
            <Route path={Path.Roles} element={<Roles />} />
            <Route path={Path.Users} element={<Users />} />
            <Route path={Path.Workspaces} element={<Workspaces />} />
            <Route path={Path.Teams} element={<Teams />} />
          </Route>
          <Route path={Path.Availability} element={<Availability />} />
          <Route path={Path.BookingPages} element={<BookingPages />} />
          <Route path={Path.CalendarConnections} element={<CalendarConnections />} />
          <Route path={Path.ConnectIntegration} element={<ConnectIntegrationPage />} />
          <Route path={Path.EditBookingPage} element={<EditBookingPage />} />
          <Route path={Path.EditGroupBookingPage} element={<EditGroupBookingPage />} />
          <Route path={Path.Integration} element={<Integration />} />
          <Route path={Path.BookedMeetings} element={<BookedMeetings />} />
          <Route path={Path.QuickSetup} element={<QuickSetup />} />
          <Route path={Path.BillingTests} element={<ChargebeePoC />} />
          <Route path={Path.Tests} element={<Tests />} />
          <Route path={Path.LicenseExpired} element={<LicenseExpired />} />
          <Route path={Path.SmartAlerts} element={<SmartAlerts />} />
          <Route path={Path.SmartAlert} element={<EditSmartAlert />} />
          <Route path={Path.Locations} element={<Locations />} />
          <Route path={Path.LocationsImport} element={<LocationsImport />} />
          <Route path={Path.EditLocation} element={<EditLocation />} />
          <Route path={Path.Journeys} element={<Journeys />} />
          <Route path={Path.EditJourney} element={<EditJourney />} />
        </Route>
        <Route path={Path.UnknownPath} element={<GlobalError />} />
        <Route path={Path.PublicBookingPage} element={<PublicBookingPage />} />
        <Route path={Path.PublicGroupBookingPage} element={<PublicBookingPage />} />
        <Route path={Path.PublicJourneyPage} element={<PublicBookingPage />} />
        <Route path={Path.Event} element={<PublicBookingPage />} />
      </Routes>
      {Boolean(toastNotificationsQueue.length) && <ToastNotification />}
    </>
  );
}
