export const OPSConsoleAccountDetails = Object.freeze({
  ACCOUNT_NAME: `Account Name`,
  ACCOUNT_NOTE: `Account Note`,
  CREATED_AT: `Created At`,
  CREATED_BY: `Created By`,
  DUPLICATE_NAME_MESSAGE: `Account with this name already exists`,
  TENANT_COLUMN_ADOPTION: `% Adoption`,
  TENANT_COLUMN_ID: `Org Id`,
  TENANT_COLUMN_OWNED: `# of Licenses`,
  TENANT_COLUMN_TERM: `Term`,
  TENANT_COLUMN_TYPE: `Type`,
  TENANTS: `Orgs`,
});

export const OPSConsoleAccounts = Object.freeze({
  BUTTON_CREATE_ACCOUNT: `Create Account`,
  BUTTON_EXPORT: `Export`,
  BUTTON_MERGE: `Merge`,
  COLUMN_ACCOUNT_NAME: `Account Name`,
  COLUMN_CREATED_BY: `Created By`,
  COLUMN_CREATED_DATE: `Created Date`,
  DELETE_ACCOUNT_ERROR_TOAST: `Could not delete account`,
  DELETE_ACCOUNT_SUCCESS_TOAST: `Account deleted.`,
  SAVE_ACCOUNT_ERROR_TOAST: `Could not save account`,
  SAVE_ACCOUNT_SUCCESS_TOAST: `Account saved.`,
  SEARCH_PLACEHOLDER: `Search by account name or domain name`,
  TITLE: `Accounts`,
  GET_ACCOUNT_ERROR_TOAST: `Get account request fail`,
  GET_ACCOUNTS_ERROR_TOAST: `Get accounts request fail`,
});

export const OPSConsoleAccountsDeleteModal = Object.freeze({
  DESCRIPTION: `Deleting account can not be undone.`,
  TITLE: `Delete Account`,
});

export const OPSConsoleAccountsMergeModal = Object.freeze({
  MERGE_TITLE_PART_1: `Merge`,
  MERGE_TITLE_PART_2: `with:`,
  NOTE: `Merging two accounts can not be undone.`,
  RULE_1: `Made for merging @gmail.com type accounts under a Brand.`,
  RULE_2: `Merging two primary domains is not allowed.`,
  RULES: `Rules`,
  SEARCH_PLACEHOLDER: `Search account name`,
  TITLE: `Merge Accounts`,
});
