
import { createSelector } from 'reselect';
import { State } from '../rootStore';

const errorState = (state: State) => state.error;

const selectErrorCode = createSelector(errorState, (error) => error.code);
const selectErrorLocation = createSelector(errorState, (error) => error.location);
const selectHasError = createSelector(selectErrorCode, (code) => Boolean(code));

export const errorSelectors = {
    selectErrorCode,
    selectErrorLocation,
    selectHasError,
};
