import { t } from '../../../i18n/i18n';

export default {
  steps: [
    { title: t('StartEasyBooking:STEP1_TITLE'), desc: t('StartEasyBooking:STEP1_DESCRIPTION') },
    { title: t('StartEasyBooking:STEP2_TITLE'), desc: t('StartEasyBooking:STEP2_DESCRIPTION') },
    { title: t('StartEasyBooking:STEP3_TITLE'), desc: t('StartEasyBooking:STEP3_DESCRIPTION') },
  ],
  title: t('StartEasyBooking:TITLE'),
};
