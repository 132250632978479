import { t } from '../../../../i18n/i18n';

export default {
  unassigned: t('EditBookingPageWhoStep:UNASSIGNED'),
  title: t('EditBookingTemplateWhereStep:TITLE'),
  skipWhereStep: t('EditBookingTemplateWhereStep:SKIP_WHERE_STEP'),
  skipWhereStepDescription: t('EditBookingTemplateWhereStep:SKIP_WHERE_STEP_DESCRIPTION'),
  phoneCall: t('EditBookingTemplateWhereStep:PHONE_CALL'),
  hostsPhone: t('EditBookingTemplateWhereStep:HOSTS_PHONE'),
  hostsPhoneDescription: t('EditBookingTemplateWhereStep:HOSTS_PHONE_DESCRIPTION'),
  inviteePhone: t('EditBookingTemplateWhereStep:INVITEE_PHONE'),
  inviteePhoneDescription: t('EditBookingTemplateWhereStep:INVITEE_PHONE_DESCRIPTION'),
  customPhone: t('EditBookingTemplateWhereStep:CUSTOM_PHONE'),
  customPhonePlaceholder: t('EditBookingTemplateWhereStep:CUSTOM_PHONE_PLACEHOLDER'),
  customPhoneDescription: t('EditBookingTemplateWhereStep:CUSTOM_PHONE_DESCRIPTION'),
  inPerson: t('EditBookingTemplateWhereStep:IN_PERSON'),
  customAddress: t('EditBookingTemplateWhereStep:CUSTOM_ADDRESS'),
  customAddressPlaceholder: t('EditBookingTemplateWhereStep:CUSTOM_ADDRESS_PLACEHOLDER'),
  customAddressDescription: t('EditBookingTemplateWhereStep:CUSTOM_ADDRESS_DESCRIPTION'),
  location: t('EditBookingTemplateWhereStep:LOCATION'),
  locationDescription: t('EditBookingTemplateWhereStep:LOCATION_DESCRIPTION'),
  inviteeLocation: t('EditBookingTemplateWhereStep:INVITEE_LOCATION'),
  inviteeLocationDescription: t('EditBookingTemplateWhereStep:INVITEE_LOCATION_DESCRIPTION'),
  defaultLocation: t('EditBookingTemplateWhereStep:DEFAULT_LOCATION'),
  videoConference: t('EditBookingTemplateWhereStep:VIDEO_CONFERENCE'),
  organizationLocationTitle: t('EditBookingTemplateWhereStep:ORGANIZATION_LOCATIONS_TITLE'),
  organizationLocationDesc: t('EditBookingTemplateWhereStep:ORGANIZATION_LOCATIONS_DESCRIPTION'),
};
