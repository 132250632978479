import { CustomType } from '../../../../API';
import labels from './labels';

export const CUSTOM_FIELDS_OPTIONS = [
  {
    value: CustomType.TEXT,
    label: labels.typeText,
  },
  {
    value: CustomType.TEXTAREA,
    label: labels.typeTextarea,
  },
  {
    value: CustomType.RADIO_BUTTONS,
    label: labels.typeRadioButtons,
  },
  {
    value: CustomType.CHECKBOXES,
    label: labels.typeCheckboxes,
  },
  {
    value: CustomType.SELECT,
    label: labels.typeSelect,
  },
  {
    value: CustomType.PHONE,
    label: labels.typeTel,
  },
];

export const DEFAULT_ANSWERS: string[] = ['', '', ''];

export const MULTI_ANSWERS_TYPES: CustomType[] = [CustomType.CHECKBOXES, CustomType.RADIO_BUTTONS, CustomType.SELECT];
