export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Vous ne pouvez connecter qu'un seul calendrier`, // Pour augmenter cette limite, contactez les ventes pour mettre à jour votre niveau de licence.
  DESCRIPTION: `Connectez votre calendrier afin que SUMO sache quand vous êtes disponible et mette à jour votre calendrier à mesure que les rendez-vous sont réservés.`,
  DISCONNECT_MESSAGE: `Êtes-vous sûr de vouloir déconnecter votre calendrier ?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Les rendez-vous ne seront ni supprimés ni annulés.`,
  DISCONNECT_TITLE: `Déconnecter le Calendrier`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 ou 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendrier Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendrier Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com ou Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Calendrier Office 365`,
  SELECT_CALENDAR_TITLE: `Sélectionner le Calendrier`,
  TITLE: `Connexions de Calendrier`,
  TOOLTIP: `Connectez votre calendrier Google ou Microsoft à SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Accepter et Fermer`,
  DESCRIPTION: `Lorsque vous visitez un site web, il a la capacité de stocker ou de récupérer certaines informations sur votre navigateur. Ces informations, souvent sous forme de cookies, peuvent concerner vos préférences, votre appareil ou d'autres détails. Leur principal objectif est de s'assurer que le site web fonctionne comme vous vous y attendez. Bien que ces informations ne vous identifient pas directement, elles peuvent améliorer votre expérience de navigation web en personnalisant le contenu. Nous comprenons et valorisons votre droit à la vie privée, c'est pourquoi nous vous offrons la possibilité de contrôler et de personnaliser vos préférences de cookies. En cliquant sur les différents en-têtes de catégorie, vous pouvez en savoir plus sur chaque type de cookie et modifier nos paramètres par défaut. Veuillez noter que le blocage de certains types de cookies peut affecter votre expérience générale sur le site et limiter les services que nous pouvons vous offrir.`,
  DESCRIPTION_FIRST_PART: `Lorsque vous visitez un site web, il a la capacité de stocker ou de récupérer certaines informations sur votre navigateur. Ces informations, souvent sous forme de cookies, peuvent concerner vos préférences, votre appareil ou d'autres détails. Leur principal objectif est de s'assurer que le site web fonctionne comme vous vous y attendez.`,
  DESCRIPTION_SECOND_PART: `Bien que ces informations ne vous identifient pas directement, elles peuvent améliorer votre expérience de navigation web en personnalisant le contenu. Nous comprenons et valorisons votre droit à la vie privée, c'est pourquoi nous vous offrons la possibilité de contrôler et de personnaliser vos préférences de cookies.`,
  DESCRIPTION_THIRD_PART: `En cliquant sur les différents en-têtes de catégorie, vous pouvez en savoir plus sur chaque type de cookie et modifier nos paramètres par défaut. Veuillez noter que le blocage de certains types de cookies peut affecter votre expérience générale sur le site et limiter les services que nous pouvons vous offrir.`,
  FUNCTIONAL_NAME: `Cookies Fonctionnels`,
  MANAGE_TITLE: `Gérer les Préférences de Consentement`,
  NECESSARY_ONLY: `Strictement Nécessaires Seulement`,
  PERFORMANCE_NAME: `Cookies de Performance`,
  STRICTLY_NAME: `Cookies Strictement Nécessaires`,
  TARGETING_NAME: `Cookies de Ciblage`,
  TITLE: `Paramètres des Cookies`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Compte Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Changer de Comptes Google`,
  GOOGLE_SWITCH: `Connexion avec Google`,
  GOOGLE_SWITCH_DESC: `Changer de l'authentification Microsoft à Google`,
  MICROSOFT_ACCOUNT: `Compte Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Changer de Comptes Microsoft`,
  MICROSOFT_SWITCH: `Connexion avec Microsoft`,
  MICROSOFT_SWITCH_DESC: `Changer de l'authentification Google à Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Votre compte SUMO1 perdra l'accès aux données disponibles uniquement via votre fournisseur d'origine. Vous devrez peut-être aussi redonner accès aux intégrations et calendriers.`,
  SWITCH_DESCRIPTION_PART1: `Changer de `,
  SWITCH_DESCRIPTION_PART2: ` authentification à `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Votre rôle actuel`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Format de Date`,
  LANGUAGE: `Langue`,
  NAME: `Nom`,
  WELCOME_MESSAGE: `Message de Bienvenue`,
  PHONE_NUMBER: `Numéro de Téléphone`,
  PROFILE_IMAGE: `Image de Profil`,
  PHONE_DETAILS: `Détails du Téléphone`,
  PHONE_DETAILS_PLACEHOLDER: `Ajoutez des informations supplémentaires ou des instructions ici.\r\nEx: J'ai hâte de vous rencontrer. Envoyez-moi un SMS à tout moment.`,
  TIME_FORMAT: `Format de l'Heure`,
  TIME_ZONE: `Fuseau Horaire`,
  COUNTRY: `Pays`,
});

export const Profile = Object.freeze({
  TITLE: `Profil`,
  ACCOUNT_SETTINGS: `Paramètres du Compte`,
  ACCOUNT_DESCRIPTION: `Informations de base et paramètres`,
  LOGIN: `Connexion`,
  LOGIN_DESCRIPTION: `Configurer le compte connecté`,
  MY_ROLE: `Mon Rôle`,
  MY_ROLE_DESCRIPTION: `Votre rôle et vos permissions actuels`,
  COOKIE_SETTINGS: `Paramètres des Cookies`,
  COOKIE_DESCRIPTION: `Cookies Nécessaires, de Performance, Fonctionnels, de Ciblage`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `Échec de la demande de nouveau calendrier`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `Nouveau calendrier connecté avec succès !`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE: `Échec de la demande de connexion de Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `Zoom connecté avec succès !`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Échec de la demande d'enregistrement des paramètres`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `Vos paramètres ont été enregistrés avec succès !`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `Vous avez changé de compte avec succès`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Échec du changement de compte. Veuillez réessayer plus tard`,
});
