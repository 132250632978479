import { Button } from 'primereact/button';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import { navigationService } from '../../../services/NavigationService';
import { Path } from '../../../routing';
import { journeysActions, journeysSelectors } from '../../../store/journeyBuilder';
import { ConfirmationModal } from '../../common';
import { useState } from 'react';
import { BookingPageLabels, BookingPageStyle, UpdateBookingPageInput } from '../../../API';
import { BookingPagePreviewModal } from '../../bookingPages';

export const EditJourneyButtonBar = () => {
  const dispatch = useDispatch();
  const isEdited = useSelector(journeysSelectors.selectIsEdited);
  const isValid = useSelector(journeysSelectors.selectIsJourneyNameValid);
  const isReadOnly = useSelector(journeysSelectors.selectJourneyReadOnly);
  const isFetching = useSelector(journeysSelectors.selectIsFetching);
  const journey = useSelector(journeysSelectors.selectJourney);
  const previewBookingPage = useSelector(journeysSelectors.selectBookingPageForPreview);

  const [brandingOpened, setBrandingOpened] = useState(false);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const handleBack = () => {
    navigationService.navigateTo(Path.Journeys);
  };

  const handleSave = () => {
    dispatch(journeysActions.upsertJourneyRequest());
  };

  const handleCancel = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleBack();
    }
  };

  const handleSaveBranding = (bookingPage?: UpdateBookingPageInput) => {
    if (bookingPage) {
      dispatch(
        journeysActions.updateJourney({
          style: bookingPage.style as BookingPageStyle,
          labels: bookingPage.labels as BookingPageLabels,
        })
      );
    }
    setBrandingOpened(false);
  };

  return (
    <>
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        onConfirm={handleBack}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      {brandingOpened && (
        <BookingPagePreviewModal
          visible
          journey={journey}
          bookingPage={previewBookingPage}
          onSave={handleSaveBranding}
          onClose={() => setBrandingOpened(false)}
        />
      )}

      <div className="flex-left-center gap-24px py-20px pl-20px pr-108px">
        <Button
          className="button-xl flex-center"
          style={{ minWidth: '120px' }}
          onClick={handleSave}
          disabled={!isValid || isReadOnly || isFetching}
        >
          {labels.save}
        </Button>
        <Button className="button-xl" text onClick={handleCancel}>
          {isEdited ? labels.cancel : labels.back}
        </Button>
        <Button
          className="button-xl ml-auto"
          outlined
          label={labels.previewJourney}
          onClick={() => setBrandingOpened(true)}
        />
      </div>
    </>
  );
};
