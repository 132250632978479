export const GroupBookingPage = Object.freeze({
  GROUP_TITLE: `Groupe`,
  GROUPS_TITLE: `Groupes`,
  GROUP_PAGE: `Page de groupe`,
  SELECT_MEETING_TYPE: `Sélectionnez un type de réunion`,
});

export const GroupBookingPagesToastsNotifications = Object.freeze({
  CLONE_GROUP_PAGES_ERROR_MESSAGE: `Échec de la demande de clonage de la page de réservation de groupe`,
  CLONE_GROUP_PAGES_SUCCESS_MESSAGE: `Page de réservation de groupe clonée avec succès`,
  DELETE_GROUP_PAGES_ERROR_MESSAGE: `Échec de la demande de suppression de la page de réservation de groupe`,
  DELETE_GROUP_PAGES_SUCCESS_MESSAGE: `Page de réservation de groupe supprimée avec succès`,
  GET_GROUP_PAGE_ERROR_MESSAGE: `Impossible de récupérer la page de réservation de groupe, elle pourrait être supprimée`,
  GET_GROUP_PAGES_ERROR_MESSAGE: `Échec de la demande de récupération des pages de réservation de groupe`,
  SAVE_GROUP_PAGE_ERROR_MESSAGE: `Échec de la demande d'enregistrement de la page de réservation de groupe`,
  SAVE_GROUP_PAGE_SUCCESS_MESSAGE: `Page de réservation de groupe enregistrée avec succès`,
  ACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Page de réservation de groupe activée avec succès`,
  DEACTIVATE_GROUP_PAGE_SUCCESS_MESSAGE: `Page de réservation de groupe désactivée avec succès`,
});

export const EditGroupBookingPage = Object.freeze({
  ADD_DESCRIPTION: `Ajouter une description`,
  ADMIN_ONLY_TITLE: `Administrateur uniquement`,
  BASIC_SETTING_INFO_MESSAGE: `Soyez conscient que cette page de réservation de groupe est basée sur les pages de réservation sélectionnées et que toute modification apportée à ces pages de réservation sources sera reflétée dans ce lien de réservation de groupe.`,
  BASIC_SETTINGS_DESCRIPTION: `Pages de réservation et nom de groupe`,
  BASIC_SETTINGS_TITLE: `Paramètres de base`,
  BOOKING_PAGES_DESCRIPTION: `Vous pouvez choisir des pages de réservation`,
  BOOKING_PAGES_TITLE: `Pages de réservation`,
  BRANDING_DESCRIPTION: `Description, styles de marque et affichages`,
  BRANDING_TITLE: `À quoi cela devrait-il ressembler?`,
  BRANDING_WARNING: `Avertissement : Vous avez des styles de marque différents pour les pages de réservation.`,
  CLONE_TITLE: `Nom de la page de groupe clonée`,
  DEACTIVATE_DESCRIPTION: `Êtes-vous sûr de vouloir désactiver `,
  DEACTIVATE_TITLE: `Désactiver la page de réservation de groupe`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer `,
  DELETE_TITLE: `Supprimer la page de réservation de groupe`,
  MEETING_DESCRIPTION_TITLE: `Description de la réunion`,
  NAME_PLACEHOLDER: `Nom personnalisé`,
  NAME_TITLE: `Afficher le nom du groupe`,
  USE_BRANDING_TITLE: `Utiliser les paramètres de marque de :`,
  VIEW_GROUP_PAGE: `Voir la page de groupe`,
  MEETING_DESCRIPTION_TOOLTIP: `Le texte de description de la réunion est affiché uniquement sur la page de réservation publique du groupe, pour l'invité.`,
});
