import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { useMountEffect } from 'primereact/hooks';
import { CSSProperties, ReactNode, useRef, useState } from 'react';
import { Preloader } from '../preloader/Preloader';
import { Button } from 'primereact/button';
import { ArrowUturnLeftIcon, ArrowUturnRightIcon } from '@heroicons/react/24/outline';

type TextEditorProps = {
  value: string;
  setValue: (newValue: string) => void;
  className?: string;
  style?: CSSProperties;
  header?: ReactNode;
  showUndoRedo?: boolean;
  useTextValue?: boolean;
  setEditorRef?: (editor: Editor | null) => void;
  isReadOnly?: boolean;
};

export const TextEditor = ({
  value,
  setValue,
  className,
  style,
  header,
  showUndoRedo,
  useTextValue,
  setEditorRef,
  isReadOnly,
}: TextEditorProps) => {
  const [hidden, setHidden] = useState(true);
  const textEditor = useRef<Editor>(null);

  useMountEffect(() => {
    setHidden(true);
    setEditorRef && setEditorRef(textEditor.current);
  });

  const headerTemplate = () => {
    return (
      <>
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
          <button className="ql-strike" aria-label="Strike"></button>
        </span>

        <span className="ql-formats">
          <button className="ql-list" value="ordered" type="button"></button>
          <button className="ql-list" value="bullet" type="button"></button>
        </span>

        {/* <span className="ql-formats">
          <button className="ql-align" value="" type="button"></button>
          <button className="ql-align" value="right" type="button"></button>
          <button className="ql-align" value="center" type="button"></button>
          <button className="ql-align" value="justify" type="button"></button>
        </span>

        <span className="ql-formats">
          <button className="ql-clean" aria-label="Remove Styles" type="button"></button>
        </span> */}
      </>
    );
  };

  const undoRedoButtons = (
    <span>
      <Button
        className="flex-center gap-4px text-heavy-60 hover:text-heavy-80 text-label-xs-reg w-fit"
        text
        onClick={() => textEditor.current && textEditor.current.getQuill().history.undo()}
        disabled={!textEditor.current || !textEditor.current.getQuill()?.history?.stack?.undo?.length}
      >
        <ArrowUturnLeftIcon className="icon-16px" />
      </Button>

      <Button
        className="flex-center gap-4px text-heavy-60 hover:text-heavy-80 text-label-xs-reg w-fit"
        text
        onClick={() => textEditor.current && textEditor.current.getQuill().history.redo()}
        disabled={!textEditor.current || !textEditor.current.getQuill()?.history?.stack?.redo?.length}
      >
        <ArrowUturnRightIcon className="icon-16px" />
      </Button>
    </span>
  );

  const editorHeader = (
    <>
      {showUndoRedo && undoRedoButtons} {header || headerTemplate()}
    </>
  );

  const handleTextChange = (e: EditorTextChangeEvent) => {
    if (useTextValue) {
      setValue(e.textValue.replaceAll('<', '&lt').replaceAll('>', '&gt'));
    } else {
      setValue(e.htmlValue?.replaceAll('&lt;', '&lt').replaceAll('&gt;', '&gt') || '');
    }
  };

  return (
    <>
      {hidden && (
        <>
          <Preloader />
          <div className={className} style={style}></div>
        </>
      )}
      <Editor
        className={className}
        style={style}
        headerTemplate={editorHeader}
        hidden={hidden}
        onLoad={() => setHidden(false)}
        value={value}
        onTextChange={handleTextChange}
        ref={textEditor}
        readOnly={isReadOnly}
      />
    </>
  );
};
