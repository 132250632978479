import { ErrorCodes } from "./types";

export enum ErrorActionTypes {
    SET_THE_ERROR = 'error/SET_THE_ERROR',
    RESET_THE_ERROR = 'error/RESET_THE_ERROR',
  }

export type ErrorAction =
  | { type: ErrorActionTypes.SET_THE_ERROR, code: ErrorCodes }
  | { type: ErrorActionTypes.RESET_THE_ERROR };

const setTheError = (code: ErrorCodes): ErrorAction => ({
  type: ErrorActionTypes.SET_THE_ERROR,
  code
});

const resetTheError = (): ErrorAction => ({
  type: ErrorActionTypes.RESET_THE_ERROR
});

export const errorActions = {
  setTheError,
  resetTheError
};
